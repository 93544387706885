import { combineReducers } from 'redux'
import actionTypes from './actionTypes'
import newCallOffActionTypes from '../newCallOff/actionTypes'

const orderReducer = (state = null, action) => {
	switch (action.type) {
		case newCallOffActionTypes.FINISH_SUCCESS:
		case actionTypes.FETCH_ORDER: {
			return null
		}

		case actionTypes.FETCH_ORDER_SUCCESS: {
			return action.payload.order
		}

		case newCallOffActionTypes.SAVE_CONCEPT_SUCCESS: {
			return {
				...state,
				concept: action.payload.concept,
				openCallOffOrdersCount:
					state.concept === null
						? state.openCallOffOrdersCount + 1
						: state.openCallOffOrdersCount
			}
		}

		case actionTypes.FETCH_CALLOFFS_SUCCESS: {
			return action.payload.orderWithCallOffs
		}

		case actionTypes.FETCH_DELIVERIES_SUCCESS: {
			return action.payload.orderWithDeliveries
		}

		case actionTypes.UPDATE_DELIVERY_WINDOWS: {
			return {
				...state,
				deliveryWindows: action.payload.deliveryWindows
			}
		}

		default: {
			return state
		}
	}
}

const initialFilterState = { searchTerm: '' }
const filterReducer = (state = initialFilterState, action) => {
	switch (action.type) {
		case actionTypes.FILTER_CALLOFFS: {
			return {
				...state,
				searchTerm: action.meta.searchTerm
			}
		}

		default: {
			return state
		}
	}
}

const callOffReducer = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.FETCH_CALLOFF:
		case actionTypes.DELETE_CALLOFF_SUCCESS: {
			return null
		}

		case actionTypes.FETCH_CALLOFF_SUCCESS: {
			return action.payload.callOff
		}

		default: {
			return state
		}
	}
}

const initialMetaState = {
	isLoading: false,
	isLoaded: false,
	isLoadingCallOffs: false,
	isLoadingCallOff: false,
	isLoadingDeliveries: false,
	isRefreshingCallOff: false,
	fetchOrderError: false,
	deleteCallOffError: false
}
const metaReducer = (state = initialMetaState, action) => {
	switch (action.type) {
		case newCallOffActionTypes.FINISH_SUCCESS:
		case actionTypes.FETCH_ORDER:
		case actionTypes.DELETE_CALLOFF: {
			return initialMetaState
		}

		case actionTypes.FETCH_ORDER_REQUEST: {
			return {
				...state,
				isLoading: true
			}
		}

		case actionTypes.FETCH_ORDER_SUCCESS: {
			return {
				...state,
				isLoading: false,
				isLoaded: true
			}
		}

		case actionTypes.FETCH_ORDER_FAILURE: {
			return {
				...state,
				isLoading: false,
				fetchOrderError: true,
				errorMessages: action.payload.errorMessages
			}
		}

		case actionTypes.FETCH_DELIVERIES_REQUEST: {
			return {
				...state,
				isLoadingDeliveries: true
			}
		}

		case actionTypes.FETCH_DELIVERIES_SUCCESS:
		case actionTypes.FETCH_DELIVERIES_FAILURE: {
			return {
				...state,
				isLoadingDeliveries: false
			}
		}

		case actionTypes.FETCH_CALLOFFS_REQUEST: {
			return {
				...state,
				isLoadingCallOffs: true
			}
		}

		case actionTypes.FETCH_CALLOFFS_SUCCESS:
		case actionTypes.FETCH_CALLOFFS_FAILURE: {
			return {
				...state,
				isLoadingCallOffs: false
			}
		}

		case actionTypes.FETCH_CALLOFF_REQUEST: {
			return {
				...state,
				isLoadingCallOff: true
			}
		}

		case actionTypes.FETCH_CALLOFF_SUCCESS:
		case actionTypes.FETCH_CALLOFF_FAILURE: {
			return {
				...state,
				isLoadingCallOff: false,
				isRefreshingCallOff: false
			}
		}

		case actionTypes.REFRESH_CALLOFF: {
			return {
				...state,
				isRefreshingCallOff: true
			}
		}

		case actionTypes.DELETE_CALLOFF_FAILURE: {
			return {
				...state,
				deleteCallOffError: true,
				errorMessages: action.payload.errorMessages
			}
		}

		default: {
			return state
		}
	}
}

export default combineReducers({
	order: orderReducer,
	meta: metaReducer,
	callOff: callOffReducer,
	filter: filterReducer
})
