import { createSelector } from 'reselect'

const filterSelector = (state) => state.filter
const callOffsSelector = (state) => state.order.callOffOrders

const filteredCallOffsSelector = createSelector(
	callOffsSelector,
	filterSelector,
	(callOffs, filter) => {
		let { searchTerm } = filter
		searchTerm = searchTerm.toLowerCase()
		if (!searchTerm) {
			return callOffs
		}
		return callOffs.filter((callOff) => {
			if (callOff.orderNumber.toLowerCase().includes(searchTerm)) {
				return true
			}
			if (
				callOff.createdBy &&
				callOff.createdBy.firstName &&
				callOff.createdBy.firstName.toLowerCase().includes(searchTerm)
			) {
				return true
			}
			if (
				callOff.createdBy &&
				callOff.createdBy.lastName &&
				callOff.createdBy.lastName.toLowerCase().includes(searchTerm)
			) {
				return true
			}
			return false
		})
	}
)

// Make sure to only export selectors which are created with 'createSelector'

const selectors = {
	filteredCallOffsSelector
}
export default selectors
