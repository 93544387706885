import { Alert, Grid, Paper, Typography } from '@mui/material'
import { isSameDay } from 'date-fns'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

const sx = {
	marginBottom: { marginBottom: 1 },
	paper: {
		borderRadius: '4px',
		padding: 3,
		paddingBottom: 1
	}
}
class SummaryAlerts extends PureComponent {
	static propTypes = {
		freights: PropTypes.array.isRequired
	}

	render() {
		const { freights } = this.props

		const hasSameStreet = freights.every(
			(freight) => freight.street === freights[0].street
		)
		const hasSameCity = freights.every(
			(freight) => freight.city === freights[0].city
		)
		const hasSameZipCode = freights.every(
			(freight) => freight.zipCode === freights[0].zipCode
		)

		const hasSameAddress = hasSameCity && hasSameStreet && hasSameZipCode

		const hasSameDeliveryDate = freights.every((freight) =>
			isSameDay(
				new Date(freights[0].deliveryDate),
				new Date(freight.deliveryDate)
			)
		)

		let differentAddressAlert
		let sameDateAlert
		let summaryAlerts

		//if there are multiple freight then compare addresses and delivery date
		if (freights.length > 1) {
			if (!hasSameAddress) {
				differentAddressAlert = (
					<Alert sx={sx.marginBottom} severity="warning">
						{t('app:Materials.SummaryAlert.differentAddress')}
					</Alert>
				)
			}
			if (hasSameDeliveryDate) {
				sameDateAlert = (
					<Alert sx={sx.marginBottom} severity="warning">
						{t('app:Materials.SummaryAlert.sameDeliveryDate')}
					</Alert>
				)
			}
			if (!hasSameAddress || hasSameDeliveryDate) {
				summaryAlerts = (
					<Grid item>
						<Paper sx={sx.paper}>
							<Typography variant="h5" sx={sx.marginBottom}>
								{t('app:Materials.SummaryAlert.attention')}
							</Typography>
							{sameDateAlert}
							{differentAddressAlert}
						</Paper>
					</Grid>
				)
			}
		}

		return summaryAlerts
	}
}

export default SummaryAlerts
