import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import orderActionCreators from '../../../redux/order/actionCreators'
import Deliveries from './Deliveries'

class DeliveriesContainer extends PureComponent {
	static propTypes = {
		fetchDeliveries: PropTypes.func.isRequired,
		isLoadingDeliveries: PropTypes.bool.isRequired,
		order: PropTypes.object,
		selectedOrderId: PropTypes.string.isRequired
	}

	state = {
		isLoading: true
	}

	// Set initial loading true to prevent loading with an older orderState.
	static getDerivedStateFromProps(props, state) {
		if (state.isLoading && props.isLoadingDeliveries) {
			return { isLoading: false }
		}
		return null
	}

	componentDidMount() {
		const { fetchDeliveries, selectedOrderId } = this.props
		fetchDeliveries(selectedOrderId)
	}

	render() {
		const { order, isLoadingDeliveries } = this.props
		const { isLoading } = this.state

		return (
			<Deliveries
				order={order}
				isLoadingDeliveries={isLoading || isLoadingDeliveries}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		order: state.orderState.order,
		isLoadingDeliveries: state.orderState.meta.isLoadingDeliveries
	}),
	{
		fetchDeliveries: orderActionCreators.fetchDeliveries
	}
)

export default connector(DeliveriesContainer)
