import moment from 'moment-timezone'
import { t } from 'i18next'
import { STATUS_CLOSED } from '../constants'

const getTrackAndTraceTextForDelivery = ({ status, delivery }) => {
	const { deliveredDate, deliveryDate, plannedDate, expectedDeliveryDate } =
		delivery

	if (status === STATUS_CLOSED && deliveredDate) {
		const date = moment(deliveredDate)
			.tz('Europe/Amsterdam')
			.format('dddd D MMMM Y')
		return t('app:CallOffs.actualDeliveryDate', { deliveryDate: date })
	}

	if (status === STATUS_CLOSED && plannedDate) {
		const date = moment(plannedDate)
			.tz('Europe/Amsterdam')
			.format('dddd D MMMM Y')
		return t('app:CallOffs.actualDeliveryDate', { deliveryDate: date })
	}

	if (status === STATUS_CLOSED && deliveryDate) {
		const date = moment(deliveryDate)
			.tz('Europe/Amsterdam')
			.format('dddd D MMMM Y')
		return t('app:CallOffs.actualDeliveryDate', { deliveryDate: date })
	}

	if (expectedDeliveryDate && expectedDeliveryDate.from) {
		const date = moment(expectedDeliveryDate.from)
			.tz('Europe/Amsterdam')
			.format('dddd D MMMM Y')
		const timeFrom = moment(expectedDeliveryDate.from)
			.tz('Europe/Amsterdam')
			.format('HH:mm')
		const timeTill = moment(expectedDeliveryDate.till)
			.tz('Europe/Amsterdam')
			.format('HH:mm')
		if (timeFrom !== '00:00') {
			return t('app:CallOffs.expectedDeliveryDateAndTime', {
				deliveryDate: date,
				deliveryTimeFrom: timeFrom,
				deliveryTimeTill: timeTill
			})
		}
		return t('app:CallOffs.expectedDeliveryDate', { deliveryDate: date })
	}

	if (deliveryDate) {
		const date = moment(deliveryDate)
			.tz('Europe/Amsterdam')
			.format('dddd D MMMM Y')
		return t('app:CallOffs.expectedDeliveryDate', { deliveryDate: date })
	}

	return null
}

const getTrackAndTraceTextForCallOff = ({ callOff, deliveryWindows }) => {
	// Only show date on closed call-offs without context
	if (callOff.callOffStatus === STATUS_CLOSED) {
		// Track and trace date is determined from the furthest delivery (highest status)
		const furthestDelivery = callOff.deliveries.reduce(
			(currentFurthestDelivery, delivery) => {
				if (
					!currentFurthestDelivery ||
					currentFurthestDelivery.status > delivery.status
				) {
					return delivery
				}
				return currentFurthestDelivery
			},
			null
		)

		const { deliveredDate, deliveryDate, plannedDate } = furthestDelivery
		return moment(deliveredDate || plannedDate || deliveryDate)
			.tz('Europe/Amsterdam')
			.format('dddd D MMMM Y')
	}

	// If status is not closed, show desired delivery date
	const deliveryDate = moment(callOff.deliveryDate)
		.tz('Europe/Amsterdam')
		.format('dddd D MMMM Y')
	if (callOff.deliveryWindow && callOff.deliveryWindow.key) {
		const deliveryWindow = deliveryWindows.options.find(
			({ key }) => key === callOff.deliveryWindow.key
		)
		const deliveryTimeFrom = moment(deliveryWindow.timeFrom, 'HH:mm:ss')
			.tz('Europe/Amsterdam')
			.format('HH:mm')
		const deliveryTimeTill = moment(deliveryWindow.timeTill, 'HH:mm:ss')
			.tz('Europe/Amsterdam')
			.format('HH:mm')

		return t('app:CallOffs.desiredDeliveryDateAndTime', {
			deliveryDate,
			deliveryTimeFrom,
			deliveryTimeTill
		})
	}

	return t('app:CallOffs.desiredDeliveryDate', { deliveryDate })
}

export { getTrackAndTraceTextForDelivery, getTrackAndTraceTextForCallOff }
