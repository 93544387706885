import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Orders from './Orders'

class OrdersContainer extends PureComponent {
	static propTypes = {
		orderId: PropTypes.string,
		expand: PropTypes.string,
		childId: PropTypes.string,
		childExpand: PropTypes.string
	}

	render() {
		const { orderId, expand, childId, childExpand } = this.props

		return (
			<Orders
				orderId={orderId}
				expand={expand}
				childId={childId}
				childExpand={childExpand}
			/>
		)
	}
}

const connector = connect((state, props) => ({
	orderId: props.match.params.orderId,
	expand: props.match.params.expand,
	childId: props.match.params.childId,
	childExpand: props.match.params.childExpand
}))

export default connector(OrdersContainer)
