import {
	all,
	call,
	fork,
	put,
	take,
	cancel,
	cancelled
} from 'redux-saga/effects'
import { t } from 'i18next'
import {
	clearTokens,
	clearRefreshToken,
	readAccessToken,
	readRefreshToken,
	storeTokens
} from '../../utils/auth'
import apiAuth from '../../services/apiAuth'
import apiUser from '../../services/apiUser'
import actionTypes from './actionTypes'
import actionCreators from './actionCreators'
import history from '../../utils/history'

function* twoFactorSaga(twoFactorToken) {
	try {
		// Can only submit two-factor when a refresh token is available
		const refreshToken = yield call(readRefreshToken)
		if (refreshToken) {
			// Refresh the tokens and store the new tokens
			yield put(actionCreators.twoFactorRequest())
			const result = yield call(
				apiAuth.refreshTokens,
				refreshToken,
				twoFactorToken
			)
			yield call(storeTokens, {
				accessToken: result.accessToken,
				refreshToken: result.refreshToken
			})
			// Fetch user details
			const userData = yield call(apiUser.get)
			yield put(actionCreators.loginSuccess(userData))
		} else {
			// No refresh token available, cannot submit two-factor
			yield put(actionCreators.twoFactorNoToken())
		}
	} catch (error) {
		const techMessage = error ? error.message : t('app:Error.unknown')
		const userMessage = t('app:Login.twoFactorFailure')
		yield put(
			actionCreators.twoFactorFailure({
				userMessage,
				techMessage
			})
		)
	} finally {
		if (yield cancelled()) {
			// The login task was cancelled. We cannot cancel the running HTTP request,
			// but the eventual result of the running request will be ignored.
			yield put(actionCreators.loginCancelled())
		}
	}
}

function* autoLoginSaga() {
	try {
		// Can only auto login when a refresh token is available
		const refreshToken = yield call(readRefreshToken)
		if (refreshToken) {
			// Refresh the tokens and store the new tokens
			yield put(actionCreators.autoLoginRequest())
			const result = yield call(apiAuth.refreshTokens, refreshToken)
			yield call(storeTokens, {
				accessToken: result.accessToken,
				refreshToken: result.refreshToken
			})
			if (result.requireTwoFactor) {
				// Two-factor required
				yield put(actionCreators.twoFactorRequired(result.maskedPhoneNumber))
			} else {
				// Fetch user details
				const userData = yield call(apiUser.get)
				yield put(actionCreators.loginSuccess(userData))
			}
		} else {
			// No refresh token available, cannot auto login
			yield put(actionCreators.autoLoginNoToken())
		}
	} catch (error) {
		const techMessage = error ? error.message : t('app:Error.unknown')
		const userMessage = t('app:Login.loginFailure')
		yield put(
			actionCreators.autoLoginFailure({
				userMessage,
				techMessage
			})
		)
	} finally {
		if (yield cancelled()) {
			// The login task was cancelled. We cannot cancel the running HTTP request,
			// but the eventual result of the running request will be ignored.
			yield put(actionCreators.loginCancelled())
		}
	}
}

function* loginSaga(credentials) {
	try {
		// Login with specified credentials and store the tokens
		const accessToken = yield call(readAccessToken)
		yield put(actionCreators.loginRequest())
		const result = yield call(apiAuth.login, credentials, accessToken)
		yield call(storeTokens, {
			accessToken: result.accessToken,
			refreshToken: result.refreshToken
		})
		if (result.requireTwoFactor) {
			// Two-factor required
			yield put(actionCreators.twoFactorRequired(result.maskedPhoneNumber))
		} else {
			// Fetch user details
			const userData = yield call(apiUser.get)
			yield put(actionCreators.loginSuccess(userData))
		}
	} catch (error) {
		const techMessage = error ? error.message : t('app:Error.unknown')
		const userMessage = t('app:Login.loginFailure')
		yield put(
			actionCreators.loginFailure({
				userMessage,
				techMessage
			})
		)
	} finally {
		if (yield cancelled()) {
			// The login task was cancelled. We cannot cancel the running HTTP request (not possible at time of writing https://github.com/whatwg/fetch/issues/447),
			// but the eventual result of the running request will be ignored due to this being a saga.
			yield put(actionCreators.loginCancelled())
		}
	}
}

function* authSaga() {
	let loginTask
	while (true) {
		const authAction = yield take([
			actionTypes.ANOTHER_USER,
			actionTypes.AUTOLOGIN,
			actionTypes.LOGIN,
			actionTypes.LOGIN_FAILURE,
			actionTypes.LOGOUT,
			actionTypes.TWOFACTOR_SUBMIT
		])
		if (loginTask && authAction.type === actionTypes.LOGOUT) {
			// Cancel login task when the user logs out before it completes
			yield cancel(loginTask)
			// Trigger navigation
			const location = history.location
			const destination = '/'
			if (location.pathname !== destination) {
				history.push(destination)
			}
		}
		if (
			authAction.type === actionTypes.LOGOUT ||
			authAction.type === actionTypes.LOGIN_FAILURE
		) {
			// Clear refresh token when user logs out, or if the login fails
			// The access token is kept to skip two-factor on the next login
			yield call(clearRefreshToken)
		}
		if (authAction.type === actionTypes.LOGIN) {
			// Login with credentials
			loginTask = yield fork(loginSaga, authAction.payload.credentials)
		}
		if (authAction.type === actionTypes.AUTOLOGIN) {
			// Login with stored refresh token
			loginTask = yield fork(autoLoginSaga)
		}
		if (authAction.type === actionTypes.TWOFACTOR_SUBMIT) {
			// Login with stored refresh token and two-factor token
			loginTask = yield fork(twoFactorSaga, authAction.payload.twoFactorToken)
		}
		if (authAction.type === actionTypes.ANOTHER_USER) {
			// Clear all tokens when the user wants to login with another user
			yield call(clearTokens)
		}
	}
}

export default function* rootSaga() {
	yield all([authSaga].map((saga) => fork(saga)))
}
