import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import history from '../../../utils/history'

class PreventNavigationDialog extends PureComponent {
	static propTypes = {
		open: PropTypes.bool.isRequired,
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		cancelButtonText: PropTypes.string.isRequired,
		cancelButtonColor: PropTypes.string,
		cancelButtonVariant: PropTypes.string,
		confirmButtonText: PropTypes.string.isRequired,
		confirmButtonColor: PropTypes.string,
		confirmButtonVariant: PropTypes.string
	}

	state = {
		nextLocation: null,
		openModal: false
	}

	static getDerivedStateFromProps(props, state) {
		const { open } = props
		const { nextLocation } = state
		if (open && nextLocation && nextLocation.pathname) {
			return { openModal: true }
		}
		return null
	}

	componentDidUpdate() {
		const { open } = this.props
		this.unblock = history.block((nextLocation) => {
			if (open) {
				this.setState({
					nextLocation
				})
			}
			if (nextLocation.pathname.endsWith('calloffs')) {
				return true
			}
			return !open
		})
	}

	onCancel = () => {
		this.setState({ nextLocation: null, openModal: false })
	}

	onConfirm = () => {
		this.navigateToNextLocation()
	}

	navigateToNextLocation() {
		const { nextLocation } = this.state
		this.unblock()
		history.push(nextLocation.pathname)
	}

	render() {
		const {
			title,
			description,
			cancelButtonText,
			cancelButtonColor = 'inherit',
			cancelButtonVariant = 'text',
			confirmButtonText,
			confirmButtonColor = 'primary',
			confirmButtonVariant = 'text'
		} = this.props
		const { openModal } = this.state

		return (
			<Dialog open={openModal} maxWidth="sm">
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{description}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant={cancelButtonVariant}
						color={cancelButtonColor}
						onClick={this.onCancel}
					>
						{cancelButtonText}
					</Button>
					<Button
						variant={confirmButtonVariant}
						color={confirmButtonColor}
						onClick={this.onConfirm}
					>
						{confirmButtonText}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default PreventNavigationDialog
