import { all, call, fork, put, select, take } from 'redux-saga/effects'
import { t } from 'i18next'
import history from '../../utils/history'
import apiNewCallOff from '../../services/apiNewCallOff'
import actionTypes from './actionTypes'
import sagaSelectors from './sagaSelectors'
import newCallOffActionCreators from './actionCreators'
import orderActionCreators from '../order/actionCreators'
import notificationsActionCreators from '../notifications/actionCreators'
import { deriveFreights, calculateTotalCosts } from '../../utils/freights'
import {
	getCorrectedFreights,
	getChangedFreights
} from '../../utils/validation'
import { NetworkError } from '../../utils/errors'

function* fetchAdresses() {
	while (true) {
		yield take(actionTypes.FETCH_ADDRESSES)
		const { newCallOffState } = yield select()
		const { freights, freightIndex } = newCallOffState
		const selectedFreight = freights[freightIndex]
		const { street, houseNumber, zipCode, city, country } = selectedFreight
		const addressQuery = { street, houseNumber, zipCode, city, country }

		yield put(newCallOffActionCreators.fetchAddressesRequest())
		try {
			const addresses = yield call(apiNewCallOff.getAddress, addressQuery)
			if (addresses.length === 1) {
				// If a single address is found, select it
				let newHouseNumber = ''
				if (houseNumber) {
					newHouseNumber = houseNumber
				} else if (addresses[0].houseNumberFrom) {
					newHouseNumber = addresses[0].houseNumberFrom.toString()
				} else if (addresses[0].houseNumberTill) {
					newHouseNumber = addresses[0].houseNumberTill.toString()
				}

				yield put(
					newCallOffActionCreators.updateFreight(freightIndex, {
						street: addresses[0].street,
						houseNumber: newHouseNumber,
						zipCode: addresses[0].zipCode,
						city: addresses[0].city,
						hasFerryCosts: addresses[0].hasFerryCosts,
						hasTunnelCosts: addresses[0].hasTunnelCosts
					})
				)
				// Resolve with an empty addresses list (to finish the task)
				yield put(newCallOffActionCreators.fetchAddressesSuccess([]))
			} else {
				if (addresses.length === 0) {
					yield put(
						notificationsActionCreators.addNotification(
							t('app:Api.Addresses.noAddressesFound')
						)
					)
				}
				if (addresses.length === 200) {
					yield put(
						notificationsActionCreators.addNotification(
							t('app:Api.Addresses.maximumAddressesFound')
						)
					)
				}
				yield put(newCallOffActionCreators.fetchAddressesSuccess(addresses))
			}
		} catch (error) {
			const techMessage = error ? error.message : t('app:Error.unknown')
			const userMessage = t('app:Api.Addresses.fetchAddresses')
			yield put(
				newCallOffActionCreators.fetchAddressesFailure({
					userMessage,
					techMessage
				})
			)
			yield put(
				notificationsActionCreators.addNotification(
					userMessage,
					error instanceof NetworkError
				)
			)
		}
	}
}

function* fetchReturnPalletTypes() {
	while (true) {
		yield take(actionTypes.FETCH_RETURNPALLETTYPES)
		yield put(newCallOffActionCreators.fetchReturnPalletTypesRequest())
		try {
			const returnPalletTypes = yield call(apiNewCallOff.getReturnPalletTypes)
			yield put(
				newCallOffActionCreators.fetchReturnPalletTypesSuccess(
					returnPalletTypes
				)
			)
		} catch (error) {
			const techMessage = error ? error.message : t('app:Error.unknown')
			const userMessage = t('app:Api.ReturnPalletTypes.fetchReturnPalletTypes')
			yield put(
				newCallOffActionCreators.fetchReturnPalletTypesFailure({
					userMessage,
					techMessage
				})
			)
		}
	}
}

function* finish() {
	while (true) {
		yield take(actionTypes.FINISH)

		// Start spinner
		yield put(newCallOffActionCreators.finishRequest())

		try {
			// Update delivery windows (for the corrections check)
			const deliveryWindows = yield call(apiNewCallOff.getDeliveryWindows)
			yield put(orderActionCreators.updateDeliveryWindows(deliveryWindows))

			// Prepare finish call-off
			const { orderState, newCallOffState } = yield select()
			const { order } = orderState
			const {
				salesOrderId: orderId,
				callOffOrderId: callOffId,
				closingFreightUsed
			} = order
			const {
				freights,
				materials,
				deliveryType,
				closingFreight,
				callOffStep,
				missingMaterials,
				returnPalletsState
			} = newCallOffState
			const returnPallets = returnPalletsState.returnPallets
			const totalWeight = freights.reduce(
				(weightAcc, { weight }) => weightAcc + weight,
				0
			)
			const totalCosts = calculateTotalCosts({
				order,
				totalWeight,
				closingFreight
			})

			const concept = {
				deliveryType,
				closingFreight,
				materials: materials.filter(({ quantity }) => Boolean(quantity)),
				freights,
				callOffStep,
				missingMaterials,
				returnPallets
			}

			// Validate concept (new call-offs can be created at 14:55 which might be incorrect at 15:05 due to time restrictions)
			const { corrections, correctedFreights } = getCorrectedFreights({
				...order,
				concept
			})
			if (corrections.length > 0) {
				// Corrections are made, set corrections and cancel finish action
				yield put(newCallOffActionCreators.setCorrections(corrections))
				yield put(newCallOffActionCreators.setFreights(correctedFreights))
				yield put(newCallOffActionCreators.finishCancel())
			} else {
				// Finish the call-off
				yield call(apiNewCallOff.finish, {
					orderId,
					callOffId,
					freights,
					deliveryType,
					closingFreight,
					closingFreightUsed,
					totalCosts,
					missingMaterials,
					returnPallets
				})
				yield put(newCallOffActionCreators.finishSuccess())

				// Trigger navigation to call-offs
				const destination = `/orders/${orderId}/calloffs`
				history.push(destination)
			}
		} catch (error) {
			const techMessage = error ? error.message : t('app:Error.unknown')
			const userMessage = t('app:Api.Finish.finishError')
			yield put(
				newCallOffActionCreators.finishFailure({
					userMessage,
					techMessage
				})
			)
		}
	}
}

function* navigateToDeliveryStep() {
	while (true) {
		yield take(actionTypes.VALIDATE_BEFORE_DELIVERY_STEP)
		const { orderState, newCallOffState } = yield select()
		const { order } = orderState
		const { freights, materials, deliveryType, closingFreight, callOffStep } =
			newCallOffState
		const concept = {
			deliveryType,
			closingFreight,
			materials: materials.filter(({ quantity }) => Boolean(quantity)),
			freights,
			callOffStep
		}
		const { corrections, correctedFreights } = getCorrectedFreights({
			...order,
			concept
		})

		if (corrections.length > 0) {
			yield put(newCallOffActionCreators.setFreights(correctedFreights))
		}
		yield put(newCallOffActionCreators.navigateToDeliveryStep())
	}
}

function* navigateToSummaryStep() {
	while (true) {
		yield take(actionTypes.VALIDATE_BEFORE_SUMMARY_STEP)
		const { orderState, newCallOffState } = yield select()
		const { order } = orderState
		const { freights, materials, deliveryType, closingFreight, callOffStep } =
			newCallOffState
		const concept = {
			deliveryType,
			closingFreight,
			materials: materials.filter(({ quantity }) => Boolean(quantity)),
			freights,
			callOffStep
		}
		const { corrections, correctedFreights } = getCorrectedFreights({
			...order,
			concept
		})

		if (corrections.length > 0) {
			yield put(newCallOffActionCreators.setCorrections(corrections))
			yield put(newCallOffActionCreators.setFreights(correctedFreights))
		} else {
			yield put(newCallOffActionCreators.navigateToSummaryStep())
		}
	}
}

function* saveConcept() {
	while (true) {
		yield take(actionTypes.SAVE_CONCEPT)
		const { orderState, newCallOffState } = yield select()
		const { order } = orderState
		const { salesOrderId: orderId, callOffOrderId: callOffId } = order
		const {
			deliveryType,
			closingFreight,
			materials,
			freights,
			callOffStep,
			missingMaterials
		} = newCallOffState

		yield put(newCallOffActionCreators.saveConceptRequest())
		try {
			const concept = {
				deliveryType,
				closingFreight,
				materials: materials.filter(({ quantity }) => Boolean(quantity)),
				freights,
				callOffStep,
				missingMaterials
			}
			const createdConcept = yield call(
				apiNewCallOff.saveConcept,
				orderId,
				concept,
				callOffId
			)
			yield put(newCallOffActionCreators.saveConceptSuccess(createdConcept))

			yield put(
				notificationsActionCreators.addNotification(
					t('app:Api.SaveConcept.saveSuccess')
				)
			)
		} catch (error) {
			const techMessage = error ? error.message : t('app:Error.unknown')
			const userMessage = t('app:Api.SaveConcept.saveError')
			yield put(
				newCallOffActionCreators.saveConceptFailure({
					userMessage,
					techMessage
				})
			)
		}
	}
}

function* removeConcept() {
	while (true) {
		yield take(actionTypes.REMOVE_CONCEPT)
		const { orderState } = yield select()
		const { order } = orderState
		const { salesOrderId: orderId, callOffOrderId: callOffId, concept } = order

		if (concept && !concept.isGenerated) {
			// The user has a saved concept which needs to be removed
			yield put(newCallOffActionCreators.removeConceptRequest())
			try {
				yield call(apiNewCallOff.removeConcept, orderId, callOffId)
				yield put(
					newCallOffActionCreators.removeConceptSuccess(orderId, callOffId)
				)
				yield put(
					notificationsActionCreators.addNotification(
						t('app:Api.RemoveConcept.removeSuccess')
					)
				)
			} catch (error) {
				const techMessage = error ? error.message : t('app:Error.unknown')
				const userMessage = t('app:Api.RemoveConcept.removeError')
				yield put(
					newCallOffActionCreators.removeConceptFailure({
						userMessage,
						techMessage
					})
				)
			}
		} else {
			// There is no saved concept, the call-off can be resetted straight away
			yield put(
				notificationsActionCreators.addNotification(
					t('app:Api.RemoveConcept.removeSuccess')
				)
			)
		}

		// Reload order
		yield put(orderActionCreators.refreshOrder(orderId, callOffId))
	}
}

function* setFreights() {
	while (true) {
		yield take([
			actionTypes.UPDATE_MATERIAL,
			actionTypes.UPDATE_MATERIALS,
			actionTypes.REMOVE_MATERIALS,
			actionTypes.SET_DELIVERY_TYPE
		])
		const { newCallOffState, orderState } = yield select()
		const { order } = orderState
		const { materials, deliveryType, freights } = newCallOffState
		const newFreights = deriveFreights({
			order,
			materials,
			deliveryType,
			freights
		})
		yield put(newCallOffActionCreators.setFreights(newFreights))
	}
}

function* callEntireOrder() {
	while (true) {
		yield take(actionTypes.CALL_ENTIRE_ORDER)
		const { newCallOffState, orderState } = yield select()
		const orderMaterials = orderState.order.materials
		const newCallOffMaterials = newCallOffState.materials
		const updatedMaterials = newCallOffMaterials.map((newCallOffMaterial) => {
			const matchedOrderMaterial = orderMaterials.find(
				(orderMaterial) =>
					orderMaterial.materialNumber === newCallOffMaterial.materialNumber &&
					orderMaterial.shippingPoint === newCallOffMaterial.shippingPoint
			)
			return {
				...newCallOffMaterial,
				quantity: matchedOrderMaterial.quantityAvailable,
				unit: matchedOrderMaterial.baseUnit
			}
		})
		yield put(newCallOffActionCreators.updateMaterials(updatedMaterials))
	}
}

function* fillAddressOnAllCallOffs() {
	while (true) {
		yield take(actionTypes.FILL_ADDRESS_ON_ALL_CALL_OFFS)
		const freights = yield select(sagaSelectors.getFreights)
		const selectedFreight = yield select(sagaSelectors.getSelectedFreight)
		const { street, houseNumber, zipCode, city, country } = selectedFreight
		const updatedFreights = freights.map((freight) => ({
			...freight,
			street,
			houseNumber,
			zipCode,
			city,
			country
		}))
		yield put(newCallOffActionCreators.setFreights(updatedFreights))
		const userMessage = t('app:Delivery.fillAddressOnAllCallOffsMessage')
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

function* fillDateAndTimeOnAllCallOffs() {
	while (true) {
		yield take(actionTypes.FILL_DATE_AND_TIME_ON_ALL_CALL_OFFS)
		const freights = yield select(sagaSelectors.getFreights)
		const selectedFreight = yield select(sagaSelectors.getSelectedFreight)
		const { orderState, newCallOffState } = yield select()
		const { order } = orderState
		const { deliveryType } = newCallOffState
		const { updatedFreights, changedFreights } = getChangedFreights({
			order,
			freights,
			selectedFreight,
			deliveryType
		})
		yield put(newCallOffActionCreators.setFreights(updatedFreights))
		if (changedFreights.length > 0) {
			yield put(newCallOffActionCreators.setChangedFreights(changedFreights))
		} else {
			const userMessage = t('app:Delivery.fillDateAndTimeOnAllCallOffsMessage')
			yield put(notificationsActionCreators.addNotification(userMessage))
		}
	}
}

function* fillContactPersonOnAllCallOffs() {
	while (true) {
		yield take(actionTypes.FILL_CONTACT_PERSON_ON_ALL_CALL_OFFS)
		const freights = yield select(sagaSelectors.getFreights)
		const selectedFreight = yield select(sagaSelectors.getSelectedFreight)
		const { name, phone } = selectedFreight
		const updatedFreights = freights.map((freight) => ({
			...freight,
			name,
			phone
		}))
		yield put(newCallOffActionCreators.setFreights(updatedFreights))
		const userMessage = t('app:Delivery.fillContactPersonOnAllCallOffsMessage')
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

function* fillInstructionsOnAllCallOffs() {
	while (true) {
		yield take(actionTypes.FILL_INSTRUCTIONS_ON_ALL_CALL_OFFS)
		const freights = yield select(sagaSelectors.getFreights)
		const selectedFreight = yield select(sagaSelectors.getSelectedFreight)
		const { instructions } = selectedFreight
		const updatedFreights = freights.map((freight) => ({
			...freight,
			instructions
		}))
		yield put(newCallOffActionCreators.setFreights(updatedFreights))
		const userMessage = t('app:Delivery.fillInstructionsOnAllCallOffsMessage')
		yield put(notificationsActionCreators.addNotification(userMessage))
	}
}

export default function* rootSaga() {
	yield all(
		[
			fetchAdresses,
			fetchReturnPalletTypes,
			finish,
			saveConcept,
			removeConcept,
			setFreights,
			callEntireOrder,
			fillAddressOnAllCallOffs,
			fillContactPersonOnAllCallOffs,
			fillDateAndTimeOnAllCallOffs,
			fillInstructionsOnAllCallOffs,
			navigateToDeliveryStep,
			navigateToSummaryStep
		].map((saga) => fork(saga))
	)
}
