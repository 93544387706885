import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import {
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText,
	Button,
	TextField
} from '@mui/material'

import { t } from 'i18next'

const sx = {
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: (theme) => theme.spacing(-1.5),
		marginLeft: (theme) => theme.spacing(-1.5)
	}
}

class OrderLookup extends PureComponent {
	static propTypes = {
		isSearching: PropTypes.bool.isRequired,
		hide: PropTypes.func.isRequired,
		lookup: PropTypes.func.isRequired
	}

	state = {
		orderNumber: ''
	}

	handleOrderNumberChange = (event) => {
		const orderNumber = event.currentTarget.value
		this.setState({ orderNumber })
	}

	handleLookup = () => {
		const { lookup } = this.props
		const { orderNumber } = this.state
		lookup(orderNumber)
	}

	handleOrderNumberKeyPress = (event) => {
		if (event.key === 'Enter') {
			this.handleLookup()
		}
	}

	render() {
		const { isSearching, hide } = this.props
		const { orderNumber } = this.state

		return (
			<Dialog open={true} onClose={hide}>
				<DialogTitle>{t('app:Orders.Master.lookupOrderTitle')}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{t('app:Orders.Master.lookupOrderContext')}
					</DialogContentText>
					<TextField
						autoFocus
						label={t('app:Orders.Master.lookupOrderLabel')}
						type="number"
						fullWidth
						onChange={this.handleOrderNumberChange}
						onKeyPress={this.handleOrderNumberKeyPress}
						value={orderNumber}
						margin="normal"
						variant="standard"
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={hide}>{t('app:Generic.cancel')}</Button>
					<Button
						disabled={isSearching || !orderNumber}
						color="primary"
						onClick={this.handleLookup}
					>
						{t('app:Generic.search')}
						{isSearching && (
							<CircularProgress size={24} sx={sx.buttonProgress} />
						)}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default OrderLookup
