import fr from './fr.json'
import nl from './nl.json'
import en from './en.json'

const languages = {
	fr,
	nl,
	en
}

export default languages
