import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Box } from '@mui/material'

import { t } from 'i18next'

import getGroupedDeliveries from '../../../utils/deliveries'
import NotFound from '../../Shared/NotFound'
import Loading from '../../Shared/Loading'
import HeaderInfo from './HeaderInfo'
import DeliveryGroup from './DeliveryGroup'
import { styled } from '@mui/styles'

const mixinsGuttersStyle = ({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	}
})

const StyledBox = styled(Box)(mixinsGuttersStyle)

const sx = {
	rootContainer: {
		height: '100%',
		overflowY: 'scroll',
		overflowX: 'hidden',
		marginTop: '-1px'
	}
}

class Deliveries extends PureComponent {
	static propTypes = {
		order: PropTypes.object,
		isLoadingDeliveries: PropTypes.bool.isRequired
	}

	render() {
		const { order, isLoadingDeliveries } = this.props

		if (isLoadingDeliveries) {
			return (
				<StyledBox sx={sx.rootContainer}>
					<Loading paper message={t('app:Orders.Deliveries.loading')} />
				</StyledBox>
			)
		}

		if (!order) {
			return (
				<StyledBox sx={sx.rootContainer}>
					<NotFound
						paper
						description={t('app:Orders.notFoundDescription')}
						header={t('app:Orders.notFoundHeader')}
					/>
				</StyledBox>
			)
		}

		const { deliveries } = order

		let content
		if (deliveries) {
			const groupedDeliveries = getGroupedDeliveries({ deliveries })
			content = groupedDeliveries.map((deliveryGroup, index) => (
				<DeliveryGroup
					key={index}
					deliveryGroup={deliveryGroup}
					index={index}
				/>
			))
		} else {
			content = (
				<Typography variant="h6">
					{t('app:Orders.Deliveries.noDeliveries')}
				</Typography>
			)
		}

		return (
			<StyledBox sx={sx.rootContainer}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<HeaderInfo order={order} />
					</Grid>
					{content}
				</Grid>
			</StyledBox>
		)
	}
}

export default Deliveries
