import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { AppBar, IconButton, ListItem, SvgIcon, TextField } from '@mui/material'

import { TimesFal } from '@oliverit/react-fontawesome'
import { t } from 'i18next'

const sx = {
	searchBar: {
		backgroundColor: (theme) => theme.palette.background.paper,
		boxShadow: 'none'
	}
}

class CallOffsFilter extends PureComponent {
	static propTypes = {
		filterCallOffs: PropTypes.func.isRequired,
		searchTerm: PropTypes.string
	}

	handleCallOffsFilter = (event) => {
		const { filterCallOffs } = this.props
		const searchTerm = event.currentTarget.value
		filterCallOffs({ searchTerm })
	}

	handleEraseCallOffsFilter = () => {
		const { filterCallOffs } = this.props
		filterCallOffs({ searchTerm: '' })
	}

	render() {
		const { searchTerm } = this.props

		return (
			<AppBar position="sticky" sx={sx.searchBar}>
				<ListItem divider>
					<TextField
						fullWidth
						label={t('app:CallOffs.search')}
						type="text"
						onChange={this.handleCallOffsFilter}
						value={searchTerm}
						variant="standard"
					/>
					<IconButton onClick={this.handleEraseCallOffsFilter} size="large">
						<SvgIcon>
							<TimesFal />
						</SvgIcon>
					</IconButton>
				</ListItem>
			</AppBar>
		)
	}
}

export default CallOffsFilter
