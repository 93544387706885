import moment from 'moment-timezone'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
import { getQuantityInAnotherUnit, getConversionRate } from '../materials'
import {
	getMaxDeliveryDate,
	isDeliveryDateDisabled,
	isPlanningDateDisabled,
	getAvailableDeliveryWindows
} from '../newCallOff'
import { ORDER_TYPE_CALLOFF_ORDER, DELIVERY_TYPE_PICKUP } from '../constants'

const EMPTY_FREIGHT = {
	materials: [],
	planningDate: null,
	deliveryDate: null,
	deliveryWindow: '',
	street: '',
	houseNumber: '',
	zipCode: '',
	city: '',
	country: '',
	hasFerryCosts: false,
	hasTunnelCosts: false,
	name: '',
	phone: '',
	userAsContactPerson: false,
	goodsOnPallets: false,
	instructions: {
		line1: '',
		line2: ''
	},
	weight: 0,
	locations: 0
}

const getDefaultPhone = ({ order }) => {
	const { defaultContact = {} } = order
	const { mobileNumber = '', mobileNumberCountry = '' } = defaultContact
	const phone = parsePhoneNumberFromString(mobileNumber, mobileNumberCountry)
	return phone && phone.isValid() ? phone.formatInternational() : ''
}

const deriveFreight = ({ order, addedMaterials }) => [
	{
		...EMPTY_FREIGHT,
		...order.defaultAddress,
		name: order.defaultContact.name,
		phone: getDefaultPhone({ order }),
		materials: addedMaterials
	}
]

const deriveForPickup = ({ order, addedMaterials }) => {
	const locations = addedMaterials.reduce(
		(locationsAccumulator, { shippingPoint }) => {
			locationsAccumulator.add(shippingPoint)
			return locationsAccumulator
		},
		new Set()
	)

	const freights = [...locations.values()].map((location) => {
		const materials = addedMaterials.filter(
			({ shippingPoint }) => shippingPoint === location
		)
		const firstMaterial = order.materials.find(
			(orderMaterial) =>
				orderMaterial.materialNumber === materials[0].materialNumber &&
				orderMaterial.shippingPoint === materials[0].shippingPoint
		)

		// Set shipping point address as freight address
		return {
			...EMPTY_FREIGHT,
			...firstMaterial.shippingPointAddress,
			name: order.defaultContact.name,
			phone: getDefaultPhone({ order }),
			materials
		}
	})
	return freights
}

const deriveFreightsForLocations = ({ order, addedMaterials }) => {
	const { weightLimitsPerTruck } = order.freightTransport

	// Sort materials by shipping point to fill the freights in order of shipping points
	const sortedMaterials = addedMaterials.sort((a, b) =>
		a.shippingPoint.localeCompare(b.shippingPoint)
	)

	const freights = sortedMaterials.reduce((currentFreights, material) => {
		const orderMaterial = order.materials.find(
			({ materialNumber }) => materialNumber === material.materialNumber
		)
		const { unitConversions, weightPerBaseUnit, baseUnit } = orderMaterial
		const weightLimitPerTruck =
			weightLimitsPerTruck[orderMaterial.shippingPointAddress.country] ||
			weightLimitsPerTruck.default
		let remainingMaterials = getQuantityInAnotherUnit({
			quantity: material.quantity,
			fromUnit: material.unit,
			toUnit: baseUnit,
			unitConversions
		})

		// Overrule material rounding unit if selected unit is PA (pakken)
		// Else freights might be divided on the LA (lagen) unit, causing 17.75 PA on one freight and 18.25 freight on the other
		let { roundingUnit } = orderMaterial
		if (material.unit === 'PA') {
			roundingUnit = 'PA'
		}

		while (remainingMaterials > 0) {
			// Check if materials can be added to the last freight
			const lastFreightIndex = currentFreights.length - 1
			if (lastFreightIndex >= 0) {
				const availableWeight =
					weightLimitPerTruck - currentFreights[lastFreightIndex].weight
				const materialsForAvailableWeight = Math.floor(
					availableWeight / weightPerBaseUnit
				)

				let materialsForFreight
				if (remainingMaterials <= materialsForAvailableWeight) {
					// All remaining materials fit on freight, add all
					materialsForFreight = remainingMaterials
				} else {
					// Not all materials fit on freight, get maximum amount in rounding unit
					let remainingMaterialsInRoundingUnit = getQuantityInAnotherUnit({
						quantity: materialsForAvailableWeight,
						fromUnit: baseUnit,
						toUnit: roundingUnit,
						unitConversions
					})
					remainingMaterialsInRoundingUnit = Math.floor(
						remainingMaterialsInRoundingUnit
					)
					materialsForFreight = getQuantityInAnotherUnit({
						quantity: remainingMaterialsInRoundingUnit,
						fromUnit: roundingUnit,
						toUnit: baseUnit,
						unitConversions
					})
				}

				if (
					materialsForFreight > 0 &&
					currentFreights[lastFreightIndex].materials[0].shippingPoint ===
						material.shippingPoint
				) {
					// Add to existing freight
					currentFreights[lastFreightIndex] = {
						...currentFreights[lastFreightIndex],
						materials: currentFreights[lastFreightIndex].materials.concat({
							...material,
							quantity: getQuantityInAnotherUnit({
								quantity: materialsForFreight,
								fromUnit: baseUnit,
								toUnit: material.unit,
								unitConversions
							})
						}),
						weight: (currentFreights[lastFreightIndex].weight +=
							materialsForFreight * weightPerBaseUnit)
					}

					// Subtract added materials from remaining materials
					remainingMaterials -= materialsForFreight
				}
			}

			if (remainingMaterials > 0) {
				// Add new freight
				const maxMaterialsPerFreight = Math.floor(
					weightLimitPerTruck / weightPerBaseUnit
				)

				let materialsForFreight
				if (remainingMaterials <= maxMaterialsPerFreight) {
					// All remaining materials fit on freight, add all
					materialsForFreight = remainingMaterials
				} else {
					// Not all materials fit on freight, get maximum amount in rounding unit
					let remainingMaterialsInRoundingUnit = getQuantityInAnotherUnit({
						quantity: maxMaterialsPerFreight,
						fromUnit: baseUnit,
						toUnit: roundingUnit,
						unitConversions
					})
					remainingMaterialsInRoundingUnit = Math.floor(
						remainingMaterialsInRoundingUnit
					)
					materialsForFreight = getQuantityInAnotherUnit({
						quantity: remainingMaterialsInRoundingUnit,
						fromUnit: roundingUnit,
						toUnit: baseUnit,
						unitConversions
					})
				}

				currentFreights.push({
					...EMPTY_FREIGHT,
					...order.defaultAddress,
					name: order.defaultContact.name,
					phone: getDefaultPhone({ order }),
					materials: [
						{
							...material,
							quantity: getQuantityInAnotherUnit({
								quantity: materialsForFreight,
								fromUnit: baseUnit,
								toUnit: material.unit,
								unitConversions
							})
						}
					],
					weight: materialsForFreight * weightPerBaseUnit
				})

				remainingMaterials -= materialsForFreight
			}
		}
		return currentFreights
	}, [])
	return freights
}

export const calculateTotalWeight = ({ order, materials }) =>
	materials.reduce((totalWeight, { materialNumber, quantity, unit }) => {
		if (!quantity || quantity === '0') {
			return totalWeight
		}

		const { weightPerBaseUnit, unitConversions } = order.materials.find(
			(material) => material.materialNumber === materialNumber
		)
		const calculatedTotalWeight =
			totalWeight +
			quantity *
				getConversionRate({ unitConversions, unit }) *
				weightPerBaseUnit
		let totalWeightRounded
		if (calculatedTotalWeight < 1) {
			totalWeightRounded = 1
		} else {
			totalWeightRounded = Math.floor(calculatedTotalWeight)
		}
		return totalWeightRounded
	}, 0)

// If the freight does not contain enough weight, CRH will charge combination costs (because they need to combine deliveries)
// These costs are defined in weight ranges, this selector will determine the correct costs based on weight
export const calculateTotalCosts = ({ order, totalWeight, closingFreight }) => {
	// If a freight is closing, it cannot have freight costs (because it is the last freight of an order)
	if (closingFreight) {
		return 0
	}

	// If a call-off does not have freight costs, only calculate freight costs if the total weight is below the initial weight
	// This is because the call-off has been part of a grouped call-off
	if (
		order.orderType === ORDER_TYPE_CALLOFF_ORDER &&
		!order.hasFreightCosts &&
		order.initialWeight <= totalWeight
	) {
		return 0
	}

	const combinationCost =
		order.freightTransport &&
		order.freightTransport.combinationCosts.find(
			({ fromWeight, toWeight }) =>
				totalWeight >= fromWeight && totalWeight <= toWeight
		)

	if (combinationCost) {
		// The total weight is too low, combination costs are charged
		return combinationCost.costs
	}

	// The total weight does not fall within a combination cost range, no additional costs are charged
	return 0
}

export const calculateTotalLocations = ({ materials }) => {
	const locations = materials.reduce(
		(locationsAccumulator, { shippingPoint, quantity }) => {
			if (!quantity || Number(quantity) === 0) {
				return locationsAccumulator
			}

			locationsAccumulator.add(shippingPoint)
			return locationsAccumulator
		},
		new Set()
	)

	return locations.size
}

export const getPlanningDate = ({
	deliveryWindows,
	deliveryType,
	canPlanNextDayTill,
	date = moment().tz('Europe/Amsterdam')
}) => {
	const maxDeliveryDate = getMaxDeliveryDate(date)

	// Start from today
	const planningDate = moment(date).tz('Europe/Amsterdam').startOf('day')

	while (planningDate.isSameOrBefore(maxDeliveryDate, 'day')) {
		if (
			!isPlanningDateDisabled({
				planningDate,
				deliveryWindows,
				deliveryType,
				canPlanNextDayTill,
				date
			})
		) {
			break
		}

		planningDate.add(1, 'days')
	}

	return planningDate.format()
}

export const getRevisedPlanningDate = ({
	deliveryWindows,
	deliveryType,
	canPlanNextDayTill,
	planningDate,
	initialDeliveryDate,
	date = moment().tz('Europe/Amsterdam')
}) => {
	// Start from the day before the initial delivery date
	const revisedPlanningDate = moment(initialDeliveryDate)
		.tz('Europe/Amsterdam')
		.startOf('day')
		.subtract(1, 'days')

	while (revisedPlanningDate.isAfter(planningDate, 'day')) {
		if (
			!isPlanningDateDisabled({
				planningDate: revisedPlanningDate,
				deliveryWindows,
				deliveryType,
				canPlanNextDayTill,
				date
			})
		) {
			break
		}

		revisedPlanningDate.subtract(1, 'days')
	}

	return revisedPlanningDate.format()
}

export const getDeliveryDate = ({
	deliveryWindows,
	deliveryType,
	freight,
	date = moment().tz('Europe/Amsterdam'),
	deliveryDateRestrictionOnWeight = undefined
}) => {
	const maxDeliveryDate = getMaxDeliveryDate(date)

	// Start from the day after the planning date
	const deliveryDate = moment(freight.planningDate)
		.tz('Europe/Amsterdam')
		.startOf('day')
		.add(1, 'days')

	while (deliveryDate.isSameOrBefore(maxDeliveryDate, 'day')) {
		if (
			!isDeliveryDateDisabled({
				deliveryDate,
				deliveryWindows,
				deliveryType,
				freight,
				date,
				deliveryDateRestrictionOnWeight
			})
		) {
			break
		}

		deliveryDate.add(1, 'days')
	}

	return deliveryDate.format()
}

export const getNextAvailableDeliveryDate = ({
	deliveryWindows,
	deliveryType,
	freight,
	startFromDate,
	date = moment().tz('Europe/Amsterdam')
}) => {
	const maxDeliveryDate = getMaxDeliveryDate(date)

	// Start from the day after the planning date
	const deliveryDate = moment(startFromDate)
		.tz('Europe/Amsterdam')
		.startOf('day')

	while (deliveryDate.isSameOrBefore(maxDeliveryDate, 'day')) {
		if (
			!isDeliveryDateDisabled({
				deliveryDate,
				deliveryWindows,
				deliveryType,
				freight,
				date
			})
		) {
			break
		}

		deliveryDate.add(1, 'days')
	}

	return deliveryDate.format()
}

export const getUsedCountries = ({ order, materials }) => {
	const usedCountries = materials.reduce(
		(usedCountriesAccumulator, usedMaterial) => {
			if (!usedMaterial.quantity || Number(usedMaterial.quantity) === 0) {
				return usedCountriesAccumulator
			}

			const orderMaterial = order.materials.find(
				({ materialNumber, shippingPoint }) =>
					materialNumber === usedMaterial.materialNumber &&
					shippingPoint === usedMaterial.shippingPoint
			)
			usedCountriesAccumulator.add(orderMaterial.shippingPointAddress.country)
			return usedCountriesAccumulator
		},
		new Set()
	)
	return [...usedCountries.values()]
}

export const deriveFreights = ({
	order,
	materials,
	deliveryType,
	freights,
	date = moment().tz('Europe/Amsterdam')
}) => {
	const usedCountries = getUsedCountries({ order, materials })
	const totalWeight = calculateTotalWeight({ order, materials })
	const {
		weightLimitsPerTruck,
		defaultDeliveryWindowKey,
		canPlanNextDayTill,
		deliveryDateWeightRestrictions
	} = order.freightTransport
	const { orderType, deliveryWindows } = order
	const addedMaterials = materials.reduce((materialsAccumulator, material) => {
		if (!material.quantity || Number(material.quantity) === 0) {
			return materialsAccumulator
		}

		return materialsAccumulator.concat(material)
	}, [])

	let newFreights
	if (orderType === ORDER_TYPE_CALLOFF_ORDER) {
		// For call-off orders (which always have a mandatory single freight), only update the materials
		newFreights = [{ ...freights[0], materials: addedMaterials }]
	} else if (deliveryType === DELIVERY_TYPE_PICKUP) {
		// For pickup orders, derive per location.
		newFreights = deriveForPickup({ order, addedMaterials })
	} else if (
		usedCountries.length === 1 &&
		totalWeight <= weightLimitsPerTruck[usedCountries[0]]
	) {
		// A single freight needs to be made
		newFreights = deriveFreight({ order, addedMaterials })
	} else {
		// Multiple freights need to be made
		newFreights = deriveFreightsForLocations({ order, addedMaterials })
	}

	let deliveryDateRestrictionOnWeight
	if (deliveryDateWeightRestrictions) {
		deliveryDateRestrictionOnWeight = deliveryDateWeightRestrictions.find(
			(_weightLimit) =>
				totalWeight >= _weightLimit.from && totalWeight <= _weightLimit.till
		)
	}
	// Add delivery date to freights
	return newFreights.map((freight) => {
		freight.weight = calculateTotalWeight({
			order,
			materials: freight.materials
		})
		freight.locations = calculateTotalLocations({
			materials: freight.materials
		})
		freight.planningDate = getPlanningDate({
			deliveryWindows,
			deliveryType,
			canPlanNextDayTill,
			date
		})
		freight.initialDeliveryDate = getDeliveryDate({
			deliveryWindows,
			deliveryType,
			freight,
			date,
			deliveryDateRestrictionOnWeight
		})
		freight.deliveryDate = freight.initialDeliveryDate

		// Get revised planning date, the planning date is the last available date for delivery
		// Scenario:
		//   * Today is monday 01.04.2019 (9:00)
		// 	 * Monday 01.04.2019 is a delivery and a planning day
		// 	 * Tuesday 01.04.2019 is not a delivery day, but planning is possible
		// 	 * Wednesday 01.04.2019 is a delivery and a planning day
		//
		// Normally the planning date would be today and the delivery will be tomorrow.
		// Tomorrow is not possible for delivery, so delivery will be scheduled for Wednesday.
		// The planning date in this case should be Tuesday and not today (Monday), because we do
		// not want the "today" planning constraints and the actual delivery will be planned tomorrow.
		// The planning date is revised to get the latest possible planning date for the initial delivery date.
		freight.planningDate = getRevisedPlanningDate({
			deliveryWindows,
			deliveryType,
			planningDate: freight.planningDate,
			initialDeliveryDate: freight.initialDeliveryDate,
			canPlanNextDayTill,
			date
		})

		// Determine delivery window (use default if available, else first available)
		const availableDeliveryWindows = getAvailableDeliveryWindows({
			deliveryDate: freight.deliveryDate,
			deliveryWindows,
			deliveryType,
			freight,
			date
		})
		freight.deliveryWindow = availableDeliveryWindows.find(
			({ key }) => key === defaultDeliveryWindowKey
		)
			? defaultDeliveryWindowKey
			: availableDeliveryWindows[0].key

		// Return modified freight object
		return freight
	})
}
