import { combineReducers } from 'redux'
import actionTypes from './actionTypes'
import orderActionTypes from '../order/actionTypes'
import {
	CALL_OFF_STEP_MATERIALS,
	CALL_OFF_STEP_DELIVERY,
	CALL_OFF_STEP_SUMMARY
} from '../../utils/constants'

const deliveryTypeReducer = (state = '', action) => {
	switch (action.type) {
		case actionTypes.FINISH_SUCCESS:
		case orderActionTypes.FETCH_ORDER: {
			return ''
		}

		case orderActionTypes.FETCH_ORDER_SUCCESS: {
			// Get delivery type from concept
			if (
				action.payload.order.concept &&
				action.payload.order.concept.deliveryType
			) {
				return action.payload.order.concept.deliveryType
			}
			return action.payload.order.incoTerm
		}

		case actionTypes.SET_DELIVERY_TYPE: {
			return action.delivery
		}

		default: {
			return state
		}
	}
}

const closingFreightReducer = (state = false, action) => {
	switch (action.type) {
		case actionTypes.FINISH_SUCCESS:
		case orderActionTypes.FETCH_ORDER: {
			return false
		}

		case orderActionTypes.FETCH_ORDER_SUCCESS: {
			// Get closing freight indicator from concept
			if (
				action.payload.order.concept &&
				action.payload.order.concept.closingFreight
			) {
				return action.payload.order.concept.closingFreight
			}
			return false
		}

		case actionTypes.SET_CLOSING_FREIGHT: {
			return action.closingFreight
		}

		default: {
			return state
		}
	}
}

const materialsReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FINISH_SUCCESS:
		case orderActionTypes.FETCH_ORDER: {
			return []
		}

		case orderActionTypes.FETCH_ORDER_SUCCESS: {
			// Get materials from concept
			if (
				action.payload.order.concept &&
				action.payload.order.concept.materials
			) {
				return action.payload.order.concept.materials
			}
			return action.payload.order.materials.map((material) => ({
				materialNumber: material.materialNumber,
				shippingPoint: material.shippingPoint,
				unit: material.baseUnit,
				quantity: '',
				complemented: false
			}))
		}

		case actionTypes.UPDATE_MATERIAL: {
			return state.map((material) => {
				if (
					material.materialNumber === action.material.materialNumber &&
					material.shippingPoint === action.material.shippingPoint
				) {
					return {
						...material,
						...action.material
					}
				}
				return material
			})
		}

		case actionTypes.UPDATE_MATERIALS: {
			return action.payload.updatedMaterials
		}

		case actionTypes.REMOVE_MATERIALS: {
			return state.map((material) => ({
				...material,
				quantity: ''
			}))
		}

		default: {
			return state
		}
	}
}

const initialReturnPalletsState = {
	showReturnPallets: false,
	returnPallets: []
}

const returnPalletsReducer = (state = initialReturnPalletsState, action) => {
	switch (action.type) {
		case orderActionTypes.FETCH_ORDER_SUCCESS:
		case actionTypes.NAVIGATE_TO_MATERIALS_STEP: {
			return initialReturnPalletsState
		}

		case actionTypes.FETCH_RETURNPALLETTYPES_SUCCESS: {
			const returnPallets = action.returnPalletTypes.map((type) => {
				const returnPallet = { ...type, quantity: 0 }
				return returnPallet
			})
			return {
				...state,
				returnPallets
			}
		}

		case actionTypes.FETCH_RETURNPALLETTYPES_FAILURE: {
			return {
				...state,
				finishErrorMessages: action.payload.errorMessages
			}
		}

		case actionTypes.SET_SHOW_RETURNPALLETS: {
			return {
				...state,
				showReturnPallets: action.returnPalletsSwitch
			}
		}

		case actionTypes.UPDATE_RETURNPALLETS: {
			return {
				...state,
				returnPallets: action.payload.updatedReturnPallets
			}
		}

		default: {
			return state
		}
	}
}

const queryReducer = (state = '', action) => {
	switch (action.type) {
		case actionTypes.FINISH_SUCCESS:
		case orderActionTypes.FETCH_ORDER: {
			return ''
		}

		case actionTypes.SEARCH: {
			return action.query
		}

		default: {
			return state
		}
	}
}

const showPurchaseObligationArticlesOnlyReducer = (state = false, action) => {
	switch (action.type) {
		case actionTypes.FINISH_SUCCESS:
		case orderActionTypes.FETCH_ORDER: {
			return false
		}

		case actionTypes.SHOW_PURCHASE_OBLIGATION_ARTICLES_ONLY: {
			return true
		}

		case actionTypes.HIDE_PURCHASE_OBLIGATION_ARTICLES_ONLY: {
			return false
		}

		default: {
			return state
		}
	}
}

const initialClassificationsFilterState = {
	productGroups: [],
	subProductGroups: [],
	productLines: []
}
const classificationsFilterReducer = (
	state = initialClassificationsFilterState,
	action
) => {
	switch (action.type) {
		case actionTypes.FINISH_SUCCESS:
		case orderActionTypes.FETCH_ORDER: {
			return initialClassificationsFilterState
		}

		case orderActionTypes.FETCH_ORDER_SUCCESS: {
			// Get all unique product groups, sub product groups and product lines
			const newState = action.payload.order.materialClassifications.reduce(
				(filter, materialClassification) => {
					const { productGroup, subProductGroup, productLine } =
						materialClassification
					return {
						...filter,
						productGroups: filter.productGroups.set(productGroup.value, {
							...productGroup,
							checked: false
						}),
						subProductGroups: filter.subProductGroups.set(
							subProductGroup.value,
							{ ...subProductGroup, checked: false }
						),
						productLines: filter.productLines.set(productLine.value, {
							...productLine,
							checked: false
						})
					}
				},
				{
					productGroups: new Map(),
					subProductGroups: new Map(),
					productLines: new Map()
				}
			)
			return {
				productGroups: [...newState.productGroups.values()],
				subProductGroups: [...newState.subProductGroups.values()],
				productLines: [...newState.productLines.values()]
			}
		}

		case actionTypes.TOGGLE_PRODUCTGROUP: {
			return {
				...state,
				productGroups: state.productGroups.map((productGroup) => {
					if (productGroup.value === action.productGroup.value) {
						return {
							...productGroup,
							checked: !productGroup.checked
						}
					}
					return productGroup
				})
			}
		}

		case actionTypes.TOGGLE_SUBPRODUCTGROUP: {
			return {
				...state,
				subProductGroups: state.subProductGroups.map((subProductGroup) => {
					if (subProductGroup.value === action.subProductGroup.value) {
						return {
							...subProductGroup,
							checked: !subProductGroup.checked
						}
					}
					return subProductGroup
				})
			}
		}

		case actionTypes.TOGGLE_PRODUCTLINE: {
			return {
				...state,
				productLines: state.productLines.map((productLine) => {
					if (productLine.value === action.productLine.value) {
						return {
							...productLine,
							checked: !productLine.checked
						}
					}
					return productLine
				})
			}
		}

		case actionTypes.RESET_CLASSIFICATION_FILTER: {
			return {
				...state,
				productGroups: state.productGroups.map((productGroup) => ({
					...productGroup,
					checked: false
				})),
				subProductGroups: state.subProductGroups.map((subProductGroup) => ({
					...subProductGroup,
					checked: false
				})),
				productLines: state.productLines.map((productLine) => ({
					...productLine,
					checked: false
				}))
			}
		}

		default: {
			return state
		}
	}
}

const initialStep = CALL_OFF_STEP_MATERIALS
const callOffStepReducer = (state = initialStep, action) => {
	switch (action.type) {
		case actionTypes.FINISH_SUCCESS:
		case orderActionTypes.FETCH_ORDER: {
			return initialStep
		}

		case orderActionTypes.FETCH_ORDER_SUCCESS: {
			// Get call-off step from concept
			if (
				action.payload.order.concept &&
				action.payload.order.concept.callOffStep
			) {
				return action.payload.order.concept.callOffStep
			}
			return initialStep
		}

		case actionTypes.NAVIGATE_TO_MATERIALS_STEP: {
			return CALL_OFF_STEP_MATERIALS
		}

		case actionTypes.NAVIGATE_TO_DELIVERY_STEP: {
			return CALL_OFF_STEP_DELIVERY
		}

		case actionTypes.NAVIGATE_TO_SUMMARY_STEP: {
			return CALL_OFF_STEP_SUMMARY
		}

		default: {
			return state
		}
	}
}

const freightsReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FINISH_SUCCESS:
		case orderActionTypes.FETCH_ORDER: {
			return []
		}

		case orderActionTypes.FETCH_ORDER_SUCCESS: {
			// Get freights from concept
			if (
				action.payload.order.concept &&
				action.payload.order.concept.freights
			) {
				return action.payload.order.concept.freights
			}
			return []
		}

		case actionTypes.SET_FREIGHTS: {
			return action.freights
		}

		case actionTypes.UPDATE_FREIGHT: {
			return state.map((freight, index) => {
				if (index === action.freightIndex) {
					return {
						...freight,
						...action.freight
					}
				}
				return freight
			})
		}

		default: {
			return state
		}
	}
}

const freightIndexReducer = (state = 0, action) => {
	switch (action.type) {
		case actionTypes.NAVIGATE_TO_DELIVERY_STEP:
		case actionTypes.FINISH_SUCCESS:
		case orderActionTypes.FETCH_ORDER: {
			return 0
		}

		case actionTypes.SET_FREIGHT_INDEX: {
			return action.freightIndex
		}

		default: {
			return state
		}
	}
}

const addressesReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.CLEAR_ADDRESSES:
		case actionTypes.FINISH_SUCCESS:
		case orderActionTypes.FETCH_ORDER: {
			return []
		}

		case actionTypes.FETCH_ADDRESSES_SUCCESS: {
			return action.addresses
		}

		default: {
			return state
		}
	}
}

const missingMaterialsReducer = (state = '', action) => {
	switch (action.type) {
		case orderActionTypes.FETCH_ORDER_SUCCESS: {
			// Get missing materials from concept
			if (
				action.payload.order.concept &&
				action.payload.order.concept.missingMaterials
			) {
				return action.payload.order.concept.missingMaterials
			}
			return ''
		}

		case actionTypes.UPDATE_MISSING_MATERIALS: {
			return action.missingMaterialsText
		}

		default: {
			return state
		}
	}
}

const initialMetaReducer = {
	isLoadingAddresses: false,
	isLoadingReturnPalletTypes: false,
	isFinishing: false,
	isSavingConcept: false,
	isRemovingConcept: false,
	hasUnsavedChanges: false,
	finishErrorMessages: null,
	saveConceptErrorMessages: null,
	removeConceptErrorMessages: null,
	corrections: null,
	changedFreights: null
}

const metaReducer = (state = initialMetaReducer, action) => {
	switch (action.type) {
		case actionTypes.FINISH_SUCCESS:
		case orderActionTypes.FETCH_ORDER: {
			return initialMetaReducer
		}

		case actionTypes.FETCH_RETURNPALLETTYPES_REQUEST: {
			return {
				...state,
				isLoadingReturnPalletTypes: true
			}
		}

		case actionTypes.FETCH_RETURNPALLETTYPES_SUCCESS:
		case actionTypes.FETCH_RETURNPALLETTYPES_FAILURE: {
			return {
				...state,
				isLoadingReturnPalletTypes: false
			}
		}

		case actionTypes.FETCH_ADDRESSES_REQUEST: {
			return {
				...state,
				isLoadingAddresses: true
			}
		}

		case actionTypes.FETCH_ADDRESSES_SUCCESS:
		case actionTypes.FETCH_ADDRESSES_FAILURE: {
			return {
				...state,
				isLoadingAddresses: false
			}
		}

		case actionTypes.FINISH_REQUEST: {
			return {
				...state,
				finishErrorMessages: null,
				isFinishing: true
			}
		}

		case actionTypes.FINISH_FAILURE: {
			return {
				...state,
				isFinishing: false,
				finishErrorMessages: action.payload.errorMessages
			}
		}

		case actionTypes.FINISH_CANCEL: {
			return {
				...state,
				isFinishing: false
			}
		}

		case actionTypes.CLEAR_FINISH_ERROR: {
			return {
				...state,
				finishErrorMessages: null
			}
		}

		case actionTypes.SAVE_CONCEPT_REQUEST: {
			return {
				...state,
				saveConceptErrorMessages: null,
				isSavingConcept: true
			}
		}

		case actionTypes.SAVE_CONCEPT_FAILURE: {
			return {
				...state,
				isSavingConcept: false,
				saveConceptErrorMessages: action.payload.errorMessages
			}
		}

		case actionTypes.SAVE_CONCEPT_SUCCESS: {
			return {
				...state,
				isSavingConcept: false,
				hasUnsavedChanges: false,
				corrections: null,
				changedFreights: null
			}
		}

		case actionTypes.CLEAR_SAVE_CONCEPT_ERROR: {
			return {
				...state,
				saveConceptErrorMessages: null
			}
		}

		case actionTypes.REMOVE_CONCEPT_REQUEST: {
			return {
				...state,
				removeConceptErrorMessages: null,
				isRemovingConcept: true
			}
		}

		case actionTypes.REMOVE_CONCEPT_FAILURE: {
			return {
				...state,
				isRemovingConcept: false,
				removeConceptErrorMessages: action.payload.errorMessages
			}
		}

		case actionTypes.REMOVE_CONCEPT_SUCCESS: {
			return {
				...state,
				isRemovingConcept: false,
				corrections: null,
				changedFreights: null
			}
		}

		case actionTypes.CLEAR_REMOVE_CONCEPT_ERROR: {
			return {
				...state,
				removeConceptErrorMessages: null
			}
		}

		case actionTypes.SET_FREIGHTS:
		case actionTypes.SET_DELIVERY_TYPE:
		case actionTypes.SET_CLOSING_FREIGHT: {
			return {
				...state,
				hasUnsavedChanges: true
			}
		}

		case actionTypes.SET_CORRECTIONS: {
			return {
				...state,
				corrections: action.corrections
			}
		}

		case actionTypes.CLEAR_CORRECTIONS: {
			return {
				...state,
				corrections: null
			}
		}

		case actionTypes.SET_CHANGED_FREIGHTS: {
			return {
				...state,
				changedFreights: action.changedFreights
			}
		}

		case actionTypes.CLEAR_CHANGED_FREIGHTS: {
			return {
				...state,
				changedFreights: null
			}
		}

		default: {
			return state
		}
	}
}

export default combineReducers({
	deliveryType: deliveryTypeReducer,
	closingFreight: closingFreightReducer,
	materials: materialsReducer,
	returnPalletsState: returnPalletsReducer,
	query: queryReducer,
	classificationsFilter: classificationsFilterReducer,
	callOffStep: callOffStepReducer,
	freights: freightsReducer,
	freightIndex: freightIndexReducer,
	addresses: addressesReducer,
	meta: metaReducer,
	missingMaterials: missingMaterialsReducer,
	showPurchaseObligationArticlesOnly: showPurchaseObligationArticlesOnlyReducer
})
