import { all, fork } from 'redux-saga/effects'
import authSaga from './auth/saga'
import userSaga from './user/saga'
import ordersSaga from './orders/saga'
import orderSaga from './order/saga'
import newCallOffSaga from './newCallOff/saga'

function* rootSaga() {
	yield all(
		[authSaga, userSaga, ordersSaga, orderSaga, newCallOffSaga].map((saga) =>
			fork(saga)
		)
	)
}

export default rootSaga
