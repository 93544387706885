import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
	Paper,
	Hidden,
	Typography,
	Grid,
	TextField,
	Button,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	IconButton,
	SvgIcon,
	CircularProgress,
	Tooltip,
	Switch,
	FormControlLabel,
	Box
} from '@mui/material'

import { t } from 'i18next'
import { TimesFal, SaveFal, UndoFal } from '@oliverit/react-fontawesome'
import ConfirmDialog from '../../../Shared/ConfirmDialog'
import {
	ORDER_TYPE_SALES_ORDER,
	ORDER_TYPE_CALLOFF_ORDER,
	CALL_OFF_STEP_MATERIALS,
	DELIVERY_TYPE_DELIVER,
	DELIVERY_TYPE_PICKUP
} from '../../../../utils/constants'
import { styled } from '@mui/styles'

const mixinsGuttersStyle = ({ theme }) => ({
	paddingLeft: theme.spacing(1),
	paddingBottom: theme.spacing(1),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(2),
		paddingBottom: theme.spacing(2)
	}
})

const StyledPaper = styled(Paper)(mixinsGuttersStyle)

const sx = {
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: (theme) => theme.spacing(-2),
		marginLeft: (theme) => theme.spacing(-3)
	},
	heading: {
		paddingTop: (theme) => theme.spacing(3),
		paddingBottom: (theme) => theme.spacing(2),
		marginBottom: (theme) => theme.spacing(3)
	}
}

class HeaderInfo extends PureComponent {
	static propTypes = {
		order: PropTypes.object.isRequired,
		deliveryType: PropTypes.string.isRequired,
		callOffStep: PropTypes.number.isRequired,
		isSavingConcept: PropTypes.bool.isRequired,
		isRemovingConcept: PropTypes.bool.isRequired,
		saveConceptErrorMessages: PropTypes.object,
		removeConceptErrorMessages: PropTypes.object,
		changeDeliveryMode: PropTypes.func.isRequired,
		saveConcept: PropTypes.func.isRequired,
		removeConcept: PropTypes.func.isRequired,
		clearSaveConceptError: PropTypes.func.isRequired,
		clearRemoveConceptError: PropTypes.func.isRequired,
		showPurchaseObligationArticles: PropTypes.bool.isRequired,
		hidePurchaseObligationArticlesOnly: PropTypes.func.isRequired,
		showPurchaseObligationArticlesOnly: PropTypes.func.isRequired
	}

	state = {
		showRemoveConceptConfirm: false
	}

	handleDeliveryTypeChange = (event) => {
		const { changeDeliveryMode } = this.props
		changeDeliveryMode(event.target.value)
	}

	handleRemoveConceptClick = () => {
		this.setState({ showRemoveConceptConfirm: true })
	}

	handleRemoveConceptCancel = () => {
		this.setState({ showRemoveConceptConfirm: false })
	}

	handleRemoveConceptConfirm = () => {
		const { removeConcept } = this.props
		this.setState({ showRemoveConceptConfirm: false })
		removeConcept()
	}

	handlePurchaseObligationArticlesChange = () => {
		const {
			showPurchaseObligationArticlesOnly,
			showPurchaseObligationArticles,
			hidePurchaseObligationArticlesOnly
		} = this.props
		if (showPurchaseObligationArticles) {
			hidePurchaseObligationArticlesOnly()
		} else {
			showPurchaseObligationArticlesOnly()
		}
	}

	render() {
		const { showRemoveConceptConfirm } = this.state
		const {
			order,
			deliveryType,
			callOffStep,
			isSavingConcept,
			isRemovingConcept,
			saveConceptErrorMessages,
			removeConceptErrorMessages,
			saveConcept,
			removeConcept,
			clearSaveConceptError,
			clearRemoveConceptError,
			showPurchaseObligationArticles
		} = this.props

		let deliveryDropDown
		let purchaseObligationSwitch
		if (callOffStep === CALL_OFF_STEP_MATERIALS) {
			deliveryDropDown = (
				<Grid item sm={4} xs={12}>
					<FormControl variant="standard">
						<InputLabel shrink>{t('app:Orders.Details.delivery')}</InputLabel>
						<Select
							value={deliveryType}
							onChange={this.handleDeliveryTypeChange}
							disabled={order.orderType === ORDER_TYPE_CALLOFF_ORDER}
						>
							<MenuItem value={DELIVERY_TYPE_DELIVER}>
								{t('app:Generic.deliver')}
							</MenuItem>
							<MenuItem value={DELIVERY_TYPE_PICKUP}>
								{t('app:Generic.pickUp')}
							</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			)
			purchaseObligationSwitch = (
				<Grid item sm={4} xs={12}>
					<FormControl fullWidth size="small" variant="standard">
						<FormControlLabel
							control={
								<Switch
									checked={showPurchaseObligationArticles}
									color="primary"
									onChange={this.handlePurchaseObligationArticlesChange}
								/>
							}
							label={t('app:Orders.Details.purchaseObligationArticles')}
							labelPlacement="end"
						/>
					</FormControl>
				</Grid>
			)
		}

		const inputProps = { readOnly: true, disableUnderline: true }
		const inputLabelProps = { shrink: true }

		let saveConceptErrorDialog
		if (saveConceptErrorMessages) {
			saveConceptErrorDialog = (
				<ConfirmDialog
					confirm={saveConcept}
					confirmButtonText={t('app:Generic.tryAgain')}
					cancel={clearSaveConceptError}
					cancelButtonText={t('app:Generic.cancel')}
					descriptionText={saveConceptErrorMessages.techMessage}
					isOpen={true}
					titleText={saveConceptErrorMessages.userMessage}
				/>
			)
		}

		let removeConceptErrorDialog
		if (removeConceptErrorMessages) {
			removeConceptErrorDialog = (
				<ConfirmDialog
					confirm={removeConcept}
					confirmButtonText={t('app:Generic.tryAgain')}
					cancel={clearRemoveConceptError}
					cancelButtonText={t('app:Generic.cancel')}
					descriptionText={removeConceptErrorMessages.techMessage}
					isOpen={true}
					titleText={removeConceptErrorMessages.userMessage}
				/>
			)
		}

		let title = t('app:Orders.Details.headingNew')
		if (order.orderType === ORDER_TYPE_CALLOFF_ORDER) {
			title = t('app:Orders.Details.headingEdit')
		}

		let closeButton
		if (order.orderType === ORDER_TYPE_SALES_ORDER) {
			closeButton = (
				<IconButton component={Link} to="/orders" size="large">
					<SvgIcon>
						<TimesFal />
					</SvgIcon>
				</IconButton>
			)
		} else {
			closeButton = (
				<IconButton
					component={Link}
					to={`/orders/${order.salesOrderId}/calloffs/${order.callOffOrderId}/trackandtrace`}
					size="large"
				>
					<SvgIcon>
						<TimesFal />
					</SvgIcon>
				</IconButton>
			)
		}

		return (
			<Fragment>
				{saveConceptErrorDialog}
				{removeConceptErrorDialog}
				<ConfirmDialog
					confirm={this.handleRemoveConceptConfirm}
					confirmButtonText={t('app:Orders.Details.removeConcept')}
					cancel={this.handleRemoveConceptCancel}
					cancelButtonText={t('app:Generic.cancel')}
					descriptionText={t(
						'app:Orders.Details.removeConceptConfirmDescription'
					)}
					isOpen={showRemoveConceptConfirm}
					isDestructive={true}
					titleText={t('app:Orders.Details.removeConceptConfirmTitle')}
				/>
				<Grid
					container
					spacing={2}
					alignItems="center"
					justifyContent="space-between"
				>
					<Grid item>
						<Box sx={sx.heading}>
							<Typography variant="h6">{title}</Typography>
						</Box>
					</Grid>
					<Grid item>
						<Grid container spacing={1} alignItems="center">
							{order.orderType === ORDER_TYPE_SALES_ORDER && (
								<Hidden mdDown>
									<Grid item>
										<Button
											variant={
												order.openCallOffOrdersCount === 0
													? 'text'
													: 'contained'
											}
											color={
												order.openCallOffOrdersCount === 0
													? 'inherit'
													: 'primary'
											}
											component={Link}
											to={`/orders/${order.id}/calloffs`}
										>
											{order.openCallOffOrdersCount === 1
												? t('app:Orders.Details.activeCallOff', {
														activeCallOffs: order.openCallOffOrdersCount
												  })
												: t('app:Orders.Details.activeCallOffs', {
														activeCallOffs: order.openCallOffOrdersCount
												  })}
										</Button>
									</Grid>
								</Hidden>
							)}
							{!order.weightLimitPerTruckExceeded &&
								!order.maximumCountriesExceeded &&
								!order.maximumLocationsExceeded && (
									<Fragment>
										<Grid item>
											<Tooltip
												title={t('app:Orders.Details.saveConcept')}
												disableFocusListener={true}
											>
												<IconButton
													disabled={isSavingConcept}
													onClick={saveConcept}
													size="large"
												>
													<SvgIcon>
														<SaveFal />
													</SvgIcon>
													{isSavingConcept && (
														<CircularProgress
															size={48}
															sx={sx.buttonProgress}
														/>
													)}
												</IconButton>
											</Tooltip>
										</Grid>
										<Grid item>
											<Tooltip
												title={t('app:Orders.Details.removeConcept')}
												disableFocusListener={true}
											>
												<IconButton
													disabled={isRemovingConcept}
													onClick={this.handleRemoveConceptClick}
													size="large"
												>
													<SvgIcon>
														<UndoFal />
													</SvgIcon>
													{isRemovingConcept && (
														<CircularProgress
															size={48}
															sx={sx.buttonProgress}
														/>
													)}
												</IconButton>
											</Tooltip>
										</Grid>
									</Fragment>
								)}
							<Grid item>{closeButton}</Grid>
						</Grid>
					</Grid>
				</Grid>
				<StyledPaper>
					<Grid container spacing={2}>
						<Grid item sm={4} xs={12}>
							<TextField
								value={order.salesOrderNumber || order.orderNumber || ''}
								fullWidth
								label={t('app:Orders.Details.orderNumber')}
								InputProps={inputProps}
								InputLabelProps={inputLabelProps}
								variant="standard"
							/>
						</Grid>
						<Grid item sm={4} xs={12}>
							<TextField
								fullWidth
								value={order.project.projectNumber || ''}
								label={t('app:Orders.Details.projectNumber')}
								InputProps={inputProps}
								InputLabelProps={inputLabelProps}
								variant="standard"
							/>
						</Grid>
						<Grid item sm={4} xs={12}>
							<TextField
								multiline
								fullWidth
								value={order.project.name || ''}
								label={t('app:Orders.Details.project')}
								InputProps={inputProps}
								InputLabelProps={inputLabelProps}
								variant="standard"
							/>
						</Grid>
						<Grid item sm={4} xs={12}>
							<TextField
								value={order.customer.customerNumber || ''}
								fullWidth
								label={t('app:Orders.Details.customerNr')}
								InputProps={inputProps}
								InputLabelProps={inputLabelProps}
								variant="standard"
							/>
						</Grid>
						<Grid item sm={4} xs={12}>
							<TextField
								multiline
								fullWidth
								value={order.customer.name || ''}
								label={t('app:Orders.Details.customerName')}
								InputProps={inputProps}
								InputLabelProps={inputLabelProps}
								variant="standard"
							/>
						</Grid>
						<Grid item sm={4} xs={12}>
							<TextField
								fullWidth
								value={order.project.city || ''}
								label={t('app:Orders.Details.projectCity')}
								InputProps={inputProps}
								InputLabelProps={inputLabelProps}
								variant="standard"
							/>
						</Grid>
						{deliveryDropDown}
						{purchaseObligationSwitch}
						{order.orderType === ORDER_TYPE_CALLOFF_ORDER && (
							<Grid item sm={4} xs={12}>
								<TextField
									value={order.orderNumber || ''}
									fullWidth
									label={t('app:Orders.Details.callOffNumber')}
									InputProps={inputProps}
									InputLabelProps={inputLabelProps}
									variant="standard"
								/>
							</Grid>
						)}
					</Grid>
				</StyledPaper>
			</Fragment>
		)
	}
}

export default HeaderInfo
