const getGroupedDeliveries = ({ deliveries }) =>
	deliveries.reduce((currentDeliveries, delivery) => {
		// Check if deliveryGroup is allready present
		const isNewDeliveryGroup = !currentDeliveries.find(
			({ route, expectedDeliveryDate }) =>
				route === delivery.route &&
				expectedDeliveryDate.from === delivery.expectedDeliveryDate.from &&
				expectedDeliveryDate.till === delivery.expectedDeliveryDate.till
		)
		if (isNewDeliveryGroup) {
			// Group all deliveries with the same route and expectedDeliveryDate
			const deliveryGroup = deliveries.filter(
				({ route, expectedDeliveryDate }) =>
					route === delivery.route &&
					expectedDeliveryDate.from === delivery.expectedDeliveryDate.from &&
					expectedDeliveryDate.till === delivery.expectedDeliveryDate.till
			)
			currentDeliveries.push({
				route: deliveryGroup[0].route,
				forwardingAgentName: deliveryGroup[0].forwardingAgentName,
				expectedDeliveryDate: deliveryGroup[0].expectedDeliveryDate,
				deliveries: deliveryGroup
			})
		}
		return currentDeliveries
	}, [])

export default getGroupedDeliveries
