import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'

import Logo from '../Logo'
import { Typography, Paper, Button, Grid } from '@mui/material'

const sx = {
	root: {
		flex: 1,
		paddingTop: (theme) => theme.spacing(12.5)
	},
	paper: {
		paddingLeft: (theme) => theme.spacing(2),
		paddingRight: (theme) => theme.spacing(2),
		[(theme) => theme.breakpoints.up('sm')]: {
			paddingLeft: (theme) => theme.spacing(3),
			paddingRight: (theme) => theme.spacing(3)
		},
		margin: '0 auto',
		paddingTop: (theme) => theme.spacing(2),
		paddingBottom: (theme) => theme.spacing(2),
		textAlign: 'center',
		width: 400
	},
	button: {
		marginTop: (theme) => theme.spacing(2.5),
		width: '100%'
	},
	autoLoginText: {
		marginBottom: (theme) => theme.spacing(2),
		marginTop: (theme) => theme.spacing(4)
	}
}

class AutoLogin extends PureComponent {
	static propTypes = {
		autoLoginRetry: PropTypes.func.isRequired,

		errorMessages: PropTypes.object,
		logout: PropTypes.func.isRequired
	}

	render() {
		const { autoLoginRetry, errorMessages, logout } = this.props

		let message
		if (errorMessages) {
			message = (
				<Typography variant="body2" sx={sx.errorMessage}>
					{errorMessages.userMessage}
				</Typography>
			)
		}

		return (
			<Grid container align="center" justifyContent="center" sx={sx.root}>
				<Grid item xs={12}>
					<Paper sx={sx.paper}>
						<div>
							<Logo />
						</div>
						<Typography variant="body2" sx={sx.autoLoginText}>
							{t('app:AutoLogin.explanation')}
						</Typography>
						{message}
						<Button variant="contained" sx={sx.button} onClick={autoLoginRetry}>
							{t('app:AutoLogin.retry')}
						</Button>
						<Button variant="contained" sx={sx.button} onClick={logout}>
							{t('app:AutoLogin.cancel')}
						</Button>
					</Paper>
				</Grid>
			</Grid>
		)
	}
}

export default AutoLogin
