import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import SummaryAlerts from './SummaryAlerts'

class SummaryAlertsContainer extends PureComponent {
	static propTypes = {
		freights: PropTypes.array.isRequired
	}

	render() {
		const { freights } = this.props

		return <SummaryAlerts freights={freights} />
	}
}

const connector = connect((state) => ({
	freights: state.newCallOffState.freights
}))

export default connector(SummaryAlertsContainer)
