import jwtDecode from 'jwt-decode'
import { t } from 'i18next'

// Tokens are saved in memory cache for fast access and in localStorage for persistent cache.
const tokenCache = {
	accessToken: null,
	refreshToken: null
}

export const readAccessToken = () => {
	if (tokenCache.accessToken) {
		return tokenCache.accessToken
	}

	try {
		const tokensJSON = localStorage.getItem('tokens')
		if (tokensJSON) {
			const tokens = JSON.parse(tokensJSON)
			tokenCache.accessToken = tokens.accessToken
			return tokenCache.accessToken
		}
		return null
	} catch (error) {
		return null
	}
}

export const readRefreshToken = () => {
	if (tokenCache.refreshToken) {
		return tokenCache.refreshToken
	}

	try {
		const tokensJSON = localStorage.getItem('tokens')
		if (tokensJSON) {
			const tokens = JSON.parse(tokensJSON)
			tokenCache.refreshToken = tokens.refreshToken
			return tokenCache.refreshToken
		}
		return null
	} catch (error) {
		return null
	}
}

export const storeTokens = ({
	accessToken = null,
	refreshToken = null
} = {}) => {
	// Store tokens in memory, throw error if this fails
	const isAccessTokenValid = Boolean(jwtDecode(accessToken))
	const isRefreshTokenValid = Boolean(
		refreshToken === null || jwtDecode(refreshToken)
	)
	tokenCache.accessToken = isAccessTokenValid ? accessToken : null
	tokenCache.refreshToken = isRefreshTokenValid ? refreshToken : null

	// Store tokens in local storage (for persistence)
	localStorage.setItem('tokens', JSON.stringify(tokenCache))
}

export const refreshRequired = () => {
	const accessTokenData = jwtDecode(readAccessToken())
	// The expiration timestamp
	const exp = new Date(accessTokenData.exp * 1000)
	// The current timestamp
	const now = new Date()
	// Amount of ms remaining until expiration
	const diff = exp - now
	// Refresh when amount of remaining ms is less than 5 minutes
	return diff < 300000
}

export const authorization = () => {
	const accessToken = readAccessToken()
	if (!accessToken) {
		throw new Error(t('app:Api.Generic.tokenAccessMissing'))
	}
	return `Bearer ${accessToken}`
}

export const clearTokens = () => {
	tokenCache.accessToken = null
	tokenCache.refreshToken = null
	localStorage.removeItem('tokens')
}

export const clearRefreshToken = () => {
	const refreshToken = readRefreshToken()
	if (refreshToken) {
		const accessToken = readAccessToken()
		storeTokens({ accessToken, refreshToken: null })
	}
}
