import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import orderActionCreators from '../../../../redux/order/actionCreators'
import CallOffsFilter from './CallOffsFilter'

class CallOffsFilterContainer extends PureComponent {
	static propTypes = {
		filterCallOffs: PropTypes.func.isRequired,
		searchTerm: PropTypes.string
	}

	render() {
		const { filterCallOffs, searchTerm } = this.props

		return (
			<CallOffsFilter filterCallOffs={filterCallOffs} searchTerm={searchTerm} />
		)
	}
}

const connector = connect(
	(state) => ({
		searchTerm: state.orderState.filter.searchTerm
	}),
	{
		filterCallOffs: orderActionCreators.filterCallOffs
	}
)

export default connector(CallOffsFilterContainer)
