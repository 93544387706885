import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Grid,
	Divider,
	Hidden,
	MobileStepper,
	Stepper,
	Step,
	StepLabel,
	Typography,
	Paper
} from '@mui/material'
import { t } from 'i18next'

import ActionBarContainer from '../ActionBar/ActionBarContainer'
import MaterialSelectionContainer from '../MaterialSelection/MaterialSelectionContainer'
import DeliveryContainer from '../Delivery/DeliveryContainer'
import SummaryContainer from '../Summary/SummaryContainer'
import SummaryAlertsContainer from '../SummaryAlerts/SummaryAlertsContainer'
import {
	CALL_OFF_STEP_MATERIALS,
	CALL_OFF_STEP_DELIVERY,
	DELIVERY_TYPE_DELIVER
} from '../../../../utils/constants'

const sx = {
	typography: {
		textAlign: 'center'
	},
	mobileStepper: {
		background: 'none'
	},
	roundedCorners: {
		borderRadius: '4px'
	},
	stepperPadding: { padding: (theme) => theme.spacing(4) }
}

class OrderSteps extends PureComponent {
	static propTypes = {
		orderDetailRef: PropTypes.any,

		deliveryType: PropTypes.string.isRequired,
		callOffStep: PropTypes.number.isRequired
	}

	render() {
		const { orderDetailRef, deliveryType, callOffStep } = this.props

		const stepDescriptions = [
			{
				title: t('app:Orders.Details.stepMaterials'),
				subheading: t('app:Orders.Details.stepQuantitiesHelperText')
			},
			{
				title:
					deliveryType === DELIVERY_TYPE_DELIVER
						? t('app:Orders.Details.stepDelivery')
						: t('app:Orders.Details.stepPickup'),
				subheading: t('app:Orders.Details.stepDeliveryHelperText')
			},
			{
				title: t('app:Orders.Details.stepConfirm'),
				subheading: t('app:Orders.Details.stepConfirmHelperText')
			}
		]

		let stepContent
		let summaryAlerts
		if (callOffStep === CALL_OFF_STEP_MATERIALS) {
			stepContent = (
				<MaterialSelectionContainer orderDetailRef={orderDetailRef} />
			)
		} else if (callOffStep === CALL_OFF_STEP_DELIVERY) {
			stepContent = <DeliveryContainer />
		} else {
			stepContent = <SummaryContainer />
			summaryAlerts = <SummaryAlertsContainer />
		}

		// Adding key on div is a workaround for an issue that makes delivery screen disappear
		// after adding -webkit-overflow-scrolling: touch to App.js.
		// Without the key, the delivery screen will not be rendered on an actual iPad in portrait.
		return (
			<div key={callOffStep}>
				<Hidden mdDown>
					<Grid container direction="column" spacing={1}>
						<Grid item>
							<Paper sx={sx.roundedCorners}>
								<Stepper
									activeStep={callOffStep}
									alternativeLabel
									sx={{ ...sx.roundedCorners, ...sx.stepperPadding }}
								>
									<Step>
										<StepLabel>{stepDescriptions[0].title}</StepLabel>
									</Step>
									<Step>
										<StepLabel>{stepDescriptions[1].title}</StepLabel>
									</Step>
									<Step>
										<StepLabel>{stepDescriptions[2].title}</StepLabel>
									</Step>
								</Stepper>
							</Paper>
						</Grid>
						{summaryAlerts}
						<Grid item>
							<Paper sx={sx.roundedCorners}>
								{stepContent}
								<ActionBarContainer />
							</Paper>
						</Grid>
					</Grid>
				</Hidden>
				<Hidden mdUp>
					<Paper sx={sx.roundedCorners}>
						<Typography variant="h5" sx={sx.typography}>
							{stepDescriptions[callOffStep].title}
						</Typography>
						<Typography variant="caption" sx={sx.typography}>
							{stepDescriptions[callOffStep].subheading}
						</Typography>
						{stepContent}
						<MobileStepper
							steps={3}
							position="static"
							activeStep={callOffStep}
							sx={sx.mobileStepper}
						/>
						<Divider />
						<ActionBarContainer />
					</Paper>
				</Hidden>
			</div>
		)
	}
}

export default OrderSteps
