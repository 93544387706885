import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { t } from 'i18next'

import OrderDetail from './OrderDetail'
import PreventNavigationDialog from '../../Shared/PreventNavigationDialog'
import orderActionCreators from '../../../redux/order/actionCreators'
import newCallOffActionCreators from '../../../redux/newCallOff/actionCreators'

class OrderDetailContainer extends PureComponent {
	static propTypes = {
		callOffStep: PropTypes.number.isRequired,
		clearFinishError: PropTypes.func.isRequired,
		errorMessages: PropTypes.object,
		fetchOrder: PropTypes.func.isRequired,
		fetchOrderError: PropTypes.bool.isRequired,
		finish: PropTypes.func.isRequired,
		finishErrorMessages: PropTypes.object,
		hasUnsavedChanges: PropTypes.bool.isRequired,
		isFinishing: PropTypes.bool.isRequired,
		isLoading: PropTypes.bool.isRequired,
		order: PropTypes.object,
		selectedOrderId: PropTypes.string,
		selectedCallOffId: PropTypes.string,
		corrections: PropTypes.array,
		clearCorrections: PropTypes.func.isRequired
	}

	state = {
		isLoading: true
	}

	// Set initial loading true to prevent loading with an older orderState.
	static getDerivedStateFromProps(props, state) {
		if (state.isLoading && props.isLoading) {
			return { isLoading: false }
		}
		return null
	}

	componentDidMount() {
		const { fetchOrder, selectedOrderId, selectedCallOffId } = this.props
		fetchOrder(selectedOrderId, selectedCallOffId)
	}

	handleTryFetchAgain = () => {
		const { fetchOrder, selectedOrderId, selectedCallOffId } = this.props
		fetchOrder(selectedOrderId, selectedCallOffId)
	}

	handleTryFinishAgain = () => {
		const { finish } = this.props
		finish()
	}

	handleCancelFinish = () => {
		const { clearFinishError } = this.props
		clearFinishError()
	}

	handleConfirmCorrections = () => {
		const { clearCorrections } = this.props
		clearCorrections()
	}

	render() {
		const {
			order,
			isLoading: isLoadingProps,
			isFinishing,
			fetchOrderError,
			errorMessages,
			finishErrorMessages,
			callOffStep,
			hasUnsavedChanges,
			corrections
		} = this.props
		const { isLoading: isLoadingState } = this.state
		return (
			<Fragment>
				<PreventNavigationDialog
					open={hasUnsavedChanges}
					title={t('app:Materials.Messages.unsavedChangesTitle')}
					description={t('app:Materials.Messages.unsavedChangesWarning')}
					cancelButtonText={t('app:Materials.Messages.cancelButtonText')}
					confirmButtonText={t('app:Materials.Messages.confirmButtonText')}
					confirmButtonColor="error"
				/>
				<OrderDetail
					order={order}
					isLoading={isLoadingState || isLoadingProps}
					isFinishing={isFinishing}
					tryFetchAgain={this.handleTryFetchAgain}
					fetchOrderError={fetchOrderError}
					errorMessages={errorMessages}
					finishErrorMessages={finishErrorMessages}
					tryFinishAgain={this.handleTryFinishAgain}
					cancelFinish={this.handleCancelFinish}
					callOffStep={callOffStep}
					corrections={corrections}
					confirmCorrections={this.handleConfirmCorrections}
				/>
			</Fragment>
		)
	}
}

const connector = connect(
	(state) => ({
		callOffStep: state.newCallOffState.callOffStep,
		errorMessages: state.orderState.meta.errorMessages,
		fetchOrderError: state.orderState.meta.fetchOrderError,
		finishErrorMessages: state.newCallOffState.meta.finishErrorMessages,
		hasUnsavedChanges: state.newCallOffState.meta.hasUnsavedChanges,
		isFinishing: state.newCallOffState.meta.isFinishing,
		isLoading: state.orderState.meta.isLoading,
		order: state.orderState.order,
		corrections: state.newCallOffState.meta.corrections
	}),
	{
		clearCorrections: newCallOffActionCreators.clearCorrections,
		clearFinishError: newCallOffActionCreators.clearFinishError,
		fetchOrder: orderActionCreators.fetchOrder,
		finish: newCallOffActionCreators.finish
	}
)

export default connector(OrderDetailContainer)
