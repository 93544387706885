import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MaterialListItem from './MaterialListItem'
import actionCreators from '../../../../../redux/newCallOff/actionCreators'
import newCallOffSelectors from '../../../../../redux/newCallOff/selectors'

class MaterialListItemContainer extends PureComponent {
	static propTypes = {
		material: PropTypes.object.isRequired,
		width: PropTypes.string.isRequired,
		newCallOffMaterial: PropTypes.object.isRequired,
		isComplemented: PropTypes.bool.isRequired,
		canComplement: PropTypes.bool.isRequired,
		deliveryType: PropTypes.string.isRequired,
		updateMaterial: PropTypes.func.isRequired,
		totalWeight: PropTypes.number.isRequired,
		weightLimitPerTruck: PropTypes.number.isRequired,
		salesOrganization: PropTypes.string.isRequired,
		orderType: PropTypes.string.isRequired
	}

	handleMaterialChange = (material) => {
		const { updateMaterial } = this.props
		updateMaterial(material)
	}

	render() {
		const {
			material,
			width,
			newCallOffMaterial,
			isComplemented,
			canComplement,
			deliveryType,
			totalWeight,
			weightLimitPerTruck,
			salesOrganization,
			orderType
		} = this.props

		return (
			<MaterialListItem
				material={material}
				width={width}
				newCallOffMaterial={newCallOffMaterial}
				isComplemented={isComplemented}
				canComplement={canComplement}
				deliveryType={deliveryType}
				changeMaterial={this.handleMaterialChange}
				totalWeight={totalWeight}
				weightLimitPerTruck={weightLimitPerTruck}
				salesOrganization={salesOrganization}
				orderType={orderType}
			/>
		)
	}
}

const connector = connect(
	(state, props) => ({
		newCallOffMaterial: newCallOffSelectors.createMaterialSelector()(
			state,
			props
		),
		isComplemented: newCallOffSelectors.isComplementedSelector(state, props),
		canComplement: newCallOffSelectors.createMaterialCanComplementSelector()(
			state,
			props
		),
		deliveryType: state.newCallOffState.deliveryType,
		totalWeight: newCallOffSelectors.totalWeightSelector(state),
		weightLimitPerTruck:
			newCallOffSelectors.createMaterialWeightLimitPerTruckSelector()(
				state,
				props
			),
		salesOrganization: state.orderState.order.salesOrganization,
		orderType: state.orderState.order.orderType
	}),
	{
		updateMaterial: actionCreators.updateMaterial
	}
)

export default connector(MaterialListItemContainer)
