import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import {
	Button,
	CircularProgress,
	Grid,
	Paper,
	TextField,
	Typography,
	Box
} from '@mui/material'

import Logo from '../Logo'
import { red } from '@mui/material/colors'
import styled from '@emotion/styled'

const StyledPaper = styled(Paper)(({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	},
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2),
	margin: '0 auto',
	textAlign: 'center',
	width: 400
}))

const sx = {
	root: {
		flex: 1,
		paddingTop: (theme) => theme.spacing(12.5)
	},
	resetPasswordButton: {
		marginBottom: (theme) => theme.spacing(3.75),
		width: '100%'
	},
	explanation: {
		marginTop: (theme) => theme.spacing(2.5),
		textAlign: 'left'
	},
	showResetButton: {
		marginBottom: (theme) => theme.spacing(1.25),
		width: '100%'
	},
	toLoginButton: {
		width: '100%'
	},
	progress: {
		marginTop: (theme) => theme.spacing(2.5)
	},
	userNameTextField: {
		marginTop: (theme) => theme.spacing(2.5),
		marginBottom: (theme) => theme.spacing(3.75),
		width: '100%'
	},
	usernamePinTextField: {
		marginTop: (theme) => theme.spacing(2.5),
		width: '100%'
	},
	pinTextField: {
		marginTop: 0,
		width: '100%'
	},
	passwordTextField: {
		marginTop: 0,
		marginBottom: (theme) => theme.spacing(3.75),
		width: '100%'
	},
	errorMessage: {
		color: red[400],
		marginBottom: (theme) => theme.spacing(3.75)
	},
	resettingText: {
		marginTop: (theme) => theme.spacing(2),
		marginBottom: (theme) => theme.spacing(3.75)
	},
	cancelButton: {
		width: '100%'
	}
}

class ResetPassword extends PureComponent {
	static propTypes = {
		initialUserName: PropTypes.string,
		isResettingPassword: PropTypes.bool.isRequired,
		isRequestingPasswordReset: PropTypes.bool.isRequired,
		hasPasswordResetCode: PropTypes.bool.isRequired,
		errorMessages: PropTypes.object,
		showPasswordReset: PropTypes.func.isRequired,
		newPasswordReset: PropTypes.func.isRequired,
		resetPassword: PropTypes.func.isRequired
	}

	state = {
		userName: this.props.initialUserName,
		password: '',
		pin: ''
	}

	handleChangeUserName = (event) => {
		this.setState({
			userName: event.target.value
		})
	}

	handleChangePin = (event) => {
		this.setState({
			pin: event.target.value
		})
	}

	handleChangePassword = (event) => {
		this.setState({
			password: event.target.value
		})
	}

	handleShowReset = () => {
		const { showPasswordReset } = this.props
		showPasswordReset()
	}

	handleNewPasswordReset = () => {
		const { newPasswordReset } = this.props
		const { userName } = this.state
		newPasswordReset(userName)
	}

	handleResetPassword = () => {
		const { resetPassword } = this.props
		const { userName, password, pin } = this.state
		resetPassword(userName, password, pin)
	}

	render() {
		const {
			isResettingPassword,
			isRequestingPasswordReset,
			hasPasswordResetCode
		} = this.props

		if (isRequestingPasswordReset) {
			return this.renderRequesting()
		}

		if (isResettingPassword) {
			return this.renderResetting()
		}

		if (hasPasswordResetCode) {
			return this.renderResetPasswordForm()
		}

		return this.renderNewPasswordResetForm()
	}

	renderRequesting() {
		return (
			<Grid container align="center" justifyContent="center" sx={sx.root}>
				<Grid item xs={12}>
					<StyledPaper>
						<div>
							<Logo />
						</div>
						<CircularProgress sx={sx.progress} size={50} />
						<Typography variant="body2" sx={sx.resettingText}>
							{t('app:Login.requestingReset')}
						</Typography>
					</StyledPaper>
				</Grid>
			</Grid>
		)
	}

	renderResetting() {
		return (
			<Grid container align="center" justifyContent="center" sx={sx.root}>
				<Grid item xs={12}>
					<StyledPaper>
						<div>
							<Logo />
						</div>
						<CircularProgress sx={sx.progress} size={50} />
						<Typography variant="body2" sx={sx.resettingText}>
							{t('app:Login.resetting')}
						</Typography>
						<Button
							variant="contained"
							component={Link}
							to="/"
							sx={sx.cancelButton}
						>
							{t('app:Login.cancelReset')}
						</Button>
					</StyledPaper>
				</Grid>
			</Grid>
		)
	}

	renderNewPasswordResetForm() {
		const { errorMessages } = this.props
		const { userName } = this.state

		let message
		if (errorMessages) {
			message = (
				<Typography variant="body2" sx={sx.errorMessage}>
					{errorMessages.techMessage}
				</Typography>
			)
		}

		return (
			<Grid container align="center" direction="column" sx={sx.root}>
				<Grid item xs={12}>
					<StyledPaper>
						<div>
							<Logo />
						</div>
						<Box sx={sx.explanation}>
							<Typography variant="body2" paragraph>
								{t('app:Login.resetExplanation1')}
							</Typography>
							<Typography variant="body2">
								{t('app:Login.resetExplanation2')}
							</Typography>
						</Box>
						<TextField
							label={t('app:Login.userName')}
							sx={sx.userNameTextField}
							value={userName}
							onChange={this.handleChangeUserName}
							margin="normal"
							variant="standard"
						/>
						{message}
						<Button
							variant="contained"
							color="primary"
							sx={sx.resetPasswordButton}
							onClick={this.handleNewPasswordReset}
						>
							{t('app:Login.newReset')}
						</Button>
						<Button
							variant="contained"
							sx={sx.showResetButton}
							onClick={this.handleShowReset}
							color="grey"
						>
							{t('app:Login.resetHavePin')}
						</Button>
						<Button
							variant="contained"
							component={Link}
							to="/"
							sx={sx.toLoginButton}
							color="grey"
						>
							{t('app:Login.toLogin')}
						</Button>
					</StyledPaper>
				</Grid>
			</Grid>
		)
	}

	renderResetPasswordForm() {
		const { errorMessages } = this.props
		const { userName, pin, password } = this.state

		let message
		if (errorMessages) {
			message = (
				<Typography variant="body2" sx={sx.errorMessage}>
					{errorMessages.userMessage}
				</Typography>
			)
		}

		return (
			<Grid container align="center" direction="column" sx={sx.root}>
				<Grid item xs={12}>
					<StyledPaper>
						<div>
							<Logo />
						</div>
						<TextField
							label={t('app:Login.userName')}
							sx={sx.usernamePinTextField}
							value={userName}
							onChange={this.handleChangeUserName}
							margin="normal"
							variant="standard"
						/>
						<TextField
							label={t('app:Login.pin')}
							sx={sx.pinTextField}
							value={pin}
							onChange={this.handleChangePin}
							margin="normal"
							variant="standard"
						/>
						<TextField
							label={t('app:Login.password')}
							sx={sx.passwordTextField}
							type="password"
							value={password}
							onChange={this.handleChangePassword}
							margin="normal"
							variant="standard"
						/>
						{message}
						<Button
							variant="contained"
							color="primary"
							sx={sx.resetPasswordButton}
							onClick={this.handleResetPassword}
						>
							{t('app:Login.reset')}
						</Button>
						<Button
							variant="contained"
							component={Link}
							to="/"
							sx={sx.toLoginButton}
							color="grey"
						>
							{t('app:Login.toLogin')}
						</Button>
					</StyledPaper>
				</Grid>
			</Grid>
		)
	}
}

export default ResetPassword
