import React, { PureComponent } from 'react'
import { Typography, Box } from '@mui/material'
import { styled } from '@mui/styles'

const StyledBox = styled(Box)(({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	},
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2)
}))

class DisclaimerSIEn extends PureComponent {
	render() {
		return (
			<StyledBox>
				<Typography variant="h5" gutterBottom>
					Disclaimer Stradus Afroep App
				</Typography>
				<Typography variant="body2" gutterBottom>
					Alle op deze site gepresenteerde informatie is met de grootste zorg
					samengesteld. Niettemin kunnen wij niet instaan voor de aanwezigheid
					van eventuele fouten op de afroep applicatie.
				</Typography>
				<Typography variant="body2" gutterBottom>
					STRADUS N.V., de directie, haar medewerkers en/of bij deze site
					betrokken derden zijn niet aansprakelijk voor de inhoud van de
					pagina&apos;s en enige schade en/of verlies veroorzaakt door het
					gebruik van de informatie en/of programmatuur op deze site.
				</Typography>
				<Typography variant="h6" gutterBottom>
					Intellectuele eigendomsrechten
				</Typography>
				<Typography variant="body2" gutterBottom>
					De intellectuele eigendomsrechten met betrekking tot de inhoud van
					deze applicatie berusten bij STRADUS N.V.. Het is de gebruiker van
					deze site niet toegestaan teksten, pictogrammen of programmatuur te
					wijzigen, geheel of gedeeltelijk te kopiëren, te verspreiden of
					openbaar te maken zonder uitdrukkelijke en voorafgaande schriftelijke
					toestemming van STRADUS N.V..
				</Typography>
				<Typography variant="body2" gutterBottom>
					De informatie en/of programmatuur op deze applicatie is aan
					verandering onderhevig en kan zonder voorafgaande mededeling worden
					gewijzigd of verwijderd. Wij behouden ons uitdrukkelijk het recht voor
					producten en inhoud van producten te wijzigen zonder voorafgaande
					mededeling. Eventueel vermelde product- en merknamen zijn eigendom van
					de geregistreerde eigenaren.
				</Typography>
			</StyledBox>
		)
	}
}

export default DisclaimerSIEn
