import actionTypes from './actionTypes'

const actionCreators = {
	addNotification: (message, isNetworkError) => ({
		type: actionTypes.ADD,
		payload: {
			message,
			isNetworkError: Boolean(isNetworkError)
		}
	}),

	removeNotification: (id) => ({
		type: actionTypes.REMOVE,
		meta: {
			id
		}
	})
}
export default actionCreators
