import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
	Box,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography
} from '@mui/material'
import StatusIcon from '../../../Shared/StatusIcon'
import StatusText from '../../../Shared/StatusText'
import { getTrackAndTraceTextForCallOff } from '../../../../utils/trackAndTrace'

class CallOffsListItem extends PureComponent {
	static propTypes = {
		callOff: PropTypes.object.isRequired,
		selectedOrderId: PropTypes.string.isRequired,
		showDivider: PropTypes.bool.isRequired,
		deliveryWindows: PropTypes.object
	}

	render() {
		const { callOff, selectedOrderId, deliveryWindows, showDivider } =
			this.props
		const { callOffStatus, orderNumber, createdBy } = callOff

		let callOffCreatedBy = ''
		if (createdBy) {
			callOffCreatedBy = `${createdBy.firstName} ${createdBy.lastName}`
		}

		const trackAndTraceInfo = getTrackAndTraceTextForCallOff({
			callOff,
			deliveryWindows
		})

		const primary = (
			<Typography variant="body1">
				{orderNumber} {callOffCreatedBy}
			</Typography>
		)

		let secondary = (
			<Box>
				<Typography variant="body1" color="text.secondary">
					{callOff.instructions.line2}
				</Typography>
				<Typography variant="body2" color="text.secondary">
					{trackAndTraceInfo}
				</Typography>
			</Box>
		)

		return (
			<ListItem
				button
				divider={showDivider}
				component={Link}
				to={`/orders/${selectedOrderId}/calloffs/${callOff.id}/trackandtrace`}
			>
				<ListItemAvatar>
					<StatusIcon status={callOffStatus} />
				</ListItemAvatar>
				<ListItemText
					disableTypography
					primary={primary}
					secondary={secondary}
				/>
				<StatusText status={callOffStatus} />
			</ListItem>
		)
	}
}

export default CallOffsListItem
