import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import newCallOffSelectors from '../../../../../redux/newCallOff/selectors'
import newCallOffActionCreators from '../../../../../redux/newCallOff/actionCreators'
import MaterialSelectionFilter from './MaterialSelectionFilter'

class MaterialSelectionFilterContainer extends PureComponent {
	static propTypes = {
		search: PropTypes.func.isRequired,
		query: PropTypes.string.isRequired,
		isClassificationsFilterActive: PropTypes.bool.isRequired
	}

	handleSearch = (query) => {
		const { search } = this.props
		search(query)
	}

	handleClearSearch = () => {
		const { search } = this.props
		search('')
	}

	render() {
		const { query, isClassificationsFilterActive } = this.props

		return (
			<MaterialSelectionFilter
				search={this.handleSearch}
				clearSearch={this.handleClearSearch}
				query={query}
				isClassificationsFilterActive={isClassificationsFilterActive}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		query: state.newCallOffState.query,
		isClassificationsFilterActive:
			newCallOffSelectors.isClassificationsFilterActiveSelector(state)
	}),
	{
		search: newCallOffActionCreators.search
	}
)

export default connector(MaterialSelectionFilterContainer)
