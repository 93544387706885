import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import i18next, { t } from 'i18next'
import {
	Button,
	Grid,
	Typography,
	TextField,
	CircularProgress,
	keyframes
} from '@mui/material'

import { DELIVERY_TYPE_PICKUP } from '../../../../../utils/constants'
import FindAddressDialog from './FindAddressDialog/FindAddressDialogContainer'
import { styled } from '@mui/styles'

const mixinsGuttersStyle = ({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	}
})

const StyledGrid = styled(Grid)(mixinsGuttersStyle)

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const sx = {
	mainContent: {
		paddingBottom: (theme) => theme.spacing(2)
	},
	typography: {
		paddingTop: (theme) => theme.spacing(2),
		fontWeight: (theme) => theme.typography.fontWeightMedium
	},
	spin: {
		animation: `${spin} 1.4s linear infinite`
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: (theme) => theme.spacing(-1.5),
		marginLeft: (theme) => theme.spacing(-1.5)
	}
}

class Address extends PureComponent {
	static propTypes = {
		freight: PropTypes.object.isRequired,
		availableCountries: PropTypes.array.isRequired,
		deliveryType: PropTypes.string.isRequired,
		isLoadingAddresses: PropTypes.bool.isRequired,
		streetChange: PropTypes.func.isRequired,
		houseNumberChange: PropTypes.func.isRequired,
		zipCodeChange: PropTypes.func.isRequired,
		cityChange: PropTypes.func.isRequired,
		fetchAddresses: PropTypes.func.isRequired,
		handleFillAddressEverywhere: PropTypes.func.isRequired,
		showFillAddressButton: PropTypes.bool.isRequired
	}

	handleSearchAddress = () => {
		const { fetchAddresses } = this.props
		fetchAddresses()
	}

	handleStreetChange = (event) => {
		const { streetChange } = this.props
		streetChange(event.target.value)
	}

	handleHouseNumberChange = (event) => {
		const { houseNumberChange } = this.props
		houseNumberChange(event.target.value)
	}

	handleZipCodeChange = (event) => {
		const { zipCodeChange } = this.props
		zipCodeChange(event.target.value)
	}

	handleCityChange = (event) => {
		const { cityChange } = this.props
		cityChange(event.target.value)
	}

	handleKeyPress = (event) => {
		const { freight, availableCountries, fetchAddresses } = this.props
		if (freight.country) {
			const { addressLookup } = availableCountries.find(
				({ code }) => code === freight.country
			)
			if (addressLookup && event.key === 'Enter') {
				fetchAddresses()
			}
		}
	}

	render() {
		const {
			freight,
			availableCountries,
			deliveryType,
			isLoadingAddresses,
			handleFillAddressEverywhere,
			showFillAddressButton
		} = this.props
		const language = i18next.language
		const isPickup = deliveryType === DELIVERY_TYPE_PICKUP

		let inputProps = {}
		if (isPickup) {
			inputProps = {
				readOnly: true
			}
		}

		const country = availableCountries.find(
			({ code }) => code === freight.country
		)

		// Only show address lookup button if country supports it
		let addressLookupButton
		if (!isPickup && freight.country) {
			const { addressLookup } = availableCountries.find(
				({ code }) => code === freight.country
			)
			if (addressLookup) {
				addressLookupButton = (
					<Grid item>
						<Button
							disabled={isLoadingAddresses}
							variant="outlined"
							color="primary"
							onClick={this.handleSearchAddress}
						>
							{t('app:Delivery.findAddress')}
							{isLoadingAddresses && (
								<CircularProgress size={24} sx={sx.buttonProgress} />
							)}
						</Button>
					</Grid>
				)
			}
		}

		let fillAddressEverywhereButton
		if (!isPickup && showFillAddressButton) {
			fillAddressEverywhereButton = (
				<Grid item>
					<Button variant="outlined" onClick={handleFillAddressEverywhere}>
						{t('app:Delivery.fillAddressOnAllCallOffs')}
					</Button>
				</Grid>
			)
		}

		let buttons
		if (addressLookupButton || fillAddressEverywhereButton) {
			buttons = (
				<Grid item xs={12}>
					<Grid container spacing={2}>
						{addressLookupButton}
						{fillAddressEverywhereButton}
					</Grid>
				</Grid>
			)
		}

		let ferryCosts
		if (freight.hasFerryCosts) {
			ferryCosts = (
				<Grid item xs={12}>
					<Typography variant="body2">
						<span role="img" aria-label="warning">
							⚠️
						</span>{' '}
						{t('app:Delivery.ferryCostsAttached')}
					</Typography>
				</Grid>
			)
		}

		let tunnelCosts
		if (freight.hasTunnelCosts) {
			tunnelCosts = (
				<Grid item xs={12}>
					<Typography variant="body2">
						<span role="img" aria-label="warning">
							⚠️
						</span>{' '}
						{t('app:Delivery.tunnelCostsAttached')}
					</Typography>
				</Grid>
			)
		}

		return (
			<Fragment>
				<FindAddressDialog />
				<StyledGrid
					container
					spacing={2}
					alignItems="center"
					sx={sx.mainContent}
				>
					<Grid item xs={12}>
						<Typography variant="subtitle1" sx={sx.typography}>
							{isPickup
								? t('app:Pickup.deliveryAddress')
								: t('app:Delivery.deliveryAddress')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid
							container
							spacing={2}
							alignItems="center"
							justifyContent="space-between"
						>
							<Grid item xs={12} sm={6}>
								<TextField
									value={country.descriptions[language]}
									required={false}
									variant="outlined"
									fullWidth
									label={t('app:Delivery.country')}
									InputProps={{ readOnly: true }}
								/>
							</Grid>
							<Grid item xs={8}>
								<TextField
									value={
										isPickup
											? freight.street || t('app:Generic.unknown')
											: freight.street
									}
									variant="outlined"
									required={!isPickup}
									fullWidth
									label={t('app:Delivery.street')}
									onChange={this.handleStreetChange}
									inputProps={{ maxLength: 35 }}
									InputProps={inputProps}
									onKeyPress={this.handleKeyPress}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextField
									value={
										isPickup
											? freight.houseNumber || t('app:Generic.unknown')
											: freight.houseNumber
									}
									variant="outlined"
									fullWidth
									label={t('app:Delivery.houseNumber')}
									onChange={this.handleHouseNumberChange}
									inputProps={{ maxLength: 10 }}
									InputProps={inputProps}
									onKeyPress={this.handleKeyPress}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									value={
										isPickup
											? freight.zipCode || t('app:Generic.unknown')
											: freight.zipCode
									}
									variant="outlined"
									required={!isPickup}
									fullWidth
									label={t('app:Delivery.zipCode')}
									onChange={this.handleZipCodeChange}
									inputProps={{ maxLength: 10 }}
									InputProps={inputProps}
									onKeyPress={this.handleKeyPress}
								/>
							</Grid>
							<Grid item xs={12} sm={8}>
								<TextField
									value={
										isPickup
											? freight.city || t('app:Generic.unknown')
											: freight.city
									}
									variant="outlined"
									required={!isPickup}
									fullWidth
									label={t('app:Delivery.city')}
									onChange={this.handleCityChange}
									inputProps={{ maxLength: 35 }}
									InputProps={inputProps}
									onKeyPress={this.handleKeyPress}
								/>
							</Grid>
							{ferryCosts}
							{tunnelCosts}
							{buttons}
						</Grid>
					</Grid>
				</StyledGrid>
			</Fragment>
		)
	}
}

export default Address
