import {
	Box,
	Button,
	CircularProgress,
	Grid,
	Paper,
	TextField,
	Typography
} from '@mui/material'
import { red } from '@mui/material/colors'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../Logo'

const registerUrlSVI =
	'https://www.struykverwoinfra.nl/afroep-app-formulier.html'
const registerUrlMX =
	'https://www.stradus.be/nl/roep-nu-digitaal-af-met-onze-afroep-app'

const registerUrlMX_FR =
	'https://www.stradus.be/fr/appelez-maintenant-par-voie-numerique-avec-notre-application-de-commande'

const sx = {
	root: {
		flex: 1,
		paddingTop: (theme) => theme.spacing(12.5)
	},
	loginPaper: {
		paddingLeft: (theme) => theme.spacing(2),
		paddingRight: (theme) => theme.spacing(2),
		[(theme) => theme.breakpoints.up('sm')]: {
			paddingLeft: (theme) => theme.spacing(3),
			paddingRight: (theme) => theme.spacing(3)
		},
		margin: '0 auto',
		paddingTop: (theme) => theme.spacing(2),
		paddingBottom: (theme) => theme.spacing(2),
		textAlign: 'center',
		width: 400
	},
	cancelLoginButton: {
		marginTop: (theme) => theme.spacing(2.5),
		width: '100%'
	},
	loginButton: {
		marginBottom: (theme) => theme.spacing(1),
		width: '100%'
	},
	resetPasswordButton: {
		width: '100%'
	},
	progress: {
		marginTop: (theme) => theme.spacing(2)
	},
	userNameTextField: {
		marginTop: (theme) => theme.spacing(2.5),
		width: '100%'
	},
	passwordTextField: {
		marginTop: 0,
		marginBottom: (theme) => theme.spacing(3.75),
		width: '100%'
	},
	errorMessage: {
		color: red[400],
		marginBottom: (theme) => theme.spacing(3.75)
	},
	loggingInText: {
		marginTop: (theme) => theme.spacing(2)
	}
}

class Login extends PureComponent {
	static propTypes = {
		errorMessages: PropTypes.object,
		isLoggingIn: PropTypes.bool,
		login: PropTypes.func.isRequired,
		logout: PropTypes.func.isRequired
	}

	state = {
		userName: '',
		password: ''
	}

	login() {
		const { userName, password } = this.state
		const { login } = this.props
		if (userName.length > 0 && password.length > 0) {
			this.setState({
				userName: '',
				password: ''
			})
			login(userName, password)
		}
	}

	cancelLogin() {
		// Calling the logout action will automatically cancel any running login requests
		const { logout } = this.props
		logout()
	}

	handleChangeUserName = (event) => {
		this.setState({
			userName: event.target.value
		})
	}

	handleKeyPressUserName = (event) => {
		if (event.key === 'Enter') {
			this.login()
		}
	}

	handleChangePassword = (event) => {
		this.setState({
			password: event.target.value
		})
	}

	handleKeyPressPassword = (event) => {
		if (event.key === 'Enter') {
			this.login()
		}
	}

	handleLogin = () => {
		this.login()
	}

	handleCancelLogin = () => {
		this.cancelLogin()
	}

	render() {
		const { isLoggingIn } = this.props

		if (isLoggingIn) {
			return this.renderLoggingIn()
		}
		return this.renderLoginForm()
	}

	renderLoggingIn() {
		return (
			<Grid container align="center" justifyContent="center" sx={sx.root}>
				<Grid item xs={12}>
					<Paper sx={sx.loginPaper}>
						<Box>
							<Logo />
						</Box>
						<CircularProgress sx={sx.progress} size={50} />
						<Typography variant="body2" sx={sx.loggingInText}>
							{t('app:Login.loggingIn')}
						</Typography>
						<Button
							variant="contained"
							sx={sx.cancelLoginButton}
							onClick={this.handleCancelLogin}
							color="grey"
						>
							{t('app:Login.cancelLogin')}
						</Button>
					</Paper>
				</Grid>
			</Grid>
		)
	}

	renderLoginForm() {
		const { errorMessages } = this.props
		const { hostname } = window.location
		let registerButton

		if (hostname.includes('struykverwoinfra')) {
			registerButton = (
				<Box>
					<Button
						href={registerUrlSVI}
						target="_blank"
						sx={sx.resetPasswordButton}
					>
						{t('app:Login.register')}
					</Button>
				</Box>
			)
		} else {
			registerButton = (
				<Box>
					<Button
						href={registerUrlMX}
						target="_blank"
						sx={sx.resetPasswordButton}
					>
						{t('app:Login.registerNL')}
					</Button>
					<Button
						href={registerUrlMX_FR}
						target="_blank"
						sx={sx.resetPasswordButton}
					>
						{t('app:Login.registerFR')}
					</Button>
				</Box>
			)
		}

		let message
		if (errorMessages) {
			message = (
				<Typography variant="body2" sx={sx.errorMessage}>
					{errorMessages.techMessage}
				</Typography>
			)
		}

		return (
			<Grid container align="center" direction="column" sx={sx.root}>
				<Grid item md={12}>
					<Paper sx={sx.loginPaper}>
						<div>
							<Logo />
						</div>
						<TextField
							label={t('app:Login.userName')}
							sx={sx.userNameTextField}
							value={this.state.userName}
							onChange={this.handleChangeUserName}
							onKeyPress={this.handleKeyPressUserName}
							margin="normal"
							variant="standard"
						/>
						<TextField
							label={t('app:Login.password')}
							sx={sx.passwordTextField}
							type="password"
							value={this.state.password}
							onChange={this.handleChangePassword}
							onKeyPress={this.handleKeyPressPassword}
							margin="normal"
							variant="standard"
						/>
						{message}
						<Button
							variant="contained"
							color="primary"
							sx={sx.loginButton}
							onClick={this.handleLogin}
						>
							{t('app:Login.login')}
						</Button>
						<Button component={Link} to="/password" sx={sx.resetPasswordButton}>
							{t('app:Login.resetPassword')}
						</Button>
						{registerButton}
					</Paper>
				</Grid>
			</Grid>
		)
	}
}

export default Login
