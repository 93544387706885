import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import i18next, { t } from 'i18next'
import {
	Button,
	Grid,
	ListItem,
	Typography,
	SvgIcon,
	TextField
} from '@mui/material'

import {
	ToggleOnFas,
	ToggleOffFal,
	PlusFal,
	ExclamationTriangleFal
} from '@oliverit/react-fontawesome'
import numeral from 'numeral'

import {
	getQuantityInAnotherUnit,
	getQuantityToComplement
} from '../../../../../utils/materials'
import { DELIVERY_TYPE_PICKUP } from '../../../../../utils/constants'
import AddMaterialDialog from '../AddMaterialDialog/AddMaterialDialog'
import MaterialParticulars from './MaterialParticulars'
import { red } from '@mui/material/colors'

const sx = {
	disabled: {
		color: (theme) => theme.palette.text.disabled
	},
	itemHeight: {
		height: '100%'
	},
	borderLeft: {
		borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
		paddingLeft: (theme) => theme.spacing()
	},
	buttonColorPrimary: {
		'.MuiButton-outlined': {
			borderColor: red[500]
		},
		borderColor: red[500]
	}
}

class MaterialListItem extends PureComponent {
	static propTypes = {
		material: PropTypes.object.isRequired,
		width: PropTypes.string.isRequired,
		newCallOffMaterial: PropTypes.object.isRequired,
		isComplemented: PropTypes.bool.isRequired,
		canComplement: PropTypes.bool.isRequired,
		deliveryType: PropTypes.string.isRequired,
		changeMaterial: PropTypes.func.isRequired,
		totalWeight: PropTypes.number.isRequired,
		weightLimitPerTruck: PropTypes.number.isRequired,
		salesOrganization: PropTypes.string.isRequired,
		orderType: PropTypes.string.isRequired
	}

	state = {
		addMaterialDialogOpen: false
	}

	componentDidUpdate(prevProps) {
		const { deliveryType, newCallOffMaterial } = this.props
		const isTradingLocation = newCallOffMaterial.shippingPoint.endsWith('90')
		// Pickup orders can't be complemented
		if (
			deliveryType === DELIVERY_TYPE_PICKUP &&
			prevProps.deliveryType !== deliveryType &&
			newCallOffMaterial.complemented
		) {
			this.resetMaterial()
		}
		// Can't pickup from tradinglocations
		if (
			deliveryType === DELIVERY_TYPE_PICKUP &&
			prevProps.deliveryType !== deliveryType &&
			isTradingLocation
		) {
			this.resetMaterial()
		}
	}

	handleAddMaterialButtonClick = () => {
		this.setState({
			addMaterialDialogOpen: true
		})
	}

	handleCancel = () => {
		this.setState({
			addMaterialDialogOpen: false
		})
	}

	handleConfirm = ({ quantity, unit }) => {
		const { newCallOffMaterial, changeMaterial } = this.props
		changeMaterial({ ...newCallOffMaterial, quantity, unit })

		this.setState({
			addMaterialDialogOpen: false
		})
	}

	handleQuantityChange = (event) => {
		const quantity = event.target.value
		if (quantity < 0) {
			return
		}
		const { newCallOffMaterial, changeMaterial } = this.props
		changeMaterial({ ...newCallOffMaterial, quantity })
	}

	handleUnitChange = (unit) => {
		const { newCallOffMaterial, changeMaterial } = this.props
		changeMaterial({ ...newCallOffMaterial, unit })
	}

	handleComplementedChange = () => {
		const {
			newCallOffMaterial,
			changeMaterial,
			totalWeight,
			weightLimitPerTruck,
			material
		} = this.props
		const { baseUnit } = material

		const complementedQuantityInPAK = getQuantityToComplement({
			newCallOffMaterial,
			material,
			weightLimitPerTruck,
			totalWeight
		})

		changeMaterial({
			...newCallOffMaterial,
			complemented: !newCallOffMaterial.complemented,
			quantity: !newCallOffMaterial.complemented
				? Number(complementedQuantityInPAK)
				: '',
			unit: !newCallOffMaterial.complemented ? 'PA' : baseUnit
		})
	}

	resetMaterial = () => {
		const { newCallOffMaterial, changeMaterial, material } = this.props
		const { baseUnit } = material
		changeMaterial({
			...newCallOffMaterial,
			complemented: false,
			quantity: 0,
			unit: baseUnit
		})
	}

	render() {
		const {
			material,
			newCallOffMaterial,
			isComplemented,
			canComplement,
			deliveryType,
			totalWeight,
			weightLimitPerTruck,
			salesOrganization,
			orderType,
			width
		} = this.props
		const { addMaterialDialogOpen } = this.state
		const {
			unitConversions,
			quantityAvailable,
			quantityStock,
			baseUnit,
			shippingPoint
		} = material
		const isTradingLocation = shippingPoint.endsWith('90')
		const isPickup = deliveryType === DELIVERY_TYPE_PICKUP
		const isSmallScreen = width === 'xs'

		let addMaterialDialog = null
		if (addMaterialDialogOpen) {
			addMaterialDialog = (
				<AddMaterialDialog
					open={addMaterialDialogOpen}
					cancel={this.handleCancel}
					confirm={this.handleConfirm}
					material={material}
					newCallOffMaterial={newCallOffMaterial}
					totalWeight={totalWeight}
					weightLimitPerTruck={weightLimitPerTruck}
					salesOrganization={salesOrganization}
					orderType={orderType}
					changeQuantity={this.handleQuantityChange}
					changeUnit={this.handleUnitChange}
					width={width}
				/>
			)
		}

		let callOffButtonDisabled
		if (isTradingLocation) {
			callOffButtonDisabled = isComplemented || !quantityAvailable
		} else {
			callOffButtonDisabled = isComplemented || !quantityStock
		}

		const callOffButton = (
			<Button
				fullWidth
				size="small"
				variant="outlined"
				color="primary"
				disabled={callOffButtonDisabled}
				onClick={this.handleAddMaterialButtonClick}
			>
				<Grid container direction="column">
					<Grid item>
						<SvgIcon fontSize="small">
							<PlusFal />
						</SvgIcon>
					</Grid>
					<Grid item>
						<Typography
							variant="caption"
							sx={callOffButtonDisabled ? sx.disabled : null}
							color={callOffButtonDisabled ? 'textSecondary' : 'primary'}
						>
							{t('app:Materials.callOffs')}
						</Typography>
					</Grid>
				</Grid>
			</Button>
		)

		// ComplementFreightButton is not visible for 'CUSTOM' materials
		const complementFreightButton = (
			<Button
				id={`${material.materialNumber}_${material.site}`}
				fullWidth
				size="small"
				variant="outlined"
				onClick={this.handleComplementedChange}
				disabled={!canComplement}
				// color={newCallOffMaterial.complemented ? 'primary' : 'inherit'}
				sx={{
					borderColor: (theme) =>
						newCallOffMaterial.complemented
							? theme.palette.primary.main
							: theme.palette.grey[400]
				}}
				color="inherit"
				disableRipple
				disableFocusRipple
			>
				<Grid container direction="column">
					<Grid item>
						{newCallOffMaterial.complemented ? (
							<SvgIcon fontSize="small" color="primary">
								<ToggleOnFas />
							</SvgIcon>
						) : (
							<SvgIcon fontSize="small">
								<ToggleOffFal />
							</SvgIcon>
						)}
					</Grid>
					<Grid item>
						<Typography
							sx={canComplement ? null : sx.disabled}
							color={newCallOffMaterial.complemented ? 'primary' : 'inherit'}
							variant="caption"
						>
							{t('app:Materials.complementFreight')}
						</Typography>
					</Grid>
				</Grid>
			</Button>
		)

		let actions
		if (isPickup && isTradingLocation) {
			// Materials can't be picked up from a business location (business locations end with '90')
			actions = (
				<Grid item xs={8}>
					<Button fullWidth size="small" variant="outlined" disabled>
						<Grid container direction="column">
							<Grid item>
								<SvgIcon fontSize="small">
									<ExclamationTriangleFal />
								</SvgIcon>
							</Grid>
							<Grid item>
								<Typography sx={sx.disabled} variant="caption">
									{t('app:Materials.noPickup')}
								</Typography>
							</Grid>
						</Grid>
					</Button>
				</Grid>
			)
		} else if (
			material.unitConversions.some(({ unit }) => unit === 'PA') &&
			!isPickup
		) {
			// Only show complementFreight (afladen) button if the material contains PAK unit and deliveryType is not pickUp
			actions = (
				<Fragment>
					<Grid item xs={4}>
						{complementFreightButton}
					</Grid>
					<Grid item xs={4}>
						{callOffButton}
					</Grid>
				</Fragment>
			)
		} else {
			actions = (
				<Grid item xs={8}>
					{callOffButton}
				</Grid>
			)
		}

		// Calculate base quantity
		const quantityInBaseUnit = getQuantityInAnotherUnit({
			unitConversions,
			quantity: newCallOffMaterial.quantity,
			fromUnit: newCallOffMaterial.unit,
			toUnit: material.baseUnit
		})
		const conversionBaseUnit = material.unitConversions.find(
			({ unit }) => unit === material.baseUnit
		)
		const baseQuantityValue = `${
			numeral(quantityInBaseUnit).format(0, 0) || 0
		} ${conversionBaseUnit.descriptions.short[i18next.language]}`

		let quantityValue
		if (baseUnit === newCallOffMaterial.unit) {
			quantityValue = baseQuantityValue
		} else if (newCallOffMaterial.quantity === '0') {
			quantityValue = baseQuantityValue
		} else if (newCallOffMaterial.complemented) {
			// If complemented (afgeladen), show PAK unit
			const conversionPAK = material.unitConversions.find(
				({ unit }) => unit === 'PA'
			)
			quantityValue = `${
				numeral(newCallOffMaterial.quantity).format(0, 0) || 0
			} ${
				conversionPAK.descriptions.short[i18next.language]
			} (${baseQuantityValue})`
		} else {
			// If not complemented, show selected unit
			const conversionSelectedUnit = material.unitConversions.find(
				({ unit }) => unit === newCallOffMaterial.unit
			)
			quantityValue = `${
				numeral(newCallOffMaterial.quantity).format(0, 0) || 0
			} ${
				conversionSelectedUnit.descriptions.short[i18next.language]
			} (${baseQuantityValue})`
		}

		return (
			<ListItem divider sx={sx.itemHeight}>
				{addMaterialDialog}
				<Grid
					container
					spacing={1}
					alignItems="flex-start"
					justifyContent="space-between"
				>
					<Grid item xs={12}>
						<Typography
							variant={isSmallScreen ? 'body1' : 'h6'}
							noWrap={!isSmallScreen}
						>
							{material.descriptions[i18next.language]}
						</Typography>
						<Typography variant="caption">
							{material.shippingPointDescription}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid
							container
							direction="row"
							alignItems="flex-start"
							justifyContent="space-between"
						>
							<Grid item sm={7} xs={12}>
								<MaterialParticulars material={material} />
							</Grid>
							<Grid
								item
								sm={5}
								xs={12}
								sx={!isSmallScreen ? sx.borderLeft : null}
							>
								<Grid container spacing={1}>
									<Grid item xs={4}>
										<TextField
											label={t('app:Materials.quantity')}
											InputProps={{ readOnly: true, disableUnderline: true }}
											InputLabelProps={{
												focused: Boolean(newCallOffMaterial.quantity)
											}}
											value={quantityValue}
											fullWidth
											variant="standard"
										/>
									</Grid>
									{actions}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ListItem>
		)
	}
}

// Do not use withWidth here because it will break the CellMeasurer in the MaterialList
export default MaterialListItem
