import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ordersSelector from '../../../../redux/orders/selectors'
import userSelector from '../../../../redux/user/selectors'
import orderActionCreators from '../../../../redux/orders/actionCreators'

import OrderList from './OrderList'

class OrderListContainer extends PureComponent {
	static propTypes = {
		selectedOrderId: PropTypes.string,
		isLoading: PropTypes.bool.isRequired,
		orders: PropTypes.array,
		favoriteOrders: PropTypes.array,
		addFavoriteOrder: PropTypes.func.isRequired,
		removeFavoriteOrder: PropTypes.func.isRequired,
		hasMultipleCustomers: PropTypes.bool.isRequired,
		fetchOrders: PropTypes.func.isRequired
	}

	componentDidMount() {
		const { fetchOrders } = this.props
		fetchOrders()
	}

	render() {
		const {
			isLoading,
			orders,
			selectedOrderId,
			favoriteOrders,
			addFavoriteOrder,
			removeFavoriteOrder,
			hasMultipleCustomers
		} = this.props
		return (
			<OrderList
				selectedOrderId={selectedOrderId}
				orders={orders}
				isLoading={isLoading}
				favoriteOrders={favoriteOrders}
				addFavoriteOrder={addFavoriteOrder}
				removeFavoriteOrder={removeFavoriteOrder}
				hasMultipleCustomers={hasMultipleCustomers}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		orders: ordersSelector.filteredOrdersSelector(
			state.ordersState,
			userSelector.favoriteOrdersSelector(state.userState)
		),
		hasMultipleCustomers: ordersSelector.hasMultipleCustomersSelector(
			state.ordersState
		),
		isLoading: state.ordersState.meta.isLoading,
		favoriteOrders: userSelector.favoriteOrdersSelector(state.userState)
	}),
	{
		addFavoriteOrder: orderActionCreators.addFavoriteOrder,
		removeFavoriteOrder: orderActionCreators.removeFavoriteOrder,
		fetchOrders: orderActionCreators.fetchOrders
	}
)

export default connector(OrderListContainer)
