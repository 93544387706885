import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import newCallOffSelectors from '../../../../redux/newCallOff/selectors'
import TotalOverview from './TotalOverview'

class TotalOverviewContainer extends PureComponent {
	static propTypes = {
		deliveryType: PropTypes.string.isRequired,
		totalWeight: PropTypes.number.isRequired,
		totalCosts: PropTypes.number.isRequired,
		totalLocations: PropTypes.number.isRequired,
		totalFreights: PropTypes.number.isRequired
	}

	render() {
		const {
			deliveryType,
			totalWeight,
			totalCosts,
			totalLocations,
			totalFreights
		} = this.props

		return (
			<TotalOverview
				totalWeight={totalWeight}
				totalCosts={totalCosts}
				totalLocations={totalLocations}
				totalFreights={totalFreights}
				deliveryType={deliveryType}
			/>
		)
	}
}

const connector = connect((state) => ({
	deliveryType: state.newCallOffState.deliveryType,
	totalWeight: newCallOffSelectors.totalWeightSelector(state),
	totalCosts: newCallOffSelectors.totalCostsSelector(state),
	totalLocations: newCallOffSelectors.totalLocationsSelector(state),
	totalFreights: newCallOffSelectors.totalFreightsSelector(state)
}))

export default connector(TotalOverviewContainer)
