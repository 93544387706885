import { all, call, fork, put, take, select } from 'redux-saga/effects'
import { t } from 'i18next'

import apiOrders from '../../services/apiOrders'
import actionTypes from './actionTypes'
import ordersActionCreators from './actionCreators'
import notificationsActionCreators from '../notifications/actionCreators'
import { NetworkError, NotFoundError } from '../../utils/errors'
import history from '../../utils/history'

function* fetchOrders() {
	while (true) {
		const action = yield take([
			actionTypes.FETCH_ORDERS,
			actionTypes.REFRESH_ORDERS
		])
		if (action.type === actionTypes.REFRESH_ORDERS) {
			yield put(ordersActionCreators.refreshOrdersRequest())
		} else {
			yield put(ordersActionCreators.fetchOrdersRequest())
		}
		try {
			const orders = yield call(apiOrders.getOrders)
			yield put(ordersActionCreators.fetchOrdersSuccess(orders))
			if (action.type === actionTypes.REFRESH_ORDERS) {
				const successMessage = t('app:Orders.Master.refreshSuccess')
				yield put(notificationsActionCreators.addNotification(successMessage))
			}
		} catch (error) {
			const techMessage = error
				? error.message
				: t('app:Api.Generic.unknownError')
			const userMessage = t('app:Api.Generic.unknownError')
			yield put(
				ordersActionCreators.fetchOrdersFailure({
					userMessage,
					techMessage
				})
			)
			yield put(
				notificationsActionCreators.addNotification(
					userMessage,
					error instanceof NetworkError
				)
			)
		}
	}
}

function* addFavoriteOrder() {
	while (true) {
		const action = yield take(actionTypes.ADD_FAVORITE_ORDER)
		const { userState } = yield select()
		const { id } = userState.user
		const orderNumber = action.meta.orderNumber
		yield put(ordersActionCreators.addFavoriteOrderRequest(orderNumber))
		try {
			const user = yield call(apiOrders.addFavoriteOrder, id, orderNumber)
			yield put(ordersActionCreators.addFavoriteOrderSuccess(user))
		} catch (error) {
			const techMessage = error
				? error.message
				: t('app:Api.Generic.unknownError')
			const userMessage = t('app:Orders.Error.addFavoriteOrder')
			yield put(
				ordersActionCreators.addFavoriteOrderFailure({
					userMessage,
					techMessage
				})
			)
			yield put(
				notificationsActionCreators.addNotification(
					userMessage,
					error instanceof NetworkError
				)
			)
		}
	}
}

function* removeFavoriteOrder() {
	while (true) {
		const action = yield take(actionTypes.REMOVE_FAVORITE_ORDER)
		const { userState } = yield select()
		const { id } = userState.user
		const orderNumber = action.meta.orderNumber
		yield put(ordersActionCreators.removeFavoriteOrderRequest(orderNumber))
		try {
			const user = yield call(apiOrders.removeFavoriteOrder, id, orderNumber)
			yield put(ordersActionCreators.removeFavoriteOrderSuccess(user))
		} catch (error) {
			const techMessage = error
				? error.message
				: t('app:Api.Generic.unknownError')
			const userMessage = t('app:Orders.Error.removeFavoriteOrder')
			yield put(
				ordersActionCreators.removeFavoriteOrderFailure({
					userMessage,
					techMessage
				})
			)
			yield put(
				notificationsActionCreators.addNotification(
					userMessage,
					error instanceof NetworkError
				)
			)
		}
	}
}

function* lookupOrder() {
	while (true) {
		const action = yield take(actionTypes.LOOKUP_ORDER)
		const orderNumber = action.meta.orderNumber
		yield put(ordersActionCreators.lookupOrderRequest(orderNumber))
		try {
			const { orderType, salesOrderId, callOffOrderId } = yield call(
				apiOrders.lookupOrder,
				orderNumber
			)
			yield put(ordersActionCreators.lookupOrderSuccess())
			if (orderType === 'CALLOFF_ORDER') {
				// Trigger navigation to call-off track & trace
				const destination = `/orders/${salesOrderId}/calloffs/${callOffOrderId}/trackandtrace`
				history.push(destination)
			} else {
				// Trigger navigation to order
				const destination = `/orders/${salesOrderId}`
				history.push(destination)
			}
		} catch (error) {
			const techMessage = error
				? error.message
				: t('app:Api.Generic.unknownError')
			const userMessage =
				error instanceof NotFoundError
					? error.message
					: t('app:Orders.Error.lookupOrder')
			yield put(
				ordersActionCreators.lookupOrderFailure({
					userMessage,
					techMessage
				})
			)
			yield put(
				notificationsActionCreators.addNotification(
					userMessage,
					error instanceof NetworkError
				)
			)
		}
	}
}

export default function* rootSaga() {
	yield all(
		[fetchOrders, addFavoriteOrder, removeFavoriteOrder, lookupOrder].map(
			(saga) => fork(saga)
		)
	)
}
