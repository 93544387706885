import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
	Avatar,
	Badge,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListItemSecondaryAction,
	Menu,
	MenuItem,
	SvgIcon,
	Typography,
	Box
} from '@mui/material'
import { yellow } from '@mui/material/colors'

import { EllipsisVFal, FolderFas } from '@oliverit/react-fontawesome'
import { t } from 'i18next'

import history from '../../../../utils/history'

const sx = {
	active: {
		backgroundColor: (theme) => theme.palette.action.selected
	},
	favorite: {
		backgroundColor: yellow[700]
	},
	primary: {
		display: 'flex',
		alignItems: 'flex-start'
	},
	bold: {
		fontWeight: 500,
		fontSize: 14
	},
	separator: {
		marginLeft: (theme) => theme.spacing(1)
	},
	secondary: {
		display: 'flex',
		alignItems: 'center',
		color: (theme) => theme.palette.text.secondary,
		fontSize: 13
	},
	tertiary: {
		display: 'flex',
		alignItems: 'center',
		color: (theme) => theme.palette.text.secondary,
		fontSize: 13
	},
	orderNumberText: {
		color: (theme) => theme.palette.text.secondary,
		fontSize: 14
	}
}

class OrderListItem extends PureComponent {
	static propTypes = {
		order: PropTypes.object.isRequired,
		selected: PropTypes.bool.isRequired,
		isFavorite: PropTypes.bool.isRequired,
		addFavoriteOrder: PropTypes.func.isRequired,
		removeFavoriteOrder: PropTypes.func.isRequired,
		hasMultipleCustomers: PropTypes.bool.isRequired
	}

	state = {
		anchorEl: null
	}

	showSecondaryMenu = (event) => {
		this.setState({
			anchorEl: event.currentTarget
		})
	}

	hideSecondaryMenu = () => {
		this.setState({
			anchorEl: null
		})
	}

	addFavorite = () => {
		const { addFavoriteOrder, order } = this.props
		this.hideSecondaryMenu()
		addFavoriteOrder(order.orderNumber)
	}

	removeFavorite = () => {
		const { removeFavoriteOrder, order } = this.props
		this.hideSecondaryMenu()
		removeFavoriteOrder(order.orderNumber)
	}

	handleCallOffsClick = (event) => {
		event.preventDefault()
		event.stopPropagation()

		this.hideSecondaryMenu()
		const { order } = this.props
		history.push(`/orders/${order.id}/calloffs`)
	}

	handleDeliveriesClick = () => {
		this.hideSecondaryMenu()
		const { order } = this.props
		history.push(`/orders/${order.id}/deliveries`)
	}

	render() {
		const { order, selected, isFavorite, hasMultipleCustomers } = this.props
		const { anchorEl } = this.state
		const favoriteStyle = isFavorite ? sx.favorite : {}

		let avatar = (
			<Avatar key="favoriteIcon" sx={favoriteStyle}>
				<SvgIcon fontSize="inherit">
					<FolderFas />
				</SvgIcon>
			</Avatar>
		)

		// If there are active call off orders, set a badge to present the amount of active call off orders
		if (order.openCallOffOrdersCount) {
			avatar = (
				<Badge
					color="primary"
					onClick={this.handleCallOffsClick}
					badgeContent={order.openCallOffOrdersCount}
				>
					{avatar}
				</Badge>
			)
		}

		const styledListItemText = (
			<Fragment>
				<Box sx={sx.primary}>
					<Typography variant="body2" sx={sx.bold}>
						{order.project.name}
					</Typography>
					<Typography variant="body2" sx={sx.separator}></Typography>
					<Typography variant="body2" noWrap sx={sx.orderNumberText}>
						{order.orderNumber}
					</Typography>
				</Box>
				<Typography variant="body2" sx={sx.secondary} noWrap>
					{order.project.city} &middot; {order.project.projectNumber}
				</Typography>
				{hasMultipleCustomers && (
					<Typography variant="body2" sx={sx.tertiary} noWrap>
						{order.customer.name} &middot; {order.customer.customerNumber}
					</Typography>
				)}
			</Fragment>
		)

		return (
			<ListItem
				divider
				button
				component={Link}
				to={`/orders/${order.id}`}
				sx={selected ? sx.active : null}
			>
				<ListItemAvatar>{avatar}</ListItemAvatar>
				<ListItemText disableTypography primary={styledListItemText} />
				<ListItemSecondaryAction>
					<IconButton onClick={this.showSecondaryMenu} size="large">
						<SvgIcon>
							<EllipsisVFal />
						</SvgIcon>
					</IconButton>
					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={this.hideSecondaryMenu}
					>
						{isFavorite ? (
							<MenuItem onClick={this.removeFavorite}>
								{t('app:Orders.Master.removeFavorite')}
							</MenuItem>
						) : (
							<MenuItem onClick={this.addFavorite}>
								{t('app:Orders.Master.addFavorite')}
							</MenuItem>
						)}
						<MenuItem
							onClick={this.handleCallOffsClick}
							component={Link}
							to={`/orders/${order.id}`}
						>
							{t('app:Orders.Master.callOffOverview')}
						</MenuItem>
						<MenuItem onClick={this.handleDeliveriesClick}>
							{t('app:Orders.Master.deliveries')}
						</MenuItem>
					</Menu>
				</ListItemSecondaryAction>
			</ListItem>
		)
	}
}

export default OrderListItem
