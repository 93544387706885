import { io } from 'socket.io-client'
import { readAccessToken } from '../utils/auth'
import webSocketUrl from '../utils/webSocketUrl'

class OrderSocket {
	close = () => {
		if (this.socket) {
			this.socket.close()
			this.socket = null
			this.isFetching = false
		}
	}

	open = async () => {
		if (!this.socket) {
			// Send the access token as a query parameter, because websockets do not support custom headers
			const accessToken = await readAccessToken()
			this.socket = io(`${webSocketUrl}/ws/order`, {
				transports: ['websocket'], // Required to make this work on Heroku without http-session-affinity
				query: { token: accessToken }
			})
			this.isFetching = false
		}
	}

	wait = async () =>
		new Promise((resolve) => {
			if (this.socket && this.socket.connected && !this.isFetching) {
				resolve()
			} else {
				const interval = setInterval(() => {
					if (this.socket && this.socket.connected && !this.isFetching) {
						clearInterval(interval)
						resolve()
					}
				}, 100)
			}
		})

	getOrder = async ({
		orderId: salesOrderId,
		callOffId: callOffOrderId,
		view
	}) => {
		// When the app is initially loaded with an order URL, then the socket is not connected yet
		// Wait for the connection
		// When an order is still fetching, wait for it to finish (else the user sees another other)
		if (!this.socket || !this.socket.connected || this.isFetching) {
			await this.wait()
		}

		const stopListeners = () => {
			this.isFetching = false
			this.socket.off('disconnect')
			this.socket.off('fulfillment')
			this.socket.off('rejection')
		}

		const waitForDisconnect = () =>
			new Promise((resolve, reject) => {
				this.socket.once('disconnect', () => {
					stopListeners()
					reject()
				})
			})

		const waitForFulfillment = () =>
			new Promise((resolve) => {
				this.socket.once('fulfillment', (result) => {
					stopListeners()
					resolve(result)
				})
			})

		const waitForRejection = () =>
			new Promise((resolve, reject) => {
				this.socket.once('rejection', (error) => {
					stopListeners()
					reject(error)
				})
			})

		try {
			this.isFetching = true
			this.socket.emit('request', { salesOrderId, callOffOrderId, view })

			const result = await Promise.race([
				waitForDisconnect(),
				waitForFulfillment(),
				waitForRejection()
			])
			return result
		} catch (error) {
			stopListeners()
			throw error
		}
	}
}

const orderSocket = new OrderSocket()

export default orderSocket
