import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'
import numeral from 'numeral'
import {
	Grid,
	SvgIcon,
	InputAdornment,
	TextField,
	Typography
} from '@mui/material'

import { amber, lightGreen } from '@mui/material/colors'
import { CircleFas } from '@oliverit/react-fontawesome'
import moment from 'moment-timezone'

const sx = {
	stockAvailable: {
		color: lightGreen.A700
	},
	stockPartiallyAvailable: {
		color: amber.A700
	},
	stockUnavailable: {
		color: (theme) => theme.palette.error.dark
	}
}

class MaterialParticulars extends PureComponent {
	static propTypes = {
		material: PropTypes.object.isRequired
	}

	render() {
		const { material } = this.props
		const isTradingLocation = material.shippingPoint.endsWith('90')
		const showAvailabilityDate =
			material.materialType === 'CUSTOM' &&
			material.shippingPoint !== '1909' &&
			material.quantityAvailable !== 0 &&
			material.quantityAvailable > material.quantityStock &&
			material.availabilityDate

		let availabilityDate
		// Add 1 week to availabilityDate
		if (material.availabilityDate) {
			availabilityDate = `${t('app:Generic.weekShort')} ${moment(
				material.availabilityDate
			)
				.tz('Europe/Amsterdam')
				.format('w, YYYY')}`
		}

		let stockAvailability
		if (material.stockAvailability === 'AVAILABLE') {
			stockAvailability = (
				<SvgIcon fontSize="inherit" sx={sx.stockAvailable}>
					<CircleFas />
				</SvgIcon>
			)
		} else if (material.stockAvailability === 'PARTIALLY_AVAILABLE') {
			stockAvailability = (
				<SvgIcon fontSize="inherit" sx={sx.stockPartiallyAvailable}>
					<CircleFas />
				</SvgIcon>
			)
		} else {
			stockAvailability = (
				<SvgIcon fontSize="inherit" sx={sx.stockUnavailable}>
					<CircleFas />
				</SvgIcon>
			)
		}

		const stockIndicator = (
			<TextField
				label={
					material.materialType === 'CUSTOM' && !isTradingLocation
						? t('app:Materials.customerStock')
						: t('app:Materials.stockIndication')
				}
				value={
					material.materialType === 'CUSTOM' && !isTradingLocation
						? numeral(material.quantityStock).format(0, 0)
						: undefined
				}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							{stockAvailability}
						</InputAdornment>
					),
					readOnly: true,
					disableUnderline: true
				}}
				InputLabelProps={{ shrink: true }}
				variant="standard"
			/>
		)

		const quantityCalled =
			Number(material.quantityCalledOffAndCancelled) +
			Number(material.quantityCalledOff)

		return (
			<Grid container>
				<Grid item sm={3} xs={6}>
					<TextField
						value={numeral(material.quantityAvailable).format(0, 0)}
						label={t('app:Materials.yetToCall')}
						InputProps={{ readOnly: true, disableUnderline: true }}
						InputLabelProps={{ shrink: true }}
						variant="standard"
					/>
				</Grid>
				<Grid item sm={3} xs={6}>
					<TextField
						value={numeral(quantityCalled).format(0, 0)}
						label={t('app:Materials.called')}
						InputProps={{ readOnly: true, disableUnderline: true }}
						InputLabelProps={{ shrink: true }}
						variant="standard"
					/>
				</Grid>
				<Grid item sm={3} xs={6}>
					{stockIndicator}
				</Grid>
				{showAvailabilityDate && (
					<Grid item sm={3} xs={6}>
						<Typography variant="caption" color="textSecondary">
							{t('app:Materials.availabilityDate')}
						</Typography>
						<Typography variant="body1">{availabilityDate}</Typography>
					</Grid>
				)}
			</Grid>
		)
	}
}

export default MaterialParticulars
