import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import newCallOffActionCreators from '../../../../../redux/newCallOff/actionCreators'
import newCallOffSelectors from '../../../../../redux/newCallOff/selectors'
import MaterialClassificationsFilter from './MaterialClassificationsFilter'

class MaterialClassificationsFilterContainer extends PureComponent {
	static propTypes = {
		openDrawer: PropTypes.bool.isRequired,
		handleDrawerClose: PropTypes.func.isRequired,
		resetClassificationFilter: PropTypes.func.isRequired,
		toggleProductGroup: PropTypes.func.isRequired,
		toggleSubProductGroup: PropTypes.func.isRequired,
		toggleProductLine: PropTypes.func.isRequired,
		classificationsFilter: PropTypes.object.isRequired
	}

	handleProductGroupToggle = (productGroup) => {
		const { toggleProductGroup } = this.props
		toggleProductGroup(productGroup)
	}

	handleSubProductGroupToggle = (subProductGroup) => {
		const { toggleSubProductGroup } = this.props
		toggleSubProductGroup(subProductGroup)
	}

	handleProductLineToggle = (productLine) => {
		const { toggleProductLine } = this.props
		toggleProductLine(productLine)
	}

	render() {
		const {
			openDrawer,
			handleDrawerClose,
			resetClassificationFilter,
			classificationsFilter
		} = this.props

		return (
			<MaterialClassificationsFilter
				openDrawer={openDrawer}
				handleDrawerClose={handleDrawerClose}
				productGroupToggle={this.handleProductGroupToggle}
				subProductGroupToggle={this.handleSubProductGroupToggle}
				productLineToggle={this.handleProductLineToggle}
				resetClassificationFilter={resetClassificationFilter}
				classificationsFilter={classificationsFilter}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		classificationsFilter:
			newCallOffSelectors.filteredClassificationsSelector(state)
	}),
	{
		resetClassificationFilter:
			newCallOffActionCreators.resetClassificationFilter,
		toggleProductGroup: newCallOffActionCreators.toggleProductGroup,
		toggleSubProductGroup: newCallOffActionCreators.toggleSubProductGroup,
		toggleProductLine: newCallOffActionCreators.toggleProductLine
	}
)

export default connector(MaterialClassificationsFilterContainer)
