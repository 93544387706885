import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Instructions from './Instructions'
import newCallOffActionCreators from '../../../../../redux/newCallOff/actionCreators'
import newCallOffSelectors from '../../../../../redux/newCallOff/selectors'

class InstructionsContainer extends PureComponent {
	static propTypes = {
		freightIndex: PropTypes.number.isRequired,
		instructions: PropTypes.object.isRequired,
		updateFreight: PropTypes.func.isRequired,
		showFillInstructionsButton: PropTypes.bool.isRequired,
		fillInstructionsOnAllCallOffs: PropTypes.func.isRequired
	}

	handleInstructionsChange = (instructions) => {
		const { freightIndex, updateFreight } = this.props
		updateFreight(freightIndex, { instructions })
	}

	handleFillInstructionsEverywhere = () => {
		const { fillInstructionsOnAllCallOffs } = this.props
		fillInstructionsOnAllCallOffs()
	}

	render() {
		const { instructions, showFillInstructionsButton } = this.props

		return (
			<Instructions
				instructions={instructions}
				instructionsChange={this.handleInstructionsChange}
				showFillInstructionsButton={showFillInstructionsButton}
				handleFillInstructionsEverywhere={this.handleFillInstructionsEverywhere}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		freightIndex: state.newCallOffState.freightIndex,
		instructions:
			newCallOffSelectors.selectedFreightSelector(state).instructions,
		showFillInstructionsButton:
			newCallOffSelectors.showFillInstructionsButtonSelector(state)
	}),
	{
		fillInstructionsOnAllCallOffs:
			newCallOffActionCreators.fillInstructionsOnAllCallOffs,
		updateFreight: newCallOffActionCreators.updateFreight
	}
)

export default connector(InstructionsContainer)
