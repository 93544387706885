// Selectors in this file are specifically made to be used in sagas with the `select` effect.
// This means they must not use the `createSelector` function from the reselect module!

const getFreights = (state) => state.newCallOffState.freights
const getSelectedFreight = (state) =>
	state.newCallOffState.freights[state.newCallOffState.freightIndex]

const sagaSelectors = {
	getFreights,
	getSelectedFreight
}

export default sagaSelectors
