import { combineReducers } from 'redux'
import notificationsReducer from './notifications/reducer'
import userReducer from './user/reducer'
import ordersReducer from './orders/reducer'
import orderReducer from './order/reducer'
import newCallOffReducer from './newCallOff/reducer'

const rootReducer = combineReducers({
	notificationsState: notificationsReducer,
	userState: userReducer,
	ordersState: ordersReducer,
	orderState: orderReducer,
	newCallOffState: newCallOffReducer
})

export default rootReducer
