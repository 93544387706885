import actionTypes from './actionTypes'

const actionCreators = {
	filterCallOffs: ({ searchTerm }) => ({
		type: actionTypes.FILTER_CALLOFFS,
		meta: {
			searchTerm
		}
	}),

	refreshOrder: (orderId, callOffId) => ({
		type: actionTypes.REFRESH_ORDER,
		meta: {
			orderId,
			callOffId
		}
	}),

	fetchOrder: (orderId, callOffId) => ({
		type: actionTypes.FETCH_ORDER,
		meta: {
			orderId,
			callOffId
		}
	}),

	fetchOrderRequest: () => ({
		type: actionTypes.FETCH_ORDER_REQUEST
	}),

	fetchOrderSuccess: (order) => ({
		type: actionTypes.FETCH_ORDER_SUCCESS,
		payload: {
			order
		}
	}),

	fetchOrderFailure: (errorMessages) => ({
		type: actionTypes.FETCH_ORDER_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	fetchDeliveries: (orderId) => ({
		type: actionTypes.FETCH_DELIVERIES,
		meta: {
			orderId
		}
	}),

	fetchDeliveriesRequest: () => ({
		type: actionTypes.FETCH_DELIVERIES_REQUEST
	}),

	fetchDeliveriesSuccess: (orderWithDeliveries) => ({
		type: actionTypes.FETCH_DELIVERIES_SUCCESS,
		payload: {
			orderWithDeliveries
		}
	}),

	fetchDeliveriesFailure: (errorMessages) => ({
		type: actionTypes.FETCH_DELIVERIES_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	fetchCallOffs: (orderId) => ({
		type: actionTypes.FETCH_CALLOFFS,
		meta: {
			orderId
		}
	}),

	fetchCallOffsRequest: () => ({
		type: actionTypes.FETCH_CALLOFFS_REQUEST
	}),

	fetchCallOffsSuccess: (orderWithCallOffs) => ({
		type: actionTypes.FETCH_CALLOFFS_SUCCESS,
		payload: {
			orderWithCallOffs
		}
	}),

	fetchCallOffsFailure: (errorMessages) => ({
		type: actionTypes.FETCH_CALLOFFS_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	refreshCallOff: (orderId, callOffId) => ({
		type: actionTypes.REFRESH_CALLOFF,
		meta: {
			orderId,
			callOffId
		}
	}),

	fetchCallOff: (orderId, callOffId) => ({
		type: actionTypes.FETCH_CALLOFF,
		meta: {
			orderId,
			callOffId
		}
	}),

	fetchCallOffRequest: (callOffId) => ({
		type: actionTypes.FETCH_CALLOFF_REQUEST,
		meta: {
			callOffId
		}
	}),

	fetchCallOffSuccess: (callOff) => ({
		type: actionTypes.FETCH_CALLOFF_SUCCESS,
		payload: {
			callOff
		}
	}),

	fetchCallOffFailure: (errorMessages) => ({
		type: actionTypes.FETCH_CALLOFF_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	deleteCallOff: (orderId, callOffId) => ({
		type: actionTypes.DELETE_CALLOFF,
		meta: {
			orderId,
			callOffId
		}
	}),

	deleteCallOffRequest: () => ({
		type: actionTypes.DELETE_CALLOFF_REQUEST
	}),

	deleteCallOffSuccess: () => ({
		type: actionTypes.DELETE_CALLOFF_SUCCESS
	}),

	deleteCallOffFailure: (errorMessages) => ({
		type: actionTypes.DELETE_CALLOFF_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	updateDeliveryWindows: (deliveryWindows) => ({
		type: actionTypes.UPDATE_DELIVERY_WINDOWS,
		payload: {
			deliveryWindows
		}
	}),

	openSocket: () => ({
		type: actionTypes.SOCKET_OPEN
	}),

	closeSocket: () => ({
		type: actionTypes.SOCKET_CLOSE
	})
}
export default actionCreators
