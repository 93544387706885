import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Address from './Address'
import newCallOffActionCreators from '../../../../../redux/newCallOff/actionCreators'
import newCallOffSelectors from '../../../../../redux/newCallOff/selectors'

class AddressContainer extends PureComponent {
	static propTypes = {
		freightIndex: PropTypes.number.isRequired,
		freight: PropTypes.object.isRequired,
		availableCountries: PropTypes.array.isRequired,
		fillAddressOnAllCallOffs: PropTypes.func.isRequired,
		updateFreight: PropTypes.func.isRequired,
		deliveryType: PropTypes.string.isRequired,
		isLoadingAddresses: PropTypes.bool.isRequired,
		fetchAddresses: PropTypes.func.isRequired,
		showFillAddressButton: PropTypes.bool.isRequired
	}

	handleStreetChange = (street) => {
		const { freightIndex, updateFreight } = this.props
		updateFreight(freightIndex, { street })
	}

	handleHouseNumberChange = (houseNumber) => {
		const { freightIndex, updateFreight } = this.props
		updateFreight(freightIndex, { houseNumber })
	}

	handleZipCodeChange = (zipCode) => {
		const { freightIndex, updateFreight } = this.props
		updateFreight(freightIndex, { zipCode })
	}

	handleCityChange = (city) => {
		const { freightIndex, updateFreight } = this.props
		updateFreight(freightIndex, { city })
	}

	handleFillAddressEverywhere = () => {
		const { fillAddressOnAllCallOffs } = this.props
		fillAddressOnAllCallOffs()
	}

	render() {
		const {
			freight,
			availableCountries,
			deliveryType,
			isLoadingAddresses,
			fetchAddresses,
			showFillAddressButton
		} = this.props

		return (
			<Address
				freight={freight}
				availableCountries={availableCountries}
				deliveryType={deliveryType}
				isLoadingAddresses={isLoadingAddresses}
				streetChange={this.handleStreetChange}
				houseNumberChange={this.handleHouseNumberChange}
				zipCodeChange={this.handleZipCodeChange}
				cityChange={this.handleCityChange}
				addressSearch={this.handleAddressSearch}
				fetchAddresses={fetchAddresses}
				handleFillAddressEverywhere={this.handleFillAddressEverywhere}
				showFillAddressButton={showFillAddressButton}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		freightIndex: state.newCallOffState.freightIndex,
		freight: newCallOffSelectors.selectedFreightSelector(state),
		availableCountries:
			state.orderState.order.freightTransport.availableCountries,
		deliveryType: state.newCallOffState.deliveryType,
		isLoadingAddresses: state.newCallOffState.meta.isLoadingAddresses,
		showFillAddressButton:
			newCallOffSelectors.showFillAddressButtonSelector(state)
	}),
	{
		updateFreight: newCallOffActionCreators.updateFreight,
		fillAddressOnAllCallOffs: newCallOffActionCreators.fillAddressOnAllCallOffs,
		fetchAddresses: newCallOffActionCreators.fetchAddresses
	}
)

export default connector(AddressContainer)
