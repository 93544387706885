import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import {
	Button,
	CircularProgress,
	Grid,
	Paper,
	TextField,
	Typography
} from '@mui/material'

import { red } from '@mui/material/colors'
import Logo from '../Logo'
import { styled } from '@mui/styles'

const StyledPaper = styled(Paper)(({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	},
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2),
	margin: '0 auto',
	textAlign: 'center',
	width: 400
}))

const sx = {
	root: {
		flex: 1,
		paddingTop: (theme) => theme.spacing(12.5)
	},
	cancelLoginButton: {
		marginTop: (theme) => theme.spacing(2.5),
		width: '100%'
	},
	submitButton: {
		marginBottom: (theme) => theme.spacing(1.25),
		width: '100%'
	},
	anotherUserButton: {
		width: '100%'
	},
	progress: {
		marginTop: (theme) => theme.spacing(2.5)
	},
	twoFactorTokenTextField: {
		marginTop: (theme) => theme.spacing(2.5),
		width: '100%'
	},
	maskedPhoneNumberMessage: {
		marginTop: (theme) => theme.spacing(2.5)
	},
	errorMessage: {
		color: red[400],
		marginBottom: (theme) => theme.spacing(3.75)
	},
	loggingInText: {
		marginTop: (theme) => theme.spacing(2)
	}
}

class TwoFactor extends PureComponent {
	static propTypes = {
		canRequestNewCode: PropTypes.bool,
		canRequestNewCodeAfterSeconds: PropTypes.number,
		errorMessages: PropTypes.object,
		maskedPhoneNumber: PropTypes.string,
		isLoggingIn: PropTypes.bool,
		requestNewCode: PropTypes.func.isRequired,
		submitTwoFactor: PropTypes.func.isRequired,
		anotherUser: PropTypes.func.isRequired,
		logout: PropTypes.func.isRequired
	}

	state = {
		twoFactorToken: ''
	}

	submitTwoFactor() {
		const { twoFactorToken } = this.state
		const { submitTwoFactor } = this.props
		if (twoFactorToken.length > 0) {
			this.setState({ twoFactorToken: '' })
			submitTwoFactor(twoFactorToken)
		}
	}

	cancelLogin() {
		// Calling the logout action will automatically cancel any running login requests
		const { logout } = this.props
		logout()
	}

	handleChangeTwoFactorToken = (event) => {
		this.setState({
			twoFactorToken: event.target.value
		})
	}

	handleKeyPressTwoFactorToken = (event) => {
		if (event.key === 'Enter') {
			this.submitTwoFactor()
		}
	}

	handleSubmit = () => {
		this.submitTwoFactor()
	}

	handleCancelLogin = () => {
		this.cancelLogin()
	}

	handleAnotherUser = () => {
		const { anotherUser } = this.props
		anotherUser()
	}

	render() {
		const { isLoggingIn } = this.props

		if (isLoggingIn) {
			return this.renderLoggingIn()
		}
		return this.renderTwoFactorForm()
	}

	renderLoggingIn() {
		return (
			<Grid container align="center" justifyContent="center" sx={sx.root}>
				<Grid item xs={12}>
					<StyledPaper>
						<div>
							<Logo />
						</div>
						<CircularProgress sx={sx.progress} size={50} />
						<Typography variant="body2" sx={sx.loggingInText}>
							{t('app:Login.loggingIn')}
						</Typography>
						<Button
							variant="contained"
							sx={sx.cancelLoginButton}
							onClick={this.handleCancelLogin}
						>
							{t('app:Login.cancelLogin')}
						</Button>
					</StyledPaper>
				</Grid>
			</Grid>
		)
	}

	renderTwoFactorForm() {
		const {
			canRequestNewCode,
			canRequestNewCodeAfterSeconds,
			maskedPhoneNumber,
			errorMessages,
			requestNewCode
		} = this.props

		let message
		if (errorMessages) {
			message = (
				<Typography variant="body2" sx={sx.errorMessage}>
					{errorMessages.techMessage}
				</Typography>
			)
		}

		let maskedPhoneNumberMessage
		if (maskedPhoneNumber) {
			maskedPhoneNumberMessage = (
				<Typography variant="body2" sx={sx.maskedPhoneNumberMessage}>
					<Trans i18nKey={t('app:Login.maskedPhoneNumber')}>
						Er is een SMS-code verstuurd naar{' '}
						<strong>{maskedPhoneNumber}</strong>.
					</Trans>
				</Typography>
			)
		}

		let requestNewCodeButton
		if (canRequestNewCode) {
			// Request button is ready
			requestNewCodeButton = (
				<Button sx={sx.anotherUserButton} onClick={requestNewCode}>
					{t('app:Login.requestNewCode')}
				</Button>
			)
		} else {
			// Request button contains countdown
			const canRequestNewCodeAfter = moment()
				.startOf('day')
				.seconds(canRequestNewCodeAfterSeconds)
				.format('m:ss')
			requestNewCodeButton = (
				<Button sx={sx.anotherUserButton} disabled>
					{t('app:Login.requestNewCodeWithTime', {
						canRequestNewCodeAfter
					})}
				</Button>
			)
		}

		return (
			<Grid container align="center" direction="column" sx={sx.root}>
				<Grid item md={12}>
					<StyledPaper>
						<div>
							<Logo />
						</div>
						{maskedPhoneNumberMessage}
						<TextField
							label={t('app:Login.twoFactorToken')}
							sx={sx.twoFactorTokenTextField}
							value={this.state.twoFactorToken}
							onChange={this.handleChangeTwoFactorToken}
							onKeyPress={this.handleKeyPressTwoFactorToken}
							margin="normal"
						/>
						{message}
						<Button
							variant="contained"
							color="primary"
							sx={sx.submitButton}
							onClick={this.handleSubmit}
						>
							{t('app:Login.login')}
						</Button>
						{requestNewCodeButton}
						<Button sx={sx.anotherUserButton} onClick={this.handleAnotherUser}>
							{t('app:Login.anotherUser')}
						</Button>
					</StyledPaper>
				</Grid>
			</Grid>
		)
	}
}

export default TwoFactor
