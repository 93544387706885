import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Avatar, SvgIcon } from '@mui/material'
import { green } from '@mui/material/colors'

import {
	ClipboardListFal,
	ClipboardListCheckFal,
	ClipboardCheckFal,
	ShippingTimedFal,
	TruckFal,
	ShippingFastFal,
	CheckFal
} from '@oliverit/react-fontawesome'
import {
	STATUS_IN_CONCEPT,
	STATUS_IN_APPROVAL,
	STATUS_APPROVED,
	STATUS_IN_PLANNING,
	STATUS_PLANNED,
	STATUS_LOADED,
	STATUS_CLOSED
} from '../../../utils/constants'

const sx = {
	green: {
		backgroundColor: green[500]
	}
}

class StatusIcon extends PureComponent {
	static propTypes = {
		status: PropTypes.number
	}

	render() {
		const { status } = this.props
		let icon
		let iconBackground
		switch (status) {
			case STATUS_IN_CONCEPT: {
				icon = (
					<SvgIcon>
						<ClipboardListFal />
					</SvgIcon>
				)
				break
			}

			case STATUS_IN_APPROVAL: {
				icon = (
					<SvgIcon>
						<ClipboardListCheckFal />
					</SvgIcon>
				)
				break
			}

			case STATUS_APPROVED: {
				icon = (
					<SvgIcon>
						<ClipboardCheckFal />
					</SvgIcon>
				)
				break
			}

			case STATUS_IN_PLANNING: {
				icon = (
					<SvgIcon>
						<ShippingTimedFal />
					</SvgIcon>
				)
				break
			}

			case STATUS_PLANNED: {
				icon = (
					<SvgIcon>
						<TruckFal />
					</SvgIcon>
				)
				break
			}

			case STATUS_LOADED: {
				icon = (
					<SvgIcon>
						<ShippingFastFal />
					</SvgIcon>
				)
				break
			}

			case STATUS_CLOSED: {
				icon = (
					<SvgIcon>
						<CheckFal />
					</SvgIcon>
				)
				iconBackground = sx.green
				break
			}

			default: {
				break
			}
		}

		return <Avatar sx={iconBackground}>{icon}</Avatar>
	}
}

export default StatusIcon
