import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	Fab,
	Grid,
	Paper,
	SvgIcon,
	Typography,
	Box
} from '@mui/material'
import {
	ChevronDownFal,
	ChevronUpFal,
	ExclamationCircleFas
} from '@oliverit/react-fontawesome'

import { red } from '@mui/material/colors'
import { t } from 'i18next'
import HeaderInfoContainer from './HeaderInfo/HeaderInfoContainer'
import OrderStepsContainer from './OrderSteps/OrderStepsContainer'
import NotFound from '../../Shared/NotFound'
import Loading from '../../Shared/Loading'
import ConfirmDialog from '../../Shared/ConfirmDialog'
import ConceptCorrectionsDialog from './ConceptCorrectionsDialog'
import { CALL_OFF_STEP_MATERIALS } from '../../../utils/constants'
import { styled } from '@mui/styles'

const mixinsGuttersStyle = ({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	}
})

const StyledPaper = styled(Paper)(mixinsGuttersStyle)
const StyledBox = styled(Box)(mixinsGuttersStyle)

const sx = {
	rootContainer: {
		height: '100%',
		overflowY: 'scroll',
		overflowX: 'hidden',
		marginTop: '-1px',
		backgroundColor: (theme) => theme.palette.background.default
	},
	message: {
		marginTop: (theme) => theme.spacing(2)
	},
	error: {
		color: red.A700
	},
	tryAgainButton: {
		textAlign: 'center'
	},
	scrollButton: {
		position: 'fixed',
		bottom: '10%',
		right: (theme) => theme.spacing(2),
		zIndex: '1150'
	}
}

class OrderDetail extends PureComponent {
	static propTypes = {
		callOffStep: PropTypes.number.isRequired,
		cancelFinish: PropTypes.func.isRequired,
		errorMessages: PropTypes.object,
		fetchOrderError: PropTypes.bool.isRequired,
		finishErrorMessages: PropTypes.object,
		isFinishing: PropTypes.bool.isRequired,
		isLoading: PropTypes.bool.isRequired,
		order: PropTypes.object,
		tryFetchAgain: PropTypes.func.isRequired,
		tryFinishAgain: PropTypes.func.isRequired,
		corrections: PropTypes.array,
		confirmCorrections: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)

		this.orderDetailRef = React.createRef()
	}

	state = {
		scrollButtonVisible: false,
		scrollDirection: 'down'
	}

	componentDidUpdate(prevProps) {
		// Scroll to top when call-off step changes
		if (prevProps.callOffStep !== this.props.callOffStep) {
			this.scrollToTop()
		}
	}

	scrollToBottom = () => {
		const pageBottom = document.getElementById('page-bottom')
		pageBottom.scrollIntoView()
	}

	scrollToTop = () => {
		const pageTop = document.getElementById('page-top')
		if (pageTop) {
			pageTop.scrollIntoView()
		}
	}

	showScrollButton = () => {
		this.setState({ scrollButtonVisible: true })
	}

	hideScrollButton = () => {
		this.setState({ scrollButtonVisible: false })
	}

	setScrollDirectionUp = () => {
		this.setState({ scrollDirection: 'up' })
	}

	setScrollDirectionDown = () => {
		this.setState({ scrollDirection: 'down' })
	}

	handleScrollButtonAppearance = () => {
		const scrollPosition = document.getElementById(
			'order-detail-content'
		).scrollTop
		const fullHeight = document.getElementById(
			'order-detail-content'
		).scrollHeight
		const offsetHeight = document.getElementById(
			'order-detail-content'
		).offsetHeight
		const pageBottomReached = scrollPosition + offsetHeight === fullHeight

		if (fullHeight > window.innerHeight) {
			this.showScrollButton()
		}

		if (scrollPosition === 0) {
			this.setScrollDirectionDown()
		}

		if (pageBottomReached) {
			this.setScrollDirectionUp()
		}
	}

	render() {
		const {
			order,
			isLoading,
			isFinishing,
			tryFetchAgain,
			fetchOrderError,
			errorMessages,
			finishErrorMessages,
			tryFinishAgain,
			cancelFinish,
			callOffStep,
			corrections,
			confirmCorrections
		} = this.props
		const { scrollButtonVisible, scrollDirection } = this.state

		if (isLoading) {
			return (
				<StyledBox sx={sx.rootContainer}>
					<Loading paper message={t('app:Materials.loadingMaterials')} />
				</StyledBox>
			)
		}

		if (isFinishing) {
			return (
				<StyledBox sx={sx.rootContainer}>
					<Loading paper message={t('app:Materials.finishing')} />
				</StyledBox>
			)
		}

		// An order was selected but it could not be found
		if (!order) {
			return (
				<StyledBox sx={sx.rootContainer}>
					<NotFound
						paper
						description={t('app:Orders.notFoundDescription')}
						header={t('app:Orders.notFoundHeader')}
					/>
				</StyledBox>
			)
		}

		let finishErrorDialog
		if (finishErrorMessages) {
			finishErrorDialog = (
				<ConfirmDialog
					confirm={tryFinishAgain}
					confirmButtonText={t('app:Generic.tryAgain')}
					cancel={cancelFinish}
					cancelButtonText={t('app:Generic.cancel')}
					descriptionText={finishErrorMessages.techMessage}
					isOpen={true}
					titleText={finishErrorMessages.userMessage}
				/>
			)
		}

		let correctionsDialog
		if (corrections) {
			correctionsDialog = (
				<ConceptCorrectionsDialog
					confirm={confirmCorrections}
					confirmButtonText={t('app:Generic.OK')}
					corrections={corrections}
					isOpen={true}
					titleText={t('app:Validation.correctionsTitle')}
				/>
			)
		}

		let content

		if (fetchOrderError) {
			content = (
				<Fragment>
					<Grid item xs={12}>
						<NotFound header={errorMessages.techMessage} />
					</Grid>
					<Grid item xs={12} sx={sx.tryAgainButton}>
						<Button variant="contained" color="primary" onClick={tryFetchAgain}>
							{t('app:Generic.tryAgain')}
						</Button>
					</Grid>
				</Fragment>
			)
		} else if (order.weightLimitPerTruckExceeded) {
			content = (
				<Grid item xs={12}>
					<StyledPaper sx={sx.message}>
						<Grid container spacing={2} wrap="nowrap" alignItems="center">
							<SvgIcon fontSize="small" sx={sx.error}>
								<ExclamationCircleFas />
							</SvgIcon>
							<Grid item>
								<Typography variant="body2">
									{t('app:CallOffs.Details.weightLimitPerTruckExceeded')}
								</Typography>
							</Grid>
						</Grid>
					</StyledPaper>
				</Grid>
			)
		} else if (order.maximumCountriesExceeded) {
			content = (
				<Grid item xs={12}>
					<StyledPaper sx={sx.message}>
						<Grid container spacing={2} wrap="nowrap" alignItems="center">
							<SvgIcon fontSize="small" sx={sx.error}>
								<ExclamationCircleFas />
							</SvgIcon>
							<Grid item>
								<Typography variant="body2">
									{t('app:CallOffs.Details.maximumCountriesExceeded')}
								</Typography>
							</Grid>
						</Grid>
					</StyledPaper>
				</Grid>
			)
		} else if (order.maximumLocationsExceeded) {
			content = (
				<Grid item xs={12}>
					<StyledPaper sx={sx.message}>
						<Grid container spacing={2} wrap="nowrap" alignItems="center">
							<SvgIcon fontSize="small" sx={sx.error}>
								<ExclamationCircleFas />
							</SvgIcon>
							<Grid item>
								<Typography variant="body2">
									{t('app:CallOffs.Details.maximumLocationsExceeded')}
								</Typography>
							</Grid>
						</Grid>
					</StyledPaper>
				</Grid>
			)
		} else {
			content = (
				<Fragment>
					{correctionsDialog}
					{finishErrorDialog}
					<Grid item xs={12}>
						<OrderStepsContainer orderDetailRef={this.orderDetailRef} />
					</Grid>
				</Fragment>
			)
		}

		let scrollButton
		if (callOffStep === CALL_OFF_STEP_MATERIALS && scrollButtonVisible) {
			scrollButton = (
				<Fab
					sx={sx.scrollButton}
					color="default"
					onClick={
						scrollDirection === 'down' ? this.scrollToBottom : this.scrollToTop
					}
				>
					{scrollDirection === 'down' ? (
						<SvgIcon>
							<ChevronDownFal />
						</SvgIcon>
					) : (
						<SvgIcon>
							<ChevronUpFal />
						</SvgIcon>
					)}
				</Fab>
			)
		}

		return (
			<Fragment>
				<StyledBox
					id="order-detail-content"
					ref={this.orderDetailRef}
					sx={sx.rootContainer}
					onScroll={this.handleScrollButtonAppearance}
				>
					<div id="page-top" />
					<Grid
						container
						justifyContent="space-between"
						direction="column"
						spacing={2}
						sx={{
							margin: (theme) => theme.spacing(-1)
						}}
					>
						<Grid
							item
							xs={12}
							sx={{
								paddingBottom: (theme) => theme.spacing(1)
							}}
						>
							<HeaderInfoContainer />
						</Grid>
						{content}
						{scrollButton}
					</Grid>
					<br />
					<div id="page-bottom" />
				</StyledBox>
			</Fragment>
		)
	}
}

export default OrderDetail
