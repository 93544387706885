import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	SvgIcon,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography
} from '@mui/material'
import { CheckSquareFal, SquareFal } from '@oliverit/react-fontawesome'
import i18next from 'i18next'

class MaterialClassification extends PureComponent {
	static propTypes = {
		classification: PropTypes.object.isRequired,
		classificationToggle: PropTypes.func.isRequired
	}

	handleClassificationToggle = () => {
		const { classification, classificationToggle } = this.props
		classificationToggle(classification)
	}

	render() {
		const { classification } = this.props
		const description = (
			<Typography variant="body2" display="block" noWrap>
				{classification.descriptions[i18next.language] || classification.value}
			</Typography>
		)

		let icon
		if (classification.checked) {
			icon = (
				<SvgIcon>
					<CheckSquareFal />
				</SvgIcon>
			)
		} else {
			icon = (
				<SvgIcon>
					<SquareFal />
				</SvgIcon>
			)
		}

		return (
			<ListItem button onClick={this.handleClassificationToggle}>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText primary={description} />
			</ListItem>
		)
	}
}

export default MaterialClassification
