import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { t } from 'i18next'

import Material from './Material'

const sx = {
	header: {
		padding: (theme) => theme.spacing(2)
	}
}

class MaterialList extends PureComponent {
	static propTypes = {
		materials: PropTypes.array
	}

	render() {
		const { materials } = this.props

		if (materials.length === 0) {
			return (
				<Typography variant="body2" sx={sx.header} align="center">
					{t('app:CallOffs.Details.noMaterials')}
				</Typography>
			)
		}

		return materials.map((material, index) => (
			<Material key={index} material={material} />
		))
	}
}

export default MaterialList
