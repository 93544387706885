import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid } from '@mui/material'

import i18next from 'i18next'
import numeral from 'numeral'
import { getQuantityInAnotherUnit } from '../../../../../utils/materials'

const sx = {
	bold: {
		fontWeight: (theme) => theme.typography.fontWeightMedium
	}
}

class Material extends PureComponent {
	static propTypes = {
		material: PropTypes.object.isRequired
	}

	render() {
		const { material } = this.props
		const language = i18next.language

		const {
			baseUnit,
			descriptions,
			quantity,
			shippingPointDescription,
			unit,
			unitConversions,
			weightPerBaseUnit,
			weightUnit
		} = material

		const conversionUnit = material.unitConversions.find(
			(unitConversion) => unitConversion.unit === unit
		)
		const conversionBaseUnit = material.unitConversions.find(
			(unitConversion) => unitConversion.unit === baseUnit
		)
		const quantityInBaseUnit = getQuantityInAnotherUnit({
			unitConversions,
			quantity,
			fromUnit: unit,
			toUnit: baseUnit
		})
		let weight
		if (quantityInBaseUnit * weightPerBaseUnit < 1) {
			weight = 1
		} else {
			weight = Math.floor(quantityInBaseUnit * weightPerBaseUnit)
		}

		let amount
		if (conversionUnit.unit === conversionBaseUnit.unit) {
			amount = `${numeral(quantity).format('0,0.[000]')} ${
				conversionUnit.descriptions.short[language]
			}`
		} else {
			amount = `${numeral(quantity).format('0,0.[000]')} ${
				conversionUnit.descriptions.short[language]
			} (${numeral(quantityInBaseUnit).format('0,0.[000]')} ${
				conversionBaseUnit.descriptions.short[language]
			})`
		}

		return (
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={7} md={8}>
						<Typography variant="body1">{descriptions[language]}</Typography>
						<Typography noWrap variant="caption" color="textSecondary">
							{shippingPointDescription}
						</Typography>
					</Grid>
					<Grid item xs={6} sm={3} md={2}>
						<Typography sx={sx.bold} variant="body1" align="left">
							{amount}
						</Typography>
					</Grid>
					<Grid item xs={6} sm={2} md={2}>
						<Typography sx={sx.bold} variant="body1" align="right">
							{numeral(weight).format(0, 0)} {weightUnit}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		)
	}
}

export default Material
