import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
	Avatar,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	SvgIcon,
	Typography
} from '@mui/material'
import { blue } from '@mui/material/colors'

import { t } from 'i18next'
import { PencilFal } from '@oliverit/react-fontawesome'

import CallOffsListItem from './CallOffsListItem'

const sx = {
	blue: {
		backgroundColor: blue[500]
	}
}

class CallOffsList extends PureComponent {
	static propTypes = {
		selectedOrderId: PropTypes.string.isRequired,
		callOffs: PropTypes.array,
		hasConcept: PropTypes.bool,
		deliveryWindows: PropTypes.object
	}

	render() {
		const { selectedOrderId, callOffs, hasConcept, deliveryWindows } =
			this.props

		if ((!callOffs || !callOffs.length) && !hasConcept) {
			return (
				<List disablePadding>
					<ListItem>
						<ListItemText
							primaryTypographyProps={{ variant: 'body1' }}
							primary={t('app:CallOffs.empty')}
						/>
					</ListItem>
				</List>
			)
		}

		let hasConceptCallOff
		if (hasConcept) {
			hasConceptCallOff = (
				<ListItem
					button
					divider={callOffs.length > 0}
					component={Link}
					to={`/orders/${selectedOrderId}`}
				>
					<ListItemAvatar>
						<Avatar sx={sx.blue}>
							<SvgIcon>
								<PencilFal />
							</SvgIcon>
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={t('app:CallOffs.Details.hasConceptPrimary')}
						primaryTypographyProps={{ variant: 'body1' }}
						secondary={t('app:CallOffs.Details.hasConceptSecondary')}
					/>
					<Typography variant="subtitle2">
						{t('app:Status.isConcept')}
					</Typography>
				</ListItem>
			)
		}

		let callOffItems
		if (callOffs && callOffs.length) {
			callOffItems = callOffs.map((callOff, index, callOffsArray) => (
				<CallOffsListItem
					key={index}
					callOff={callOff}
					selectedOrderId={selectedOrderId}
					showDivider={callOffsArray.length - 1 !== index}
					deliveryWindows={deliveryWindows}
				/>
			))
		}

		return (
			<List disablePadding>
				{hasConceptCallOff}
				{callOffItems}
			</List>
		)
	}
}

export default CallOffsList
