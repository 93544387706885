import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { t } from 'i18next'
import {
	Collapse,
	Grid,
	List,
	ListItem,
	IconButton,
	SvgIcon,
	Typography,
	Box
} from '@mui/material'

import {
	ChevronDownFal,
	ChevronUpFal,
	PhoneFas
} from '@oliverit/react-fontawesome'
import { DELIVERY_TYPE_PICKUP } from '../../../../utils/constants'

import SummaryMaterialListItem from './SummaryMaterialListItem'

const sx = {
	container: {
		padding: (theme) => theme.spacing()
	},
	greyBackground: {
		backgroundColor: (theme) => theme.palette.grey[100]
	},
	instructions: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: (theme) => theme.spacing(2)
	},
	phoneIcon: {
		color: (theme) => theme.palette.grey[600]
	}
}

class SummaryItem extends PureComponent {
	static propTypes = {
		freight: PropTypes.object.isRequired,
		deliveryType: PropTypes.string.isRequired,
		index: PropTypes.number.isRequired,
		materials: PropTypes.array.isRequired,
		matchDeliveryWindow: PropTypes.func.isRequired,
		matchCountryName: PropTypes.func.isRequired,
		returnPalletsState: PropTypes.object.isRequired
	}

	state = {
		isExpandSectionOpen: false
	}

	handleExpandSection = () => {
		const { isExpandSectionOpen } = this.state
		this.setState({
			isExpandSectionOpen: !isExpandSectionOpen
		})
	}

	render() {
		const {
			freight,
			deliveryType,
			index,
			materials,
			matchDeliveryWindow,
			matchCountryName,
			returnPalletsState
		} = this.props
		const { isExpandSectionOpen } = this.state
		const isPickup = deliveryType === DELIVERY_TYPE_PICKUP
		const totalMaterials = freight.materials.length

		const staticMaterials = []
		const expandMaterials = []
		for (let i = 0; i <= totalMaterials - 1; i += 1) {
			// If there are exactly 3 materials or less, show 3 materials.
			if ((i <= 2 && totalMaterials > 3) || totalMaterials <= 3) {
				staticMaterials.push(
					<SummaryMaterialListItem
						key={i}
						freightMaterial={freight.materials[i]}
						materials={materials}
						showDivider={totalMaterials - 1 !== i}
						isFirstListItem={i === 0}
					/>
				)
			} else {
				// All other materials should be shown in expand
				expandMaterials.push(
					<SummaryMaterialListItem
						key={i}
						freightMaterial={freight.materials[i]}
						materials={materials}
						showDivider={totalMaterials - 1 !== i}
					/>
				)
			}
		}

		let returnPalletsView
		if (returnPalletsState.showReturnPallets) {
			returnPalletsView = returnPalletsState.returnPallets.map(
				(returnPallet, returnPalletIndex) => (
					<Grid container direction="row" key={returnPalletIndex}>
						<Grid item xs={8}>
							<Typography variant="body2">
								{returnPallet.description}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body2">{returnPallet.quantity}</Typography>
						</Grid>
					</Grid>
				)
			)
		} else {
			returnPalletsView = (
				<Typography variant="body2">
					{t('app:Materials.Summary.noReturnPallets')}
				</Typography>
			)
		}

		return (
			<ListItem divider>
				<Grid container spacing={2} sx={sx.container}>
					<Grid item xs={12}>
						<Typography variant="h5">
							{t('app:Delivery.freightCount', { count: index + 1 })}
						</Typography>
					</Grid>
					<Grid item sm={6} xs={12}>
						<Typography variant="h6" paragraph>
							{isPickup
								? t('app:Materials.Summary.pickUpInfo')
								: t('app:Materials.Summary.deliveryInfo')}
						</Typography>
						<Grid container spacing={2}>
							<Grid item sm={6} xs={12}>
								<Typography variant="subtitle2">
									{isPickup
										? t('app:Materials.Summary.pickUpAddress')
										: t('app:Materials.Summary.deliveryAddress')}
								</Typography>
								<Typography variant="body2">
									{freight.street} {freight.houseNumber}
								</Typography>
								<Typography variant="body2">
									{freight.zipCode} {freight.city}
								</Typography>
								<Typography variant="body2">
									{matchCountryName(freight.country)}
								</Typography>
							</Grid>
							<Grid item sm={6} xs={12}>
								<Typography variant="subtitle2">
									{isPickup
										? t('app:Materials.Summary.pickUpDateTime')
										: t('app:Materials.Summary.deliveryDateTime')}
								</Typography>
								<Typography variant="body2">
									{moment(freight.deliveryDate)
										.tz('Europe/Amsterdam')
										.format('DD-MM-YYYY')}
								</Typography>
								<Typography variant="body2">
									{matchDeliveryWindow(freight)}
								</Typography>
							</Grid>
							<Grid item sm={6} xs={12} sx={sx.instructions}>
								<Box>
									<Typography variant="subtitle2">
										{t('app:Materials.Summary.contactPerson')}
									</Typography>
									<Typography variant="body2">{freight.name}</Typography>
									<Typography variant="body2">
										<SvgIcon fontSize="inherit" sx={sx.phoneIcon}>
											<PhoneFas />
										</SvgIcon>{' '}
										{freight.phone}
									</Typography>
								</Box>
								<Box>
									<Typography variant="subtitle2">
										{t('app:Materials.Summary.returnPalletsTitle')}
									</Typography>
									{returnPalletsView}
								</Box>
							</Grid>
							{(freight.instructions.line1 || freight.instructions.line2) && (
								<Grid item sm={6} xs={12} sx={sx.instructions}>
									{freight.instructions.line1 && (
										<Box>
											<Typography variant="subtitle2">
												{t('app:Materials.Summary.instructions')}
											</Typography>
											<Typography variant="body2">
												{freight.instructions.line1}
											</Typography>
										</Box>
									)}
									{freight.instructions.line2 && (
										<Box>
											<Typography variant="subtitle2">
												{t('app:Materials.Summary.additionalCustomerReference')}
											</Typography>
											<Typography variant="body2">
												{freight.instructions.line2}
											</Typography>
										</Box>
									)}
								</Grid>
							)}
						</Grid>
					</Grid>
					<Grid item sm={6} xs={12} sx={sx.greyBackground}>
						<Typography variant="h6" paragraph>
							{t('app:Materials.Summary.materials')}
						</Typography>
						<List>
							{staticMaterials}
							{totalMaterials > 3 && (
								<Fragment>
									<Collapse
										in={!isExpandSectionOpen}
										timeout="auto"
										unmountOnExit
									>
										<Grid
											container
											direction="row"
											alignItems="center"
											justifyContent="space-around"
										>
											<Grid item>
												<IconButton
													onClick={this.handleExpandSection}
													size="large"
												>
													<SvgIcon>
														<ChevronDownFal />
													</SvgIcon>
												</IconButton>
											</Grid>
										</Grid>
									</Collapse>
									<Collapse
										in={isExpandSectionOpen}
										timeout="auto"
										unmountOnExit
									>
										{expandMaterials}
									</Collapse>
									<Collapse
										in={isExpandSectionOpen}
										timeout="auto"
										unmountOnExit
									>
										<Grid
											container
											direction="row"
											alignItems="center"
											justifyContent="space-around"
										>
											<Grid item>
												<IconButton
													onClick={this.handleExpandSection}
													size="large"
												>
													<SvgIcon>
														<ChevronUpFal />
													</SvgIcon>
												</IconButton>
											</Grid>
										</Grid>
									</Collapse>
								</Fragment>
							)}
						</List>
					</Grid>
				</Grid>
			</ListItem>
		)
	}
}

export default SummaryItem
