import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import newCallOffSelectors from '../../../../../redux/newCallOff/selectors'
import Materials from './Materials'

class MaterialsContainer extends PureComponent {
	static propTypes = {
		freight: PropTypes.object.isRequired,
		materials: PropTypes.array.isRequired,
		deliveryType: PropTypes.string.isRequired
	}

	render() {
		const { freight, materials, deliveryType } = this.props

		return (
			<Materials
				freight={freight}
				materials={materials}
				deliveryType={deliveryType}
			/>
		)
	}
}

const connector = connect((state) => ({
	freight: newCallOffSelectors.selectedFreightSelector(state),
	materials: newCallOffSelectors.selectedFreightMaterialsSelector(state),
	deliveryType: state.newCallOffState.deliveryType
}))

export default connector(MaterialsContainer)
