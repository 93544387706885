import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import newCallOffActionCreators from '../../../../redux/newCallOff/actionCreators'
import HeaderInfo from './HeaderInfo'

class HeaderInfoContainer extends PureComponent {
	static propTypes = {
		order: PropTypes.object,
		deliveryType: PropTypes.string.isRequired,
		callOffStep: PropTypes.number.isRequired,
		isSavingConcept: PropTypes.bool.isRequired,
		isRemovingConcept: PropTypes.bool.isRequired,
		saveConceptErrorMessages: PropTypes.object,
		removeConceptErrorMessages: PropTypes.object,
		setDeliveryType: PropTypes.func.isRequired,
		saveConcept: PropTypes.func.isRequired,
		removeConcept: PropTypes.func.isRequired,
		clearSaveConceptError: PropTypes.func.isRequired,
		clearRemoveConceptError: PropTypes.func.isRequired,
		showPurchaseObligationArticlesOnly: PropTypes.func.isRequired,
		hidePurchaseObligationArticlesOnly: PropTypes.func.isRequired,
		showPurchaseObligationArticles: PropTypes.bool.isRequired
	}

	changeDeliveryMode = (newDeliveryType) => {
		const { setDeliveryType } = this.props
		setDeliveryType(newDeliveryType)
	}

	handleShowPurchaseObligationArticles = () => {
		const { showPurchaseObligationArticlesOnly } = this.props
		showPurchaseObligationArticlesOnly()
	}

	handleHidePurchaseObligationArticles = () => {
		const { hidePurchaseObligationArticlesOnly } = this.props
		hidePurchaseObligationArticlesOnly()
	}

	render() {
		const {
			order,
			deliveryType,
			callOffStep,
			isSavingConcept,
			isRemovingConcept,
			saveConceptErrorMessages,
			removeConceptErrorMessages,
			saveConcept,
			removeConcept,
			clearSaveConceptError,
			clearRemoveConceptError,
			showPurchaseObligationArticlesOnly,
			hidePurchaseObligationArticlesOnly,
			showPurchaseObligationArticles
		} = this.props

		return (
			<HeaderInfo
				order={order}
				deliveryType={deliveryType}
				callOffStep={callOffStep}
				isSavingConcept={isSavingConcept}
				isRemovingConcept={isRemovingConcept}
				saveConceptErrorMessages={saveConceptErrorMessages}
				removeConceptErrorMessages={removeConceptErrorMessages}
				changeDeliveryMode={this.changeDeliveryMode}
				saveConcept={saveConcept}
				removeConcept={removeConcept}
				clearSaveConceptError={clearSaveConceptError}
				clearRemoveConceptError={clearRemoveConceptError}
				showPurchaseObligationArticlesOnly={showPurchaseObligationArticlesOnly}
				hidePurchaseObligationArticlesOnly={hidePurchaseObligationArticlesOnly}
				showPurchaseObligationArticles={showPurchaseObligationArticles}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		order: state.orderState.order,
		deliveryType: state.newCallOffState.deliveryType,
		callOffStep: state.newCallOffState.callOffStep,
		isSavingConcept: state.newCallOffState.meta.isSavingConcept,
		isRemovingConcept: state.newCallOffState.meta.isRemovingConcept,
		saveConceptErrorMessages:
			state.newCallOffState.meta.saveConceptErrorMessages,
		removeConceptErrorMessages:
			state.newCallOffState.meta.removeConceptErrorMessages,
		showPurchaseObligationArticles:
			state.newCallOffState.showPurchaseObligationArticlesOnly
	}),
	{
		setDeliveryType: newCallOffActionCreators.setDeliveryType,
		saveConcept: newCallOffActionCreators.saveConcept,
		removeConcept: newCallOffActionCreators.removeConcept,
		clearSaveConceptError: newCallOffActionCreators.clearSaveConceptError,
		clearRemoveConceptError: newCallOffActionCreators.clearRemoveConceptError,
		showPurchaseObligationArticlesOnly:
			newCallOffActionCreators.showPurchaseObligationArticlesOnly,
		hidePurchaseObligationArticlesOnly:
			newCallOffActionCreators.hidePurchaseObligationArticlesOnly
	}
)

export default connector(HeaderInfoContainer)
