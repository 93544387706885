import actionTypes from './actionTypes'

const actionCreators = {
	search: (query) => ({
		type: actionTypes.SEARCH,
		query
	}),

	toggleProductGroup: (productGroup) => ({
		type: actionTypes.TOGGLE_PRODUCTGROUP,
		productGroup
	}),

	toggleSubProductGroup: (subProductGroup) => ({
		type: actionTypes.TOGGLE_SUBPRODUCTGROUP,
		subProductGroup
	}),

	toggleProductLine: (productLine) => ({
		type: actionTypes.TOGGLE_PRODUCTLINE,
		productLine
	}),

	resetClassificationFilter: () => ({
		type: actionTypes.RESET_CLASSIFICATION_FILTER
	}),

	setDeliveryType: (delivery) => ({
		type: actionTypes.SET_DELIVERY_TYPE,
		delivery
	}),

	setClosingFreight: (closingFreight) => ({
		type: actionTypes.SET_CLOSING_FREIGHT,
		closingFreight
	}),

	updateMaterial: (material) => ({
		type: actionTypes.UPDATE_MATERIAL,
		material
	}),

	updateFreight: (freightIndex, freight) => ({
		type: actionTypes.UPDATE_FREIGHT,
		freightIndex,
		freight
	}),

	navigateToDeliveryStep: () => ({
		type: actionTypes.NAVIGATE_TO_DELIVERY_STEP
	}),

	validateBeforeDeliveryStep: () => ({
		type: actionTypes.VALIDATE_BEFORE_DELIVERY_STEP
	}),

	navigateToMaterialsStep: () => ({
		type: actionTypes.NAVIGATE_TO_MATERIALS_STEP
	}),

	navigateToSummaryStep: () => ({
		type: actionTypes.NAVIGATE_TO_SUMMARY_STEP
	}),

	validateBeforeSummaryStep: () => ({
		type: actionTypes.VALIDATE_BEFORE_SUMMARY_STEP
	}),

	setFreights: (freights) => ({
		type: actionTypes.SET_FREIGHTS,
		freights
	}),

	setFreightIndex: (freightIndex) => ({
		type: actionTypes.SET_FREIGHT_INDEX,
		freightIndex
	}),

	clearAddresses: () => ({
		type: actionTypes.CLEAR_ADDRESSES
	}),

	fetchAddresses: () => ({
		type: actionTypes.FETCH_ADDRESSES
	}),

	fetchAddressesRequest: () => ({
		type: actionTypes.FETCH_ADDRESSES_REQUEST
	}),

	fetchAddressesSuccess: (addresses) => ({
		type: actionTypes.FETCH_ADDRESSES_SUCCESS,
		addresses
	}),

	fetchAddressesFailure: (errorMessages) => ({
		type: actionTypes.FETCH_ADDRESSES_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	fillAddressOnAllCallOffs: () => ({
		type: actionTypes.FILL_ADDRESS_ON_ALL_CALL_OFFS
	}),

	fillContactPersonOnAllCallOffs: () => ({
		type: actionTypes.FILL_CONTACT_PERSON_ON_ALL_CALL_OFFS
	}),

	fillDateAndTimeOnAllCallOffs: () => ({
		type: actionTypes.FILL_DATE_AND_TIME_ON_ALL_CALL_OFFS
	}),

	fillInstructionsOnAllCallOffs: () => ({
		type: actionTypes.FILL_INSTRUCTIONS_ON_ALL_CALL_OFFS
	}),

	finish: () => ({
		type: actionTypes.FINISH
	}),

	finishRequest: () => ({
		type: actionTypes.FINISH_REQUEST
	}),

	finishSuccess: () => ({
		type: actionTypes.FINISH_SUCCESS
	}),

	finishFailure: (errorMessages) => ({
		type: actionTypes.FINISH_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	finishCancel: () => ({
		type: actionTypes.FINISH_CANCEL
	}),

	clearFinishError: () => ({
		type: actionTypes.CLEAR_FINISH_ERROR
	}),

	saveConcept: () => ({
		type: actionTypes.SAVE_CONCEPT
	}),

	saveConceptRequest: () => ({
		type: actionTypes.SAVE_CONCEPT_REQUEST
	}),

	saveConceptSuccess: (concept) => ({
		type: actionTypes.SAVE_CONCEPT_SUCCESS,
		payload: {
			concept
		}
	}),

	saveConceptFailure: (errorMessages) => ({
		type: actionTypes.SAVE_CONCEPT_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	clearSaveConceptError: () => ({
		type: actionTypes.CLEAR_SAVE_CONCEPT_ERROR
	}),

	removeConcept: () => ({
		type: actionTypes.REMOVE_CONCEPT
	}),

	removeConceptRequest: () => ({
		type: actionTypes.REMOVE_CONCEPT_REQUEST
	}),

	removeConceptSuccess: (orderId, callOffId) => ({
		type: actionTypes.REMOVE_CONCEPT_SUCCESS,
		meta: {
			orderId,
			callOffId
		}
	}),

	removeConceptFailure: (errorMessages) => ({
		type: actionTypes.REMOVE_CONCEPT_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	clearRemoveConceptError: () => ({
		type: actionTypes.CLEAR_REMOVE_CONCEPT_ERROR
	}),

	setCorrections: (corrections) => ({
		type: actionTypes.SET_CORRECTIONS,
		corrections
	}),

	clearCorrections: () => ({
		type: actionTypes.CLEAR_CORRECTIONS
	}),

	setChangedFreights: (changedFreights) => ({
		type: actionTypes.SET_CHANGED_FREIGHTS,
		changedFreights
	}),

	clearChangedFreights: () => ({
		type: actionTypes.CLEAR_CHANGED_FREIGHTS
	}),

	callEntireOrder: () => ({
		type: actionTypes.CALL_ENTIRE_ORDER
	}),

	updateMaterials: (updatedMaterials) => ({
		type: actionTypes.UPDATE_MATERIALS,
		payload: {
			updatedMaterials
		}
	}),

	updateReturnPallets: (updatedReturnPallets) => ({
		type: actionTypes.UPDATE_RETURNPALLETS,
		payload: {
			updatedReturnPallets
		}
	}),

	setShowReturnPallets: (returnPalletsSwitch) => ({
		type: actionTypes.SET_SHOW_RETURNPALLETS,
		returnPalletsSwitch
	}),

	fetchReturnPalletTypes: () => ({
		type: actionTypes.FETCH_RETURNPALLETTYPES
	}),

	fetchReturnPalletTypesRequest: () => ({
		type: actionTypes.FETCH_RETURNPALLETTYPES_REQUEST
	}),

	fetchReturnPalletTypesSuccess: (returnPalletTypes) => ({
		type: actionTypes.FETCH_RETURNPALLETTYPES_SUCCESS,
		returnPalletTypes
	}),

	fetchReturnPalletTypesFailure: (errorMessages) => ({
		type: actionTypes.FETCH_RETURNPALLETTYPES_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	removeMaterials: () => ({
		type: actionTypes.REMOVE_MATERIALS
	}),

	updateMissingMaterials: (missingMaterialsText) => ({
		type: actionTypes.UPDATE_MISSING_MATERIALS,
		missingMaterialsText
	}),

	showPurchaseObligationArticlesOnly: () => ({
		type: actionTypes.SHOW_PURCHASE_OBLIGATION_ARTICLES_ONLY
	}),

	hidePurchaseObligationArticlesOnly: () => ({
		type: actionTypes.HIDE_PURCHASE_OBLIGATION_ARTICLES_ONLY
	})
}
export default actionCreators
