import React, { PureComponent } from 'react'
import { t } from 'i18next'
import { Paper, Typography } from '@mui/material'
import styled from '@emotion/styled'

const StyledPaper = styled(Paper)(({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	},
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2)
}))

class Notfound extends PureComponent {
	render() {
		return (
			<StyledPaper>
				<Typography variant="body2">{t('app:Notfound.title')}</Typography>
			</StyledPaper>
		)
	}
}

export default Notfound
