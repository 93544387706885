import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'
import { Button, Grid, Typography, TextField } from '@mui/material'
import { styled } from '@mui/styles'

const mixinsGuttersStyle = ({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	}
})

const StyledGrid = styled(Grid)(mixinsGuttersStyle)

const sx = {
	mainContent: {
		paddingBottom: (theme) => theme.spacing(2)
	},
	typography: {
		paddingTop: (theme) => theme.spacing(2),
		fontWeight: (theme) => theme.typography.fontWeightMedium
	}
}

class Instructions extends PureComponent {
	static propTypes = {
		instructions: PropTypes.object.isRequired,
		instructionsChange: PropTypes.func.isRequired,
		handleFillInstructionsEverywhere: PropTypes.func.isRequired,
		showFillInstructionsButton: PropTypes.bool.isRequired
	}

	handleInstructionsChange = (event) => {
		const { instructions, instructionsChange } = this.props
		const line1 = event.target.value
		instructionsChange({ ...instructions, line1 })
	}

	handleInstructionsLine2Change = (event) => {
		const { instructions, instructionsChange } = this.props
		const line2 = event.target.value
		instructionsChange({ ...instructions, line2 })
	}

	render() {
		const {
			instructions,
			showFillInstructionsButton,
			handleFillInstructionsEverywhere
		} = this.props

		let fillInstructionsButton
		if (showFillInstructionsButton) {
			fillInstructionsButton = (
				<Grid item xs={12}>
					<Button variant="outlined" onClick={handleFillInstructionsEverywhere}>
						{t('app:Delivery.fillInstructionsOnAllCallOffs')}
					</Button>
				</Grid>
			)
		}

		return (
			<StyledGrid container spacing={2} sx={sx.mainContent}>
				<Grid item xs={12}>
					<Typography variant="subtitle1" sx={sx.typography}>
						{t('app:Delivery.instructions')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						value={instructions.line1}
						sx={sx.textArea}
						fullWidth
						variant="outlined"
						placeholder={t('app:Delivery.line1')}
						onChange={this.handleInstructionsChange}
						inputProps={{ maxLength: 35 }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="subtitle1" sx={sx.typography}>
						{t('app:Delivery.additionalCustomerReference')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						value={instructions.line2}
						sx={sx.textArea}
						fullWidth
						variant="outlined"
						placeholder={t('app:Delivery.line2')}
						onChange={this.handleInstructionsLine2Change}
						inputProps={{ maxLength: 35 }}
					/>
				</Grid>
				{fillInstructionsButton}
			</StyledGrid>
		)
	}
}

export default Instructions
