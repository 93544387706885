import { combineReducers } from 'redux'
import actionTypes from './actionTypes'
import ordersActionTypes from '../orders/actionTypes'
import authActionTypes from '../auth/actionTypes'

const initialUserState = null

const userReducer = (state = initialUserState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_USER_SUCCESS: {
			return action.payload.user
		}

		case ordersActionTypes.ADD_FAVORITE_ORDER_SUCCESS:
		case ordersActionTypes.REMOVE_FAVORITE_ORDER_SUCCESS: {
			return {
				...state,
				...action.payload.user
			}
		}

		case authActionTypes.LOGIN_SUCCESS: {
			return action.payload.user
		}

		case authActionTypes.AUTOLOGIN_FAILURE:
		case authActionTypes.LOGIN_FAILURE: {
			return initialUserState
		}

		case authActionTypes.LOGIN_CANCELLED: {
			return initialUserState
		}

		case authActionTypes.LOGOUT: {
			return initialUserState
		}

		default: {
			return state
		}
	}
}

const initialUserMetaState = {
	isLoggingIn: false,
	isAutoLoggingIn: true,
	isRequestingPasswordReset: false,
	isResettingPassword: false,
	hasPasswordResetCode: false,
	errorMessages: null,
	autoLoginFailed: false,
	requireTwoFactor: false,
	maskedPhoneNumber: ''
}

const metaReducer = (state = initialUserMetaState, action) => {
	switch (action.type) {
		case authActionTypes.AUTOLOGIN_REQUEST: {
			return {
				...state,
				isLoggingIn: true,
				isAutoLoggingIn: true,
				autoLoginFailed: false,
				errorMessages: null,
				requireTwoFactor: false,
				maskedPhoneNumber: ''
			}
		}

		case authActionTypes.AUTOLOGIN_NOTOKEN:
		case authActionTypes.TWOFACTOR_NOTOKEN: {
			return {
				...state,
				isLoggingIn: false,
				isAutoLoggingIn: false,
				autoLoginFailed: false,
				errorMessages: null,
				requireTwoFactor: false,
				maskedPhoneNumber: ''
			}
		}

		case authActionTypes.AUTOLOGIN_FAILURE: {
			return {
				...state,
				isLoggingIn: false,
				isAutoLoggingIn: false,
				autoLoginFailed: true,
				errorMessages: null,
				requireTwoFactor: false,
				maskedPhoneNumber: ''
			}
		}

		case actionTypes.CLEAR_ERROR: {
			return {
				...state,
				errorMessages: null
			}
		}

		case authActionTypes.LOGIN_REQUEST: {
			return {
				...state,
				isLoggingIn: true,
				isAutoLoggingIn: false,
				autoLoginFailed: false,
				errorMessages: null,
				requireTwoFactor: false,
				maskedPhoneNumber: ''
			}
		}

		case authActionTypes.LOGIN_SUCCESS: {
			return {
				...state,
				isLoggingIn: false,
				isAutoLoggingIn: false,
				autoLoginFailed: false,
				errorMessages: null,
				requireTwoFactor: false,
				maskedPhoneNumber: ''
			}
		}

		case authActionTypes.LOGIN_FAILURE: {
			return {
				...state,
				isLoggingIn: false,
				isAutoLoggingIn: false,
				autoLoginFailed: false,
				errorMessages: action.payload.errorMessages
			}
		}

		case authActionTypes.LOGIN_CANCELLED: {
			return {
				...state,
				isLoggingIn: false,
				isAutoLoggingIn: false,
				autoLoginFailed: false,
				errorMessages: null
			}
		}

		case authActionTypes.TWOFACTOR_REQUEST: {
			return {
				...state,
				isLoggingIn: true,
				isAutoLoggingIn: true,
				autoLoginFailed: false,
				errorMessages: null,
				maskedPhoneNumber: ''
			}
		}

		case authActionTypes.TWOFACTOR_FAILURE: {
			return {
				...state,
				isLoggingIn: false,
				isAutoLoggingIn: false,
				errorMessages: action.payload.errorMessages
			}
		}

		case authActionTypes.TWOFACTOR_REQUIRED: {
			return {
				...state,
				isLoggingIn: false,
				isAutoLoggingIn: false,
				autoLoginFailed: false,
				errorMessages: null,
				requireTwoFactor: true,
				maskedPhoneNumber: action.payload.maskedPhoneNumber
			}
		}

		case authActionTypes.ANOTHER_USER: {
			return {
				...state,
				requireTwoFactor: false,
				maskedPhoneNumber: ''
			}
		}

		case actionTypes.SHOW_PASSWORDRESET: {
			return {
				...state,
				hasPasswordResetCode: true
			}
		}

		case actionTypes.HIDE_PASSWORDRESET: {
			return {
				...state,
				hasPasswordResetCode: false
			}
		}

		case actionTypes.NEWPASSWORDRESET_REQUEST: {
			return {
				...state,
				isRequestingPasswordReset: true,
				errorMessages: null
			}
		}

		case actionTypes.NEWPASSWORDRESET_FAILURE: {
			return {
				...state,
				isRequestingPasswordReset: false,
				errorMessages: action.payload.errorMessages
			}
		}

		case actionTypes.NEWPASSWORDRESET_SUCCESS: {
			return {
				...state,
				isRequestingPasswordReset: false,
				hasPasswordResetCode: true
			}
		}

		case actionTypes.RESETPASSWORD_REQUEST: {
			return {
				...state,
				isResettingPassword: true,
				errorMessages: null
			}
		}

		case actionTypes.RESETPASSWORD_FAILURE: {
			return {
				...state,
				isResettingPassword: false,
				errorMessages: action.payload.errorMessages
			}
		}

		case actionTypes.RESETPASSWORD_SUCCESS: {
			return {
				...state,
				isResettingPassword: false
			}
		}

		case authActionTypes.LOGOUT: {
			return {
				...state,
				isLoggingIn: false,
				isAutoLoggingIn: false,
				autoLoginFailed: false,
				errorMessages: null
			}
		}

		default: {
			return state
		}
	}
}

export default combineReducers({
	user: userReducer,
	meta: metaReducer
})
