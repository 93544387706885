import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { t } from 'i18next'
import {
	InputAdornment,
	List,
	ListItem,
	Typography,
	TextField
} from '@mui/material'

import {
	AutoSizer,
	List as VirtualizedList,
	WindowScroller,
	CellMeasurer,
	CellMeasurerCache
} from 'react-virtualized'

import MaterialListItemContainer from '../MaterialListItem/MaterialListItemContainer'

const ROW_HEIGHT_XS = 234
const ROW_HEIGHT_SM_UP = 130

const sx = {
	list: {
		outline: 'none'
	},
	textSecondary: {
		color: (theme) => theme.palette.text.secondary
	}
}

class MaterialList extends PureComponent {
	static propTypes = {
		orderDetailRef: PropTypes.any,
		width: PropTypes.string.isRequired,
		materials: PropTypes.array.isRequired,
		missingMaterials: PropTypes.string.isRequired,
		updateMissingMaterials: PropTypes.func.isRequired
	}

	cache = new CellMeasurerCache({
		defaultHeight: this.props.width === 'xs' ? ROW_HEIGHT_XS : ROW_HEIGHT_SM_UP,
		fixedWidth: true
	})

	state = {
		characterCount: 0
	}

	componentDidUpdate() {
		if (this.listRef) {
			// clear CellMeasurerCache and remeasure all rows when list changes after filtering,
			// so rows will be re-rendered in the right height.
			this.cache.clearAll()
			this.listRef.measureAllRows()
		}
	}

	setListRef = (listRef) => {
		this.listRef = listRef
	}

	rowRenderer = ({ index, key, parent, style }) => {
		const { materials, width } = this.props
		const material = materials[index]
		return (
			<CellMeasurer
				cache={this.cache}
				columnIndex={0}
				key={key}
				parent={parent}
				rowIndex={index}
			>
				<div key={key} style={style}>
					<MaterialListItemContainer
						key={`${material.materialNumber}-${material.shippingPoint}`}
						material={material}
						width={width} // Pass width to MaterialListItem to prevent height 0 rendering issue with MUI withWidth & CellMeasurer
					/>
				</div>
			</CellMeasurer>
		)
	}

	handleMissingMaterialsDescription = (event) => {
		const { updateMissingMaterials } = this.props
		updateMissingMaterials(event.target.value)

		this.setState({
			characterCount: event.target.value.length
		})
	}

	render() {
		const { orderDetailRef, materials, missingMaterials } = this.props
		const { characterCount } = this.state

		if (materials.length === 0) {
			return (
				<List>
					<ListItem>
						<Typography variant="subtitle1" color="error">
							{t('app:Materials.noMaterialsFound')}
						</Typography>
					</ListItem>
				</List>
			)
		}

		const missingMaterialSection = (
			<List>
				<ListItem divider>
					<TextField
						variant="filled"
						fullWidth
						label={t('app:Materials.missingMaterialsLabel')}
						placeholder={t('app:Materials.missingMaterialsPlaceholder')}
						value={missingMaterials}
						onChange={this.handleMissingMaterialsDescription}
						inputProps={{ maxLength: 111 }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end" sx={sx.textSecondary}>
									{characterCount}/111
								</InputAdornment>
							)
						}}
						InputLabelProps={{ shrink: true, focused: true }}
					/>
				</ListItem>
			</List>
		)

		return (
			<Fragment>
				{missingMaterialSection}
				<WindowScroller scrollElement={orderDetailRef.current}>
					{({ height, scrollTop }) => (
						<AutoSizer disableHeight>
							{({ width: rowWidth }) => (
								<VirtualizedList
									autoHeight
									deferredMeasurementCache={this.cache}
									sx={sx.list}
									height={height || 0}
									rowCount={materials.length}
									rowHeight={this.cache.rowHeight}
									rowRenderer={this.rowRenderer}
									scrollTop={scrollTop}
									width={rowWidth}
									ref={this.setListRef}
								/>
							)}
						</AutoSizer>
					)}
				</WindowScroller>
				{missingMaterialSection}
			</Fragment>
		)
	}
}

export default compose()(MaterialList)
