const actionTypes = {
	FETCH_ORDERS: 'afroep/orders/FETCH_ORDERS',
	FETCH_ORDERS_REQUEST: 'afroep/orders/FETCH_ORDERS_REQUEST',
	FETCH_ORDERS_FAILURE: 'afroep/orders/FETCH_ORDERS_FAILURE',
	FETCH_ORDERS_SUCCESS: 'afroep/orders/FETCH_ORDERS_SUCCESS',

	FILTER_ORDERS: 'afroep/orders/FILTER_ORDERS',

	REFRESH_ORDERS: 'afroep/orders/REFRESH_ORDERS',
	REFRESH_ORDERS_REQUEST: 'afroep/orders/REFRESH_ORDERS_REQUEST',

	ADD_FAVORITE_ORDER: 'afroep/orders/ADD_FAVORITE_ORDER',
	ADD_FAVORITE_ORDER_REQUEST: 'afroep/orders/ADD_FAVORITE_ORDER_REQUEST',
	ADD_FAVORITE_ORDER_FAILURE: 'afroep/orders/ADD_FAVORITE_ORDER_FAILURE',
	ADD_FAVORITE_ORDER_SUCCESS: 'afroep/orders/ADD_FAVORITE_ORDER_SUCCESS',

	REMOVE_FAVORITE_ORDER: 'afroep/orders/REMOVE_FAVORITE_ORDER',
	REMOVE_FAVORITE_ORDER_REQUEST: 'afroep/orders/REMOVE_FAVORITE_ORDER_REQUEST',
	REMOVE_FAVORITE_ORDER_FAILURE: 'afroep/orders/REMOVE_FAVORITE_ORDER_FAILURE',
	REMOVE_FAVORITE_ORDER_SUCCESS: 'afroep/orders/REMOVE_FAVORITE_ORDER_SUCCESS',

	LOOKUP_ORDER: 'afroep/orders/LOOKUP_ORDER',
	LOOKUP_ORDER_REQUEST: 'afroep/orders/LOOKUP_ORDER_REQUEST',
	LOOKUP_ORDER_FAILURE: 'afroep/orders/LOOKUP_ORDER_FAILURE',
	LOOKUP_ORDER_SUCCESS: 'afroep/orders/LOOKUP_ORDER_SUCCESS',

	LOOKUP_ORDER_SHOW: 'afroep/orders/LOOKUP_ORDER_SHOW',
	LOOKUP_ORDER_HIDE: 'afroep/orders/LOOKUP_ORDER_HIDE'
}
export default actionTypes
