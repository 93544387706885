import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Paper, Typography, Box } from '@mui/material'
import { styled } from '@mui/styles'

const mixinsGuttersStyle = ({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	}
})

const StyledPaper = styled(Paper)(mixinsGuttersStyle)
const StyledBox = styled(Box)(mixinsGuttersStyle)

const sx = {
	notfound: {
		paddingTop: (theme) => theme.spacing(2),
		paddingBottom: (theme) => theme.spacing(2),
		textAlign: 'center'
	},
	paper: {
		marginTop: (theme) => theme.spacing(2),
		marginBottom: (theme) => theme.spacing(2)
	}
}

class NotFound extends PureComponent {
	static propTypes = {
		description: PropTypes.string,
		header: PropTypes.string,
		paper: PropTypes.bool
	}

	render() {
		const { description, header, paper } = this.props

		const descriptionText = description || ''
		const headerText = header || ''

		if (paper) {
			return (
				<StyledPaper sx={{ ...sx.notfound, ...sx.paper }}>
					<Typography variant="h5">{headerText}</Typography>
					<Typography variant="body2">{descriptionText}</Typography>
				</StyledPaper>
			)
		}
		return (
			<StyledBox sx={sx.notfound}>
				<Typography variant="h5">{headerText}</Typography>
				<Typography variant="body2">{descriptionText}</Typography>
			</StyledBox>
		)
	}
}

export default NotFound
