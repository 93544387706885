import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'
import {
	FormControl,
	FormControlLabel,
	List,
	Switch,
	Grid,
	Typography
} from '@mui/material'
import { styled } from '@mui/styles'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import newCallOffSelectors from '../../../../../redux/newCallOff/selectors'
import newCallOffActionCreators from '../../../../../redux/newCallOff/actionCreators'
import ReturnPallet from './ReturnPallet'

const mixinsGuttersStyle = ({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	}
})

const StyledGrid = styled(Grid)(mixinsGuttersStyle)

const sx = {
	switch: {
		marginTop: (theme) => theme.spacing(2),
		marginLeft: (theme) => theme.spacing(4)
	},
	mainContent: {
		paddingBottom: (theme) => theme.spacing(2)
	},
	typography: {
		marginTop: (theme) => theme.spacing(2),
		fontWeight: (theme) => theme.typography.fontWeightMedium
	}
}

class ReturnPallets extends PureComponent {
	static propTypes = {
		returnPallets: PropTypes.array,
		updateReturnPallets: PropTypes.func.isRequired,
		showReturnPallets: PropTypes.bool.isRequired,
		setShowReturnPallets: PropTypes.func.isRequired,
		fetchReturnPalletTypes: PropTypes.func.isRequired
	}

	render() {
		const {
			returnPallets,
			updateReturnPallets,
			showReturnPallets,
			setShowReturnPallets,
			fetchReturnPalletTypes
		} = this.props
		const setShowReturnPalletsLoc = async (event) => {
			setShowReturnPallets(event.target.checked)
			await fetchReturnPalletTypes()
		}

		const updatePalletItem = (palletItem, index) => {
			const newReturnPallets = [...returnPallets]
			newReturnPallets[index] = palletItem
			updateReturnPallets(newReturnPallets)
		}

		let returnPalletsListItems
		if (returnPallets) {
			returnPalletsListItems = returnPallets.map((palletItem, index) => (
				<ReturnPallet
					palletItem={palletItem}
					index={index}
					updatePalletItem={updatePalletItem}
					key={index}
				/>
			))
		}

		let returnPalletsList
		if (showReturnPallets) {
			returnPalletsList = (
				<StyledGrid container sx={sx.mainContent}>
					<Grid item xs={12}>
						<Typography variant="subtitle1" sx={sx.typography}>
							{t('app:Orders.Details.ReturnPallets.title')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<List>{returnPalletsListItems}</List>
					</Grid>
				</StyledGrid>
			)
		}

		return (
			<>
				<FormControl sx={sx.switch}>
					<FormControlLabel
						control={
							<Switch
								checked={showReturnPallets}
								onChange={setShowReturnPalletsLoc}
								color="primary"
							/>
						}
						label={`${t('app:Orders.Details.ReturnPallets.switch')}`}
					/>
				</FormControl>
				{returnPalletsList}
			</>
		)
	}
}

const enhance = compose(
	connect(
		(state) => ({
			showReturnPallets: newCallOffSelectors.showReturnPalletsSelector(state),
			returnPallets: newCallOffSelectors.returnPalletsSelector(state)
		}),
		{
			fetchReturnPalletTypes: newCallOffActionCreators.fetchReturnPalletTypes,
			setShowReturnPallets: newCallOffActionCreators.setShowReturnPallets,
			updateReturnPallets: newCallOffActionCreators.updateReturnPallets
		}
	)
)

export default enhance(ReturnPallets)
