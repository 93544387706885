import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import {
	AppBar,
	Button,
	Drawer,
	IconButton,
	SvgIcon,
	List,
	ListSubheader,
	Toolbar,
	Typography
} from '@mui/material'
import { ChevronRightFal } from '@oliverit/react-fontawesome'
import { t } from 'i18next'

import MaterialClassification from './MaterialClassification'
import { styled } from '@mui/styles'

const StyledAppBar = styled(AppBar)(({ theme }) => ({
	[theme.breakpoints.down('sm')]: {
		height: theme.getToolbarMinHeight('xs')
	},
	[theme.breakpoints.between('sm', 'md')]: {
		height: theme.getToolbarMinHeight('sm')
	},
	[theme.breakpoints.up('md')]: {
		height: theme.getToolbarMinHeight()
	}
}))

const sx = {
	appBar: {
		paddingLeft: (theme) => theme.spacing(),
		paddingRight: (theme) => theme.spacing(),
		position: 'sticky',
		top: 0,
		backgroundColor: (theme) => theme.palette.background.paper,
		zIndex: (theme) => theme.zIndex.drawer + 2
	},
	drawer: {
		width: 320,
		display: 'flex'
	},
	title: {
		paddingLeft: (theme) => theme.spacing(),
		paddingRight: (theme) => theme.spacing(),
		flex: 1
	}
}

class MaterialClassificationsFilter extends PureComponent {
	static propTypes = {
		openDrawer: PropTypes.bool.isRequired,
		handleDrawerClose: PropTypes.func.isRequired,
		classificationsFilter: PropTypes.object.isRequired,
		resetClassificationFilter: PropTypes.func.isRequired,
		productGroupToggle: PropTypes.func.isRequired,
		subProductGroupToggle: PropTypes.func.isRequired,
		productLineToggle: PropTypes.func.isRequired
	}

	render() {
		const {
			classificationsFilter,
			openDrawer,
			handleDrawerClose,
			resetClassificationFilter,
			productGroupToggle,
			subProductGroupToggle,
			productLineToggle
		} = this.props
		const { productGroups, subProductGroups, productLines } =
			classificationsFilter

		let eraseButton
		if (
			productGroups.some(({ checked }) => Boolean(checked)) ||
			subProductGroups.some(({ checked }) => Boolean(checked)) ||
			productLines.some(({ checked }) => Boolean(checked))
		) {
			eraseButton = (
				<Button onClick={resetClassificationFilter} color="primary">
					{t('app:Materials.Filter.eraseFilter')}
				</Button>
			)
		}

		let productGroupsContext
		if (classificationsFilter.productGroups.length > 0) {
			const productGroupItems = productGroups.map((productGroup) => (
				<MaterialClassification
					key={productGroup.value}
					classification={productGroup}
					classificationToggle={productGroupToggle}
				/>
			))

			productGroupsContext = (
				<List>
					<ListSubheader>
						{t('app:Materials.Filter.productGroups')}
					</ListSubheader>
					{productGroupItems}
				</List>
			)
		}

		let subProductGroupsContext
		if (classificationsFilter.productGroups.length > 0) {
			const subProductGroupItems = subProductGroups.map((subProductGroup) => (
				<MaterialClassification
					key={subProductGroup.value}
					classification={subProductGroup}
					classificationToggle={subProductGroupToggle}
				/>
			))

			subProductGroupsContext = (
				<List>
					<ListSubheader>
						{t('app:Materials.Filter.subProductGroups')}
					</ListSubheader>
					{subProductGroupItems}
				</List>
			)
		}

		let productLinesContext
		if (classificationsFilter.productLines.length > 0) {
			const productLineItems = productLines.map((productLine) => (
				<MaterialClassification
					key={productLine.value}
					classification={productLine}
					classificationToggle={productLineToggle}
				/>
			))

			productLinesContext = (
				<List>
					<ListSubheader>
						{t('app:Materials.Filter.productLines')}
					</ListSubheader>
					{productLineItems}
				</List>
			)
		}

		return (
			<Drawer
				sx={{
					'& .MuiDrawer-paper': sx.drawer,
					zIndex: (theme) => theme.zIndex.drawer + 2
				}}
				variant="temporary"
				anchor="right"
				open={openDrawer}
				ModalProps={{ keepMounted: true, onBackdropClick: handleDrawerClose }}
			>
				<StyledAppBar position="fixed" color="default" sx={sx.appBar}>
					<Toolbar disableGutters>
						<IconButton onClick={handleDrawerClose} size="large">
							<SvgIcon>
								<ChevronRightFal />
							</SvgIcon>
						</IconButton>
						<Typography sx={sx.title} variant="h6" color="inherit">
							{t('app:Materials.Filter.title')}
						</Typography>
						{eraseButton}
					</Toolbar>
				</StyledAppBar>
				{productGroupsContext}
				{subProductGroupsContext}
				{productLinesContext}
			</Drawer>
		)
	}
}

export default MaterialClassificationsFilter
