import { StyledEngineProvider } from '@mui/material'
import { blue, grey } from '@mui/material/colors'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import i18next, { use } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import moment from 'moment-timezone'
import 'moment/locale/en-gb'
import 'moment/locale/fr'
import 'moment/locale/nl'
import numeral from 'numeral'
import 'numeral/locales'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { initReactI18next } from 'react-i18next'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import AppContainer from './components/App'
import VersionUpdateNotification from './components/Shared/VersionUpdateNotification'
import locales from './locales'
import authActionCreators from './redux/auth/actionCreators'
import configureStore from './redux/configureStore'
import history from './utils/history'
import {
	getDateFnsLocale,
	getLocaleTexts,
	momentLocale,
	numeralLocale
} from './utils/localeStrings'

const startApp = async () => {
	// Initialize i18n
	await use(LanguageDetector).use(initReactI18next).init({
		fallbackLng: 'nl',
		resources: locales
	})

	i18next.on('languageChanged', (lng) => {
		// Change moment language
		moment.locale(momentLocale(lng))

		// Change numeral language
		numeral.locale(numeralLocale(lng))
	})

	// Initialize moment
	moment.locale(momentLocale(i18next.language))

	// Initialize numeral
	numeral.locale(numeralLocale(i18next.language))

	const createExtendedTheme = () => {
		const defaultTheme = createTheme()
		const spacingNumber = (spacing) =>
			parseInt(defaultTheme.spacing(spacing), 10)
		const getToolbarMinHeight = (screenWidth) => {
			let toolbarMinHeight
			switch (screenWidth) {
				case 'xs':
					toolbarMinHeight =
						defaultTheme.mixins.toolbar['@media (min-width:0px)'][
							'@media (orientation: landscape)'
						].minHeight
					break
				case 'sm':
					toolbarMinHeight =
						defaultTheme.mixins.toolbar['@media (min-width:600px)'].minHeight
					break
				default:
					toolbarMinHeight = defaultTheme.mixins.toolbar.minHeight
			}
			return `${toolbarMinHeight}px`
		}
		const { augmentColor, getContrastText } = defaultTheme.palette
		const createColor = (mainColor) =>
			augmentColor({ color: { main: mainColor } })

		// MUI Theme
		const createdTheme = createTheme({
			spacingNumber,
			getToolbarMinHeight,
			palette: {
				primary: blue,
				background: {
					default: '#fafafa'
				},
				default: createColor('#000000de'),
				grey: {
					main: grey[300],
					dark: grey[400],
					contrastText: getContrastText(grey[300])
				}
			},
			typography: {
				fontFamily:
					'-apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Helvetica", "Arial", system-ui, "Segoe UI", sans-serif'
			},
			customTypography: {
				monospaced: {
					fontFamily:
						'"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace'
				}
			}
		})
		return createdTheme
	}

	const theme = createExtendedTheme()

	// Initialize Redux store
	const store = configureStore()

	// Autologin
	store.dispatch(authActionCreators.autoLogin())

	const container = document.getElementById('app')
	const root = createRoot(container)
	root.render(
		<Provider store={store}>
			<Router history={history}>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<LocalizationProvider
							dateAdapter={AdapterDateFns}
							adapterLocale={getDateFnsLocale()}
							localeText={getLocaleTexts()}
						>
							<VersionUpdateNotification />
							<AppContainer />
						</LocalizationProvider>
					</ThemeProvider>
				</StyledEngineProvider>
			</Router>
		</Provider>
	)
}

startApp()
