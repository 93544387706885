import { t } from 'i18next'
import elementsFetch from '../utils/fetch'
import {
	NetworkError,
	ServerError,
	UnauthenticatedError,
	UnauthorizedError,
	UserNotFoundError
} from '../utils/errors'
import oeServer from '../utils/oeServer'

const userUrl = '/api/whoami'
const newPasswordResetUrl = `${oeServer}/api/passwordresets/new`
const resetPasswordUrl = `${oeServer}/api/passwordresets/reset`

const get = async () => {
	let response
	try {
		response = await elementsFetch(userUrl)
	} catch (error) {
		throw new NetworkError(t('app:Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new UnauthenticatedError(t('app:Api.Generic.unauthorized'))
		}
		if (response.status === 403) {
			throw new UnauthorizedError(t('app:Api.Generic.unauthorized'))
		}
		throw new ServerError(t('app:Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const newPasswordReset = async (userName) => {
	let response
	try {
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await fetch(newPasswordResetUrl, {
			method: 'POST',
			headers,
			body: JSON.stringify({
				userName
			}),
			timeout: 10000
		})
	} catch (error) {
		throw new NetworkError(t('app:Api.Generic.networkError'))
	}

	let result
	try {
		result = await response.json()
	} catch {
		result = {}
	}

	if (!response.ok) {
		if (
			response.status === 403 &&
			result.message &&
			result.message === 'iam:passwordReset.createPasswordReset.userNotFound'
		) {
			throw new UserNotFoundError(t('app:Api.Login.userNotFound'))
		}
		throw new ServerError(t('app:Api.Generic.serverError'))
	} else {
		return result
	}
}

const resetPassword = async (userName, password, pin) => {
	let response
	try {
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await fetch(resetPasswordUrl, {
			method: 'POST',
			headers,
			body: JSON.stringify({
				userName,
				password,
				pin
			}),
			timeout: 10000
		})
	} catch (error) {
		throw new NetworkError(t('app:Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new UnauthenticatedError(t('app:Api.Generic.unauthorized'))
		}
		if (response.status === 403) {
			throw new UnauthorizedError(t('app:Api.Generic.unauthorized'))
		}
		throw new ServerError(t('app:Api.Generic.serverError'))
	}
}
const apiUser = {
	get,
	newPasswordReset,
	resetPassword
}
export default apiUser
