import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'
import { compose } from 'recompose'
import {
	AppBar,
	Menu,
	MenuItem,
	Tabs,
	Tab,
	SvgIcon,
	Divider,
	Grid,
	Box
} from '@mui/material'
import { ChevronDownFal } from '@oliverit/react-fontawesome'
import { DELIVERY_TYPE_PICKUP } from '../../../../utils/constants'
import DateAndTimeContainer from './DateAndTime/DateAndTimeContainer'
import AddressContainer from './Address/AddressContainer'
import ContactPersonContainer from './ContactPerson/ContactPersonContainer'
import MaterialsContainer from './Materials/MaterialsContainer'
import InstructionsContainer from './Instructions/InstructionsContainer'
import ReturnPallets from './ReturnPallets/ReturnPallets'

import { styled } from '@mui/styles'

const mixinsGuttersStyle = ({ theme }) => {
	return {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			paddingLeft: theme.spacing(3),
			paddingRight: theme.spacing(3)
		}
	}
}

const StyledGrid = styled(Grid)(mixinsGuttersStyle)

const sx = {
	rootContainer: {
		flexGrow: 1,
		backgroundColor: (theme) => theme.palette.background.paper
	},
	tabBar: {
		display: 'flex'
	},
	tabs: {
		flexGrow: 1,
		backgroundColor: (theme) => theme.palette.primary.main,
		'& button': { color: (theme) => theme.palette.grey[50] },
		'& button.Mui-selected': {
			backgroundColor: (theme) => theme.palette.grey[50]
		}
	},
	tab: {
		'&:active': {
			backgroundColor: (theme) => theme.palette.action.selected
		}
	}
}

class Delivery extends PureComponent {
	static propTypes = {
		deliveryType: PropTypes.string.isRequired,
		totalFreights: PropTypes.number.isRequired,
		freightIndex: PropTypes.number.isRequired,
		setFreightIndex: PropTypes.func.isRequired,
		width: PropTypes.string.isRequired
	}

	state = {
		isFreightMenuOpen: false,
		anchorEl: null,
		showReturnPalletsForm: false
	}

	handleTabSwitch = (freightIndex) => {
		const { isFreightMenuOpen } = this.state
		if (isFreightMenuOpen) {
			this.closeFreightMenu()
		}

		const { setFreightIndex } = this.props
		setFreightIndex(freightIndex)
	}

	setShowReturnPalletsForm = (event) => {
		this.setState({
			showReturnPalletsForm: event.target.checked
		})
	}

	openFreightMenu = (event) => {
		this.setState({
			isFreightMenuOpen: true,
			anchorEl: event.currentTarget
		})
	}

	closeFreightMenu = () => {
		this.setState({
			isFreightMenuOpen: false,
			anchorEl: null
		})
	}

	render() {
		const { deliveryType, totalFreights, freightIndex, width } = this.props
		const { isFreightMenuOpen, anchorEl } = this.state
		const isPickup = deliveryType === DELIVERY_TYPE_PICKUP
		// Set number of maximum visible freights, based on screen width
		let visibleFreights
		switch (width) {
			case 'xs': {
				visibleFreights = 2
				break
			}
			case 'sm': {
				visibleFreights = 3
				break
			}
			case 'md': {
				visibleFreights = 4
				break
			}
			case 'lg': {
				visibleFreights = 3
				break
			}
			default: {
				visibleFreights = 6
			}
		}

		const tabs = []
		const menuItems = []
		for (let i = 0; i <= totalFreights - 1; i += 1) {
			// If there are exactly the same or less visibleFreights then freights, show maximum number of tabs that equals visibleFreights.
			if (totalFreights <= visibleFreights + 1 || i < visibleFreights) {
				tabs.push(
					<Tab
						key={`Tab${i}`}
						disableRipple
						label={t('app:Delivery.freightCount', { count: i + 1 })}
						onClick={() => this.handleTabSwitch(i)}
					/>
				)
			} else {
				// If there are more freights then visibleFreights, make the last visibleFreight tab act as a dropdown menu to show other freights.
				if (i === visibleFreights) {
					let label = t('app:Delivery.otherFreight')
					if (freightIndex >= visibleFreights) {
						label = t('app:Delivery.freightCount', {
							count: freightIndex + 1
						})
					}
					tabs.push(
						<Tab
							key={`Tab${i}`}
							disableRipple
							label={
								<Grid
									container
									spacing={0}
									alignItems="center"
									justifyContent="center"
								>
									<Grid item>{label}</Grid>
									<StyledGrid item>
										<SvgIcon>
											<ChevronDownFal />
										</SvgIcon>
									</StyledGrid>
								</Grid>
							}
							onClick={this.openFreightMenu}
						/>
					)
				}
				// Freights above number of visibleFreights are menuitems of the dropdown.
				menuItems.push(
					<MenuItem key={`Tab${i}`}>
						<Tab
							key={`Tab${i}`}
							disableRipple
							label={t('app:Delivery.freightCount', { count: i + 1 })}
							onClick={() => this.handleTabSwitch(i)}
						/>
					</MenuItem>
				)
			}
		}

		// Selected tab is last visibleFreight tab if there are more freights then visibleFreights
		let selectedTab = freightIndex
		if (freightIndex >= visibleFreights) {
			selectedTab = visibleFreights
		}

		return (
			<Box sx={sx.rootContainer}>
				{totalFreights > 1 && (
					<AppBar position="static" color="default" sx={sx.tabBar}>
						<Tabs sx={sx.tabs} value={selectedTab} variant="fullWidth">
							{tabs}
						</Tabs>
						<Menu
							open={isFreightMenuOpen}
							onClose={this.closeFreightMenu}
							anchorEl={anchorEl}
						>
							{menuItems}
						</Menu>
					</AppBar>
				)}
				<MaterialsContainer />
				<Divider />
				<ReturnPallets />
				<DateAndTimeContainer />
				<AddressContainer />
				{!isPickup && <ContactPersonContainer />}
				<InstructionsContainer />
				<Divider />
			</Box>
		)
	}
}

export default compose()(Delivery)
