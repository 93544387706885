import moment from 'moment-timezone'
import { DELIVERY_TYPE_PICKUP, DELIVERY_TYPE_DELIVER } from '../constants'

export const isValidEmailAddress = (email) =>
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
		email
	)

export const getMaxDeliveryDate = (fromDate) =>
	moment(fromDate).tz('Europe/Amsterdam').endOf('week').add(8, 'weeks')

export const getAvailableDeliveryWindows = ({
	deliveryDate,
	deliveryWindows,
	deliveryType,
	freight,
	date = moment().tz('Europe/Amsterdam')
}) => {
	// Delivery windows do not need to be checked for pick-up (afhalen), all windows are ok
	if (deliveryType === DELIVERY_TYPE_PICKUP) {
		return deliveryWindows.options
	}

	let availableDeliveryWindows

	const closedAtDate = deliveryWindows.closedAt.find((closedDate) =>
		moment(deliveryDate)
			.tz('Europe/Amsterdam')
			.isSame(moment(closedDate.date).tz('Europe/Amsterdam'), 'day')
	)
	if (closedAtDate && closedAtDate.windows.length > 0) {
		// Date is closed for specific windows
		availableDeliveryWindows = deliveryWindows.options.reduce((acc, option) => {
			// Check if option can be used for all materials
			const canDeliverAllMaterials = freight.materials.every(
				({ shippingPoint }) => {
					// Check if iterated material can be delivered
					const isWindowClosed = closedAtDate.windows.some(
						({ key, site }) => key === option.key && site === shippingPoint
					)
					return !isWindowClosed
				}
			)
			if (canDeliverAllMaterials) {
				return acc.concat(option)
			}
			return acc
		}, [])
	} else if (closedAtDate) {
		// Date is closed without specific windows (all windows are closed)
		availableDeliveryWindows = []
	} else {
		// Date is not closed
		availableDeliveryWindows = deliveryWindows.options
	}

	// Filter windows by limitations
	availableDeliveryWindows = availableDeliveryWindows.filter(
		(deliveryWindow) => {
			if (
				deliveryWindow.minimumWeight &&
				freight.weight < deliveryWindow.minimumWeight
			) {
				// Delivery window has a minimum weight (for example; small orders cannot be delivered between 7:00 and 9:00)
				return false
			}
			if (
				deliveryWindow.maximumLocations &&
				freight.locations > deliveryWindow.maximumLocations
			) {
				// Delivery window has a maximum amount of locations
				return false
			}

			const isPlanningDateToday = moment(freight.planningDate)
				.tz('Europe/Amsterdam')
				.isSame(date, 'day')
			const isInitialDeliveryDate = moment(freight.initialDeliveryDate)
				.tz('Europe/Amsterdam')
				.isSame(moment(deliveryDate).tz('Europe/Amsterdam'), 'day')
			if (
				deliveryWindow.canSelectNextDayTill &&
				isPlanningDateToday &&
				isInitialDeliveryDate
			) {
				// Only validate time on the first available delivery date, this is done because if a freight will be planned in
				// the afternoon (14:30), then the freight cannot be delivered anymore in the morning on the next day but
				// only in the entire day (07:00-16:00) or the afternoon (12:00-16:00).
				let canSelectNextDayTillDate = moment.tz(
					deliveryWindow.canSelectNextDayTill,
					'HH:mm:ss',
					'Europe/Amsterdam'
				)
				canSelectNextDayTillDate = canSelectNextDayTillDate.year(date.year())
				canSelectNextDayTillDate = canSelectNextDayTillDate.month(date.month())
				canSelectNextDayTillDate = canSelectNextDayTillDate.date(date.date())

				return date.isBefore(canSelectNextDayTillDate)
			}
			return true
		}
	)

	return availableDeliveryWindows
}

export const getEarliestDeliveryDateBasedOnWeightRestriction = ({
	deliveryDateRestrictionOnWeight,
	date,
	deliveryWindows
}) => {
	let numberOfAddedWorkingdays = 0
	let numberOfAddedDays = 0
	while (numberOfAddedWorkingdays !== deliveryDateRestrictionOnWeight.days) {
		const checkDate = moment(date).add(numberOfAddedDays + 1, 'day')
		const closedAtDate = deliveryWindows.closedAt.find((closed) =>
			moment(checkDate)
				.tz('Europe/Amsterdam')
				.isSame(moment(closed.date).tz('Europe/Amsterdam'), 'day')
		)
		if (closedAtDate && closedAtDate.isFree) {
			numberOfAddedDays += 1
		} else {
			numberOfAddedDays += 1
			numberOfAddedWorkingdays += 1
		}
	}
	return moment(date).add(numberOfAddedDays, 'day')
}

export const isDeliveryDateDisabled = ({
	deliveryDate,
	deliveryWindows,
	deliveryType,
	freight,
	deliveryDateRestrictionOnWeight,
	date = moment().tz('Europe/Amsterdam')
}) => {
	const maxDeliveryDate = getMaxDeliveryDate()
	if (
		moment(deliveryDate).tz('Europe/Amsterdam').isAfter(maxDeliveryDate, 'day')
	) {
		// Delivery not possible after maximum delivery date
		return true
	}

	if (
		deliveryType === DELIVERY_TYPE_DELIVER &&
		moment(deliveryDate).tz('Europe/Amsterdam').isSame(date, 'day')
	) {
		// Delivery not possible today
		return true
	}

	if (
		deliveryType === DELIVERY_TYPE_DELIVER &&
		moment(deliveryDate)
			.tz('Europe/Amsterdam')
			.isSameOrBefore(
				moment(freight.planningDate).tz('Europe/Amsterdam'),
				'day'
			)
	) {
		// Delivery cannot be delivered before or on the planning date
		return true
	}

	const closedAtDate = deliveryWindows.closedAt.find((closed) =>
		moment(deliveryDate)
			.tz('Europe/Amsterdam')
			.isSame(moment(closed.date).tz('Europe/Amsterdam'), 'day')
	)
	if (closedAtDate && closedAtDate.isFree) {
		// Date is a free date (cannot deliver/pick-up)
		return true
	}

	// Pick-ups can be picked-up any time when date is available
	if (deliveryType === DELIVERY_TYPE_PICKUP) {
		return false
	}

	if (closedAtDate && closedAtDate.windows.length === 0) {
		// No windows, date is closed for all delivery windows
		return true
	}

	if (deliveryDateRestrictionOnWeight) {
		const earliestDeliveryDateBasedOnWeight =
			getEarliestDeliveryDateBasedOnWeightRestriction({
				deliveryDateRestrictionOnWeight,
				date,
				deliveryWindows
			})
		if (
			moment(deliveryDate)
				.tz('Europe/Amsterdam')
				.isBefore(earliestDeliveryDateBasedOnWeight, 'day')
		) {
			return true
		}
	}

	// Date is available or closed for specific delivery windows, check delivery windows
	const availableDeliveryWindows = getAvailableDeliveryWindows({
		deliveryDate,
		deliveryWindows,
		deliveryType,
		freight,
		date
	})
	return availableDeliveryWindows.length === 0
}

export const isPlanningDateDisabled = ({
	planningDate,
	deliveryWindows,
	deliveryType,
	canPlanNextDayTill,
	date = moment().tz('Europe/Amsterdam')
}) => {
	// Planning not possible in the past
	const maxDeliveryDate = getMaxDeliveryDate()
	if (
		moment(planningDate).tz('Europe/Amsterdam').isBefore(date, 'day') ||
		moment(planningDate).tz('Europe/Amsterdam').isAfter(maxDeliveryDate)
	) {
		return true
	}

	// Pick-ups do not require planning
	if (deliveryType === DELIVERY_TYPE_PICKUP) {
		return false
	}

	const closedAtDate = deliveryWindows.closedAt.find((closed) =>
		moment(planningDate)
			.tz('Europe/Amsterdam')
			.isSame(moment(closed.date).tz('Europe/Amsterdam'), 'day')
	)
	if (closedAtDate && closedAtDate.isFree) {
		// Date is a free date (cannot plan)
		return true
	}

	if (moment(planningDate).tz('Europe/Amsterdam').isSame(date, 'day')) {
		// Only validate time if the freight will be planned today, this is done because if a freight will be planned in
		// the afternoon (14:30), then the freight cannot be delivered anymore in the morning on the next day but
		// only in the entire day (07:00-16:00) or the afternoon (12:00-16:00).
		let canPlanNextDayTillDate = moment.tz(
			canPlanNextDayTill,
			'HH:mm:ss',
			'Europe/Amsterdam'
		)
		canPlanNextDayTillDate = canPlanNextDayTillDate.year(date.year())
		canPlanNextDayTillDate = canPlanNextDayTillDate.month(date.month())
		canPlanNextDayTillDate = canPlanNextDayTillDate.date(date.date())

		return date.isSameOrAfter(canPlanNextDayTillDate)
	}

	return false
}

export const sortBySortIndex = (left, right) => {
	if (left.sortIndex === -2) {
		return 1
	}
	if (right.sortIndex === -2) {
		return -1
	}
	if (left.sortIndex === -1) {
		return 1
	}
	if (right.sortIndex === -1) {
		return -1
	}
	if (left.sortIndex !== right.sortIndex) {
		return left.sortIndex - right.sortIndex
	}
	return 0
}
