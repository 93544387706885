import { createSelector } from 'reselect'

const filterSelector = (state) => state.filter
const ordersSelector = (state) => state.orders

const sortedOrdersSelector = createSelector(
	ordersSelector,
	(state, favoriteOrderNumbers) => favoriteOrderNumbers,
	(orders, favoriteOrderNumbers) =>
		orders.sort((a, b) => {
			if (
				favoriteOrderNumbers.includes(a.orderNumber) &&
				!favoriteOrderNumbers.includes(b.orderNumber)
			) {
				return -1
			}
			if (
				favoriteOrderNumbers.includes(b.orderNumber) &&
				!favoriteOrderNumbers.includes(a.orderNumber)
			) {
				return 1
			}
			if (a.openCallOffOrdersCount && !b.openCallOffOrdersCount) {
				return -1
			}
			if (b.openCallOffOrdersCount && !a.openCallOffOrdersCount) {
				return 1
			}
			const compareProjectCity = a.project.city.localeCompare(b.project.city)
			if (compareProjectCity !== 0) {
				return compareProjectCity
			}
			return a.project.projectNumber.localeCompare(b.project.projectNumber)
		})
)

// Check for multiple customers in order list to show customerName & customerNumber.
const hasMultipleCustomersSelector = createSelector(ordersSelector, (orders) =>
	orders.some(
		(order) =>
			order.customer.customerNumber !== orders[0].customer.customerNumber
	)
)

const filteredOrdersSelector = createSelector(
	sortedOrdersSelector,
	filterSelector,
	(orders, filter) => {
		const { searchInput } = filter
		let searchInputParts = searchInput.toLowerCase().split(';')
		searchInputParts = searchInputParts.map((searchInputPart) =>
			searchInputPart.trim()
		)

		if (!searchInput) {
			return orders
		}
		return orders.filter((order) =>
			searchInputParts.every((searchTerm) => {
				searchTerm.trim()
				if (order.orderNumber.toLowerCase().includes(searchTerm)) {
					return true
				}
				if (
					order.project.name &&
					order.project.name.toLowerCase().includes(searchTerm)
				) {
					return true
				}
				if (
					order.project.city &&
					order.project.city.toLowerCase().includes(searchTerm)
				) {
					return true
				}
				if (
					order.project.projectNumber &&
					order.project.projectNumber.toLowerCase().includes(searchTerm)
				) {
					return true
				}
				if (
					order.customer.name &&
					order.customer.name.toLowerCase().includes(searchTerm)
				) {
					return true
				}
				if (
					order.customer.customerNumber &&
					order.customer.customerNumber.toLowerCase().includes(searchTerm)
				) {
					return true
				}
				return false
			})
		)
	}
)

// Make sure to only export selectors which are created with 'createSelector'
const selectors = {
	filteredOrdersSelector,
	hasMultipleCustomersSelector
}
export default selectors
