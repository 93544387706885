import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import authActionCreators from '../../../redux/auth/actionCreators'
import TwoFactor from './TwoFactor'

class TwoFactorContainer extends PureComponent {
	static propTypes = {
		errorMessages: PropTypes.object,
		isLoggingIn: PropTypes.bool,
		maskedPhoneNumber: PropTypes.string,
		autoLogin: PropTypes.func.isRequired,
		submitTwoFactor: PropTypes.func.isRequired,
		anotherUser: PropTypes.func.isRequired,
		logout: PropTypes.func.isRequired
	}

	state = {
		canRequestNewCode: false,
		canRequestNewCodeAfterSeconds: 180
	}

	componentDidMount() {
		this.interval = setInterval(this.updateInterval, 1000)
	}

	componentWillUnmount() {
		clearInterval(this.interval)
	}

	updateInterval = () => {
		let { canRequestNewCode, canRequestNewCodeAfterSeconds } = this.state
		canRequestNewCodeAfterSeconds -= 1

		if (canRequestNewCodeAfterSeconds === 0) {
			canRequestNewCode = true
			clearInterval(this.interval)
			this.setState({ canRequestNewCode, canRequestNewCodeAfterSeconds })
		} else {
			this.setState({ canRequestNewCodeAfterSeconds })
		}
	}

	handleRequestNewCode = () => {
		const { autoLogin } = this.props
		autoLogin()
	}

	render() {
		const {
			errorMessages,
			isLoggingIn,
			maskedPhoneNumber,
			submitTwoFactor,
			anotherUser,
			logout
		} = this.props

		const { canRequestNewCode, canRequestNewCodeAfterSeconds } = this.state

		return (
			<TwoFactor
				canRequestNewCode={canRequestNewCode}
				canRequestNewCodeAfterSeconds={canRequestNewCodeAfterSeconds}
				errorMessages={errorMessages}
				isLoggingIn={isLoggingIn}
				maskedPhoneNumber={maskedPhoneNumber}
				submitTwoFactor={submitTwoFactor}
				requestNewCode={this.handleRequestNewCode}
				anotherUser={anotherUser}
				logout={logout}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		errorMessages: state.userState.meta.errorMessages,
		isLoggingIn: state.userState.meta.isLoggingIn,
		maskedPhoneNumber: state.userState.meta.maskedPhoneNumber
	}),
	{
		autoLogin: authActionCreators.autoLogin,
		submitTwoFactor: authActionCreators.submitTwoFactor,
		anotherUser: authActionCreators.anotherUser,
		logout: authActionCreators.logout
	}
)

export default connector(TwoFactorContainer)
