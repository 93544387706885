import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Grid, TextField } from '@mui/material'

import { t } from 'i18next'
import numeral from 'numeral'
import { DELIVERY_TYPE_PICKUP } from '../../../../utils/constants'

numeral.locale('nl')

const sx = {
	input: {
		fontWeight: (theme) => theme.typography.fontWeightMedium
	}
}

class TotalOverview extends PureComponent {
	static propTypes = {
		deliveryType: PropTypes.string.isRequired,
		totalWeight: PropTypes.number.isRequired,
		totalCosts: PropTypes.number.isRequired,
		totalLocations: PropTypes.number.isRequired,
		totalFreights: PropTypes.number.isRequired
	}

	render() {
		const {
			deliveryType,
			totalWeight,
			totalCosts,
			totalLocations,
			totalFreights
		} = this.props
		const isPickup = deliveryType === DELIVERY_TYPE_PICKUP

		let freightCost
		if (isPickup) {
			freightCost = numeral(0).format('$0.0,')
		} else {
			freightCost = numeral(totalCosts).format('$0.0,')
		}

		const inputProps = {
			readOnly: true,
			disableUnderline: true,
			sx: sx.input
		}

		const inputLabelProps = {
			focused: true
		}

		return (
			<Grid container spacing={1} alignItems="center">
				<Grid item xs={6} sm={3}>
					<TextField
						value={t('app:Orders.Details.orderWeightValue', {
							weight: numeral(totalWeight).format(0, 0),
							unit: 'KG'
						})}
						label={t('app:Orders.Details.orderWeight')}
						InputProps={inputProps}
						InputLabelProps={inputLabelProps}
						variant="standard"
					/>
				</Grid>
				<Grid item xs={6} sm={3}>
					<TextField
						value={freightCost}
						label={t('app:Orders.Details.freightCost')}
						InputProps={inputProps}
						InputLabelProps={inputLabelProps}
						variant="standard"
					/>
				</Grid>
				<Grid item xs={6} sm={3}>
					<TextField
						value={totalLocations}
						label={t('app:Orders.Details.numberOfLocations')}
						InputProps={inputProps}
						InputLabelProps={inputLabelProps}
						variant="standard"
					/>
				</Grid>
				<Grid item xs={6} sm={3}>
					{!isPickup && (
						<TextField
							value={totalFreights}
							label={t('app:Orders.Details.numberOfFreights')}
							InputProps={inputProps}
							InputLabelProps={inputLabelProps}
							variant="standard"
						/>
					)}
				</Grid>
			</Grid>
		)
	}
}

export default TotalOverview
