import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import i18next, { t } from 'i18next'
import moment from 'moment'
import { Trans } from 'react-i18next'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@mui/material'

class DateAndTimeCorrectionsDialog extends PureComponent {
	static propTypes = {
		confirm: PropTypes.func.isRequired,
		confirmButtonText: PropTypes.string.isRequired,
		changedFreights: PropTypes.array.isRequired,
		isOpen: PropTypes.bool
	}

	render() {
		const { confirm, confirmButtonText, changedFreights, isOpen } = this.props

		const changedFreightsOnCallOff = changedFreights.map(
			(changedFreight, index) => {
				const { freightIndex, deliveryDate, deliveryWindow } = changedFreight
				const deliveryDateText = moment(deliveryDate).format('dddd D MMMM Y')
				const deliveryWindowText = `${
					deliveryWindow.descriptions[i18next.language]
				} (${moment(deliveryWindow.timeFrom, 'HH:mm:ss')
					.tz('Europe/Amsterdam')
					.format('HH:mm')} - ${moment(deliveryWindow.timeTill, 'HH:mm:ss')
					.tz('Europe/Amsterdam')
					.format('HH:mm')})`
				return (
					<DialogContentText key={index} gutterBottom>
						<Trans i18nKey={t('app:Validation.deliveryUnavailable')}>
							Het gewenste levermoment voor{' '}
							<strong>Afroep {freightIndex}</strong> is niet beschikbaar en is
							aangepast naar <strong>{deliveryDateText}</strong> met tijdvak{' '}
							<strong>{deliveryWindowText}</strong>
						</Trans>
					</DialogContentText>
				)
			}
		)

		return (
			<Dialog open={isOpen} onClose={confirm} maxWidth="lg">
				<DialogTitle>
					{t('app:Validation.deliveryUnavailableTitle')}
				</DialogTitle>
				<DialogContent>{changedFreightsOnCallOff}</DialogContent>
				<DialogActions>
					<Button onClick={confirm} color="primary">
						{confirmButtonText}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default DateAndTimeCorrectionsDialog
