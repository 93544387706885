import React from 'react'
import { connect } from 'react-redux'
import MaterialList from './MaterialList'
import newCallOffSelector from '../../../../../redux/newCallOff/selectors'
import newCallOffActionCreators from '../../../../../redux/newCallOff/actionCreators'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const MaterialSelectionContainer = (props) => {
	const {
		orderDetailRef,
		materials,
		missingMaterials,
		updateMissingMaterials
	} = props

	const theme = useTheme()
	const breakpoint = theme.breakpoints

	const smallerThanSM = useMediaQuery(breakpoint.down('sm'))
	const smallerThanMD = useMediaQuery(breakpoint.down('md'))
	const smallerThanLG = useMediaQuery(breakpoint.down('lg'))
	const smallerThanXL = useMediaQuery(breakpoint.down('xl'))

	let screenWidth = 'xl'

	if (smallerThanSM) {
		screenWidth = 'xs'
	} else if (smallerThanMD) {
		screenWidth = 'sm'
	} else if (smallerThanLG) {
		screenWidth = 'md'
	} else if (smallerThanXL) {
		screenWidth = 'lg'
	}

	return (
		<MaterialList
			orderDetailRef={orderDetailRef}
			materials={materials}
			missingMaterials={missingMaterials}
			updateMissingMaterials={updateMissingMaterials}
			width={screenWidth}
		/>
	)
}

const connector = connect(
	(state) => ({
		materials: newCallOffSelector.filteredMaterialsSelector(state),
		missingMaterials: state.newCallOffState.missingMaterials
	}),
	{
		updateMissingMaterials: newCallOffActionCreators.updateMissingMaterials
	}
)

export default connector(MaterialSelectionContainer)
