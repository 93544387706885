import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import orderActionCreators from '../../../../redux/orders/actionCreators'
import OrderFilter from './OrderFilter'

class OrderFilterContainer extends PureComponent {
	static propTypes = {
		isRefreshing: PropTypes.bool.isRequired,
		filterOrders: PropTypes.func.isRequired,
		refreshOrders: PropTypes.func.isRequired,
		showLookupOrder: PropTypes.func.isRequired,
		searchInput: PropTypes.string
	}

	render() {
		const {
			filterOrders,
			isRefreshing,
			refreshOrders,
			showLookupOrder,
			searchInput
		} = this.props

		return (
			<OrderFilter
				refreshOrderList={refreshOrders}
				isRefreshing={isRefreshing}
				filterOrders={filterOrders}
				showLookupOrder={showLookupOrder}
				searchInput={searchInput}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		isRefreshing: state.ordersState.meta.isRefreshing,
		searchInput: state.ordersState.filter.searchInput
	}),
	{
		refreshOrders: orderActionCreators.refreshOrders,
		filterOrders: orderActionCreators.filterOrders,
		showLookupOrder: orderActionCreators.showLookupOrder
	}
)

export default connector(OrderFilterContainer)
