import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import orderActionCreators from '../../../redux/order/actionCreators'
import CallOffs from './CallOffs'

class CallOffsContainer extends PureComponent {
	static propTypes = {
		selectedOrderId: PropTypes.string.isRequired,
		order: PropTypes.object,
		isLoadingCallOffs: PropTypes.bool.isRequired,
		fetchCallOffs: PropTypes.func.isRequired
	}

	state = {
		isLoading: true
	}

	// Set initial loading true to prevent loading with an older orderState.
	static getDerivedStateFromProps(props, state) {
		if (state.isLoading && props.isLoadingCallOffs) {
			return { isLoading: false }
		}
		return null
	}

	componentDidMount() {
		const { fetchCallOffs, selectedOrderId } = this.props
		fetchCallOffs(selectedOrderId)
	}

	render() {
		const { selectedOrderId, order, isLoadingCallOffs } = this.props
		const { isLoading } = this.state

		return (
			<CallOffs
				selectedOrderId={selectedOrderId}
				order={order}
				isLoadingCallOffs={isLoading || isLoadingCallOffs}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		order: state.orderState.order,
		isLoadingCallOffs: state.orderState.meta.isLoadingCallOffs
	}),
	{
		fetchCallOffs: orderActionCreators.fetchCallOffs
	}
)

export default connector(CallOffsContainer)
