import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

import { t } from 'i18next'

import Delivery from './Delivery'

const sx = {
	header: {
		padding: (theme) => theme.spacing(2)
	}
}

class Deliveries extends PureComponent {
	static propTypes = {
		deliveries: PropTypes.array
	}

	render() {
		const { deliveries } = this.props

		if (!deliveries || deliveries.length === 0) {
			return (
				<Typography variant="body2" sx={sx.header} align="center">
					{t('app:CallOffs.Details.noDeliveries')}
				</Typography>
			)
		}

		return deliveries.map((delivery, index) => (
			<Delivery key={index} delivery={delivery} />
		))
	}
}

export default Deliveries
