import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
	Button,
	Divider,
	Grid,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
	SvgIcon,
	Paper,
	TextField,
	CircularProgress,
	Box,
	keyframes
} from '@mui/material'
import {
	TimesFal,
	ExclamationCircleFas,
	SyncFal
} from '@oliverit/react-fontawesome'
import { t } from 'i18next'

import StatusIcon from '../../../Shared/StatusIcon'
import StatusText from '../../../Shared/StatusText'
import ConfirmDialog from '../../../Shared/ConfirmDialog'
import {
	DELIVERY_TYPE_PICKUP,
	STATUS_IN_CONCEPT,
	STATUS_IN_APPROVAL,
	STATUS_APPROVED
} from '../../../../utils/constants'
import { getTrackAndTraceTextForCallOff } from '../../../../utils/trackAndTrace'
import { amber, red } from '@mui/material/colors'
import { styled } from '@mui/styles'

const mixinsGuttersStyle = ({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	}
})

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const StyledPaper = styled(Paper)(mixinsGuttersStyle)

const sx = {
	message: {
		marginBottom: (theme) => theme.spacing(2)
	},
	heading: {
		paddingTop: (theme) => theme.spacing(3),
		paddingBottom: (theme) => theme.spacing(2),
		marginBottom: (theme) => theme.spacing(3)
	},
	warning: {
		color: amber.A700
	},
	error: {
		color: red.A700
	},
	spin: {
		animation: `${spin} 1.4s linear infinite`
	}
}

class CallOffDetailHeaderInfo extends PureComponent {
	static propTypes = {
		callOff: PropTypes.object.isRequired,
		deleteCallOff: PropTypes.func.isRequired,
		selectedOrderId: PropTypes.string.isRequired,
		refresh: PropTypes.func.isRequired,
		isRefreshingCallOff: PropTypes.bool.isRequired
	}

	state = {
		dialogIsOpen: false
	}

	openConfirmationDialog = () => {
		this.setState({
			dialogIsOpen: true
		})
	}

	closeConfirmationDialog = () => {
		this.setState({
			dialogIsOpen: false
		})
	}

	handleDeleteCallOff = () => {
		const { deleteCallOff, selectedOrderId, callOff } = this.props
		this.setState({
			dialogIsOpen: false
		})
		deleteCallOff(selectedOrderId, callOff.id)
	}

	render() {
		const { callOff, selectedOrderId, refresh, isRefreshingCallOff } =
			this.props
		const { dialogIsOpen } = this.state
		const { callOffStatus, orderNumber, salesOrderNumber, createdBy } = callOff

		let callOffCreatedBy = ''
		if (createdBy) {
			callOffCreatedBy = t('app:CallOffs.Details.calledOffBy', {
				firstName: createdBy.firstName,
				lastName: createdBy.lastName
			})
		}

		const trackAndTraceInfo = getTrackAndTraceTextForCallOff({
			callOff,
			deliveryWindows: callOff.deliveryWindows
		})

		let refreshButton
		if (isRefreshingCallOff) {
			refreshButton = (
				<IconButton disabled={true} size="large">
					<SvgIcon sx={sx.spin}>
						<SyncFal />
					</SvgIcon>
				</IconButton>
			)
		} else {
			refreshButton = (
				<IconButton onClick={refresh} size="large">
					<SvgIcon>
						<SyncFal />
					</SvgIcon>
				</IconButton>
			)
		}

		// The call-off can be modified if:
		//   - The call-off is not pending (which means the call-off is up-to-date from SAP)
		//   - The call-off has status in concept, in approval or approved (for other statuses deliveries are created and the call-off cannot be modified anymore)
		//   - The maximum weight of the call-off is not exceeded (can happen with data from SAP)
		//   - The maximum locations of the call-off is not exceeded (can happen with data from SAP)
		const canEditWithoutExceedances =
			!callOff.isPending &&
			[STATUS_IN_CONCEPT, STATUS_IN_APPROVAL, STATUS_APPROVED].includes(
				callOff.callOffStatus
			)
		const canEdit =
			canEditWithoutExceedances &&
			!callOff.weightLimitPerTruckExceeded &&
			!callOff.maximumCountriesExceeded &&
			!callOff.maximumLocationsExceeded

		// The call-off can be removed if:
		//   - The call-off is not pending (which means the call-off is up-to-date from SAP)
		//   - The call-off has status in concept, in approval or approved (for other statuses deliveries are created and the call-off cannot be modified anymore)
		//   - The call-off is of delivery type EXW (pick-up), FH (delivery) call-offs cannot be removed because then it could be used to skip freightcosts
		const canRemove =
			!callOff.isPending &&
			[STATUS_IN_CONCEPT, STATUS_IN_APPROVAL, STATUS_APPROVED].includes(
				callOff.callOffStatus
			) &&
			callOff.incoTerm === DELIVERY_TYPE_PICKUP

		let pending
		if (callOff.isPending) {
			const pendingMessage = callOff.isTemporary
				? t('app:CallOffs.Details.isTemporary')
				: t('app:CallOffs.Details.isPending')
			pending = (
				<StyledPaper sx={sx.message}>
					<Grid
						container
						spacing={2}
						justifyContent="space-between"
						alignItems="center"
					>
						<Grid item>
							<Grid container spacing={2} alignItems="center">
								<Grid item>
									<CircularProgress size={24} />
								</Grid>
								<Grid item>
									<Typography variant="body2">{pendingMessage}</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>{refreshButton}</Grid>
					</Grid>
				</StyledPaper>
			)
		}

		let weightLimitPerTruckExceeded
		if (canEditWithoutExceedances && callOff.weightLimitPerTruckExceeded) {
			weightLimitPerTruckExceeded = (
				<StyledPaper sx={sx.message}>
					<Grid container spacing={2} wrap="nowrap" alignItems="center">
						<SvgIcon fontSize="small" sx={sx.error}>
							<ExclamationCircleFas />
						</SvgIcon>
						<Grid item>
							<Typography variant="body2">
								{t('app:CallOffs.Details.weightLimitPerTruckExceeded')}
							</Typography>
						</Grid>
					</Grid>
				</StyledPaper>
			)
		}

		let maximumCountriesExceeded
		if (canEditWithoutExceedances && callOff.maximumCountriesExceeded) {
			maximumCountriesExceeded = (
				<StyledPaper sx={sx.message}>
					<Grid container spacing={2} wrap="nowrap" alignItems="center">
						<SvgIcon fontSize="small" sx={sx.error}>
							<ExclamationCircleFas />
						</SvgIcon>
						<Grid item>
							<Typography variant="body2">
								{t('app:CallOffs.Details.maximumCountriesExceeded')}
							</Typography>
						</Grid>
					</Grid>
				</StyledPaper>
			)
		}

		let maximumLocationsExceeded
		if (canEditWithoutExceedances && callOff.maximumLocationsExceeded) {
			maximumLocationsExceeded = (
				<StyledPaper sx={sx.message}>
					<Grid container spacing={2} wrap="nowrap" alignItems="center">
						<SvgIcon fontSize="small" sx={sx.error}>
							<ExclamationCircleFas />
						</SvgIcon>
						<Grid item>
							<Typography variant="body2">
								{t('app:CallOffs.Details.maximumLocationsExceeded')}
							</Typography>
						</Grid>
					</Grid>
				</StyledPaper>
			)
		}

		let deleteCallOffDialog
		if (canEdit) {
			deleteCallOffDialog = (
				<ConfirmDialog
					isDestructive
					cancel={this.closeConfirmationDialog}
					cancelButtonText={t('app:Generic.cancel')}
					confirm={this.handleDeleteCallOff}
					confirmButtonText={t('app:CallOffs.Details.delete')}
					descriptionText={t('app:CallOffs.Details.deleteCallOffDescription')}
					isOpen={dialogIsOpen}
					titleText={t('app:CallOffs.Details.deleteCallOffTitle')}
				/>
			)
		}

		return (
			<Fragment>
				<Grid
					container
					spacing={2}
					justifyContent="space-between"
					alignItems="center"
				>
					<Grid item>
						<Box sx={sx.heading}>
							<Typography variant="h6">
								{t('app:CallOffs.Details.heading')}
							</Typography>
						</Box>
					</Grid>
					<Grid item>
						{canEdit && (
							<Button
								color="primary"
								component={Link}
								to={`/orders/${selectedOrderId}/calloffs/${callOff.id}`}
							>
								{t('app:CallOffs.Details.edit')}
							</Button>
						)}
						{canRemove && (
							<Button color="secondary" onClick={this.openConfirmationDialog}>
								{t('app:Generic.delete')}
							</Button>
						)}
						<IconButton
							component={Link}
							to={`/orders/${selectedOrderId}/calloffs`}
							size="large"
						>
							<SvgIcon>
								<TimesFal />
							</SvgIcon>
						</IconButton>
					</Grid>
				</Grid>
				{pending}
				{weightLimitPerTruckExceeded}
				{maximumCountriesExceeded}
				{maximumLocationsExceeded}
				<StyledPaper>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<TextField
										value={salesOrderNumber || ''}
										fullWidth
										label={t('app:Orders.Details.orderNumber')}
										InputProps={{ readOnly: true, disableUnderline: true }}
										InputLabelProps={{ shrink: true }}
										variant="standard"
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										multiline
										fullWidth
										value={orderNumber || ''}
										label={t('app:Orders.Details.callOffNumber')}
										InputProps={{ readOnly: true, disableUnderline: true }}
										InputLabelProps={{ shrink: true }}
										variant="standard"
									/>
								</Grid>
							</Grid>
						</Grid>
						<Divider />
						<Grid item xs={12}>
							<ListItem disableGutters>
								<ListItemAvatar>
									<StatusIcon status={callOffStatus} />
								</ListItemAvatar>
								<ListItemText
									primary={trackAndTraceInfo}
									primaryTypographyProps={{ variant: 'body1' }}
									secondary={callOffCreatedBy}
								/>
								<StatusText status={callOffStatus} />
							</ListItem>
						</Grid>
					</Grid>
				</StyledPaper>
				{deleteCallOffDialog}
			</Fragment>
		)
	}
}

export default CallOffDetailHeaderInfo
