import { combineReducers } from 'redux'
import { v4 as uuidv4 } from 'uuid'
import actionTypes from './actionTypes'
import authActionTypes from '../auth/actionTypes'

const initialNotificationsState = []

const notificationsReducer = (state = initialNotificationsState, action) => {
	switch (action.type) {
		case actionTypes.ADD: {
			return [
				...state,
				{
					id: uuidv4(),
					message: action.payload.message,
					isNetworkError: action.payload.isNetworkError
				}
			]
		}

		case actionTypes.REMOVE: {
			return state.filter((notification) => notification.id !== action.meta.id)
		}

		case authActionTypes.LOGOUT: {
			return initialNotificationsState
		}

		default: {
			return state
		}
	}
}

export default combineReducers({
	notifications: notificationsReducer
})
