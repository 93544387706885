import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Grid, Hidden } from '@mui/material'

import OrderMaster from './OrderMaster/OrderMaster'
import OrderDetailContainer from './OrderDetail/OrderDetailContainer'
import CallOffsContainer from './CallOffs/CallOffsContainer'
import CallOffDetailContainer from './CallOffs/CallOffDetail/CallOffDetailContainer'
import DeliveriesContainer from './Deliveries/DeliveriesContainer'

const sx = {
	container: {
		height: '100%'
	},
	fullHeight: {
		height: '100%'
	}
}

class Orders extends PureComponent {
	static propTypes = {
		orderId: PropTypes.string,
		expand: PropTypes.string,
		childId: PropTypes.string,
		childExpand: PropTypes.string
	}

	render() {
		const { orderId, expand, childId, childExpand } = this.props

		// Generate key of view
		const key = [orderId, expand, childId, childExpand]
			.filter((element) => element)
			.join('-')

		let detailContent
		if (expand === 'calloffs' && childId && childExpand === 'trackandtrace') {
			detailContent = (
				<CallOffDetailContainer
					key={key}
					selectedOrderId={orderId}
					selectedCallOffId={childId}
				/>
			)
		} else if (expand === 'calloffs' && childId) {
			detailContent = (
				<OrderDetailContainer
					key={key}
					selectedOrderId={orderId}
					selectedCallOffId={childId}
				/>
			)
		} else if (expand === 'deliveries') {
			detailContent = (
				<DeliveriesContainer key={key} selectedOrderId={orderId} />
			)
		} else if (expand === 'calloffs') {
			detailContent = <CallOffsContainer key={key} selectedOrderId={orderId} />
		} else if (orderId) {
			detailContent = (
				<OrderDetailContainer key={key} selectedOrderId={orderId} />
			)
		}

		return (
			<Grid container sx={sx.container}>
				<Hidden lgDown={Boolean(orderId)}>
					<Grid item lg={4} xs={12}>
						<OrderMaster selectedOrderId={orderId} />
					</Grid>
				</Hidden>
				<Hidden lgDown={Boolean(!orderId)}>
					<Grid item lg={8} xs={12} sx={sx.fullHeight}>
						{detailContent}
					</Grid>
				</Hidden>
			</Grid>
		)
	}
}

export default Orders
