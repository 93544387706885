import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { ListItem, ListItemText } from '@mui/material'

import i18next from 'i18next'

import { getQuantityInAnotherUnit } from '../../../../utils/materials'

const sx = {
	listItem: {
		paddingLeft: 0,
		paddingRight: 0
	},
	firstListItem: {
		paddingTop: 0
	}
}

class SummaryMaterialListItem extends PureComponent {
	static propTypes = {
		freightMaterial: PropTypes.object.isRequired,
		materials: PropTypes.array.isRequired,
		showDivider: PropTypes.bool.isRequired,
		isFirstListItem: PropTypes.bool.isRequired
	}

	render() {
		const { freightMaterial, materials, showDivider, isFirstListItem } =
			this.props
		const language = i18next.language
		const freightMaterialDetails = materials.find(
			({ materialNumber }) => materialNumber === freightMaterial.materialNumber
		)
		const { baseUnit } = freightMaterialDetails
		const { quantity, unit } = freightMaterial
		const conversionUnit = freightMaterialDetails.unitConversions.find(
			(unitConversion) => unitConversion.unit === freightMaterial.unit
		)
		const conversionBaseUnit = freightMaterialDetails.unitConversions.find(
			(unitConversion) => unitConversion.unit === baseUnit
		)
		const quantityInBaseUnit = getQuantityInAnotherUnit({
			unitConversions: freightMaterialDetails.unitConversions,
			quantity,
			fromUnit: unit,
			toUnit: baseUnit
		})

		let amount
		if (conversionUnit.unit === conversionBaseUnit.unit) {
			amount = `${numeral(quantity).format('0,0.[000]')} ${
				conversionUnit.descriptions.short[language]
			}`
		} else {
			amount = `${numeral(quantity).format('0,0.[000]')} ${
				conversionUnit.descriptions.short[language]
			} (${numeral(quantityInBaseUnit).format('0,0.[000]')} ${
				conversionBaseUnit.descriptions.short[language]
			})`
		}
		const listItemStyle = isFirstListItem
			? { ...sx.listItem, ...sx.firstListItem }
			: sx.listItem

		return (
			<ListItem sx={listItemStyle} divider={showDivider}>
				<ListItemText
					primary={freightMaterialDetails.descriptions[language]}
					secondary={amount}
				/>
			</ListItem>
		)
	}
}

export default SummaryMaterialListItem
