import { combineReducers } from 'redux'
import actionTypes from './actionTypes'
import newCallOffActionTypes from '../newCallOff/actionTypes'
import orderActionTypes from '../order/actionTypes'

const ordersReducer = (state = [], action) => {
	switch (action.type) {
		case actionTypes.FETCH_ORDERS_SUCCESS: {
			return action.payload.orders
		}

		case newCallOffActionTypes.SAVE_CONCEPT_SUCCESS: {
			return state.map((order) => {
				if (order.id === action.payload.concept.orderId && !order.hasConcept) {
					return {
						...order,
						openCallOffOrdersCount: order.openCallOffOrdersCount + 1,
						hasConcept: true
					}
				}
				return order
			})
		}

		case newCallOffActionTypes.REMOVE_CONCEPT_SUCCESS: {
			return state.map((order) => {
				if (order.id === action.meta.orderId && order.hasConcept) {
					return {
						...order,
						openCallOffOrdersCount: order.openCallOffOrdersCount - 1,
						hasConcept: false
					}
				}
				return order
			})
		}

		case orderActionTypes.FETCH_CALLOFFS_SUCCESS: {
			return state.map((order) => {
				if (order.id === action.payload.orderWithCallOffs.id) {
					const { openCallOffOrdersCount, hasConcept } =
						action.payload.orderWithCallOffs
					let activeCallOffs
					if (hasConcept) {
						// Concept should be counted as openCallOff
						activeCallOffs = openCallOffOrdersCount + 1
					} else {
						activeCallOffs = openCallOffOrdersCount
					}
					return {
						...order,
						openCallOffOrdersCount: activeCallOffs
					}
				}
				return order
			})
		}

		default: {
			return state
		}
	}
}

const initialFilterState = { searchInput: '' }
const filterReducer = (state = initialFilterState, action) => {
	switch (action.type) {
		case actionTypes.FILTER_ORDERS: {
			return {
				searchInput: action.meta.searchInput
			}
		}

		default: {
			return state
		}
	}
}

const initialMetaState = {
	isLoading: false,
	isRefreshing: false,
	isSearching: false,
	isOrderLookupOpen: false
}
const metaReducer = (state = initialMetaState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_ORDERS_REQUEST:
		case actionTypes.FETCH_CALLOFFS_REQUEST: {
			return {
				...state,
				isLoading: true
			}
		}

		case actionTypes.REFRESH_ORDERS_REQUEST: {
			return {
				...state,
				isRefreshing: true
			}
		}

		case actionTypes.FETCH_ORDERS_SUCCESS:
		case actionTypes.FETCH_CALLOFFS_SUCCESS:
		case actionTypes.FETCH_ORDERS_FAILURE: {
			return {
				...state,
				isLoading: false,
				isRefreshing: false
			}
		}

		case actionTypes.LOOKUP_ORDER_SHOW: {
			return {
				...state,
				isOrderLookupOpen: true
			}
		}

		case actionTypes.LOOKUP_ORDER_HIDE: {
			return {
				...state,
				isOrderLookupOpen: false
			}
		}

		case actionTypes.LOOKUP_ORDER_REQUEST: {
			return {
				...state,
				isSearching: true
			}
		}

		case actionTypes.LOOKUP_ORDER_SUCCESS: {
			return {
				...state,
				isOrderLookupOpen: false,
				isSearching: false
			}
		}

		case actionTypes.LOOKUP_ORDER_FAILURE: {
			return {
				...state,
				isSearching: false
			}
		}

		default: {
			return state
		}
	}
}

export default combineReducers({
	orders: ordersReducer,
	meta: metaReducer,
	filter: filterReducer
})
