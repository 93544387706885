import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Collapse,
	Icon,
	ListItem,
	ListItemText,
	SvgIcon,
	Typography,
	Grid
} from '@mui/material'

import { ChevronDownFal } from '@oliverit/react-fontawesome'
import { t } from 'i18next'
import numeral from 'numeral'
import { DELIVERY_TYPE_PICKUP } from '../../../../../utils/constants'
import Material from './Material'
import { styled } from '@mui/styles'

const mixinsGuttersStyle = ({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	}
})

const StyledGrid = styled(Grid)(mixinsGuttersStyle)

const sx = {
	mainContent: {
		paddingTop: (theme) => theme.spacing(2),
		paddingBottom: (theme) => theme.spacing(2)
	},
	typography: {
		fontWeight: (theme) => theme.typography.fontWeightMedium
	},
	totalWeight: {
		fontWeight: (theme) => theme.typography.fontWeightMedium,
		textDecoration: 'underline'
	},
	header: {
		display: 'flex'
	},
	expand: {
		transform: 'rotate(0deg)',
		transition: (theme) =>
			theme.transitions.create('transform', {
				duration: theme.transitions.duration.shortest
			}),
		marginLeft: 'auto',
		[(theme) => theme.breakpoints.up('sm')]: {
			marginRight: (theme) => theme.spacing(-1)
		}
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	}
}

class Materials extends PureComponent {
	static propTypes = {
		freight: PropTypes.object.isRequired,
		materials: PropTypes.array.isRequired,
		deliveryType: PropTypes.string.isRequired
	}

	state = {
		isMaterialSectionOpen: true
	}

	handleMaterialSectionCollapse = () => {
		const { isMaterialSectionOpen } = this.state

		this.setState({
			isMaterialSectionOpen: !isMaterialSectionOpen
		})
	}

	render() {
		const { freight, materials, deliveryType } = this.props
		const { isMaterialSectionOpen } = this.state
		const isPickup = deliveryType === DELIVERY_TYPE_PICKUP

		let totalWeight
		if (materials.length > 1) {
			totalWeight = (
				<Grid item xs={12}>
					<Typography sx={sx.totalWeight} variant="body1" align="right">
						{numeral(freight.weight).format(0, 0)} {materials[0].weightUnit}
					</Typography>
				</Grid>
			)
		}

		const iconStyle = isMaterialSectionOpen
			? { ...sx.expand, ...sx.expandOpen }
			: sx.expand

		return (
			<div>
				<ListItem
					sx={sx.header}
					button
					disableRipple
					onClick={this.handleMaterialSectionCollapse}
				>
					<ListItemText
						disableTypography
						primary={
							<Typography variant="subtitle1" sx={sx.typography}>
								{isPickup
									? t('app:Pickup.materialsOnThisLocation')
									: t('app:Delivery.materials')}
							</Typography>
						}
					/>
					<Icon sx={iconStyle}>
						<SvgIcon>
							<ChevronDownFal />
						</SvgIcon>
					</Icon>
				</ListItem>
				<Collapse in={isMaterialSectionOpen} timeout="auto" unmountOnExit>
					<StyledGrid container spacing={2} sx={sx.mainContent}>
						{materials.map((material, index) => (
							<Material key={index} material={material} />
						))}
						{totalWeight}
					</StyledGrid>
				</Collapse>
			</div>
		)
	}
}

export default Materials
