import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import NavigationContainer from '../Navigation/NavigationContainer'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const sx = {
	root: {
		position: 'absolute',
		display: 'flex',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		flexGrow: 1,
		// iOS smooth scrolling fix
		WebkitOverflowScrolling: 'touch',
		overflowY: 'scroll',
		overflowX: 'hidden'
	}
}

const Content = styled(Box)(({ theme }) => {
	return {
		flexGrow: 1,
		height: '100%',
		padding: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.getToolbarMinHeight('xs')
		},
		[theme.breakpoints.between('sm', 'md')]: {
			paddingTop: theme.getToolbarMinHeight('sm')
		},
		[theme.breakpoints.up('md')]: {
			paddingTop: theme.getToolbarMinHeight()
		}
	}
})

class App extends PureComponent {
	static propTypes = {
		children: PropTypes.node
	}

	render() {
		const { children } = this.props

		return (
			<Box sx={sx.root}>
				<NavigationContainer />
				<Content>{children}</Content>
			</Box>
		)
	}
}

export default App
