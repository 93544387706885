import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'
import { Typography } from '@mui/material'
import {
	STATUS_IN_CONCEPT,
	STATUS_IN_APPROVAL,
	STATUS_APPROVED,
	STATUS_IN_PLANNING,
	STATUS_PLANNED,
	STATUS_LOADED,
	STATUS_CLOSED
} from '../../../utils/constants'

class StatusText extends PureComponent {
	static propTypes = {
		status: PropTypes.number
	}

	render() {
		const { status } = this.props
		let statusText
		switch (status) {
			case STATUS_IN_CONCEPT: {
				statusText = t('app:Status.inConcept')
				break
			}

			case STATUS_IN_APPROVAL: {
				statusText = t('app:Status.inApproval')
				break
			}

			case STATUS_APPROVED: {
				statusText = t('app:Status.approved')
				break
			}

			case STATUS_IN_PLANNING: {
				statusText = t('app:Status.inPlanning')
				break
			}

			case STATUS_PLANNED: {
				statusText = t('app:Status.planned')
				break
			}

			case STATUS_LOADED: {
				statusText = t('app:Status.loaded')
				break
			}

			case STATUS_CLOSED: {
				statusText = t('app:Status.closed')
				break
			}

			default: {
				break
			}
		}

		return <Typography variant="subtitle2">{statusText}</Typography>
	}
}

export default StatusText
