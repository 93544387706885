const actionTypes = {
	CLEAR_ADDRESSES: 'newCallOff/CLEAR_ADDRESSES',
	FETCH_ADDRESSES: 'newCallOff/FETCH_ADDRESSES',
	FETCH_ADDRESSES_FAILURE: 'newCallOff/FETCH_ADDRESSES_FAILURE',
	FETCH_ADDRESSES_REQUEST: 'newCallOff/FETCH_ADDRESSES_REQUEST',
	FETCH_ADDRESSES_SUCCESS: 'newCallOff/FETCH_ADDRESSES_SUCCESS',

	FILL_ADDRESS_ON_ALL_CALL_OFFS: 'newCallOff/FILL_ADDRESS_ON_ALL_CALL_OFFS',
	FILL_CONTACT_PERSON_ON_ALL_CALL_OFFS:
		'newCallOff/FILL_CONTACT_PERSON_ON_ALL_CALL_OFFS',
	FILL_DATE_AND_TIME_ON_ALL_CALL_OFFS:
		'newCallOff/FILL_DATE_AND_TIME_ON_ALL_CALL_OFFS',
	FILL_INSTRUCTIONS_ON_ALL_CALL_OFFS:
		'newCallOff/FILL_INSTRUCTIONS_ON_ALL_CALL_OFFS',

	NAVIGATE_TO_DELIVERY_STEP: 'newCallOff/NAVIGATE_TO_DELIVERY_STEP',
	NAVIGATE_TO_MATERIALS_STEP: 'newCallOff/NAVIGATE_TO_MATERIALS_STEP',
	NAVIGATE_TO_SUMMARY_STEP: 'newCallOff/NAVIGATE_TO_SUMMARY_STEP',

	VALIDATE_BEFORE_DELIVERY_STEP: 'newCallOff/VALIDATE_BEFORE_DELIVERY_STEP',
	VALIDATE_BEFORE_SUMMARY_STEP: 'newCallOff/VALIDATE_BEFORE_SUMMARY_STEP',

	RESET_CLASSIFICATION_FILTER: 'newCallOff/RESET_CLASSIFICATION_FILTER',
	RESET_STEPPER: 'newCallOff/RESET_STEPPER',
	SEARCH: 'newCallOff/SEARCH',
	SET_CLOSING_FREIGHT: 'newCallOff/SET_CLOSING_FREIGHT',
	SET_DELIVERY_TYPE: 'newCallOff/SET_DELIVERY_TYPE',
	SET_FREIGHTS: 'newCallOff/SET_FREIGHTS',
	SET_FREIGHT_INDEX: 'newCallOff/SET_FREIGHT_INDEX',
	TOGGLE_PRODUCTGROUP: 'newCallOff/TOGGLE_PRODUCTGROUP',
	TOGGLE_PRODUCTLINE: 'newCallOff/TOGGLE_PRODUCTLINE',
	TOGGLE_SUBPRODUCTGROUP: 'newCallOff/TOGGLE_SUBPRODUCTGROUP',
	UPDATE_FREIGHT: 'delivery/UPDATE_FREIGHT',
	UPDATE_MATERIAL: 'newCallOff/UPDATE_MATERIAL',

	FINISH: 'newCallOff/FINISH',
	FINISH_FAILURE: 'newCallOff/FINISH_FAILURE',
	FINISH_REQUEST: 'newCallOff/FINISH_REQUEST',
	FINISH_SUCCESS: 'newCallOff/FINISH_SUCCESS',
	FINISH_CANCEL: 'newCallOff/FINISH_CANCEL',

	CLEAR_FINISH_ERROR: 'newCallOff/CLEAR_FINISH_ERROR',

	SAVE_CONCEPT: 'newCallOff/SAVE_CONCEPT',
	SAVE_CONCEPT_FAILURE: 'newCallOff/SAVE_CONCEPT_FAILURE',
	SAVE_CONCEPT_REQUEST: 'newCallOff/SAVE_CONCEPT_REQUEST',
	SAVE_CONCEPT_SUCCESS: 'newCallOff/SAVE_CONCEPT_SUCCESS',

	CLEAR_SAVE_CONCEPT_ERROR: 'newCallOff/CLEAR_SAVE_CONCEPT_ERROR',

	REMOVE_CONCEPT: 'newCallOff/REMOVE_CONCEPT',
	REMOVE_CONCEPT_FAILURE: 'newCallOff/REMOVE_CONCEPT_FAILURE',
	REMOVE_CONCEPT_REQUEST: 'newCallOff/REMOVE_CONCEPT_REQUEST',
	REMOVE_CONCEPT_SUCCESS: 'newCallOff/REMOVE_CONCEPT_SUCCESS',

	CLEAR_REMOVE_CONCEPT_ERROR: 'newCallOff/CLEAR_REMOVE_CONCEPT_ERROR',

	SET_CORRECTIONS: 'newCallOff/SET_CORRECTIONS',
	CLEAR_CORRECTIONS: 'newCallOff/CLEAR_CORRECTIONS',

	SET_CHANGED_FREIGHTS: 'newCallOff/SET_CHANGED_FREIGHTS',
	CLEAR_CHANGED_FREIGHTS: 'newCallOff/CLEAR_CHANGED_FREIGHTS',

	CALL_ENTIRE_ORDER: 'newCallOff/CALL_ENTIRE_ORDER',
	UPDATE_MATERIALS: 'newCallOff/UPDATE_MATERIALS',
	REMOVE_MATERIALS: 'newCallOff/REMOVE_MATERIALS',

	UPDATE_RETURNPALLETS: 'newCallOff/UPDATE_RETURNPALLETS',
	SET_SHOW_RETURNPALLETS: 'newCallOff/SET_SHOW_RETURNPALLETS',

	FETCH_RETURNPALLETTYPES: 'newCallOff/FETCH_RETURNPALLETTYPES',
	FETCH_RETURNPALLETTYPES_SUCCESS: 'newCallOff/FETCH_RETURNPALLETTYPES_SUCCESS',
	FETCH_RETURNPALLETTYPES_FAILURE: 'newCallOff/FETCH_RETURNPALLETTYPES_FAILURE',
	FETCH_RETURNPALLETTYPES_REQUEST: 'newCallOff/FETCH_RETURNPALLETTYPES_REQUEST',

	UPDATE_MISSING_MATERIALS: 'newCallOff/UPDATE_MISSING_MATERIALS',

	SHOW_PURCHASE_OBLIGATION_ARTICLES_ONLY:
		'newCallOff/SHOW_PURCHASE_OBLIGATION_ARTICLES_ONLY',
	HIDE_PURCHASE_OBLIGATION_ARTICLES_ONLY:
		'newCallOff/HIDE_PURCHASE_OBLIGATION_ARTICLES_ONLY'
}
export default actionTypes
