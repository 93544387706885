import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import qs from 'qs'
import userActionCreators from '../../../redux/user/actionCreators'
import Password from './Password'

class PasswordContainer extends PureComponent {
	static propTypes = {
		query: PropTypes.any,
		clearUserError: PropTypes.func.isRequired,
		isResettingPassword: PropTypes.bool.isRequired,
		isRequestingPasswordReset: PropTypes.bool.isRequired,
		hasPasswordResetCode: PropTypes.bool.isRequired,
		errorMessages: PropTypes.object,
		showPasswordReset: PropTypes.func.isRequired,
		hidePasswordReset: PropTypes.func.isRequired,
		newPasswordReset: PropTypes.func.isRequired,
		resetPassword: PropTypes.func.isRequired
	}

	componentDidMount() {
		const { clearUserError, hidePasswordReset } = this.props
		hidePasswordReset()
		clearUserError()
	}

	render() {
		const {
			query,
			isResettingPassword,
			isRequestingPasswordReset,
			hasPasswordResetCode,
			errorMessages,
			showPasswordReset,
			newPasswordReset,
			resetPassword
		} = this.props
		const { userName = '' } = query

		return (
			<Password
				initialUserName={userName}
				isResettingPassword={isResettingPassword}
				isRequestingPasswordReset={isRequestingPasswordReset}
				hasPasswordResetCode={hasPasswordResetCode}
				errorMessages={errorMessages}
				showPasswordReset={showPasswordReset}
				newPasswordReset={newPasswordReset}
				resetPassword={resetPassword}
			/>
		)
	}
}

const connector = connect(
	(state, props) => {
		return {
			query: qs.parse(props.location.search, { ignoreQueryPrefix: true }),
			isResettingPassword: state.userState.meta.isResettingPassword,
			isRequestingPasswordReset: state.userState.meta.isRequestingPasswordReset,
			hasPasswordResetCode: state.userState.meta.hasPasswordResetCode,
			errorMessages: state.userState.meta.errorMessages
		}
	},
	{
		clearUserError: userActionCreators.clearUserError,
		showPasswordReset: userActionCreators.showPasswordReset,
		hidePasswordReset: userActionCreators.hidePasswordReset,
		newPasswordReset: userActionCreators.newPasswordReset,
		resetPassword: userActionCreators.resetPassword
	}
)

export default connector(PasswordContainer)
