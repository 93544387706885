import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import i18next, { t } from 'i18next'
import { Trans } from 'react-i18next'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@mui/material'

class ConceptCorrectionsDialog extends PureComponent {
	static propTypes = {
		confirm: PropTypes.func.isRequired,
		confirmButtonText: PropTypes.string.isRequired,
		corrections: PropTypes.array.isRequired,
		isOpen: PropTypes.bool
	}

	render() {
		const { confirm, confirmButtonText, corrections, isOpen } = this.props

		const correctionsOnConcept = corrections.map((correction, index) => {
			if (correction.key === 'app:Validation.materialQuantityChanged') {
				const { descriptions, originalQuantity, correctedQuantity, unit } =
					correction.context
				return (
					<DialogContentText key={index} gutterBottom>
						<Trans i18nKey={t('app:Validation.materialQuantityChanged')}>
							Materiaal <strong>{descriptions[i18next.language]}</strong> is
							aangepast van{' '}
							<strong>
								{originalQuantity} {unit.descriptions.short[i18next.language]}
							</strong>{' '}
							naar{' '}
							<strong>
								{correctedQuantity} {unit.descriptions.short[i18next.language]}
							</strong>
						</Trans>
					</DialogContentText>
				)
			}
			if (correction.key === 'app:Validation.materialNotAvailable') {
				const { materialNumber } = correction.context
				return (
					<DialogContentText key={index} gutterBottom>
						<Trans i18nKey={t('app:Validation.materialNotAvailable')}>
							Materiaal <strong>{materialNumber}</strong> staat niet meer op de
							order en is verwijderd uit het concept
						</Trans>
					</DialogContentText>
				)
			}
			if (correction.key === 'app:Validation.deliveryDateChanged') {
				const { freightIndex, deliveryDate, correctedDeliveryDate } =
					correction.context
				return (
					<DialogContentText key={index} gutterBottom>
						<Trans i18nKey={t('app:Validation.deliveryDateChanged')}>
							Leverdatum voor <strong>Afroep {freightIndex}</strong> is
							gewijzigd van <strong>{deliveryDate}</strong> naar{' '}
							<strong>{correctedDeliveryDate}</strong>
						</Trans>
					</DialogContentText>
				)
			}
			if (correction.key === 'app:Validation.deliveryWindowChanged') {
				const { freightIndex, oldDeliveryWindow, newDeliveryWindow } =
					correction.context
				const oldDeliveryWindowText = `${
					oldDeliveryWindow.descriptions[i18next.language]
				} (${moment(oldDeliveryWindow.timeFrom, 'HH:mm:ss')
					.tz('Europe/Amsterdam')
					.format('HH:mm')} - ${moment(oldDeliveryWindow.timeTill, 'HH:mm:ss')
					.tz('Europe/Amsterdam')
					.format('HH:mm')})`
				const newDeliveryWindowText = `${
					newDeliveryWindow.descriptions[i18next.language]
				} (${moment(newDeliveryWindow.timeFrom, 'HH:mm:ss')
					.tz('Europe/Amsterdam')
					.format('HH:mm')} - ${moment(newDeliveryWindow.timeTill, 'HH:mm:ss')
					.tz('Europe/Amsterdam')
					.format('HH:mm')})`
				return (
					<DialogContentText key={index} gutterBottom>
						<Trans i18nKey={t('app:Validation.deliveryDateChanged')}>
							Tijdvak voor <strong>Afroep {freightIndex}</strong> is gewijzigd
							van <strong>{oldDeliveryWindowText}</strong> naar{' '}
							<strong>{newDeliveryWindowText}</strong>
						</Trans>
					</DialogContentText>
				)
			}
			return (
				<DialogContentText key={index} gutterBottom>
					{t(correction.key, correction.context)}
				</DialogContentText>
			)
		})

		return (
			<Dialog open={isOpen} onClose={confirm} maxWidth="lg">
				<DialogTitle>{t('app:Validation.correctionsTitle')}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{t('app:Validation.correctionsDescription')}
					</DialogContentText>
				</DialogContent>
				<DialogContent>{correctionsOnConcept}</DialogContent>
				<DialogActions>
					<Button onClick={confirm} color="primary">
						{confirmButtonText}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default ConceptCorrectionsDialog
