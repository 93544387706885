import React from 'react'
import { connect } from 'react-redux'
import Delivery from './Delivery'
import newCallOffSelectors from '../../../../redux/newCallOff/selectors'
import newCallOffActionCreators from '../../../../redux/newCallOff/actionCreators'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const DeliveryContainer = (props) => {
	const { deliveryType, totalFreights, freightIndex, setFreightIndex } = props
	const theme = useTheme()
	const breakpoint = theme.breakpoints

	const smallerThanSM = useMediaQuery(breakpoint.down('sm'))
	const smallerThanMD = useMediaQuery(breakpoint.down('md'))
	const smallerThanLG = useMediaQuery(breakpoint.down('lg'))
	const smallerThanXL = useMediaQuery(breakpoint.down('xl'))

	let screenWidth = 'xl'

	if (smallerThanSM) {
		screenWidth = 'xs'
	} else if (smallerThanMD) {
		screenWidth = 'sm'
	} else if (smallerThanLG) {
		screenWidth = 'md'
	} else if (smallerThanXL) {
		screenWidth = 'lg'
	}
	return (
		<Delivery
			deliveryType={deliveryType}
			totalFreights={totalFreights}
			freightIndex={freightIndex}
			setFreightIndex={setFreightIndex}
			width={screenWidth}
		/>
	)
}

const connector = connect(
	(state) => ({
		deliveryType: state.newCallOffState.deliveryType,
		totalFreights: newCallOffSelectors.totalFreightsSelector(state),
		freightIndex: state.newCallOffState.freightIndex
	}),
	{
		setFreightIndex: newCallOffActionCreators.setFreightIndex
	}
)

export default connector(DeliveryContainer)
