import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DateAndTime from './DateAndTime'
import newCallOffSelectors from '../../../../../redux/newCallOff/selectors'
import newCallOffActionCreators from '../../../../../redux/newCallOff/actionCreators'

class DateAndTimeContainer extends PureComponent {
	static propTypes = {
		order: PropTypes.object.isRequired,
		freightIndex: PropTypes.number.isRequired,
		freight: PropTypes.object.isRequired,
		updateFreight: PropTypes.func.isRequired,
		availableDeliveryWindows: PropTypes.array.isRequired,
		deliveryType: PropTypes.string.isRequired,
		totalWeight: PropTypes.number.isRequired,
		showFillDateAndTimeButton: PropTypes.bool.isRequired,
		fillDateAndTimeOnAllCallOffs: PropTypes.func.isRequired,
		changedFreights: PropTypes.array,
		clearChangedFreights: PropTypes.func.isRequired
	}

	handleGoodsOnPalletsChange = (goodsOnPallets) => {
		const { freightIndex, updateFreight } = this.props
		updateFreight(freightIndex, { goodsOnPallets })
	}

	handleDeliveryDateChange = (deliveryDate) => {
		const { freightIndex, updateFreight } = this.props
		updateFreight(freightIndex, { deliveryDate })
	}

	handledeliveryWindowChange = (deliveryWindow) => {
		const { freightIndex, updateFreight } = this.props
		updateFreight(freightIndex, { deliveryWindow })
	}

	handleFillDateAndTimeEverywhere = () => {
		const { fillDateAndTimeOnAllCallOffs } = this.props
		fillDateAndTimeOnAllCallOffs()
	}

	render() {
		const {
			freight,
			availableDeliveryWindows,
			deliveryType,
			order,
			totalWeight,
			showFillDateAndTimeButton,
			changedFreights,
			clearChangedFreights
		} = this.props

		return (
			<DateAndTime
				freight={freight}
				availableDeliveryWindows={availableDeliveryWindows}
				deliveryType={deliveryType}
				order={order}
				deliveryDateChange={this.handleDeliveryDateChange}
				deliveryWindowChange={this.handledeliveryWindowChange}
				goodsOnPalletsChange={this.handleGoodsOnPalletsChange}
				totalWeight={totalWeight}
				showFillDateAndTimeButton={showFillDateAndTimeButton}
				handleFillDateAndTimeEverywhere={this.handleFillDateAndTimeEverywhere}
				changedFreights={changedFreights}
				clearChangedFreights={clearChangedFreights}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		order: state.orderState.order,
		freightIndex: state.newCallOffState.freightIndex,
		freight: newCallOffSelectors.selectedFreightSelector(state),
		availableDeliveryWindows:
			newCallOffSelectors.availableDeliveryWindowsSelector(state),
		deliveryType: state.newCallOffState.deliveryType,
		totalWeight: newCallOffSelectors.totalWeightSelector(state),
		showFillDateAndTimeButton:
			newCallOffSelectors.showFillDateAndTimeButtonSelector(state),
		changedFreights: state.newCallOffState.meta.changedFreights
	}),
	{
		fillDateAndTimeOnAllCallOffs:
			newCallOffActionCreators.fillDateAndTimeOnAllCallOffs,
		updateFreight: newCallOffActionCreators.updateFreight,
		clearChangedFreights: newCallOffActionCreators.clearChangedFreights
	}
)

export default connector(DateAndTimeContainer)
