import { all, call, fork, put, take } from 'redux-saga/effects'
import { t } from 'i18next'
import apiUser from '../../services/apiUser'
import actionTypes from './actionTypes'
import authActionCreators from '../auth/actionCreators'
import actionCreators from './actionCreators'
import notificationsActionCreators from '../notifications/actionCreators'
import { NetworkError } from '../../utils/errors'
import history from '../../utils/history'

function* fetchUser() {
	while (true) {
		yield take(actionTypes.FETCH_USER)
		yield put(actionCreators.fetchUserRequest())
		try {
			const userData = yield call(apiUser.get)
			yield put(actionCreators.fetchUserSuccess(userData))
		} catch (error) {
			// Fetching our own user failed, so something must be broken...
			// Immediately logout if this ever happens
			yield put(authActionCreators.logout())
		}
	}
}

function* newPasswordReset() {
	while (true) {
		const newPasswordResetAction = yield take(actionTypes.NEWPASSWORDRESET)
		yield put(actionCreators.newPasswordResetRequest())
		try {
			yield call(
				apiUser.newPasswordReset,
				newPasswordResetAction.payload.userName
			)
			yield put(actionCreators.newPasswordResetSuccess())
			yield put(
				notificationsActionCreators.addNotification(
					t('app:Login.newResetSuccess')
				)
			)
		} catch (error) {
			const techMessage = error ? error.message : t('app:Error.unknown')
			const userMessage = t('app:Login.resetFailure')
			yield put(
				actionCreators.newPasswordResetFailure({
					userMessage,
					techMessage
				})
			)
			yield put(
				notificationsActionCreators.addNotification(
					userMessage,
					error instanceof NetworkError
				)
			)
		}
	}
}

function* resetPassword() {
	while (true) {
		const resetPasswordAction = yield take(actionTypes.RESETPASSWORD)
		yield put(actionCreators.resetPasswordRequest())
		try {
			yield call(
				apiUser.resetPassword,
				resetPasswordAction.payload.userName,
				resetPasswordAction.payload.password,
				resetPasswordAction.payload.pin
			)
			yield put(actionCreators.resetPasswordSuccess())
			yield put(
				notificationsActionCreators.addNotification(t('app:Login.resetSuccess'))
			)
			// Automatically login after successful password reset
			yield put(
				authActionCreators.login(
					resetPasswordAction.payload.userName,
					resetPasswordAction.payload.password
				)
			)
			// Trigger navigation to root
			const location = history.location
			const destination = '/'
			if (location.pathname !== destination) {
				history.push(destination)
			}
		} catch (error) {
			const techMessage = error ? error.message : t('app:Error.unknown')
			const userMessage = t('app:Login.resetFailure')
			yield put(
				actionCreators.resetPasswordFailure({
					userMessage,
					techMessage
				})
			)
			yield put(
				notificationsActionCreators.addNotification(
					userMessage,
					error instanceof NetworkError
				)
			)
		}
	}
}

export default function* rootSaga() {
	yield all(
		[fetchUser, newPasswordReset, resetPassword].map((saga) => fork(saga))
	)
}
