import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Grid,
	ListItem,
	ListItemText,
	TextField,
	Typography,
	Box
} from '@mui/material'

import i18next, { t } from 'i18next'
import numeral from 'numeral'

const sx = {
	primary: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: (theme) => theme.spacing()
	},
	bold: {
		fontWeight: 500
	}
}

class Material extends PureComponent {
	static propTypes = {
		material: PropTypes.object.isRequired
	}

	render() {
		const { material } = this.props

		const primaryText = (
			<Box sx={sx.primary}>
				<Typography variant="body2" sx={sx.bold}>
					{material.descriptions[i18next.language]}
				</Typography>
			</Box>
		)

		let unitDescription = material.baseUnit
		const unitConversion = material.unitConversions.find(
			({ unit }) => unit === material.baseUnit
		)
		if (unitConversion) {
			unitDescription = unitConversion.descriptions.short[i18next.language]
		}

		const materialDetails = (
			<Fragment>
				<Grid container spacing={1}>
					<Grid item sm={4} xs={12}>
						<TextField
							multiline
							fullWidth
							label={t('app:CallOffs.Deliveries.articleNumber')}
							value={material.materialNumber}
							InputProps={{
								readOnly: true,
								disableUnderline: true
							}}
							variant="standard"
						/>
					</Grid>
					<Grid item sm={4} xs={12}>
						<TextField
							multiline
							fullWidth
							label={t('app:CallOffs.Deliveries.called')}
							value={`${numeral(material.quantityOrdered).format(
								0,
								0
							)} ${unitDescription}`}
							InputProps={{
								readOnly: true,
								disableUnderline: true
							}}
							variant="standard"
						/>
					</Grid>
				</Grid>
			</Fragment>
		)

		return (
			<ListItem>
				<ListItemText
					disableTypography
					primary={primaryText}
					secondary={materialDetails}
				/>
			</ListItem>
		)
	}
}

export default Material
