import React, { PureComponent } from 'react'
import { Typography, Box } from '@mui/material'

import { styled } from '@mui/styles'

const StyledBox = styled(Box)(({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	},
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2)
}))

class PrivacyStatementSVIen extends PureComponent {
	render() {
		return (
			<StyledBox>
				<Typography variant="h5" gutterBottom>
					Privacyverklaring Struyk Verwo Infra Afroep App
				</Typography>
				<Typography variant="body2">
					Voor een goed gebruik van de app, legt Struyk Verwo Infra gegevens
					over u vast.
				</Typography>
				<Typography variant="body2">
					Struyk Verwo Infra gaat zorgvuldig om met de door u verstrekte
					persoonlijke gegevens.
				</Typography>
				<Typography variant="body2" gutterBottom>
					De verwerking van de schriftelijke gegevens verloopt volgens de
					Algemene Verordening Gegevensbescherming (AVG).
				</Typography>
				<Typography variant="h6" gutterBottom>
					Gegevensverwerking
				</Typography>
				<Typography variant="body2" gutterBottom>
					Wij verzamelen de volgende gegevens van de gebruiker van de app: naam,
					email-adres, telefoonnummer en bedrijfsnaam.
				</Typography>
				<Typography variant="h6" gutterBottom>
					Doel van de gegevensverwerking
				</Typography>
				<Typography variant="body2" gutterBottom>
					Struyk Verwo Infra gebruikt de gegevens voor een correct gebruik van
					de applicatie. Persoonlijke informatie wordt niet aan derden verstrekt
					of ter inzage gegeven, tenzij dit nodig is voor het gebruik van de
					applicatie.
				</Typography>
				<Typography variant="h6" gutterBottom>
					Recht op inzage
				</Typography>
				<Typography variant="body2" gutterBottom>
					U heeft recht op inzage in gegevens die over u zijn vastgelegd.
				</Typography>
				<Typography variant="h6" gutterBottom>
					Verbetering of verwijdering van gegevens
				</Typography>
				<Typography variant="body2" gutterBottom>
					U kunt onjuistheden in uw gegevens laten verbeteren of uw gegevens
					laten verwijderen.
				</Typography>
			</StyledBox>
		)
	}
}

export default PrivacyStatementSVIen
