import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'
import { ListItem, ListItemText } from '@mui/material'

import StatusIcon from '../../../../Shared/StatusIcon'
import StatusText from '../../../../Shared/StatusText'
import Material from '../../../Deliveries/Material'
import { getTrackAndTraceTextForDelivery } from '../../../../../utils/trackAndTrace'

class Delivery extends PureComponent {
	static propTypes = {
		delivery: PropTypes.object.isRequired
	}

	render() {
		const { delivery } = this.props
		const { deliveryNumber, materials, status } = delivery

		const trackAndTraceText = getTrackAndTraceTextForDelivery({
			status: delivery.status,
			delivery
		})

		return (
			<Fragment>
				<ListItem divider>
					<StatusIcon status={status} />
					<ListItemText
						primary={t('app:Orders.Deliveries.delivery', {
							deliveryNumber
						})}
						secondary={trackAndTraceText}
						primaryTypographyProps={{ variant: 'h6' }}
					/>
					<StatusText status={status} />
				</ListItem>
				{materials.map((material, i) => (
					<Material key={i} material={material} />
				))}
			</Fragment>
		)
	}
}

export default Delivery
