import React, { PureComponent } from 'react'
import { Typography, Box } from '@mui/material'

import { styled } from '@mui/styles'

const StyledBox = styled(Box)(({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	},
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2)
}))

class PrivacyStatementSI extends PureComponent {
	render() {
		return (
			<StyledBox>
				<Typography variant="h5" gutterBottom>
					Déclaration de confidentialité Application
				</Typography>
				<Typography variant="body2">
					Stradus enregistre vos données pour garantir une bonne utilisation de
					l&apos;application.
				</Typography>
				<Typography variant="body2">
					Stradus traite soigneusement les données personnelles que vous lui
					fournissez.
				</Typography>
				<Typography variant="body2" gutterBottom>
					Le traitement des données écrites est conforme au Règlement Général
					sur la Protection des Données (RGPD).
				</Typography>
				<Typography variant="h6" gutterBottom>
					Traitement des données
				</Typography>
				<Typography variant="body2" gutterBottom>
					Nous recueillons les données suivantes de l&apos;utilisateur de
					l&apos;application: nom, adresse électronique, numéro de téléphone et
					nom de la société.
				</Typography>
				<Typography variant="h6" gutterBottom>
					But du traitement des données
				</Typography>
				<Typography variant="body2" gutterBottom>
					Stradus utilise les données pour garantir une bonne utilisation de
					l&apos;application. Les données personnelles ne sont pas communiquées
					à des tiers et ne peuvent être consultées, sauf si cela est nécessaire
					à l&apos;utilisation de l&apos;application.
				</Typography>
				<Typography variant="h6" gutterBottom>
					Droit de consultation
				</Typography>
				<Typography variant="body2" gutterBottom>
					Vous avez le droit de consulter les informations enregistrées à votre
					sujet.
				</Typography>
				<Typography variant="h6" gutterBottom>
					Correction ou suppression de données
				</Typography>
				<Typography variant="body2" gutterBottom>
					Vous pouvez faire corriger des données erronées ou faire supprimer vos
					données.
				</Typography>
			</StyledBox>
		)
	}
}

export default PrivacyStatementSI
