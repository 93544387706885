import { t } from 'i18next'
import qs from 'qs'
import elementsFetch from '../utils/fetch'
import {
	NetworkError,
	ServerError,
	UnauthenticatedError,
	UnauthorizedError
} from '../utils/errors'

const addressUrl = (queryString) => `/api/addresses?${queryString}`
const returnPalletTypesUrl = '/api/salesorders/callofforders/returnpallettypes'
const deliveryWindowsUrl = '/api/deliverywindows'
const createCallOffUrl = (orderId) =>
	`/api/salesorders/${orderId}/callofforders`
const editCallOffUrl = (orderId, callOffId) =>
	`/api/salesorders/${orderId}/callofforders/${callOffId}`
const orderConceptsUrl = (orderId) => `/api/salesorders/${orderId}/concept`
const callOffConceptsUrl = (orderId, callOffId) =>
	`/api/salesorders/${orderId}/callofforders/${callOffId}/concept`

const getAddress = async (addressQuery) => {
	let response
	try {
		response = await elementsFetch(addressUrl(qs.stringify(addressQuery)))
	} catch (error) {
		throw new NetworkError(t('app:Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new UnauthenticatedError(t('app:Api.Generic.unauthorized'))
		}
		if (response.status === 403) {
			throw new UnauthorizedError(t('app:Api.Generic.unauthorized'))
		}
		throw new ServerError(t('app:Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const getReturnPalletTypes = async () => {
	let response
	try {
		response = await elementsFetch(returnPalletTypesUrl)
		// response = await elementsFetch('TODO')
	} catch (error) {
		throw new NetworkError(t('app:Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new UnauthenticatedError(t('app:Api.Generic.unauthorized'))
		}
		if (response.status === 403) {
			throw new UnauthorizedError(t('app:Api.Generic.unauthorized'))
		}
		throw new ServerError(t('app:Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const getDeliveryWindows = async () => {
	let response
	try {
		response = await elementsFetch(deliveryWindowsUrl)
	} catch (error) {
		throw new NetworkError(t('app:Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new UnauthenticatedError(t('app:Api.Generic.unauthorized'))
		}
		if (response.status === 403) {
			throw new UnauthorizedError(t('app:Api.Generic.unauthorized'))
		}
		throw new ServerError(t('app:Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const finish = async ({
	orderId,
	callOffId,
	freights,
	deliveryType,
	closingFreight,
	closingFreightUsed,
	totalCosts,
	missingMaterials,
	returnPallets
}) => {
	let response
	try {
		const url = callOffId
			? editCallOffUrl(orderId, callOffId)
			: createCallOffUrl(orderId)
		const method = callOffId ? 'PUT' : 'POST'
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await elementsFetch(url, {
			method,
			headers,
			body: JSON.stringify({
				callOffs: freights,
				incoTerm: deliveryType,
				isClosingFreight: closingFreightUsed ? false : Boolean(closingFreight),
				hasFreightCosts: Boolean(totalCosts),
				missingMaterials,
				returnPallets
			})
		})
	} catch (error) {
		throw new NetworkError(t('app:Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new UnauthenticatedError(t('app:Api.Generic.unauthorized'))
		}
		if (response.status === 403) {
			throw new UnauthorizedError(t('app:Api.Generic.unauthorized'))
		}
		throw new ServerError(t('app:Api.Generic.serverError'))
	}
}

const saveConcept = async (orderId, concept, callOffId) => {
	let response
	try {
		const url = callOffId
			? callOffConceptsUrl(orderId, callOffId)
			: orderConceptsUrl(orderId)
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await elementsFetch(url, {
			method: 'PUT',
			headers,
			body: JSON.stringify(concept)
		})
	} catch (error) {
		throw new NetworkError(t('app:Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new UnauthenticatedError(t('app:Api.Generic.unauthorized'))
		}
		if (response.status === 403) {
			throw new UnauthorizedError(t('app:Api.Generic.unauthorized'))
		}
		throw new ServerError(t('app:Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const removeConcept = async (orderId, callOffId) => {
	let response
	try {
		const url = callOffId
			? callOffConceptsUrl(orderId, callOffId)
			: orderConceptsUrl(orderId)
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await elementsFetch(url, {
			method: 'DELETE',
			headers
		})
	} catch (error) {
		throw new NetworkError(t('app:Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new UnauthenticatedError(t('app:Api.Generic.unauthorized'))
		}
		if (response.status === 403) {
			throw new UnauthorizedError(t('app:Api.Generic.unauthorized'))
		}
		throw new ServerError(t('app:Api.Generic.serverError'))
	}
}

const apiNewCallOff = {
	getAddress,
	getReturnPalletTypes,
	getDeliveryWindows,
	finish,
	saveConcept,
	removeConcept
}
export default apiNewCallOff
