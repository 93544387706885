import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Box, CircularProgress, Paper, Typography } from '@mui/material'
import { styled } from '@mui/styles'

const mixinsGuttersStyle = ({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	}
})

const StyledPaper = styled(Paper)(mixinsGuttersStyle)
const StyledBox = styled(Box)(mixinsGuttersStyle)

const sx = {
	loading: {
		paddingTop: (theme) => theme.spacing(2),
		paddingBottom: (theme) => theme.spacing(2),
		textAlign: 'center'
	},
	paper: {
		marginTop: (theme) => theme.spacing(2),
		marginBottom: (theme) => theme.spacing(2)
	},
	loadingText: {
		marginTop: (theme) => theme.spacing(2)
	}
}

class Loading extends PureComponent {
	static propTypes = {
		message: PropTypes.string.isRequired,
		paper: PropTypes.bool
	}

	render() {
		const { message, paper } = this.props

		if (paper) {
			return (
				<StyledPaper sx={{ ...sx.loading, ...sx.paper }}>
					<CircularProgress size={50} />
					<Typography variant="body2" sx={sx.loadingText}>
						{message}
					</Typography>
				</StyledPaper>
			)
		}
		return (
			<StyledBox sx={sx.loading}>
				<CircularProgress size={50} />
				<Typography variant="body2" sx={sx.loadingText}>
					{message}
				</Typography>
			</StyledBox>
		)
	}
}

export default Loading
