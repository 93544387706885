import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'
import { ListItem, TextField, Grid, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import newCallOffActionCreators from '../../../../../redux/newCallOff/actionCreators'
import { localizedReturnPalletCodeOrUomDescription } from '../../../../../utils/localizedDescription/returnpallets'
import { styled } from '@mui/styles'

const mixinsGuttersStyle = ({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	}
})

const StyledTypography = styled(Typography)(mixinsGuttersStyle)

class ReturnPallet extends PureComponent {
	static propTypes = {
		palletItem: PropTypes.object.isRequired,
		index: PropTypes.number.isRequired,
		updatePalletItem: PropTypes.func.isRequired
	}

	state = {
		isQuantityValid: true
	}

	render() {
		const { palletItem, index, updatePalletItem } = this.props
		const { isQuantityValid } = this.state

		let quantityErrorText
		if (!isQuantityValid) {
			quantityErrorText = t(
				'app:Orders.Details.ReturnPallets.quantityErrorText'
			)
		}

		const handleBlurQuantity = (event) => {
			if (event.target.value && event.target.value < 999) {
				this.setState({
					isQuantityValid: true
				})
				const newPalletItem = { ...palletItem }
				newPalletItem.quantity = event.target.value
				updatePalletItem(newPalletItem, index)
			} else {
				this.setState({
					isQuantityValid: false
				})
			}
		}

		const handleChangeQuantity = (event) => {
			const newPalletItem = { ...palletItem }
			newPalletItem.quantity = event.target.value
			updatePalletItem(newPalletItem, index)
		}

		return (
			<ListItem key={`returnPalletItem-${index}`} disableGutters>
				<Grid container alignItems="center">
					<Grid item xs={2}>
						<Typography variant="body1">
							{localizedReturnPalletCodeOrUomDescription(palletItem.code)}
						</Typography>
					</Grid>
					<Grid item xs={2}>
						<TextField
							value={palletItem.quantity}
							required={false}
							variant="outlined"
							fullWidth
							label={t('app:Orders.Details.ReturnPallets.quantity')}
							onBlur={handleBlurQuantity}
							onChange={handleChangeQuantity}
							inputProps={{ pattern: '[0-9]*', inputMode: 'numeric' }}
							error={!isQuantityValid}
							helperText={quantityErrorText}
						/>
					</Grid>
					<Grid item>
						<StyledTypography variant="body1">
							{localizedReturnPalletCodeOrUomDescription(palletItem.uom)}
						</StyledTypography>
					</Grid>
				</Grid>
			</ListItem>
		)
	}
}

const mapStateToProps = (state) => state

const enhance = compose(
	connect(mapStateToProps, {
		updateReturnPallets: newCallOffActionCreators.updateReturnPallets
	})
)

export default enhance(ReturnPallet)
