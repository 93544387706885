import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import {
	AppBar,
	Button,
	IconButton,
	ListItem,
	TextField,
	SvgIcon
} from '@mui/material'
import { TimesFal } from '@oliverit/react-fontawesome'
import { t } from 'i18next'
import MaterialClassificationsFilterContainer from '../MaterialClassificationsFilter/MaterialClassificationsFilterContainer'
import TotalOverviewContainer from '../../TotalOverview/TotalOverviewContainer'

const sx = {
	searchBar: {
		backgroundColor: (theme) => theme.palette.background.paper,
		boxShadow: 'none'
	},
	searchInputField: {
		flex: '1'
	},
	showFiltersButton: {
		marginLeft: (theme) => theme.spacing(2)
	}
}

class MaterialFilter extends PureComponent {
	static propTypes = {
		search: PropTypes.func.isRequired,
		clearSearch: PropTypes.func.isRequired,
		query: PropTypes.string.isRequired,
		isClassificationsFilterActive: PropTypes.bool.isRequired,
		width: PropTypes.string
	}

	state = { openDrawer: false }

	handleDrawerOpen = () => {
		this.setState({ openDrawer: true })
	}

	handleDrawerClose = () => {
		this.setState({ openDrawer: false })
	}

	handleSearchInputChange = (event) => {
		const { search } = this.props
		const query = event.currentTarget.value
		search(query)
	}

	render() {
		const { query, isClassificationsFilterActive, clearSearch, width } =
			this.props
		const { openDrawer } = this.state
		const smallScreen = width === 'xs'

		return (
			<Fragment>
				<AppBar position={smallScreen ? 'static' : 'sticky'} sx={sx.searchBar}>
					<ListItem>
						<TotalOverviewContainer />
					</ListItem>
					<ListItem divider>
						<TextField
							fullWidth
							sx={sx.searchInputField}
							label={t('app:Orders.Master.search')}
							type="text"
							onChange={this.handleSearchInputChange}
							value={query}
							variant="standard"
						/>
						<IconButton onClick={clearSearch} size="large">
							<SvgIcon>
								<TimesFal />
							</SvgIcon>
						</IconButton>
						<Button
							sx={sx.showFiltersButton}
							variant="contained"
							color={isClassificationsFilterActive ? 'primary' : 'grey'}
							onClick={this.handleDrawerOpen}
						>
							{t('app:Materials.Filter.showFilters')}
						</Button>
					</ListItem>
				</AppBar>
				<MaterialClassificationsFilterContainer
					openDrawer={openDrawer}
					handleDrawerClose={this.handleDrawerClose}
				/>
			</Fragment>
		)
	}
}

export default compose()(MaterialFilter)
