import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import MaterialListContainer from './MaterialList/MaterialListContainer'
import MaterialSelectionFilterContainer from './MaterialSelectionFilter/MaterialSelectionFilterContainer'

class MaterialSelectionContainer extends PureComponent {
	static propTypes = {
		orderDetailRef: PropTypes.any
	}

	render() {
		const { orderDetailRef } = this.props

		return (
			<Fragment>
				<MaterialSelectionFilterContainer />
				<MaterialListContainer orderDetailRef={orderDetailRef} />
			</Fragment>
		)
	}
}

export default MaterialSelectionContainer
