import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import orderActionCreators from '../../../../../redux/orders/actionCreators'
import OrderLookup from './OrderLookup'

class OrderLookupContainer extends PureComponent {
	static propTypes = {
		isSearching: PropTypes.bool.isRequired,
		isOpen: PropTypes.bool.isRequired,
		hide: PropTypes.func.isRequired,
		lookup: PropTypes.func.isRequired
	}

	render() {
		const { isSearching, isOpen, hide, lookup } = this.props

		if (!isOpen) {
			return null
		}

		return <OrderLookup isSearching={isSearching} hide={hide} lookup={lookup} />
	}
}

const connector = connect(
	(state) => ({
		isSearching: state.ordersState.meta.isSearching,
		isOpen: state.ordersState.meta.isOrderLookupOpen
	}),
	{
		hide: orderActionCreators.hideLookupOrder,
		lookup: orderActionCreators.lookupOrder
	}
)

export default connector(OrderLookupContainer)
