import React, { PureComponent } from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import {
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContent,
	List
} from '@mui/material'

import Address from './Address'

const sx = {
	dialogContent: {
		padding: 0
	}
}

class FindAddressDialog extends PureComponent {
	static propTypes = {
		width: PropTypes.string.isRequired,
		addresses: PropTypes.array.isRequired,
		clearAddresses: PropTypes.func.isRequired,
		selectAddress: PropTypes.func.isRequired
	}

	render() {
		const { width, addresses, clearAddresses, selectAddress } = this.props

		const addressItems = addresses.map((address, index) => (
			<Address key={index} address={address} select={selectAddress} />
		))

		return (
			<Dialog
				open={addresses.length > 0}
				onClose={clearAddresses}
				fullWidth
				maxWidth="sm"
				fullScreen={width === 'xs'}
			>
				<DialogTitle>{t('app:Delivery.selectAddress')}</DialogTitle>
				<DialogContent sx={sx.dialogContent}>
					<List>{addressItems}</List>
				</DialogContent>
				<DialogActions>
					<Button variant="text" onClick={clearAddresses}>
						{t('app:Generic.cancel')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

export default compose()(FindAddressDialog)
