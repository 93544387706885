import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'
import { ListItem, ListItemText } from '@mui/material'

class Address extends PureComponent {
	static propTypes = {
		address: PropTypes.object.isRequired,
		select: PropTypes.func.isRequired
	}

	handleClick = () => {
		const { address, select } = this.props
		select(address)
	}

	render() {
		const { address } = this.props

		let houseNumber = ''
		if (address.houseNumberFrom && address.houseNumberTill) {
			houseNumber = `${address.houseNumberFrom}-${address.houseNumberTill}`
		} else if (address.houseNumberFrom) {
			houseNumber = address.houseNumberFrom
		} else if (address.houseNumberTill) {
			houseNumber = address.houseNumberTill
		}

		let ferryCosts = ''
		if (address.hasFerryCosts) {
			ferryCosts = `· ⚠️ ${t('app:Delivery.ferryCosts')}`
		}

		let tunnelCosts = ''
		if (address.hasTunnelCosts) {
			tunnelCosts = `· ⚠️ ${t('app:Delivery.tunnelCosts')}`
		}

		return (
			<ListItem button onClick={this.handleClick}>
				<ListItemText
					primary={`${address.street} ${houseNumber}`}
					secondary={`${address.zipCode} ${address.city} ${ferryCosts} ${tunnelCosts}`}
				/>
			</ListItem>
		)
	}
}

export default Address
