import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { List, ListItem, ListItemText } from '@mui/material'
import {
	AutoSizer,
	List as VirtualizedList,
	CellMeasurer,
	CellMeasurerCache
} from 'react-virtualized'

import { t } from 'i18next'

import OrderListItem from './OrderListItem'
import Loading from '../../../Shared/Loading'

const sx = {
	content: {
		flex: '1',
		backgroundColor: (theme) => theme.palette.background.paper,
		borderRight: (theme) => `1px solid ${theme.palette.divider}`
	},
	list: {
		outline: 'none'
	}
}

class OrderList extends PureComponent {
	static propTypes = {
		isLoading: PropTypes.bool.isRequired,
		orders: PropTypes.array.isRequired,
		selectedOrderId: PropTypes.string,
		favoriteOrders: PropTypes.array,
		addFavoriteOrder: PropTypes.func.isRequired,
		removeFavoriteOrder: PropTypes.func.isRequired,
		hasMultipleCustomers: PropTypes.bool.isRequired
	}

	state = {
		cache: new CellMeasurerCache({
			defaultHeight: 68,
			fixedWidth: true
		})
	}

	rowRenderer = ({ index, key, parent, style }) => {
		const {
			orders,
			selectedOrderId,
			favoriteOrders,
			addFavoriteOrder,
			removeFavoriteOrder,
			hasMultipleCustomers
		} = this.props
		const { cache } = this.state

		const order = orders[index]
		return (
			<CellMeasurer
				cache={cache}
				columnIndex={0}
				key={key}
				parent={parent}
				rowIndex={index}
			>
				<div key={key} style={style}>
					<OrderListItem
						key={order.id}
						order={order}
						selected={order.id === selectedOrderId}
						isFavorite={favoriteOrders.includes(order.orderNumber)}
						addFavoriteOrder={addFavoriteOrder}
						removeFavoriteOrder={removeFavoriteOrder}
						hasMultipleCustomers={hasMultipleCustomers}
					/>
				</div>
			</CellMeasurer>
		)
	}

	render() {
		const { favoriteOrders, orders, selectedOrderId, isLoading } = this.props
		const { cache } = this.state

		if (isLoading) {
			return (
				<List sx={sx.content}>
					<Loading message={t('app:Api.Generic.loading')} />
				</List>
			)
		}

		if (orders.length === 0) {
			return (
				<List sx={sx.content}>
					<ListItem>
						<ListItemText primary={t('app:Orders.empty')} />
					</ListItem>
				</List>
			)
		}

		return (
			<List disablePadding sx={sx.content}>
				<AutoSizer>
					{({ height, width }) => (
						<VirtualizedList
							style={sx.list}
							deferredMeasurementCache={cache}
							height={height}
							rowCount={orders.length}
							rowHeight={cache.rowHeight}
							rowRenderer={this.rowRenderer}
							width={width}
							orders={orders}
							selectedOrderId={selectedOrderId}
							favoriteOrders={favoriteOrders}
						/>
					)}
				</AutoSizer>
			</List>
		)
	}
}

export default OrderList
