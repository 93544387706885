import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'
import {
	Button,
	CircularProgress,
	Grid,
	Paper,
	Typography
} from '@mui/material'

import Logo from '../Logo'
import styled from '@emotion/styled'

const StyledPaper = styled(Paper)(({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	},
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2),
	margin: '0 auto',
	textAlign: 'center',
	width: 400
}))

const sx = {
	root: {
		flex: 1,
		paddingTop: (theme) => theme.spacing(12.5)
	},
	progress: {
		marginTop: (theme) => theme.spacing(2.5)
	},
	progressDescription: {
		marginTop: (theme) => theme.spacing(2)
	},
	cancelButton: {
		marginTop: (theme) => theme.spacing(2.5),
		width: '100%'
	}
}

class Starting extends PureComponent {
	static propTypes = {
		logout: PropTypes.func.isRequired
	}

	render() {
		const { logout } = this.props

		return (
			<Grid container align="center" justifyContent="center" sx={sx.root}>
				<Grid item xs={12}>
					<StyledPaper>
						<div>
							<Logo />
						</div>
						<CircularProgress sx={sx.progress} size={50} />
						<Typography variant="body2" sx={sx.progressDescription}>
							{t('app:Starting.application')}
						</Typography>
						<Button variant="contained" sx={sx.cancelButton} onClick={logout}>
							{t('app:Starting.cancel')}
						</Button>
					</StyledPaper>
				</Grid>
			</Grid>
		)
	}
}

export default Starting
