import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import orderSelector from '../../../../redux/order/selectors'
import CallOffsFilterContainer from '../CallOffsFilter/CallOffsFilterContainer'
import CallOffsList from './CallOffsList'

class CallOffsListContainer extends PureComponent {
	static propTypes = {
		selectedOrderId: PropTypes.string.isRequired,
		selectedCallOffId: PropTypes.string,
		callOffs: PropTypes.array,
		hasConcept: PropTypes.bool.isRequired,
		deliveryWindows: PropTypes.object
	}

	render() {
		const { selectedOrderId, callOffs, hasConcept, deliveryWindows } =
			this.props
		return (
			<Fragment>
				<CallOffsFilterContainer />
				<CallOffsList
					selectedOrderId={selectedOrderId}
					callOffs={callOffs}
					hasConcept={hasConcept}
					deliveryWindows={deliveryWindows}
				/>
			</Fragment>
		)
	}
}

const connector = connect((state) => ({
	callOffs: orderSelector.filteredCallOffsSelector(state.orderState),
	hasConcept: state.orderState.order.hasConcept,
	deliveryWindows: state.orderState.order.deliveryWindows
}))

export default connector(CallOffsListContainer)
