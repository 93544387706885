import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@mui/material'

import { red } from '@mui/material/colors'

const sx = {
	destructiveButton: {
		color: red[500]
	}
}

class ConfirmDialog extends PureComponent {
	static propTypes = {
		cancel: PropTypes.func.isRequired,
		cancelButtonText: PropTypes.string.isRequired,
		confirm: PropTypes.func.isRequired,
		confirmButtonText: PropTypes.string.isRequired,
		descriptionText: PropTypes.string,
		isDestructive: PropTypes.bool,
		isOpen: PropTypes.bool,
		titleText: PropTypes.string.isRequired
	}

	render() {
		const {
			cancel,
			cancelButtonText,
			confirm,
			confirmButtonText,
			descriptionText,
			isDestructive,
			isOpen,
			titleText
		} = this.props

		let confirmButton
		if (isDestructive) {
			confirmButton = (
				<Button onClick={confirm} sx={sx.destructiveButton}>
					{confirmButtonText}
				</Button>
			)
		} else {
			confirmButton = (
				<Button onClick={confirm} color="primary">
					{confirmButtonText}
				</Button>
			)
		}

		let description
		if (descriptionText) {
			const descriptionTextLines = descriptionText
				.split(/\r?\n/)
				.map((descriptionTextLine, key) => (
					<span key={key}>
						{descriptionTextLine}
						<br />
					</span>
				))
			description = (
				<DialogContent>
					<DialogContentText>{descriptionTextLines}</DialogContentText>
				</DialogContent>
			)
		}

		return (
			<Dialog open={isOpen} onClose={cancel}>
				<DialogTitle>{titleText}</DialogTitle>
				{description}
				<DialogActions>
					<Button onClick={cancel} color="default">
						{cancelButtonText}
					</Button>
					{confirmButton}
				</DialogActions>
			</Dialog>
		)
	}
}

export default ConfirmDialog
