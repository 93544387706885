import actionTypes from './actionTypes'

const actions = {
	autoLogin: () => ({
		type: actionTypes.AUTOLOGIN
	}),

	autoLoginRetry: () => ({
		type: actionTypes.AUTOLOGIN
	}),

	autoLoginRequest: () => ({
		type: actionTypes.AUTOLOGIN_REQUEST
	}),

	autoLoginNoToken: () => ({
		type: actionTypes.AUTOLOGIN_NOTOKEN
	}),

	autoLoginFailure: (errorMessages) => ({
		type: actionTypes.AUTOLOGIN_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	login: (userName, password) => ({
		type: actionTypes.LOGIN,
		payload: {
			credentials: {
				userName,
				password
			}
		}
	}),

	loginRequest: () => ({
		type: actionTypes.LOGIN_REQUEST
	}),

	loginCancelled: () => ({
		type: actionTypes.LOGIN_CANCELLED
	}),

	loginFailure: (errorMessages) => ({
		type: actionTypes.LOGIN_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	loginSuccess: (user) => ({
		type: actionTypes.LOGIN_SUCCESS,
		payload: {
			user
		}
	}),

	logout: () => ({
		type: actionTypes.LOGOUT
	}),

	twoFactorRequired: (maskedPhoneNumber) => ({
		type: actionTypes.TWOFACTOR_REQUIRED,
		payload: { maskedPhoneNumber }
	}),

	twoFactorRequest: () => ({
		type: actionTypes.TWOFACTOR_REQUEST
	}),

	twoFactorNoToken: () => ({
		type: actionTypes.TWOFACTOR_NOTOKEN
	}),

	twoFactorFailure: (errorMessages) => ({
		type: actionTypes.TWOFACTOR_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	submitTwoFactor: (twoFactorToken) => ({
		type: actionTypes.TWOFACTOR_SUBMIT,
		payload: { twoFactorToken }
	}),

	anotherUser: () => ({
		type: actionTypes.ANOTHER_USER
	})
}

export default actions
