import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	Grid,
	Button,
	Switch,
	FormControlLabel,
	SvgIcon,
	Typography
} from '@mui/material'

import { ChevronRightFal, ChevronLeftFal } from '@oliverit/react-fontawesome'
import { t } from 'i18next'
import {
	ORDER_TYPE_SALES_ORDER,
	CALL_OFF_STEP_MATERIALS,
	CALL_OFF_STEP_DELIVERY,
	CALL_OFF_STEP_SUMMARY,
	DELIVERY_TYPE_PICKUP
} from '../../../../utils/constants'
import ConfirmDialog from '../../../Shared/ConfirmDialog'

const sx = {
	actionContainer: {
		paddingLeft: (theme) => theme.spacing(2),
		paddingRight: (theme) => theme.spacing(2),
		paddingTop: (theme) => theme.spacing(),
		paddingBottom: (theme) => theme.spacing()
	},
	backButton: {
		marginRight: (theme) => theme.spacing(2)
	},
	buttonIconLeft: {
		fontSize: 'inherit',
		marginRight: (theme) => theme.spacing()
	},
	buttonIconRight: {
		fontSize: 'inherit',
		marginLeft: (theme) => theme.spacing()
	}
}

class ActionBar extends PureComponent {
	static propTypes = {
		deliveryType: PropTypes.string.isRequired,
		orderType: PropTypes.string.isRequired,
		closingFreightUsed: PropTypes.bool,
		closingFreight: PropTypes.bool.isRequired,
		changeClosingFreight: PropTypes.func.isRequired,
		callOffStep: PropTypes.number.isRequired,
		totalWeight: PropTypes.number.isRequired,
		allDeliveryInfoFilled: PropTypes.bool.isRequired,
		navigateToMaterialsStep: PropTypes.func.isRequired,
		navigateToDeliveryStep: PropTypes.func.isRequired,
		navigateToSummaryStep: PropTypes.func.isRequired,
		finish: PropTypes.func.isRequired,
		canCallEntireOrder: PropTypes.bool.isRequired,
		callEntireOrder: PropTypes.func.isRequired,
		selectedEntireOrder: PropTypes.bool.isRequired,
		removeMaterials: PropTypes.func.isRequired,
		callOffOrdersCount: PropTypes.number
	}

	state = {
		dialogIsOpen: false
	}

	handleFreightSwitchToggle = (event) => {
		const { callOffStep, changeClosingFreight } = this.props
		if (callOffStep !== CALL_OFF_STEP_SUMMARY) {
			changeClosingFreight(event.target.checked)
		}
	}

	handleCallEntireOrderSwitchToggle = () => {
		const { selectedEntireOrder, callEntireOrder, removeMaterials } = this.props
		if (selectedEntireOrder) {
			removeMaterials()
		} else {
			callEntireOrder()
		}
	}

	openConfirmationDialog = () => {
		this.setState({
			dialogIsOpen: true
		})
	}

	closeConfirmationDialog = () => {
		this.setState({
			dialogIsOpen: false
		})
	}

	handleConfirm = () => {
		const { callOffStep, navigateToDeliveryStep, navigateToMaterialsStep } =
			this.props
		this.setState({
			dialogIsOpen: false
		})
		if (callOffStep === CALL_OFF_STEP_SUMMARY) {
			navigateToDeliveryStep()
		} else {
			navigateToMaterialsStep()
		}
	}

	render() {
		const {
			deliveryType,
			orderType,
			navigateToDeliveryStep,
			navigateToSummaryStep,
			closingFreightUsed,
			closingFreight,
			callOffStep,
			totalWeight,
			allDeliveryInfoFilled,
			finish,
			canCallEntireOrder,
			selectedEntireOrder,
			callOffOrdersCount
		} = this.props
		const { dialogIsOpen } = this.state
		const isPickup = deliveryType === DELIVERY_TYPE_PICKUP

		let nextButtonText
		if (callOffStep === CALL_OFF_STEP_MATERIALS) {
			nextButtonText = isPickup
				? t('app:Orders.Details.stepPickup')
				: t('app:Orders.Details.stepDelivery')
		} else {
			nextButtonText = t('app:Orders.Details.stepConfirm')
		}

		let nextButtonAction
		if (callOffStep === CALL_OFF_STEP_MATERIALS) {
			nextButtonAction = navigateToDeliveryStep
		} else if (callOffStep === CALL_OFF_STEP_DELIVERY) {
			nextButtonAction = navigateToSummaryStep
		}

		let backButtonText
		if (callOffStep === CALL_OFF_STEP_SUMMARY) {
			backButtonText = isPickup
				? t('app:Orders.Details.stepPickup')
				: t('app:Orders.Details.stepDelivery')
		} else {
			backButtonText = t('app:Orders.Details.stepMaterials')
		}

		let backButtonAction
		if (callOffStep === CALL_OFF_STEP_SUMMARY) {
			backButtonAction = navigateToDeliveryStep
		} else {
			backButtonAction = this.openConfirmationDialog
		}

		const finishButtonText =
			orderType === ORDER_TYPE_SALES_ORDER
				? t('app:Orders.Details.submitNewCallOff')
				: t('app:Orders.Details.editCallOff')
		const finishButton = (
			<Button variant="contained" size="small" color="primary" onClick={finish}>
				{finishButtonText}
			</Button>
		)

		const nextButtonDisabled =
			(callOffStep === CALL_OFF_STEP_MATERIALS && !totalWeight) ||
			(callOffStep === CALL_OFF_STEP_DELIVERY && !allDeliveryInfoFilled)
		const nextButton = (
			<Button
				variant="contained"
				disabled={nextButtonDisabled}
				size="small"
				color="primary"
				onClick={nextButtonAction}
			>
				{nextButtonText}
				<SvgIcon sx={sx.buttonIconRight}>
					<ChevronRightFal />
				</SvgIcon>
			</Button>
		)
		const backButton = (
			<Button
				sx={sx.backButton}
				variant="contained"
				size="small"
				onClick={backButtonAction}
				color="grey"
			>
				<SvgIcon sx={sx.buttonIconLeft}>
					<ChevronLeftFal />
				</SvgIcon>
				{backButtonText}
			</Button>
		)

		let closingFreightSwitch
		if (
			callOffOrdersCount > 0 &&
			!closingFreightUsed &&
			orderType === ORDER_TYPE_SALES_ORDER
		) {
			closingFreightSwitch = (
				<Grid item>
					<FormControlLabel
						control={
							<Switch
								checked={closingFreight}
								onChange={this.handleFreightSwitchToggle}
								disableRipple={callOffStep === CALL_OFF_STEP_SUMMARY}
							/>
						}
						label={
							<Typography variant="body2">
								{t('app:Orders.Details.closingFreight')}
							</Typography>
						}
					/>
				</Grid>
			)
		}

		let callEntireOrderSwitch
		if (canCallEntireOrder && callOffStep === CALL_OFF_STEP_MATERIALS) {
			callEntireOrderSwitch = (
				<Grid item>
					<FormControlLabel
						control={
							<Switch
								checked={selectedEntireOrder}
								onChange={this.handleCallEntireOrderSwitchToggle}
								disableRipple={callOffStep === CALL_OFF_STEP_SUMMARY}
							/>
						}
						label={
							<Typography variant="body2">
								{t('app:Orders.Details.callEntireOrder')}
							</Typography>
						}
					/>
				</Grid>
			)
		}

		return (
			<Fragment>
				<Grid
					container
					justifyContent="space-between"
					spacing={2}
					alignItems="flex-end"
					sx={sx.actionContainer}
				>
					<Grid item>
						<Grid container spacing={2}>
							{closingFreightSwitch}
							{callEntireOrderSwitch}
						</Grid>
					</Grid>
					<Grid item>
						<Grid container spacing={2}>
							<Grid item>
								{callOffStep !== CALL_OFF_STEP_MATERIALS && backButton}
								{callOffStep === CALL_OFF_STEP_SUMMARY
									? finishButton
									: nextButton}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<ConfirmDialog
					cancel={this.closeConfirmationDialog}
					cancelButtonText={t('app:Materials.Messages.cancelButtonText')}
					confirm={this.handleConfirm}
					confirmButtonText={t(
						'app:Materials.Messages.confirmGoBackButtonText'
					)}
					descriptionText={t('app:Materials.Messages.unsavedDeliveryWarning')}
					isOpen={dialogIsOpen}
					titleText={t('app:Materials.Messages.unsavedDeliveryTitle')}
				/>
			</Fragment>
		)
	}
}

export default ActionBar
