import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

// This component will catch all JavaScript errors
// When this happens, we show an error view which lets the user restart the application
// To make sure this component will always properly render in case of an error,
// we do not use any modules or other components here.

class ErrorBoundary extends PureComponent {
	static propTypes = {
		children: PropTypes.node
	}

	state = {
		error: null,
		info: null
	}

	componentDidCatch(error, info) {
		this.setState({
			error,
			info
		})
	}

	handleRestart = () => {
		window.location.reload()
	}

	render() {
		const { children } = this.props
		const { error, info } = this.state

		if (!error) {
			return children
		}

		let stackLines = 'Stack not available'
		if (info && info.componentStack) {
			stackLines = info.componentStack.split(/\r?\n/).map((stackLine, key) => (
				<span key={key}>
					{stackLine}
					<br />
				</span>
			))
		}

		return (
			<div
				style={{
					fontFamily:
						'-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
					padding: 16
				}}
			>
				<h1
					style={{
						margin: 0
					}}
				>
					Fatal error{' '}
					<span role="img" aria-label="error">
						😕
					</span>
				</h1>
				<div
					style={{
						fontFamily:
							'"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',
						fontSize: '0.7em',
						height: '300px',
						overflow: 'scroll',
						margin: '16px 0'
					}}
				>
					{error.message}
					<br />
					{stackLines}
				</div>
				<button
					onClick={this.handleRestart}
					style={{
						color: '#fff',
						backgroundColor: '#2196f3',
						boxShadow:
							'0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
						padding: '8px 16px',
						minWidth: '88px',
						fontSize: '0.875rem',
						boxSizing: 'border-box',
						minHeight: '36px',
						transition:
							'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
						lineHeight: '1.4em',
						fontWeight: '500',
						borderRadius: '2px',
						textTransform: 'uppercase',
						cursor: 'pointer',
						margin: '0',
						border: '0',
						display: 'inline-flex',
						outline: 'none',
						position: 'relative',
						userSelect: 'none',
						alignItems: 'center',
						verticalAlign: 'middle',
						justifyContent: 'center',
						textDecoration: 'none',
						textAlign: 'center'
					}}
				>
					Opnieuw Starten
				</button>
			</div>
		)
	}
}

export default ErrorBoundary
