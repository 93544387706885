import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import orderActionCreators from '../../../../redux/order/actionCreators'
import CallOffDetail from './CallOffDetail'

class CallOffDetailContainer extends PureComponent {
	static propTypes = {
		selectedOrderId: PropTypes.string.isRequired,
		selectedCallOffId: PropTypes.string.isRequired,
		callOff: PropTypes.object,
		isLoadingCallOff: PropTypes.bool.isRequired,
		isRefreshingCallOff: PropTypes.bool.isRequired,
		deleteCallOff: PropTypes.func.isRequired,
		deleteCallOffError: PropTypes.bool.isRequired,
		errorMessages: PropTypes.object,
		fetchCallOff: PropTypes.func.isRequired,
		refreshCallOff: PropTypes.func.isRequired,
		match: PropTypes.object
	}

	state = {
		isLoading: true
	}

	// Set initial loading true to prevent loading with an older orderState.
	static getDerivedStateFromProps(props, state) {
		if (state.isLoading && props.isLoadingCallOff) {
			return { isLoading: false }
		}
		return null
	}

	componentDidMount() {
		const { selectedOrderId, selectedCallOffId, fetchCallOff } = this.props
		fetchCallOff(selectedOrderId, selectedCallOffId)
	}

	handleRefresh = () => {
		const { callOff, refreshCallOff } = this.props
		refreshCallOff(callOff.salesOrderId, callOff.callOffOrderId)
	}

	render() {
		const {
			selectedOrderId,
			callOff,
			isLoadingCallOff,
			deleteCallOff,
			deleteCallOffError,
			errorMessages,
			isRefreshingCallOff
		} = this.props
		const { isLoading } = this.state

		return (
			<CallOffDetail
				callOff={callOff}
				isLoading={isLoading}
				isLoadingCallOff={isLoadingCallOff}
				selectedOrderId={selectedOrderId}
				refresh={this.handleRefresh}
				deleteCallOff={deleteCallOff}
				deleteCallOffError={deleteCallOffError}
				errorMessages={errorMessages}
				isRefreshingCallOff={isRefreshingCallOff}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		callOff: state.orderState.callOff,
		isLoadingCallOff: state.orderState.meta.isLoadingCallOff,
		isRefreshingCallOff: state.orderState.meta.isRefreshingCallOff,
		deleteCallOffError: state.orderState.meta.deleteCallOffError,
		errorMessages: state.orderState.meta.errorMessages
	}),
	{
		deleteCallOff: orderActionCreators.deleteCallOff,
		fetchCallOff: orderActionCreators.fetchCallOff,
		refreshCallOff: orderActionCreators.refreshCallOff
	}
)

export default connector(CallOffDetailContainer)
