import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import newCallOffActionCreators from '../../../../redux/newCallOff/actionCreators'
import newCallOffSelectors from '../../../../redux/newCallOff/selectors'
import ActionBar from './ActionBar'

class ActionBarContainer extends PureComponent {
	static propTypes = {
		deliveryType: PropTypes.string.isRequired,
		orderType: PropTypes.string.isRequired,
		closingFreightUsed: PropTypes.bool,
		closingFreight: PropTypes.bool.isRequired,
		setClosingFreight: PropTypes.func.isRequired,
		callOffStep: PropTypes.number.isRequired,
		totalWeight: PropTypes.number.isRequired,
		allDeliveryInfoFilled: PropTypes.bool.isRequired,
		navigateToMaterialsStep: PropTypes.func.isRequired,
		validateBeforeDeliveryStep: PropTypes.func.isRequired,
		validateBeforeSummaryStep: PropTypes.func.isRequired,
		finish: PropTypes.func.isRequired,
		canCallEntireOrder: PropTypes.bool.isRequired,
		callEntireOrder: PropTypes.func.isRequired,
		selectedEntireOrder: PropTypes.bool.isRequired,
		removeMaterials: PropTypes.func.isRequired,
		callOffOrdersCount: PropTypes.number
	}

	changeClosingFreight = (closingFreight) => {
		const { setClosingFreight } = this.props
		setClosingFreight(closingFreight)
	}

	handleNavigateToMaterialsStep = () => {
		const { navigateToMaterialsStep } = this.props
		navigateToMaterialsStep()
	}

	handleNavigateToDeliveryStep = () => {
		const { validateBeforeDeliveryStep } = this.props
		validateBeforeDeliveryStep()
	}

	handleNavigateToSummaryStep = () => {
		const { validateBeforeSummaryStep } = this.props
		validateBeforeSummaryStep()
	}

	handleFinish = () => {
		const { finish } = this.props
		finish()
	}

	render() {
		const {
			deliveryType,
			orderType,
			closingFreightUsed,
			closingFreight,
			callOffStep,
			totalWeight,
			allDeliveryInfoFilled,
			canCallEntireOrder,
			callEntireOrder,
			selectedEntireOrder,
			removeMaterials,
			callOffOrdersCount
		} = this.props

		return (
			<ActionBar
				deliveryType={deliveryType}
				orderType={orderType}
				closingFreightUsed={closingFreightUsed}
				closingFreight={closingFreight}
				callOffStep={callOffStep}
				totalWeight={totalWeight}
				navigateToMaterialsStep={this.handleNavigateToMaterialsStep}
				navigateToDeliveryStep={this.handleNavigateToDeliveryStep}
				navigateToSummaryStep={this.handleNavigateToSummaryStep}
				finish={this.handleFinish}
				changeClosingFreight={this.changeClosingFreight}
				allDeliveryInfoFilled={allDeliveryInfoFilled}
				canCallEntireOrder={canCallEntireOrder}
				callEntireOrder={callEntireOrder}
				selectedEntireOrder={selectedEntireOrder}
				removeMaterials={removeMaterials}
				callOffOrdersCount={callOffOrdersCount}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		deliveryType: state.newCallOffState.deliveryType,
		orderType: state.orderState.order.orderType,
		closingFreightUsed: state.orderState.order.closingFreightUsed,
		closingFreight: state.newCallOffState.closingFreight,
		callOffStep: state.newCallOffState.callOffStep,
		totalWeight: newCallOffSelectors.totalWeightSelector(state),
		allDeliveryInfoFilled:
			newCallOffSelectors.allDeliveryInfoFilledSelector(state),
		meta: state.orderState.meta,
		canCallEntireOrder: newCallOffSelectors.canCallEntireOrderSelector(state),
		selectedEntireOrder: newCallOffSelectors.selectedEntireOrderSelector(state),
		callOffOrdersCount: state.orderState.order.callOffOrdersCount
	}),
	{
		setClosingFreight: newCallOffActionCreators.setClosingFreight,
		navigateToMaterialsStep: newCallOffActionCreators.navigateToMaterialsStep,
		validateBeforeDeliveryStep:
			newCallOffActionCreators.validateBeforeDeliveryStep,
		validateBeforeSummaryStep:
			newCallOffActionCreators.validateBeforeSummaryStep,
		finish: newCallOffActionCreators.finish,
		callEntireOrder: newCallOffActionCreators.callEntireOrder,
		removeMaterials: newCallOffActionCreators.removeMaterials
	}
)

export default connector(ActionBarContainer)
