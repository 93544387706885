import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import authActionCreators from '../../redux/auth/actionCreators'
import userSelectors from '../../redux/user/selectors'
import TopBar from './TopBar'
import SideMenu from './SideMenu'

class NavigationContainer extends PureComponent {
	static propTypes = {
		fullName: PropTypes.string.isRequired,
		logout: PropTypes.func.isRequired,
		title: PropTypes.string,
		user: PropTypes.object
	}

	state = {
		isSideMenuOpen: false
	}

	handleShowSideMenu = () => {
		this.setState({
			isSideMenuOpen: true
		})
	}

	handleHideSideMenu = () => {
		this.setState({
			isSideMenuOpen: false
		})
	}

	render() {
		const { logout, title, fullName } = this.props
		const { isSideMenuOpen } = this.state

		return (
			<Fragment>
				<TopBar
					fullName={fullName}
					isSideMenuOpen={isSideMenuOpen}
					logout={logout}
					showSideMenu={this.handleShowSideMenu}
					title={title}
				/>
				<SideMenu
					hideSideMenu={this.handleHideSideMenu}
					isSideMenuOpen={isSideMenuOpen}
					showSideMenu={this.handleShowSideMenu}
				/>
			</Fragment>
		)
	}
}

const connector = connect(
	(state) => ({
		fullName: userSelectors.fullNameSelector(state.userState),
		user: state.userState.user
	}),
	{
		logout: authActionCreators.logout
	}
)

export default connector(NavigationContainer)
