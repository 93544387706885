import { createSelector } from 'reselect'

const userSelector = (userState) => userState.user

const fullNameSelector = createSelector(userSelector, (user) =>
	user ? `${user.firstName} ${user.lastName}` : null
)

const favoriteOrdersSelector = createSelector(userSelector, (user) =>
	user && user.customProperties && user.customProperties.favoriteOrderNumbers
		? user.customProperties.favoriteOrderNumbers
		: []
)

// Make sure to only export selectors which are created with 'createSelector'
const selectors = {
	fullNameSelector,
	favoriteOrdersSelector
}
export default selectors
