import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'
import { IconButton, Snackbar, SvgIcon } from '@mui/material'
import { TimesFal } from '@oliverit/react-fontawesome'

class Notification extends PureComponent {
	static propTypes = {
		done: PropTypes.func.isRequired,
		notification: PropTypes.object
	}

	state = {
		open: true
	}

	handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}
		// Hide the notification
		this.setState({
			open: false
		})
		// Add a delay before removing the notification, so the hide animation can complete
		const { done, notification } = this.props
		if (notification) {
			setTimeout(() => {
				done(notification.id)
			}, 600)
		}
	}

	render() {
		const { notification } = this.props
		const { open } = this.state

		let message = notification ? notification.message : ''
		if (notification.isNetworkError) {
			message = `${notification.message} - ${t(
				'app:Api.Generic.checkNetworkAccess'
			)}`
		}

		return (
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				open={open}
				autoHideDuration={3000}
				onClose={this.handleClose}
				message={<span>{message}</span>}
				action={[
					<IconButton
						key="close"
						color="grey"
						onClick={this.handleClose}
						size="large"
					>
						<SvgIcon>
							<TimesFal />
						</SvgIcon>
					</IconButton>
				]}
			/>
		)
	}
}

export default Notification
