import React, { PureComponent } from 'react'

const sx = {
	logo: {
		width: '100%'
	}
}

class Logo extends PureComponent {
	render() {
		// Show OE logo
		let image = <img src="/images/logo-oe.png" alt="logo" style={sx.logo} />
		// Struyk Verwo Infra environment
		if (process.env.REACT_APP_CLIENT_ID === 'si') {
			image = <img src="/images/logo-si.png" alt="logo" style={sx.logo} />
		}

		// Stradus Infra environment
		if (process.env.REACT_APP_CLIENT_ID === 'svi') {
			image = <img src="/images/logo-svi.png" alt="logo" style={sx.logo} />
		}

		return image
	}
}

export default Logo
