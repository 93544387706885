import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import i18next, { t } from 'i18next'
import {
	Button,
	Grid,
	Typography,
	FormGroup,
	FormControl,
	FormControlLabel,
	Checkbox,
	OutlinedInput,
	Select,
	MenuItem,
	TextField
} from '@mui/material'

import moment from 'moment-timezone'

import { DELIVERY_TYPE_PICKUP } from '../../../../../utils/constants'
import { isDeliveryDateDisabled } from '../../../../../utils/newCallOff'
import DateAndTimeCorrectionsDialog from '../DateAndTimeCorrectionsDialog'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { styled } from '@mui/styles'
import { parseISO } from 'date-fns'

const mixinsGuttersStyle = ({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	}
})

const StyledGrid = styled(Grid)(mixinsGuttersStyle)

const sx = {
	helperText: {
		color: (theme) => theme.palette.error.main
	},
	mainContent: {
		paddingBottom: (theme) => theme.spacing(2)
	},
	typography: {
		paddingTop: (theme) => theme.spacing(2),
		fontWeight: (theme) => theme.typography.fontWeightMedium
	}
}

class DateAndTime extends PureComponent {
	static propTypes = {
		freight: PropTypes.object.isRequired,
		availableDeliveryWindows: PropTypes.array.isRequired,
		deliveryType: PropTypes.string.isRequired,
		order: PropTypes.object.isRequired,
		deliveryDateChange: PropTypes.func.isRequired,
		deliveryWindowChange: PropTypes.func.isRequired,
		goodsOnPalletsChange: PropTypes.func.isRequired,
		totalWeight: PropTypes.number.isRequired,
		showFillDateAndTimeButton: PropTypes.bool.isRequired,
		handleFillDateAndTimeEverywhere: PropTypes.func.isRequired,
		changedFreights: PropTypes.array,
		clearChangedFreights: PropTypes.func.isRequired
	}

	disableSpecificDate = (deliveryDate) => {
		const { order, deliveryType, freight } = this.props
		const { deliveryWindows, freightTransport } = order

		let deliveryDateRestrictionOnWeight
		if (freightTransport.deliveryDateWeightRestrictions) {
			const deliveryDateRestrictionOnWeightCheck =
				this.getDeliveryDateRestrictionOnWeight()
			if (deliveryDateRestrictionOnWeightCheck) {
				deliveryDateRestrictionOnWeight = deliveryDateRestrictionOnWeightCheck
			}
		}
		return isDeliveryDateDisabled({
			deliveryDate,
			deliveryWindows,
			deliveryType,
			freight,
			deliveryDateRestrictionOnWeight
		})
	}

	handleGoodsOnPalletsChange = (event) => {
		const { goodsOnPalletsChange } = this.props
		goodsOnPalletsChange(event.target.checked)
	}

	handleDateChange = (deliveryDate) => {
		const { deliveryDateChange } = this.props
		// Convert to date only to prevent timezone issues of the datepicker
		const newDeliveryDate = moment(deliveryDate).format('YYYY-MM-DD')
		deliveryDateChange(
			moment.tz(newDeliveryDate, 'Europe/Amsterdam').startOf('day').format()
		)
	}

	handledeliveryWindowChange = (event) => {
		const { deliveryWindowChange } = this.props
		deliveryWindowChange(event.target.value)
	}

	getDeliveryDateRestrictionOnWeight = () => {
		const { order, totalWeight } = this.props
		const { deliveryDateWeightRestrictions } = order.freightTransport
		return deliveryDateWeightRestrictions.find(
			(_weightLimit) =>
				totalWeight >= _weightLimit.from && totalWeight <= _weightLimit.till
		)
	}

	render() {
		const {
			freight,
			availableDeliveryWindows,
			deliveryType,
			order,
			showFillDateAndTimeButton,
			handleFillDateAndTimeEverywhere,
			changedFreights,
			clearChangedFreights
		} = this.props
		const language = i18next.language || 'nl'
		const isPickup = deliveryType === DELIVERY_TYPE_PICKUP

		let goodsOnPalletsCheckbox
		if (order.freightTransport.askGoodsOnPallets) {
			goodsOnPalletsCheckbox = (
				<Grid item>
					<FormGroup row>
						<FormControlLabel
							control={
								<Checkbox
									checked={Boolean(freight.goodsOnPallets)}
									onChange={this.handleGoodsOnPalletsChange}
								/>
							}
							label={t('app:Delivery.goodsOnPallets')}
						/>
					</FormGroup>
				</Grid>
			)
		}

		const deliveryWindowItems = availableDeliveryWindows.map(
			(deliveryWindow) => (
				<MenuItem key={deliveryWindow.key} value={deliveryWindow.key}>
					<Grid
						container
						spacing={2}
						justifyContent="space-between"
						alignItems="center"
					>
						<Grid item>
							<Typography variant="body2">
								{moment(deliveryWindow.timeFrom, 'HH:mm:ss').format('HH:mm')} -{' '}
								{moment(deliveryWindow.timeTill, 'HH:mm:ss').format('HH:mm')}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="caption">
								{deliveryWindow.descriptions[language]}
							</Typography>
						</Grid>
					</Grid>
				</MenuItem>
			)
		)

		let fillDateAndTimeButton
		if (showFillDateAndTimeButton) {
			fillDateAndTimeButton = (
				<Grid item>
					<Button variant="outlined" onClick={handleFillDateAndTimeEverywhere}>
						{t('app:Delivery.fillDateAndTimeOnAllCallOffs')}
					</Button>
				</Grid>
			)
		}

		let correctionsDialog
		if (changedFreights) {
			correctionsDialog = (
				<DateAndTimeCorrectionsDialog
					confirm={clearChangedFreights}
					confirmButtonText={t('app:Generic.OK')}
					changedFreights={changedFreights}
					isOpen={true}
					titleText={t('app:Validation.correctionsTitle')}
				/>
			)
		}

		// Convert to date only to prevent timezone issues of the datepicker
		const deliveryDate = moment
			.tz(freight.deliveryDate, 'Europe/Amsterdam')
			.format('YYYY-MM-DD')

		let weightNotification

		if (order.freightTransport.deliveryDateWeightRestrictions) {
			const deliveryDateWeightLimit = this.getDeliveryDateRestrictionOnWeight()
			if (deliveryDateWeightLimit) {
				weightNotification = t('app:Delivery.weightNotification')
			}
		}

		return (
			<StyledGrid container spacing={2} sx={sx.mainContent}>
				<Grid item xs={12} sm={6}>
					<Grid item xs={12}>
						<Typography variant="subtitle1" sx={sx.typography}>
							{isPickup
								? t('app:Pickup.desiredDate')
								: t('app:Delivery.desiredDate')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<MobileDatePicker
							disablePast
							shouldDisableDate={this.disableSpecificDate}
							minDate={moment().tz('Europe/Amsterdam')}
							inputFormat="EEEE dd MMMM Y"
							onChange={this.handleDateChange}
							value={parseISO(deliveryDate)}
							renderInput={(params) => (
								<TextField
									variant="outlined"
									required
									fullWidth
									helperText={!isPickup && weightNotification}
									FormHelperTextProps={{ sx: sx.helperText }}
									{...params}
								/>
							)}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Grid item xs={12}>
						<Typography variant="subtitle1" sx={sx.typography}>
							{isPickup
								? t('app:Pickup.desiredTimePeriod')
								: t('app:Delivery.desiredTimePeriod')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<FormControl required fullWidth variant="outlined">
							<Select
								value={freight.deliveryWindow}
								onChange={this.handledeliveryWindowChange}
								input={<OutlinedInput />}
							>
								{deliveryWindowItems}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
				{fillDateAndTimeButton}
				{goodsOnPalletsCheckbox}
				{correctionsDialog}
			</StyledGrid>
		)
	}
}

export default DateAndTime
