const actionTypes = {
	FILTER_CALLOFFS: 'afroep/order/FILTER_CALLOFFS',

	REFRESH_ORDER: 'afroep/order/REFRESH_ORDER',

	FETCH_ORDER: 'afroep/order/FETCH_ORDER',
	FETCH_ORDER_REQUEST: 'afroep/order/FETCH_ORDER_REQUEST',
	FETCH_ORDER_FAILURE: 'afroep/order/FETCH_ORDER_FAILURE',
	FETCH_ORDER_SUCCESS: 'afroep/order/FETCH_ORDER_SUCCESS',

	FETCH_DELIVERIES: 'afroep/order/FETCH_DELIVERIES',
	FETCH_DELIVERIES_REQUEST: 'afroep/order/FETCH_DELIVERIES_REQUEST',
	FETCH_DELIVERIES_FAILURE: 'afroep/order/FETCH_DELIVERIES_FAILURE',
	FETCH_DELIVERIES_SUCCESS: 'afroep/order/FETCH_DELIVERIES_SUCCESS',

	FETCH_CALLOFFS: 'afroep/order/FETCH_CALLOFFS',
	FETCH_CALLOFFS_REQUEST: 'afroep/order/FETCH_CALLOFFS_REQUEST',
	FETCH_CALLOFFS_FAILURE: 'afroep/order/FETCH_CALLOFFS_FAILURE',
	FETCH_CALLOFFS_SUCCESS: 'afroep/order/FETCH_CALLOFFS_SUCCESS',

	REFRESH_CALLOFF: 'afroep/order/REFRESH_CALLOFF',

	FETCH_CALLOFF: 'afroep/order/FETCH_CALLOFF',
	FETCH_CALLOFF_REQUEST: 'afroep/order/FETCH_CALLOFF_REQUEST',
	FETCH_CALLOFF_FAILURE: 'afroep/order/FETCH_CALLOFF_FAILURE',
	FETCH_CALLOFF_SUCCESS: 'afroep/order/FETCH_CALLOFF_SUCCESS',

	DELETE_CALLOFF: 'afroep/order/DELETE_CALLOFF',
	DELETE_CALLOFF_REQUEST: 'afroep/order/DELETE_CALLOFF_REQUEST',
	DELETE_CALLOFF_FAILURE: 'afroep/order/DELETE_CALLOFF_FAILURE',
	DELETE_CALLOFF_SUCCESS: 'afroep/order/DELETE_CALLOFF_SUCCESS',

	UPDATE_DELIVERY_WINDOWS: 'afroep/order/UPDATE_DELIVERY_WINDOWS',

	SOCKET_OPEN: 'afroep/order/SOCKET_OPEN',
	SOCKET_CLOSE: 'afroep/order/SOCKET_CLOSE'
}
export default actionTypes
