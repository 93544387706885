import {
	Box,
	Drawer,
	Hidden,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	SvgIcon,
	Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import React, { Fragment, PureComponent } from 'react'

import {
	AngleLeftFal,
	BoxOpenFal,
	HandshakeAltFal,
	InfoFal,
	PhoneFal,
	UserLockFal
} from '@oliverit/react-fontawesome'
import i18next, { t } from 'i18next'
import { Link } from 'react-router-dom'
import Logo from '../App/Logo'

const drawerWidth = 280

const sx = {
	menuList: {
		width: drawerWidth,
		paddingTop: 0,
		flex: 1
	},
	menuIcon: {
		marginLeft: (theme) => theme.spacing(-1)
	},
	drawerPaper: {
		position: 'relative',
		display: 'flex',
		overflow: 'hidden',
		flexDirection: 'column',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: (theme) =>
			theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			})
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: (theme) =>
			theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
		width: (theme) => theme.spacing(8)
	},
	logoStandard: {
		transition: (theme) =>
			theme.transitions.create('transform', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			}),
		transform: 'rotate(0deg)',
		transformOrigin: (theme) => `${theme.spacing(4)} ${theme.spacing(4)}`,
		padding: (theme) => theme.spacing(1),
		width: (theme) => drawerWidth - theme.spacingNumber(1)
	},
	logoRotated: {
		transition: (theme) =>
			theme.transitions.create('transform', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
		transform: 'rotate(-90deg)',
		transformOrigin: (theme) => `${theme.spacing(4)} ${theme.spacing(4)}`,
		padding: (theme) => theme.spacing(1),
		width: (theme) => drawerWidth - theme.spacingNumber(1)
	}
}

const ListItemLink = (props) => <ListItem button component="a" {...props} />

const MenuHeader = styled(ListItem)(({ theme }) => ({
	[theme.breakpoints.down('sm')]: {
		height: theme.getToolbarMinHeight('xs')
	},
	[theme.breakpoints.between('sm', 'md')]: {
		height: theme.getToolbarMinHeight('sm')
	},
	[theme.breakpoints.up('md')]: {
		height: theme.getToolbarMinHeight()
	}
}))

class SideMenu extends PureComponent {
	static propTypes = {
		isSideMenuOpen: PropTypes.bool.isRequired,
		hideSideMenu: PropTypes.func.isRequired,
		showSideMenu: PropTypes.func.isRequired
	}

	render() {
		const { isSideMenuOpen, hideSideMenu, showSideMenu } = this.props
		const clientID = process.env.REACT_APP_CLIENT_ID || 'svi'

		let phoneNumber
		if (clientID === 'svi') {
			phoneNumber = <Typography variant="secondary">0800 555 55 54</Typography>
		} else if (clientID === 'si') {
			phoneNumber = (
				<Box>
					<Typography variant="body2" color="text.secondary">
						{t('app:Navigation.transport')} +32 13 550 531
					</Typography>
					<Typography variant="body2" color="text.secondary">
						{t('app:Navigation.sales')} +32 13 550 523
					</Typography>
				</Box>
			)
		} else {
			phoneNumber = <Typography variant="secondary">localHost</Typography>
		}

		let pdfLink
		if (clientID === 'svi') {
			pdfLink =
				'/manuals/Instructie_Manual_Struyk_Verwo_Infra_Afroep_Applicatie_2020.pdf'
		} else if (clientID === 'si' && i18next.language === 'fr') {
			pdfLink =
				'/manuals/Manuel_d_instructions_application_pour_les_appels_de_livraison_2020.pdf'
		} else if (clientID === 'si') {
			pdfLink = '/manuals/Instructie_Manual_Stradus_Afroep_Applicatie_2020.pdf'
		}

		const handleHideSideMenu = () => {
			if (isSideMenuOpen) {
				hideSideMenu()
			}
		}

		const drawerContent = (
			<Fragment>
				<List component="nav" onClick={handleHideSideMenu} sx={sx.menuList}>
					<MenuHeader divider>
						<Typography variant="h6">{t('app:Afroep.Main.title')}</Typography>
						<ListItemSecondaryAction>
							<IconButton sx={sx.menuIcon} size="large">
								<SvgIcon>
									<AngleLeftFal />
								</SvgIcon>
							</IconButton>
						</ListItemSecondaryAction>
					</MenuHeader>
					<ListItem button component={Link} to="/orders">
						<ListItemAvatar>
							<IconButton sx={sx.menuIcon} size="large">
								<SvgIcon>
									<BoxOpenFal />
								</SvgIcon>
							</IconButton>
						</ListItemAvatar>
						<ListItemText primary={t('app:Navigation.projectOrders')} />
					</ListItem>
					<ListItemLink button href={pdfLink} target="_blank">
						<ListItemAvatar>
							<IconButton sx={sx.menuIcon} size="large">
								<SvgIcon>
									<InfoFal />
								</SvgIcon>
							</IconButton>
						</ListItemAvatar>
						<ListItemText primary={t('app:Navigation.help')} />
					</ListItemLink>
					<ListItem button onClick={showSideMenu}>
						<ListItemAvatar>
							<IconButton sx={sx.menuIcon} size="large">
								<SvgIcon>
									<PhoneFal />
								</SvgIcon>
							</IconButton>
						</ListItemAvatar>
						<ListItemText
							disableTypography
							primary={
								<Typography>{t('app:Navigation.directContact')}</Typography>
							}
							secondary={phoneNumber}
						/>
					</ListItem>
					<ListItem button component={Link} to="/disclaimer">
						<ListItemAvatar>
							<IconButton sx={sx.menuIcon} size="large">
								<SvgIcon>
									<HandshakeAltFal />
								</SvgIcon>
							</IconButton>
						</ListItemAvatar>
						<ListItemText primary={t('app:Navigation.disclaimer')} />
					</ListItem>
					<ListItem button component={Link} to="/privacystatement">
						<ListItemAvatar>
							<IconButton sx={sx.menuIcon} size="large">
								<SvgIcon>
									<UserLockFal />
								</SvgIcon>
							</IconButton>
						</ListItemAvatar>
						<ListItemText primary={t('app:Navigation.privacyStatement')} />
					</ListItem>
				</List>
				<Box sx={isSideMenuOpen ? sx.logoStandard : sx.logoRotated}>
					<Logo />
				</Box>
			</Fragment>
		)

		const paperStyle = !isSideMenuOpen
			? { ...sx.drawerPaper, ...sx.drawerPaperClose }
			: sx.drawerPaper

		return (
			<Fragment>
				<Hidden mdDown>
					<Drawer
						variant="permanent"
						sx={{ '.MuiDrawer-paper': paperStyle }}
						open={isSideMenuOpen}
						onClose={hideSideMenu}
						ModalProps={{
							keepMounted: true
						}}
					>
						{drawerContent}
					</Drawer>
				</Hidden>
				<Hidden mdUp>
					<Drawer
						variant="temporary"
						sx={{ '.MuiDrawer-paper': paperStyle }}
						open={isSideMenuOpen}
						onClose={hideSideMenu}
						ModalProps={{
							keepMounted: true
						}}
					>
						{drawerContent}
					</Drawer>
				</Hidden>
			</Fragment>
		)
	}
}

export default SideMenu
