import React, { PureComponent } from 'react'
import { Typography, Box } from '@mui/material'
import { styled } from '@mui/styles'

const StyledBox = styled(Box)(({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	},
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2)
}))

class DisclaimerSI extends PureComponent {
	render() {
		return (
			<StyledBox>
				<Typography variant="h5" gutterBottom>
					Disclaimer Stradus
				</Typography>
				<Typography variant="body2" gutterBottom>
					Toutes les informations publiées sur ce site Internet sont collectées
					avec le plus grand soin. Nous ne pouvons néanmoins pas être tenus
					responsables en cas d&apos;erreurs dans l&apos;application.
				</Typography>
				<Typography variant="body2" gutterBottom>
					Stradus, sa direction, ses collaborateurs et/ou les tiers impliqués
					dans la conception de ce site ne sont pas responsables du contenu des
					pages ni de tout(e) dommage et/ou perte causé(e)(s) par
					l&apos;utilisation des informations et/ou logiciels de ce site.
				</Typography>
				<Typography variant="h6" gutterBottom>
					Droits de propriété intellectuelle
				</Typography>
				<Typography variant="body2" gutterBottom>
					Stradus dispose des droits de propriété intellectuelle relatifs au
					contenu de cette application. Sauf autorisation écrite expresse et
					préalable de Stradus, l&apos;utilisateur de ce site ne peut pas
					modifier, copier, distribuer, ni publier des textes, pictogrammes ou
					logiciels, en tout ou en partie.
				</Typography>
				<Typography variant="body2" gutterBottom>
					Les informations et/ou logiciels de cette application sont
					susceptibles d&apos;être modifiés et peuvent être changés ou supprimés
					sans préavis. Nous nous réservons expressément le droit de modifier
					les produits et contenus sans préavis. Tous les noms de produits et
					marques mentionnés appartiennent aux propriétaires enregistrés.
				</Typography>
			</StyledBox>
		)
	}
}

export default DisclaimerSI
