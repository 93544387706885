import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'

import {
	Grid,
	IconButton,
	TextField,
	InputAdornment,
	ListItem,
	SvgIcon,
	Tooltip,
	keyframes
} from '@mui/material'
import { SyncFal, TimesFal, FolderOpenFal } from '@oliverit/react-fontawesome'

import { t } from 'i18next'

import OrderLookupContainer from './OrderLookup/OrderLookupContainer'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const sx = {
	filter: {
		backgroundColor: (theme) => theme.palette.background.paper,
		borderRight: (theme) => `1px solid ${theme.palette.divider}`
	},
	spin: {
		animation: `${spin} 1.4s linear infinite`
	},
	formControlMargin: {
		marginTop: (theme) => theme.spacing()
	}
}

class OrderFilter extends PureComponent {
	static propTypes = {
		filterOrders: PropTypes.func.isRequired,
		isRefreshing: PropTypes.bool.isRequired,
		refreshOrderList: PropTypes.func.isRequired,
		showLookupOrder: PropTypes.func.isRequired,
		searchInput: PropTypes.string
	}

	handleOrdersFilter = (event) => {
		const { filterOrders } = this.props
		const searchInput = event.currentTarget.value
		filterOrders({ searchInput })
	}

	handleEraseFilter = () => {
		const { filterOrders } = this.props
		filterOrders({ searchInput: '' })
	}

	render() {
		const { isRefreshing, refreshOrderList, showLookupOrder, searchInput } =
			this.props

		let refreshButton
		if (isRefreshing) {
			refreshButton = (
				<IconButton disabled={true} sx={sx.formControlMargin} size="large">
					<SvgIcon sx={sx.spin}>
						<SyncFal />
					</SvgIcon>
				</IconButton>
			)
		} else {
			refreshButton = (
				<Tooltip
					title={t('app:Orders.Master.refreshTooltip')}
					disableFocusListener={true}
				>
					<IconButton
						onClick={refreshOrderList}
						sx={sx.formControlMargin}
						size="large"
					>
						<SvgIcon>
							<SyncFal />
						</SvgIcon>
					</IconButton>
				</Tooltip>
			)
		}

		return (
			<Fragment>
				<OrderLookupContainer />
				<ListItem divider dense sx={sx.filter}>
					<Grid container spacing={1}>
						<Grid item style={{ flex: 1 }}>
							<TextField
								fullWidth
								label={t('app:Orders.Master.search')}
								type="text"
								variant="standard"
								onChange={this.handleOrdersFilter}
								value={searchInput}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton onClick={this.handleEraseFilter} size="large">
												<SvgIcon>
													<TimesFal />
												</SvgIcon>
											</IconButton>
										</InputAdornment>
									)
								}}
							/>
						</Grid>
						<Grid item>
							<Tooltip
								title={t('app:Orders.Master.lookupOrderTooltip')}
								disableFocusListener={true}
							>
								<IconButton
									onClick={showLookupOrder}
									sx={sx.formControlMargin}
									size="large"
								>
									<SvgIcon>
										<FolderOpenFal />
									</SvgIcon>
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item>{refreshButton}</Grid>
					</Grid>
				</ListItem>
			</Fragment>
		)
	}
}

export default OrderFilter
