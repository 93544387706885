import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { t } from 'i18next'
import {
	Collapse,
	Grid,
	Paper,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Typography,
	SvgIcon
} from '@mui/material'

import { ChevronDownFal } from '@oliverit/react-fontawesome'

import Delivery from './Delivery'

const sx = {
	list: {
		width: '100%'
	},
	open: {
		backgroundColor: (theme) => theme.palette.action.selected
	},
	expand: {
		transform: 'rotate(0deg)',
		transition: (theme) =>
			theme.transitions.create('transform', {
				duration: theme.transitions.duration.shortest
			}),
		marginLeft: 'auto',
		[(theme) => theme.breakpoints.up('sm')]: {
			marginRight: (theme) => theme.spacing(-1)
		}
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	}
}

class DeliveryGroup extends PureComponent {
	static propTypes = {
		deliveryGroup: PropTypes.object.isRequired,
		index: PropTypes.number.isRequired
	}

	constructor(props) {
		super()

		const { deliveryGroup } = props
		let isDeliveryGroupOpen = false
		if (
			deliveryGroup &&
			deliveryGroup.expectedDeliveryDate &&
			deliveryGroup.expectedDeliveryDate.from &&
			moment()
				.tz('Europe/Amsterdam')
				.isSame(
					moment(deliveryGroup.expectedDeliveryDate.from).tz(
						'Europe/Amsterdam'
					),
					'day'
				)
		) {
			isDeliveryGroupOpen = true
		}

		this.state = { isDeliveryGroupOpen }
	}

	handleDeliveryGroupCollapse = () => {
		const { isDeliveryGroupOpen } = this.state

		this.setState({
			isDeliveryGroupOpen: !isDeliveryGroupOpen
		})
	}

	render() {
		const { deliveryGroup } = this.props
		const { forwardingAgentName, expectedDeliveryDate, deliveries } =
			deliveryGroup
		const { isDeliveryGroupOpen } = this.state

		const deliveryDateMoment = moment(expectedDeliveryDate.from).tz(
			'Europe/Amsterdam'
		)
		let deliveryTitlePrimary
		let deliveryTitleSecondary
		if (deliveryDateMoment.isValid()) {
			const deliveryDate = deliveryDateMoment.format('dddd D MMMM YYYY')
			const deliveryTimeFrom = moment(expectedDeliveryDate.from)
				.tz('Europe/Amsterdam')
				.format('HH:mm')
			const deliveryTimeTill = moment(expectedDeliveryDate.till)
				.tz('Europe/Amsterdam')
				.format('HH:mm')
			deliveryTitlePrimary = t('app:Orders.Deliveries.deliveryTitlePrimary', {
				deliveryDate
			})

			// Added interpolation escapeValue to show & instead of &amp;
			if (deliveryTimeFrom !== '00:00' && forwardingAgentName) {
				deliveryTitleSecondary = t(
					'app:Orders.Deliveries.deliveryTitleSecondaryWithTimeAndForwardingAgent',
					{
						deliveryTimeFrom,
						deliveryTimeTill,
						forwardingAgentName,
						interpolation: { escapeValue: false }
					}
				)
			} else if (forwardingAgentName) {
				deliveryTitleSecondary = t(
					'app:Orders.Deliveries.deliveryTitleSecondaryWithForwardingAgent',
					{ forwardingAgentName, interpolation: { escapeValue: false } }
				)
			} else if (deliveryTimeFrom !== '00:00') {
				deliveryTitleSecondary = t(
					'app:Orders.Deliveries.deliveryTitleSecondaryWithTime',
					{ deliveryTimeFrom, deliveryTimeTill }
				)
			}
		} else {
			deliveryTitlePrimary = t('app:Orders.Deliveries.deliveryUnknown')
		}

		const paperListItemStyle = isDeliveryGroupOpen
			? { ...sx.header, ...sx.open }
			: sx.header
		const iconButtonStyle = isDeliveryGroupOpen
			? { ...sx.expand, ...sx.expandOpen }
			: sx.expand
		return (
			<Grid item xs={12}>
				<Paper>
					<ListItem
						sx={paperListItemStyle}
						button
						divider
						onClick={this.handleDeliveryGroupCollapse}
					>
						<ListItemText
							primary={
								<Typography variant="h6">{deliveryTitlePrimary}</Typography>
							}
							secondary={deliveryTitleSecondary}
						/>
						<IconButton sx={iconButtonStyle} size="large">
							<SvgIcon>
								<ChevronDownFal />
							</SvgIcon>
						</IconButton>
					</ListItem>
					<Collapse in={isDeliveryGroupOpen} timeout="auto" unmountOnExit>
						<List sx={sx.list}>
							{deliveries.map((delivery, i) => (
								<Delivery key={i} delivery={delivery} />
							))}
						</List>
					</Collapse>
				</Paper>
			</Grid>
		)
	}
}

export default DeliveryGroup
