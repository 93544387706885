import { t } from 'i18next'

export const localizedReturnPalletCodeOrUomDescription = (codeOrUom) => {
	switch (codeOrUom) {
		case 'EUR':
			return t('app:Orders.Details.ReturnPallets.EUR')
		case 'MAR':
			return t('app:Orders.Details.ReturnPallets.MAR')
		case 'OVE':
			return t('app:Orders.Details.ReturnPallets.OVE')
		case 'PCE':
			return t('app:Orders.Details.ReturnPallets.PCE')
		case 'STE':
			return t('app:Orders.Details.ReturnPallets.STE')
		case 'TEG':
			return t('app:Orders.Details.ReturnPallets.TEG')
		default:
			return t('app:Orders.Details.ReturnPallets.unknown')
	}
}
