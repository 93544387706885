// CallOff steps
export const CALL_OFF_STEP_MATERIALS = 0
export const CALL_OFF_STEP_DELIVERY = 1
export const CALL_OFF_STEP_SUMMARY = 2

// CallOff and delivery status
export const STATUS_IN_CONCEPT = 0
export const STATUS_IN_APPROVAL = 1
export const STATUS_APPROVED = 2
export const STATUS_IN_PLANNING = 3
export const STATUS_PLANNED = 4
export const STATUS_LOADED = 5
export const STATUS_CLOSED = 6

// Delivery types
export const DELIVERY_TYPE_DELIVER = 'FH'
export const DELIVERY_TYPE_PICKUP = 'EXW'

// Order types
export const ORDER_TYPE_SALES_ORDER = 'SALES_ORDER'
export const ORDER_TYPE_CALLOFF_ORDER = 'CALLOFF_ORDER'

// Sales organizations
export const SALES_ORGANIZATION_STRUYK_VERWO_INFRA = '1900'
export const SALES_ORGANIZATION_STRADUS_INFRA = '2100'
