import { createSelector } from 'reselect'
import i18next from 'i18next'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'

import {
	getAvailableDeliveryWindows,
	sortBySortIndex
} from '../../utils/newCallOff'
import {
	getUsedCountries,
	calculateTotalWeight,
	calculateTotalCosts,
	calculateTotalLocations
} from '../../utils/freights'
import {
	getConversionRate,
	getQuantityInAnotherUnit
} from '../../utils/materials'
import { DELIVERY_TYPE_PICKUP } from '../../utils/constants'

const deliveryTypeSelector = (state) => state.newCallOffState.deliveryType
const materialsSelector = (state) => state.newCallOffState.materials
const orderSelector = (state) => state.orderState.order
const querySelector = (state) => state.newCallOffState.query
const showPurchaseObligationArticlesSelector = (state) =>
	state.newCallOffState.showPurchaseObligationArticlesOnly
const classificationsFilterSelector = (state) =>
	state.newCallOffState.classificationsFilter
const freightIndexSelector = (state) => state.newCallOffState.freightIndex
const freightsSelector = (state) => state.newCallOffState.freights
const closingFreightSelector = (state) => state.newCallOffState.closingFreight

// Product groups / Sub product groups / Product lines need to be sorted by the sort index
const sortedClassificationsFilterSelector = createSelector(
	classificationsFilterSelector,
	(classificationsFilter) => ({
		...classificationsFilter,
		productGroups: classificationsFilter.productGroups.sort(sortBySortIndex),
		subProductGroups:
			classificationsFilter.subProductGroups.sort(sortBySortIndex),
		productLines: classificationsFilter.productLines.sort(sortBySortIndex)
	})
)

const filteredMaterialsByClassificationsSelector = createSelector(
	orderSelector,
	sortedClassificationsFilterSelector,
	(order, classificationsFilter) => {
		const { materialClassifications } = order
		const materials = order.materials || []

		const selectedProductGroupValues = classificationsFilter.productGroups
			.filter(({ checked }) => Boolean(checked))
			.map(({ value }) => value)
		const selectedSubProductGroupValues = classificationsFilter.subProductGroups
			.filter(({ checked }) => Boolean(checked))
			.map(({ value }) => value)
		const selectedProductLineValues = classificationsFilter.productLines
			.filter(({ checked }) => Boolean(checked))
			.map(({ value }) => value)
		const selectedClassifications = [
			...selectedProductGroupValues,
			...selectedSubProductGroupValues,
			...selectedProductLineValues
		]

		// If no filters are active, return materials
		if (!selectedClassifications.length) {
			return materials
		}

		return materials.filter((material) => {
			let showMaterial = true

			const {
				productGroup: materialProductGroup,
				subProductGroup: materialSubProductGroup,
				productLine: materialProductLine
			} = materialClassifications.find(
				({ materialNumber }) => materialNumber === material.materialNumber
			)

			// Filter by product group
			if (showMaterial && selectedProductGroupValues.length > 0) {
				showMaterial = selectedProductGroupValues.includes(
					materialProductGroup.value
				)
			}

			// Filter by sub-product group
			if (showMaterial && selectedSubProductGroupValues.length > 0) {
				showMaterial = selectedSubProductGroupValues.includes(
					materialSubProductGroup.value
				)
			}

			// Filter by product line
			if (showMaterial && selectedProductLineValues.length > 0) {
				showMaterial = selectedProductLineValues.includes(
					materialProductLine.value
				)
			}

			return showMaterial
		})
	}
)

const filteredMaterialsSelector = createSelector(
	filteredMaterialsByClassificationsSelector,
	querySelector,
	showPurchaseObligationArticlesSelector,
	(materials, query, showPurchaseObligationArticles) => {
		// If no query is filled and all articles should be shown, return materials
		if (!query && !showPurchaseObligationArticles) {
			return materials
		}

		if (!query && showPurchaseObligationArticles) {
			return materials.filter((material) => material.materialType === 'CUSTOM')
		}

		if (query && !showPurchaseObligationArticles) {
			const language = i18next.language

			return materials.filter((material) =>
				[
					material.descriptions[language].toLowerCase(),
					material.materialNumber,
					material.customerMaterialNumber
				].some((searchValue) => searchValue.includes(query.toLowerCase()))
			)
		}

		if (query && showPurchaseObligationArticles) {
			const language = i18next.language
			const purchaseObligationMaterials = materials.filter(
				(material) => material.materialType === 'CUSTOM'
			)

			return purchaseObligationMaterials.filter((purchaseObligationMaterial) =>
				[
					purchaseObligationMaterial.descriptions[language].toLowerCase(),
					purchaseObligationMaterial.materialNumber,
					purchaseObligationMaterial.customerMaterialNumber
				].some((searchValue) => searchValue.includes(query.toLowerCase()))
			)
		}

		// If no query is filled and all articles should be shown, return all materials
		return materials
	}
)

// Reverse-engineer the filtered materials to possible classifications
const filteredClassificationsSelector = createSelector(
	orderSelector,
	classificationsFilterSelector,
	filteredMaterialsByClassificationsSelector,
	(order, classificationsFilter, filteredMaterials) => {
		const { materialClassifications } = order

		// Get material classifications for selected materials
		const filteredMaterialNumbers = filteredMaterials.map(
			({ materialNumber }) => materialNumber
		)
		const filteredMaterialClassifications = materialClassifications.filter(
			({ materialNumber }) => filteredMaterialNumbers.includes(materialNumber)
		)

		// Get product groups/sub-product groups/product lines for selected materials
		const filteredProductGroupValues = filteredMaterialClassifications.reduce(
			(acc, { productGroup }) => acc.add(productGroup.value),
			new Set()
		)
		const filteredSubProductGroupValues =
			filteredMaterialClassifications.reduce(
				(acc, { subProductGroup }) => acc.add(subProductGroup.value),
				new Set()
			)
		const filteredProductLineValues = filteredMaterialClassifications.reduce(
			(acc, { productLine }) => acc.add(productLine.value),
			new Set()
		)

		return {
			...classificationsFilter,
			productGroups: classificationsFilter.productGroups.filter(({ value }) =>
				filteredProductGroupValues.has(value)
			),
			subProductGroups: classificationsFilter.subProductGroups.filter(
				({ value }) => filteredSubProductGroupValues.has(value)
			),
			productLines: classificationsFilter.productLines.filter(({ value }) =>
				filteredProductLineValues.has(value)
			)
		}
	}
)

const isClassificationsFilterActiveSelector = createSelector(
	classificationsFilterSelector,
	(classificationsFilter) => {
		const { productGroups, subProductGroups, productLines } =
			classificationsFilter
		return (
			productGroups.some(({ checked }) => Boolean(checked)) ||
			subProductGroups.some(({ checked }) => Boolean(checked)) ||
			productLines.some(({ checked }) => Boolean(checked))
		)
	}
)

const totalWeightSelector = createSelector(
	orderSelector,
	materialsSelector,
	(order, materials) => calculateTotalWeight({ order, materials })
)

const totalCostsSelector = createSelector(
	orderSelector,
	totalWeightSelector,
	closingFreightSelector,
	(order, totalWeight, closingFreight) =>
		calculateTotalCosts({ order, totalWeight, closingFreight })
)

const totalLocationsSelector = createSelector(materialsSelector, (materials) =>
	calculateTotalLocations({ materials })
)

const totalFreightsSelector = createSelector(
	freightsSelector,
	(freights) => freights.length
)

const returnPalletsStateSelector = (state) =>
	state.newCallOffState.returnPalletsState

const returnPalletsSelector = createSelector(
	returnPalletsStateSelector,
	(returnPalletsState) => returnPalletsState.returnPallets
)

const showReturnPalletsSelector = createSelector(
	returnPalletsStateSelector,
	(returnPalletsState) => returnPalletsState.showReturnPallets
)

const allDeliveryInfoFilledSelector = createSelector(
	freightsSelector,
	deliveryTypeSelector,
	returnPalletsSelector,
	showReturnPalletsSelector,
	(freights, deliveryType, returnPallets, showReturnPallets) =>
		freights.every((freightItem) => {
			const isPickup = deliveryType === DELIVERY_TYPE_PICKUP
			const phoneNumber = parsePhoneNumberFromString(
				freightItem.phone,
				freightItem.country.toUpperCase()
			)
			const validPhoneNumber = phoneNumber ? phoneNumber.isValid() : false
			let filled = true
			Object.keys(freightItem).forEach((key) => {
				const value = freightItem[key]
				// Instructions and houseNumber fields are not required
				if (key === 'instructions' || key === 'houseNumber') {
					return
				}

				if (isPickup) {
					// For pickup orders, only deliveryDate and deliveryWindow are available to fill in
					if (key === 'deliveryDate' && value === null) {
						filled = false
					}
					if (key === 'deliveryWindow' && value === '') {
						filled = false
					}
				} else if (
					(typeof value === 'string' && value === '') ||
					(key === 'phone' && !validPhoneNumber)
				) {
					filled = false
				}
			})

			// If returnpallets switch is on, one of the quantities should be filled.
			if (showReturnPallets) {
				if (!returnPallets.some((pallet) => pallet.quantity > 0)) {
					filled = false
				}
			}

			return filled
		})
)

const selectedFreightSelector = createSelector(
	freightIndexSelector,
	freightsSelector,
	(freightIndex, freights) => freights[freightIndex]
)

const showFillDateAndTimeButtonSelector = createSelector(
	selectedFreightSelector,
	freightsSelector,
	(selectedFreight, freights) => {
		if (
			freights.some(
				(freight) => freight.deliveryDate !== selectedFreight.deliveryDate
			)
		) {
			return true
		}
		if (
			freights.some(
				(freight) => freight.deliveryWindow !== selectedFreight.deliveryWindow
			)
		) {
			return true
		}
		return false
	}
)

const showFillAddressButtonSelector = createSelector(
	selectedFreightSelector,
	freightsSelector,
	(selectedFreight, freights) => {
		if (freights.some((freight) => freight.street !== selectedFreight.street)) {
			return true
		}
		if (
			freights.some(
				(freight) => freight.houseNumber !== selectedFreight.houseNumber
			)
		) {
			return true
		}
		if (
			freights.some((freight) => freight.zipCode !== selectedFreight.zipCode)
		) {
			return true
		}
		if (freights.some((freight) => freight.city !== selectedFreight.city)) {
			return true
		}
		if (
			freights.some((freight) => freight.country !== selectedFreight.country)
		) {
			return true
		}
		return false
	}
)

const showFillContactPersonButtonSelector = createSelector(
	selectedFreightSelector,
	freightsSelector,
	(selectedFreight, freights) => {
		if (freights.some((freight) => freight.name !== selectedFreight.name)) {
			return true
		}
		if (freights.some((freight) => freight.phone !== selectedFreight.phone)) {
			return true
		}
		return false
	}
)

const showFillInstructionsButtonSelector = createSelector(
	selectedFreightSelector,
	freightsSelector,
	(selectedFreight, freights) => {
		if (
			freights.some(
				(freight) =>
					freight.instructions.line1 !== selectedFreight.instructions.line1 ||
					freight.instructions.line2 !== selectedFreight.instructions.line2
			)
		) {
			return true
		}
		return false
	}
)

const availableDeliveryWindowsSelector = createSelector(
	orderSelector,
	deliveryTypeSelector,
	selectedFreightSelector,
	({ deliveryWindows }, deliveryType, freight) =>
		getAvailableDeliveryWindows({
			deliveryDate: freight.deliveryDate,
			deliveryWindows,
			deliveryType,
			freight
		})
)

const selectedFreightMaterialsSelector = createSelector(
	orderSelector,
	selectedFreightSelector,
	(order, freight) =>
		freight.materials.map((material) => ({
			...order.materials.find(
				({ materialNumber, shippingPoint }) =>
					materialNumber === material.materialNumber &&
					shippingPoint === material.shippingPoint
			),
			...material
		}))
)

const canCallEntireOrderSelector = createSelector(
	(state) => state.orderState.order.materials,
	(materials) =>
		materials?.every(
			(material) =>
				material.quantityStock > 0 &&
				material.quantityStock >= material.quantityAvailable
		)
)

const selectedEntireOrderSelector = createSelector(
	(state) => state.orderState.order.materials,
	materialsSelector,
	(orderMaterials, newCallOffMaterials) =>
		newCallOffMaterials.every((newCallOffMaterial) => {
			const matchedOrderMaterial = orderMaterials.find(
				(orderMaterial) =>
					orderMaterial.materialNumber === newCallOffMaterial.materialNumber &&
					orderMaterial.shippingPoint === newCallOffMaterial.shippingPoint
			)
			return (
				matchedOrderMaterial.quantityAvailable ===
					newCallOffMaterial.quantity &&
				matchedOrderMaterial.baseUnit === newCallOffMaterial.unit
			)
		})
)

// Creates a local selector for material complementing
const isComplementedSelector = createSelector(
	(state) => state.newCallOffState.materials,
	(materials) => materials.some(({ complemented }) => complemented)
)

// Creates a local selector for material details
const createMaterialSelector = () =>
	createSelector(
		(state) => state.newCallOffState.materials,
		(state, props) => props.material,
		(usedMaterials, material) =>
			usedMaterials.find(
				({ materialNumber, shippingPoint }) =>
					materialNumber === material.materialNumber &&
					shippingPoint === material.shippingPoint
			)
	)

// Creates a local selector for material complementing
const createMaterialCanComplementSelector = () =>
	createSelector(
		totalWeightSelector,
		totalFreightsSelector,
		isComplementedSelector,
		(state) => state.orderState.order,
		(state) => state.newCallOffState.materials,
		(state, props) => props.material,
		(
			totalWeight,
			totalFreights,
			isComplemented,
			order,
			usedMaterials,
			material
		) => {
			const usedMaterial = usedMaterials.find(
				({ materialNumber, shippingPoint }) =>
					materialNumber === material.materialNumber &&
					shippingPoint === material.shippingPoint
			)

			// If the material itself is complemented, it can be switched off
			if (usedMaterial.complemented) {
				return true
			}

			// Only one material can be complemented
			if (isComplemented) {
				return false
			}

			if (totalFreights > 1) {
				return false
			}

			// Materials can only be complemented when a single country is used
			const usedCountries = getUsedCountries({
				order,
				materials: usedMaterials
			})
			if (
				usedCountries.length !== 0 &&
				!usedCountries.includes(material.shippingPointAddress.country)
			) {
				return false
			}

			// Only materials with the PAK unit can be complemented
			const { baseUnit, unitConversions, weightPerBaseUnit } = material
			const includesPAK = material.unitConversions.some(
				({ unit }) => unit === 'PA'
			)
			if (!includesPAK) {
				return false
			}

			// The total weight is above the weight limit of a single truck
			const weightLimitPerTruck =
				order.freightTransport.weightLimitsPerTruck[
					material.shippingPointAddress.country
				] || order.freightTransport.weightLimitsPerTruck.default
			if (totalWeight > weightLimitPerTruck) {
				return false
			}

			// The material is on a tradinglocation and has no quantity available
			const isTradingLocation = material.shippingPoint.endsWith('90')
			if (isTradingLocation && !material.quantityAvailable) {
				return false
			}

			// The material is not on a tradinglocation and has no quantity in stock
			if (!material.quantityStock && !isTradingLocation) {
				return false
			}

			// There is less than 1 PAK of weight available in the truck
			const quantityInBaseUnit = getQuantityInAnotherUnit({
				unitConversions,
				quantity: usedMaterial.quantity,
				fromUnit: usedMaterial.unit,
				toUnit: material.baseUnit
			})
			const quantityWeight = quantityInBaseUnit * weightPerBaseUnit
			const availableWeight = weightLimitPerTruck - totalWeight + quantityWeight
			const conversionRatePAK = getConversionRate({
				unitConversions,
				unit: 'PA'
			})
			const availableQuantityInPAK = Math.floor(
				availableWeight /
					(baseUnit === 'PA'
						? weightPerBaseUnit
						: weightPerBaseUnit * conversionRatePAK)
			)
			if (availableQuantityInPAK < 1) {
				return false
			}

			return true
		}
	)

// Creates a local selector for material weight limit per truck
const createMaterialWeightLimitPerTruckSelector = () =>
	createSelector(
		(state) => state.orderState.order.freightTransport.weightLimitsPerTruck,
		(state, props) => props.material.shippingPointAddress.country,
		(weightLimitsPerTruck, shippingPointAddressCountry) =>
			weightLimitsPerTruck[shippingPointAddressCountry] ||
			weightLimitsPerTruck.default
	)

// Make sure to only export selectors which are created with 'createSelector'

const selectors = {
	filteredClassificationsSelector,
	filteredMaterialsSelector,
	isClassificationsFilterActiveSelector,
	totalWeightSelector,
	totalCostsSelector,
	totalLocationsSelector,
	totalFreightsSelector,
	availableDeliveryWindowsSelector,
	allDeliveryInfoFilledSelector,
	selectedFreightSelector,
	selectedFreightMaterialsSelector,
	showFillAddressButtonSelector,
	showFillContactPersonButtonSelector,
	showFillDateAndTimeButtonSelector,
	showFillInstructionsButtonSelector,
	isComplementedSelector,
	createMaterialSelector,
	createMaterialCanComplementSelector,
	createMaterialWeightLimitPerTruckSelector,
	canCallEntireOrderSelector,
	returnPalletsSelector,
	showReturnPalletsSelector,
	selectedEntireOrderSelector
}
export default selectors
