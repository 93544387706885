import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import MaterialSelection from './MaterialSelection'

class MaterialSelectionContainer extends PureComponent {
	static propTypes = {
		orderDetailRef: PropTypes.any
	}

	render() {
		const { orderDetailRef } = this.props

		return <MaterialSelection orderDetailRef={orderDetailRef} />
	}
}

export default MaterialSelectionContainer
