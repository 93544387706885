import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { AppBar, Button, Grid, Paper, Tabs, Tab, Box } from '@mui/material'

import { t } from 'i18next'

import Loading from '../../../Shared/Loading'
import NotFound from '../../../Shared/NotFound'
import CallOffDetailHeaderInfo from './CallOffDetailHeaderInfo'
import Deliveries from './Deliveries/Deliveries'
import MaterialList from './Deliveries/Materials/MaterialList'

import { styled } from '@mui/styles'

const rootContainerStyle = ({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	},
	backgroundColor: theme.palette.background.default,
	height: '100%',
	overflowY: 'scroll',
	overflowX: 'hidden'
})

const StyledBox = styled(Box)(rootContainerStyle)
const StyledPaper = styled(Paper)(rootContainerStyle)

const DELIVERIES_TAB = 0
const MATERIALS_TAB = 1

const sx = {
	tryAgainButton: {
		textAlign: 'center'
	},
	tabs: {
		backgroundColor: (theme) => theme.palette.background.paper
	}
}

class CallOffDetail extends PureComponent {
	static propTypes = {
		callOff: PropTypes.object,
		isLoading: PropTypes.bool.isRequired,
		isLoadingCallOff: PropTypes.bool.isRequired,
		deleteCallOff: PropTypes.func.isRequired,
		deleteCallOffError: PropTypes.bool.isRequired,
		errorMessages: PropTypes.object,
		isRefreshingCallOff: PropTypes.bool.isRequired,
		selectedOrderId: PropTypes.string.isRequired,
		refresh: PropTypes.func.isRequired
	}

	state = {
		selectedTab:
			this.props.callOff &&
			this.props.callOff.deliveries &&
			this.props.callOff.deliveries.length > 0
				? DELIVERIES_TAB
				: MATERIALS_TAB
	}

	handleDeliveriesTabClick = () => {
		this.setState({ selectedTab: DELIVERIES_TAB })
	}

	handleMaterialsTabClick = () => {
		this.setState({ selectedTab: MATERIALS_TAB })
	}

	render() {
		const {
			callOff,
			isLoading,
			isLoadingCallOff,
			selectedOrderId,
			refresh,
			deleteCallOff,
			deleteCallOffError,
			errorMessages,
			isRefreshingCallOff
		} = this.props
		const { selectedTab } = this.state

		if (isLoadingCallOff || isLoading) {
			return (
				<StyledBox>
					<Loading paper message={t('app:CallOffs.Details.loading')} />
				</StyledBox>
			)
		}

		if (!callOff) {
			return (
				<StyledBox>
					<NotFound
						paper
						description={t('app:Orders.notFoundDescription')}
						header={t('app:CallOffs.empty')}
					/>
				</StyledBox>
			)
		}

		if (!callOff.deliveries) {
			return (
				<StyledBox>
					<NotFound
						paper
						description={t('app:Orders.notFoundDescription')}
						header={t('app:Orders.notFoundHeader')}
					/>
				</StyledBox>
			)
		}

		if (deleteCallOffError) {
			return (
				<StyledPaper>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<NotFound header={errorMessages.userMessage} />
						</Grid>
						<Grid item xs={12} sx={sx.tryAgainButton}>
							<Button
								variant="contained"
								color="primary"
								onClick={deleteCallOff}
							>
								{t('app:Generic.tryAgain')}
							</Button>
						</Grid>
					</Grid>
				</StyledPaper>
			)
		}

		let content
		if (selectedTab === DELIVERIES_TAB) {
			content = <Deliveries deliveries={callOff.deliveries} />
		} else if (selectedTab === MATERIALS_TAB) {
			content = <MaterialList materials={callOff.materials} />
		}

		let details
		if (!callOff.isPending) {
			details = (
				<Grid item xs={12}>
					<Paper sx={sx.paper}>
						<AppBar position="static" color="default">
							<Tabs variant="fullWidth" value={selectedTab} sx={sx.tabs}>
								<Tab
									onClick={this.handleDeliveriesTabClick}
									label={t('app:CallOffs.Details.deliveries')}
								></Tab>
								<Tab
									onClick={this.handleMaterialsTabClick}
									label={t('app:CallOffs.Details.materials')}
								></Tab>
							</Tabs>
						</AppBar>
						{content}
					</Paper>
				</Grid>
			)
		}

		return (
			<StyledPaper>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<CallOffDetailHeaderInfo
							callOff={callOff}
							selectedOrderId={selectedOrderId}
							refresh={refresh}
							deleteCallOff={deleteCallOff}
							deleteCallOffError={deleteCallOffError}
							isRefreshingCallOff={isRefreshingCallOff}
						/>
					</Grid>
					{details}
				</Grid>
			</StyledPaper>
		)
	}
}

export default CallOffDetail
