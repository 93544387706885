import React from 'react'
import { connect } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import newCallOffActionCreators from '../../../../../../redux/newCallOff/actionCreators'
import FindAddressDialog from './FindAddressDialog'

const FindAddressDialogContainer = (props) => {
	const {
		freightIndex,
		addresses,
		isLoadingAddresses,
		clearAddresses,
		updateFreight
	} = props

	const handleAddressSelect = (address) => {
		// Keep existing house number
		updateFreight(freightIndex, {
			street: address.street,
			zipCode: address.zipCode,
			city: address.city,
			hasFerryCosts: address.hasFerryCosts,
			hasTunnelCosts: address.hasTunnelCosts
		})

		// Clear addresses
		clearAddresses()
	}

	const theme = useTheme()
	const breakpoint = theme.breakpoints

	const smallerThanSM = useMediaQuery(breakpoint.down('sm'))
	const smallerThanMD = useMediaQuery(breakpoint.down('md'))
	const smallerThanLG = useMediaQuery(breakpoint.down('lg'))
	const smallerThanXL = useMediaQuery(breakpoint.down('xl'))

	let screenWidth = 'xl'

	if (smallerThanSM) {
		screenWidth = 'xs'
	} else if (smallerThanMD) {
		screenWidth = 'sm'
	} else if (smallerThanLG) {
		screenWidth = 'md'
	} else if (smallerThanXL) {
		screenWidth = 'lg'
	}

	return (
		<FindAddressDialog
			addresses={addresses}
			isLoadingAddresses={isLoadingAddresses}
			clearAddresses={clearAddresses}
			selectAddress={handleAddressSelect}
			width={screenWidth}
		/>
	)
}

const connector = connect(
	(state) => ({
		freightIndex: state.newCallOffState.freightIndex,
		addresses: state.newCallOffState.addresses,
		isLoadingAddresses: state.newCallOffState.meta.isLoadingAddresses
	}),
	{
		clearAddresses: newCallOffActionCreators.clearAddresses,
		updateFreight: newCallOffActionCreators.updateFreight
	}
)

export default connector(FindAddressDialogContainer)
