import moment from 'moment-timezone'
import numeral from 'numeral'

import { getConvertedQuantity, getQuantityInAnotherUnit } from '../materials'
import {
	isDeliveryDateDisabled,
	getAvailableDeliveryWindows
} from '../newCallOff'
import {
	getPlanningDate,
	getRevisedPlanningDate,
	getDeliveryDate,
	deriveFreights,
	getNextAvailableDeliveryDate
} from '../freights'
import { CALL_OFF_STEP_MATERIALS } from '../constants'

const getCorrectedMaterial = ({
	salesOrganization,
	addedMaterial,
	materials
}) => {
	// match detailed materialinfo to addedMaterial
	const material = materials.find(
		({ materialNumber, shippingPoint }) =>
			materialNumber === addedMaterial.materialNumber &&
			shippingPoint === addedMaterial.shippingPoint
	)
	const { quantity, unit } = addedMaterial
	const quantityInBaseUnit = getQuantityInAnotherUnit({
		unitConversions: material.unitConversions,
		quantity,
		fromUnit: unit,
		toUnit: material.baseUnit
	})
	const convertedQuantity = getConvertedQuantity({
		salesOrganization,
		quantity,
		unit,
		material
	})
	if (quantityInBaseUnit <= convertedQuantity.quantityRounded) {
		// If ordered quantity is still available, material is valid
		return { isValid: true }
	}
	// If ordered quantity is different, return new available quantity to call off
	const correctedQuantity = getQuantityInAnotherUnit({
		unitConversions: material.unitConversions,
		quantity: convertedQuantity.quantityRounded,
		fromUnit: material.baseUnit,
		toUnit: unit
	})
	return { isValid: false, quantity: correctedQuantity }
}

const getCorrectedMaterials = (order) => {
	const { salesOrganization, concept, materials } = order
	const corrections = []

	// Initialize all materials
	let correctedMaterials = order.materials.map((material) => ({
		materialNumber: material.materialNumber,
		shippingPoint: material.shippingPoint,
		unit: material.baseUnit,
		quantity: '',
		complemented: false,
		...concept.materials.find(
			({ materialNumber, shippingPoint }) =>
				materialNumber === material.materialNumber &&
				shippingPoint === material.shippingPoint
		)
	}))

	// Check if all ordered materials on concept are still valid
	correctedMaterials = correctedMaterials.reduce(
		(correctedMaterialsAccumulator, addedMaterial) => {
			const material = materials.find(
				({ materialNumber, shippingPoint }) =>
					materialNumber === addedMaterial.materialNumber &&
					shippingPoint === addedMaterial.shippingPoint
			)
			const { isValid, quantity } = getCorrectedMaterial({
				salesOrganization,
				addedMaterial,
				materials
			})
			if (isValid) {
				// Material is valid and nothing has changed
				return correctedMaterialsAccumulator.concat(addedMaterial)
			}

			// Quantity has changed
			// Used key and context instead of i18n to make this function testable
			corrections.push({
				key: 'app:Validation.materialQuantityChanged',
				context: {
					descriptions: material.descriptions,
					originalQuantity: numeral(addedMaterial.quantity).format('0,0.[000]'),
					correctedQuantity: numeral(quantity).format('0,0.[000]'),
					unit: material.unitConversions.find(
						(unitConversion) => unitConversion.unit === addedMaterial.unit
					)
				}
			})
			return correctedMaterialsAccumulator.concat({
				...addedMaterial,
				quantity,
				complemented: false
			})
		},
		[]
	)

	// Check if materials are no longer available
	concept.materials.forEach((material) => {
		const hasMaterial = materials.some(
			({ materialNumber, shippingPoint }) =>
				materialNumber === material.materialNumber &&
				shippingPoint === material.shippingPoint
		)
		if (!hasMaterial) {
			// Material does not exist anymore
			// Used key and context instead of i18n to make this function testable
			corrections.push({
				key: 'app:Validation.materialNotAvailable',
				context: { materialNumber: material.materialNumber }
			})
		}
	})

	return {
		corrections,
		correctedMaterials
	}
}

export const getCorrectedFreights = (
	order,
	date = moment().tz('Europe/Amsterdam')
) => {
	const { concept, deliveryWindows, freightTransport } = order
	const { deliveryType } = concept
	const corrections = []

	const correctedFreights = concept.freights.map((freight, index) => {
		const { deliveryDate, deliveryWindow } = freight
		const { defaultDeliveryWindowKey, canPlanNextDayTill } = freightTransport

		// Calculate new (first available) planningDate, to validate deliveryDate
		// Change in planningDate is not visible to the user, so no correction entry is made.
		freight.planningDate = getPlanningDate({
			deliveryWindows,
			deliveryType,
			canPlanNextDayTill,
			date
		})
		freight.initialDeliveryDate = getDeliveryDate({
			deliveryWindows,
			deliveryType,
			freight,
			date
		})
		freight.planningDate = getRevisedPlanningDate({
			deliveryWindows,
			deliveryType,
			planningDate: freight.planningDate,
			initialDeliveryDate: freight.initialDeliveryDate,
			canPlanNextDayTill,
			date
		})

		// Validate deliveryDate
		const deliveryDateDisabled = isDeliveryDateDisabled({
			deliveryDate,
			deliveryWindows,
			deliveryType,
			freight,
			date
		})
		if (deliveryDateDisabled) {
			freight.deliveryDate = freight.initialDeliveryDate

			// Used key and context instead of i18n to make this function testable
			corrections.push({
				key: 'app:Validation.deliveryDateChanged',
				context: {
					freightIndex: index + 1,
					correctedDeliveryDate: moment(freight.deliveryDate)
						.tz('Europe/Amsterdam')
						.format('dddd D MMMM Y'),
					deliveryDate: moment(deliveryDate)
						.tz('Europe/Amsterdam')
						.format('dddd D MMMM Y')
				}
			})

			// Determine delivery window (use default if available, else first available)
			const availableDeliveryWindows = getAvailableDeliveryWindows({
				deliveryDate: freight.deliveryDate,
				deliveryWindows,
				deliveryType,
				freight,
				date
			})
			freight.deliveryWindow = availableDeliveryWindows.find(
				({ key }) => key === defaultDeliveryWindowKey
			)
				? defaultDeliveryWindowKey
				: availableDeliveryWindows[0].key
		} else {
			// Check if selected delivery window is still available
			// Determine available delivery windows
			const availableDeliveryWindows = getAvailableDeliveryWindows({
				deliveryDate,
				deliveryWindows,
				deliveryType,
				freight,
				date
			})
			const isDeliveryWindowAvailable = availableDeliveryWindows.some(
				({ key }) => key === deliveryWindow
			)
			if (!isDeliveryWindowAvailable) {
				// Current delivery window is not available
				freight.deliveryWindow = availableDeliveryWindows.find(
					({ key }) => key === defaultDeliveryWindowKey
				)
					? defaultDeliveryWindowKey
					: availableDeliveryWindows[0].key

				corrections.push({
					key: 'app:Validation.deliveryWindowChanged',
					context: {
						freightIndex: index + 1,
						oldDeliveryWindow: deliveryWindows.options.find(
							({ key }) => key === deliveryWindow
						),
						newDeliveryWindow: deliveryWindows.options.find(
							({ key }) => key === freight.deliveryWindow
						)
					}
				})
			}
		}

		// Return modified freight object
		return freight
	})

	return {
		corrections,
		correctedFreights
	}
}

export const getCorrectedConcept = (
	order,
	date = moment().tz('Europe/Amsterdam')
) => {
	const { concept } = order
	const { callOffStep, deliveryType, freights } = concept

	let corrections = []

	const { corrections: materialCorrections, correctedMaterials } =
		getCorrectedMaterials(order)
	corrections = corrections.concat(materialCorrections)

	if (corrections.length !== 0) {
		// If corrections are made at this point, freights are also invalid, so they have to be derived again.
		return {
			correctedConcept: {
				...concept,
				callOffStep: CALL_OFF_STEP_MATERIALS,
				materials: correctedMaterials,
				freights: deriveFreights({
					order,
					materials: correctedMaterials,
					deliveryType,
					freights,
					date
				})
			},
			corrections
		}
	}

	const { corrections: freightCorrections, correctedFreights } =
		getCorrectedFreights(order, date)
	corrections = corrections.concat(freightCorrections)

	if (corrections.length !== 0 && callOffStep === CALL_OFF_STEP_MATERIALS) {
		// If the call-off step is materials and freight corrections are set, silently derive freights again
		// and return without corrections.
		// Else keep the existing freights so the delivery dates remain equal.
		return {
			correctedConcept: {
				...concept,
				callOffStep: CALL_OFF_STEP_MATERIALS,
				materials: correctedMaterials,
				freights: deriveFreights({
					order,
					materials: correctedMaterials,
					deliveryType,
					freights,
					date
				})
			},
			corrections: []
		}
	}

	return {
		correctedConcept: {
			...concept,
			materials: correctedMaterials,
			freights: correctedFreights
		},
		corrections
	}
}

export const getChangedFreights = ({
	order,
	freights,
	selectedFreight,
	deliveryType,
	date = moment().tz('Europe/Amsterdam')
}) => {
	const { deliveryWindow, deliveryDate } = selectedFreight
	const { deliveryWindows, freightTransport } = order
	const { defaultDeliveryWindowKey } = freightTransport

	const changedFreights = []
	const updatedFreights = freights.map((freight, index) => {
		// Check if deliverydate is disabled
		const isDateDisabled = isDeliveryDateDisabled({
			deliveryDate,
			deliveryWindows,
			deliveryType,
			freight,
			date
		})
		const availableDeliveryWindows = getAvailableDeliveryWindows({
			deliveryDate,
			deliveryWindows,
			deliveryType,
			freight,
			date
		})
		const isWindowDisabled = !availableDeliveryWindows.some(
			({ key }) => key === deliveryWindow
		)

		if (!isDateDisabled && !isWindowDisabled) {
			return {
				...freight,
				deliveryDate,
				deliveryWindow
			}
		}

		// If deliverydate is disabled, find the next available date
		const newDeliveryDate = getNextAvailableDeliveryDate({
			deliveryWindows,
			deliveryType,
			freight,
			startFromDate: deliveryDate
		})
		const newAvailableDeliveryWindows = getAvailableDeliveryWindows({
			deliveryDate: newDeliveryDate,
			deliveryWindows,
			deliveryType,
			freight,
			date
		})
		const newDeliveryWindow =
			newAvailableDeliveryWindows.find(
				({ key }) => key === defaultDeliveryWindowKey
			) || newAvailableDeliveryWindows[0]

		// Add it to the changedFreights to inform the user
		changedFreights.push({
			freightIndex: index + 1,
			deliveryDate: newDeliveryDate,
			deliveryWindow: {
				key: newDeliveryWindow.key,
				descriptions: newDeliveryWindow.descriptions,
				timeFrom: newDeliveryWindow.timeFrom,
				timeTill: newDeliveryWindow.timeTill
			}
		})

		// And add it to the updatedFreights
		return {
			...freight,
			deliveryDate: newDeliveryDate,
			deliveryWindow: newDeliveryWindow.key
		}
	})

	return { updatedFreights, changedFreights }
}
