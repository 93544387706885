import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect, Switch, withRouter } from 'react-router-dom'
import i18next, { changeLanguage } from 'i18next'
import AutoLoginContainer from './AutoLogin'
import LoginContainer from './Login'
import TwoFactorContainer from './TwoFactor'
import PasswordContainer from './Password'
import Notfound from './Notfound'
import NotificationContainer from './Notification'
import StartingContainer from './Starting'
import App from './App'
import ErrorBoundary from './ErrorBoundary'

import OrdersContainer from '../Orders/OrdersContainer'
import orderActionCreators from '../../redux/order/actionCreators'
import DisclaimerSVI from '../Documents/DisclaimerSVI'
import DisclaimerSVIen from '../Documents/DisclaimerSVI-en'
import DisclaimerSI from '../Documents/DisclaimerSI'
import DisclaimerSIen from '../Documents/DisclaimerSI-en'
import DisclaimerSIfr from '../Documents/DisclaimerSI-fr'
import PrivacyStatementSVI from '../Documents/PrivacyStatementSVI'
import PrivacyStatementSVIen from '../Documents/PrivacyStatementSVI-en'
import PrivacyStatementSI from '../Documents/PrivacyStatementSI'
import PrivacyStatementSIen from '../Documents/PrivacyStatementSI-en'
import PrivacyStatementSIfr from '../Documents/PrivacyStatementSI-fr'
import { CssBaseline } from '@mui/material'

class AppContainer extends PureComponent {
	static propTypes = {
		autoLoginFailed: PropTypes.bool,
		isAutoLoggingIn: PropTypes.bool,
		requireTwoFactor: PropTypes.bool,
		loggedIn: PropTypes.bool,
		userLanguage: PropTypes.string,
		openOrderSocket: PropTypes.func.isRequired,
		closeOrderSocket: PropTypes.func.isRequired
	}

	componentDidUpdate(prevProps) {
		const { loggedIn, openOrderSocket, closeOrderSocket } = this.props
		if (loggedIn && !prevProps.loggedIn) {
			// Logged in
			openOrderSocket()
		}
		if (!loggedIn && prevProps.loggedIn) {
			// Logged out
			closeOrderSocket()
		}
	}

	render() {
		const {
			autoLoginFailed,
			isAutoLoggingIn,
			requireTwoFactor,
			loggedIn,
			userLanguage
		} = this.props

		let content
		if (isAutoLoggingIn) {
			// App is starting up and trying to login automatically
			content = <StartingContainer />
		} else if (autoLoginFailed) {
			// Autologin failed, show retry view
			content = <AutoLoginContainer />
		} else if (requireTwoFactor) {
			content = <TwoFactorContainer />
		} else if (!loggedIn) {
			// User is not logged in
			content = (
				<Switch>
					<Route exact path="/password" component={PasswordContainer} />
					<Route component={LoginContainer} />
				</Switch>
			)
		} else {
			if (
				userLanguage &&
				i18next.language &&
				userLanguage !== i18next.language
			) {
				// Change language
				changeLanguage(userLanguage)
			}

			// Render disclaimer, manual & privacy statement based on client ID
			const clientID = process.env.REACT_APP_CLIENT_ID || 'svi'

			let disclaimer
			if (clientID === 'svi') {
				if (i18next.language === 'en') {
					disclaimer = DisclaimerSVIen
				} else {
					disclaimer = DisclaimerSVI
				}
			} else if (clientID === 'si') {
				if (i18next.language === 'fr') {
					disclaimer = DisclaimerSIfr
				} else if (i18next.language === 'en') {
					disclaimer = DisclaimerSIen
				} else {
					disclaimer = DisclaimerSI
				}
			}

			let privacystatement
			if (clientID === 'svi') {
				if (i18next.language === 'en') {
					privacystatement = PrivacyStatementSVIen
				} else {
					privacystatement = PrivacyStatementSVI
				}
			} else if (clientID === 'si') {
				if (i18next.language === 'fr') {
					privacystatement = PrivacyStatementSIfr
				} else if (i18next.language === 'en') {
					privacystatement = PrivacyStatementSIen
				} else {
					privacystatement = PrivacyStatementSI
				}
			}

			// User is logged in
			content = (
				<App>
					<Switch>
						<Route
							exact
							path="/orders/:orderId?/:expand?/:childId?/:childExpand?"
							component={OrdersContainer}
						/>
						<Route exact path="/">
							<Redirect to="/orders" />
						</Route>
						<Route exact path="/disclaimer" component={disclaimer} />
						<Route
							exact
							path="/privacystatement"
							component={privacystatement}
						/>
						<Route component={Notfound} />
					</Switch>
				</App>
			)
		}

		return (
			<ErrorBoundary>
				<CssBaseline />
				<NotificationContainer />
				{content}
			</ErrorBoundary>
		)
	}
}

const connector = connect(
	(state) => ({
		autoLoginFailed: state.userState.meta.autoLoginFailed,
		isAutoLoggingIn: state.userState.meta.isAutoLoggingIn,
		requireTwoFactor: state.userState.meta.requireTwoFactor,
		loggedIn: state.userState.user
			? state.userState.user.userName &&
			  state.userState.user.userName.length > 0
			: false,
		userLanguage:
			state.userState.user && state.userState.user.language
				? state.userState.user.language
				: null
	}),
	{
		openOrderSocket: orderActionCreators.openSocket,
		closeOrderSocket: orderActionCreators.closeSocket
	}
)

export default withRouter(connector(AppContainer))
