import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Grid, Hidden, Paper } from '@mui/material'

import OrderFilterContainer from './OrderFilter/OrderFilterContainer'
import OrderListContainer from './OrderList/OrderListContainer'

const sx = {
	container: {
		height: '100%',
		backgroundColor: (theme) => theme.palette.background.default
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		margin: (theme) => theme.spacing(2)
	}
}

class OrderMaster extends PureComponent {
	static propTypes = {
		selectedOrderId: PropTypes.string
	}

	render() {
		const { selectedOrderId } = this.props

		return (
			<Grid container direction="column" sx={sx.container}>
				<Hidden mdUp>
					<Paper sx={sx.paper}>
						<OrderFilterContainer />
						<OrderListContainer />
					</Paper>
				</Hidden>
				<Hidden mdDown>
					<OrderFilterContainer />
					<OrderListContainer selectedOrderId={selectedOrderId} />
				</Hidden>
			</Grid>
		)
	}
}

export default OrderMaster
