import { all, call, fork, put, take, takeLatest } from 'redux-saga/effects'
import { t } from 'i18next'

import history from '../../utils/history'
import apiOrders from '../../services/apiOrders'
import actionTypes from './actionTypes'
import orderActionCreators from './actionCreators'
import newCallOffActionCreators from '../newCallOff/actionCreators'
import notificationsActionCreators from '../notifications/actionCreators'
import orderSocket from '../../sockets/orderSocket'

import { getCorrectedConcept } from '../../utils/validation'
import { NetworkError } from '../../utils/errors'

// Will toggle the date proposal socket
function* socket() {
	while (true) {
		yield take(actionTypes.SOCKET_OPEN)
		yield call(orderSocket.open)
		yield take(actionTypes.SOCKET_CLOSE)
		yield call(orderSocket.close)
	}
}

function* fetchOrder(action) {
	const { orderId, callOffId } = action.meta

	yield put(orderActionCreators.fetchOrderRequest())
	try {
		const order = yield call(orderSocket.getOrder, { orderId, callOffId })
		if (order.concept) {
			const { corrections, correctedConcept } = getCorrectedConcept(order)
			if (corrections.length > 0) {
				yield put(newCallOffActionCreators.setCorrections(corrections))
			}
			yield put(
				orderActionCreators.fetchOrderSuccess({
					...order,
					concept: correctedConcept
				})
			)
		} else {
			yield put(orderActionCreators.fetchOrderSuccess(order))
		}
	} catch (error) {
		const techMessage = error ? error.message : t('app:Error.unknown')
		const userMessage = t('app:Api.Order.fetchOrder')
		yield put(
			orderActionCreators.fetchOrderFailure({
				userMessage,
				techMessage
			})
		)
		yield put(
			notificationsActionCreators.addNotification(
				userMessage,
				error instanceof NetworkError
			)
		)
	}
}

function* watchLastFetchOrder() {
	yield takeLatest(
		[actionTypes.FETCH_ORDER, actionTypes.REFRESH_ORDER],
		fetchOrder
	)
}

function* fetchDeliveries(action) {
	const { orderId } = action.meta

	yield put(orderActionCreators.fetchDeliveriesRequest())
	try {
		const orderWithDeliveries = yield call(orderSocket.getOrder, {
			orderId,
			view: 'deliveries'
		})
		yield put(orderActionCreators.fetchDeliveriesSuccess(orderWithDeliveries))
	} catch (error) {
		const techMessage = error ? error.message : t('app:Error.unknown')
		const userMessage = t('app:Orders.Deliveries.fetchError')
		yield put(
			orderActionCreators.fetchDeliveriesFailure({
				userMessage,
				techMessage
			})
		)
		yield put(
			notificationsActionCreators.addNotification(
				userMessage,
				error instanceof NetworkError
			)
		)
	}
}

function* watchLastFetchDeliveries() {
	yield takeLatest(actionTypes.FETCH_DELIVERIES, fetchDeliveries)
}

function* fetchCallOffs(action) {
	const { orderId } = action.meta

	yield put(orderActionCreators.fetchCallOffsRequest())
	try {
		const orderWithCallOffs = yield call(orderSocket.getOrder, {
			orderId,
			view: 'callOffOrders'
		})
		yield put(orderActionCreators.fetchCallOffsSuccess(orderWithCallOffs))
	} catch (error) {
		const techMessage = error ? error.message : t('app:Error.unknown')
		const userMessage = t('app:CallOffs.fetchError')
		yield put(
			orderActionCreators.fetchCallOffsFailure({
				userMessage,
				techMessage
			})
		)
		yield put(
			notificationsActionCreators.addNotification(
				userMessage,
				error instanceof NetworkError
			)
		)
	}
}

function* watchLastFetchCallOffs() {
	yield takeLatest(actionTypes.FETCH_CALLOFFS, fetchCallOffs)
}

function* fetchCallOff(action) {
	const { orderId, callOffId } = action.meta

	yield put(orderActionCreators.fetchCallOffRequest(callOffId))
	try {
		const callOffWithDeliveries = yield call(orderSocket.getOrder, {
			orderId,
			callOffId,
			view: 'deliveries'
		})
		yield put(orderActionCreators.fetchCallOffSuccess(callOffWithDeliveries))
	} catch (error) {
		const techMessage = error ? error.message : t('app:Error.unknown')
		const userMessage = t('app:CallOffs.fetchError')
		yield put(
			orderActionCreators.fetchCallOffFailure({
				userMessage,
				techMessage
			})
		)
		yield put(
			notificationsActionCreators.addNotification(
				userMessage,
				error instanceof NetworkError
			)
		)
	}
}

function* watchLastFetchCallOff() {
	yield takeLatest(
		[actionTypes.FETCH_CALLOFF, actionTypes.REFRESH_CALLOFF],
		fetchCallOff
	)
}

function* deleteCallOff() {
	while (true) {
		const action = yield take(actionTypes.DELETE_CALLOFF)
		const { orderId, callOffId } = action.meta

		yield put(orderActionCreators.deleteCallOffRequest())
		try {
			yield call(apiOrders.deleteCallOff, orderId, callOffId)
			yield put(orderActionCreators.deleteCallOffSuccess())
			// Trigger navigation to call-offs
			const destination = `/orders/${orderId}/calloffs`
			history.push(destination)
		} catch (error) {
			const techMessage = error ? error.message : t('app:Error.unknown')
			const userMessage = t('app:CallOffs.deleteError')
			yield put(
				orderActionCreators.deleteCallOffFailure({
					userMessage,
					techMessage
				})
			)
			yield put(
				notificationsActionCreators.addNotification(
					userMessage,
					error instanceof NetworkError
				)
			)
		}
	}
}

export default function* rootSaga() {
	yield all(
		[
			socket,
			watchLastFetchOrder,
			watchLastFetchDeliveries,
			watchLastFetchCallOffs,
			watchLastFetchCallOff,
			deleteCallOff
		].map((saga) => fork(saga))
	)
}
