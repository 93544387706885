import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Summary from './Summary'

class SummaryContainer extends PureComponent {
	static propTypes = {
		availableCountries: PropTypes.array.isRequired,
		deliveryWindows: PropTypes.object.isRequired,
		deliveryType: PropTypes.string.isRequired,
		freights: PropTypes.array.isRequired,
		materials: PropTypes.array.isRequired,
		missingMaterials: PropTypes.string.isRequired,
		returnPalletsState: PropTypes.object.isRequired
	}

	render() {
		const {
			availableCountries,
			deliveryWindows,
			deliveryType,
			freights,
			materials,
			missingMaterials,
			returnPalletsState
		} = this.props

		return (
			<Summary
				deliveryType={deliveryType}
				freights={freights}
				materials={materials}
				deliveryWindows={deliveryWindows}
				availableCountries={availableCountries}
				missingMaterials={missingMaterials}
				returnPalletsState={returnPalletsState}
			/>
		)
	}
}

const connector = connect((state) => ({
	freights: state.newCallOffState.freights,
	materials: state.orderState.order.materials,
	deliveryType: state.newCallOffState.deliveryType,
	deliveryWindows: state.orderState.order.deliveryWindows,
	availableCountries:
		state.orderState.order.freightTransport.availableCountries,
	missingMaterials: state.newCallOffState.missingMaterials,
	returnPalletsState: state.newCallOffState.returnPalletsState
}))

export default connector(SummaryContainer)
