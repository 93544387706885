import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'
import { ListItem, ListItemAvatar, ListItemText, Divider } from '@mui/material'

import StatusIcon from '../../Shared/StatusIcon'
import StatusText from '../../Shared/StatusText'
import Material from './Material'

class Delivery extends PureComponent {
	static propTypes = {
		delivery: PropTypes.object.isRequired
	}

	render() {
		const { delivery } = this.props
		const { deliveryNumber, materials, status } = delivery

		return (
			<Fragment>
				<ListItem>
					<ListItemAvatar>
						<StatusIcon status={status} />
					</ListItemAvatar>
					<ListItemText
						primary={t('app:Orders.Deliveries.delivery', {
							deliveryNumber
						})}
						primaryTypographyProps={{ variant: 'h6' }}
					/>
					<StatusText status={status} />
				</ListItem>
				<li>
					<Divider variant="inset" />
				</li>
				{materials.map((material, i) => (
					<Material key={i} material={material} />
				))}
			</Fragment>
		)
	}
}

export default Delivery
