import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Grid, Paper, Box } from '@mui/material'
import { t } from 'i18next'

import CallOffsListContainer from './CallOffsList/CallOffsListContainer'
import HeaderInfo from './HeaderInfo'
import Loading from '../../Shared/Loading'
import NotFound from '../../Shared/NotFound'

import { styled } from '@mui/styles'

const StyledBox = styled(Box)(({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	},
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2),
	backgroundColor: theme.palette.background.default,
	height: '100%',
	overflowY: 'scroll',
	overflowX: 'hidden'
}))

class CallOffs extends PureComponent {
	static propTypes = {
		selectedOrderId: PropTypes.string.isRequired,

		order: PropTypes.object,
		isLoadingCallOffs: PropTypes.bool.isRequired
	}

	render() {
		const { selectedOrderId, order, isLoadingCallOffs } = this.props

		if (isLoadingCallOffs) {
			return (
				<StyledBox>
					<Loading paper message={t('app:CallOffs.loading')} />
				</StyledBox>
			)
		}

		if (!order) {
			return (
				<StyledBox>
					<NotFound
						paper
						description={t('app:Orders.notFoundDescription')}
						header={t('app:Orders.notFoundHeader')}
					/>
				</StyledBox>
			)
		}

		return (
			<StyledBox>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<HeaderInfo order={order} />
					</Grid>
					<Grid item xs={12}>
						<Paper>
							<CallOffsListContainer selectedOrderId={selectedOrderId} />
						</Paper>
					</Grid>
				</Grid>
			</StyledBox>
		)
	}
}

export default CallOffs
