import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import OrderSteps from './OrderSteps'

class OrderStepsContainer extends PureComponent {
	static propTypes = {
		orderDetailRef: PropTypes.any,
		deliveryType: PropTypes.string.isRequired,
		callOffStep: PropTypes.number.isRequired
	}

	render() {
		const { orderDetailRef, deliveryType, callOffStep } = this.props

		return (
			<OrderSteps
				orderDetailRef={orderDetailRef}
				deliveryType={deliveryType}
				callOffStep={callOffStep}
			/>
		)
	}
}

const connector = connect((state) => ({
	deliveryType: state.newCallOffState.deliveryType,
	callOffStep: state.newCallOffState.callOffStep
}))

export default connector(OrderStepsContainer)
