import { t } from 'i18next'
import elementsFetch from '../utils/fetch'
import {
	NetworkError,
	ServerError,
	UnauthenticatedError,
	UnauthorizedError,
	NotFoundError
} from '../utils/errors'

const ordersUrl = '/api/salesorders'
const orderLookupUrl = (orderNumber) => `/api/orderlookup/${orderNumber}`
const userFavoritesUrl = (userId, orderNumber) =>
	`/api/users/${userId}/favorites/${orderNumber}`
const callOffUrl = (orderId, callOffId) =>
	`/api/salesorders/${orderId}/callofforders/${callOffId}`

const getOrders = async () => {
	let response
	try {
		response = await elementsFetch(ordersUrl)
	} catch (error) {
		throw new NetworkError(t('app:Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new UnauthenticatedError(t('app:Api.Generic.unauthorized'))
		}
		if (response.status === 403) {
			throw new UnauthorizedError(t('app:Api.Generic.unauthorized'))
		}
		throw new ServerError(t('app:Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const lookupOrder = async (orderNumber) => {
	let response
	try {
		response = await elementsFetch(orderLookupUrl(orderNumber))
	} catch (error) {
		throw new NetworkError(t('app:Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new UnauthenticatedError(t('app:Api.Generic.unauthorized'))
		}
		if (response.status === 403) {
			throw new UnauthorizedError(t('app:Api.Generic.unauthorized'))
		}
		if (response.status === 404) {
			throw new NotFoundError(t('app:Api.Order.notFound'))
		}
		throw new ServerError(t('app:Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const addFavoriteOrder = async (userId, orderNumber) => {
	let response
	try {
		response = await elementsFetch(userFavoritesUrl(userId, orderNumber), {
			method: 'PUT',
			timeout: 10000
		})
	} catch (error) {
		throw new NetworkError(t('app:Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new UnauthenticatedError(t('app:Api.Generic.unauthorized'))
		}
		if (response.status === 403) {
			throw new UnauthorizedError(t('app:Api.Generic.unauthorized'))
		}
		throw new ServerError(t('app:Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const removeFavoriteOrder = async (userId, orderNumber) => {
	let response
	try {
		response = await elementsFetch(userFavoritesUrl(userId, orderNumber), {
			method: 'DELETE',
			timeout: 10000
		})
	} catch (error) {
		throw new NetworkError(t('app:Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new UnauthenticatedError(t('app:Api.Generic.unauthorized'))
		}
		if (response.status === 403) {
			throw new UnauthorizedError(t('app:Api.Generic.unauthorized'))
		}
		throw new ServerError(t('app:Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const deleteCallOff = async (orderId, callOffId) => {
	let response
	try {
		response = await elementsFetch(callOffUrl(orderId, callOffId), {
			method: 'DELETE',
			timeout: 10000
		})
	} catch {
		throw new NetworkError(t('app:Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new UnauthenticatedError(t('app:Api.Generic.unauthorized'))
		}
		if (response.status === 403) {
			throw new UnauthorizedError(t('app:Api.Generic.unauthorized'))
		}
		throw new ServerError(t('app:Api.Generic.serverError'))
	} else {
		return response.json()
	}
}

const apiOrders = {
	getOrders,
	lookupOrder,
	addFavoriteOrder,
	removeFavoriteOrder,
	deleteCallOff
}
export default apiOrders
