import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
	Grid,
	IconButton,
	Paper,
	TextField,
	Typography,
	SvgIcon,
	Box
} from '@mui/material'

import { TimesFal } from '@oliverit/react-fontawesome'
import { t } from 'i18next'

const sx = {
	paper: {
		paddingLeft: (theme) => theme.spacing(2),
		paddingRight: (theme) => theme.spacing(2)
	},
	heading: {
		paddingTop: (theme) => theme.spacing(3),
		paddingBottom: (theme) => theme.spacing(2),
		marginBottom: (theme) => theme.spacing(3)
	}
}

class HeaderInfo extends PureComponent {
	static propTypes = {
		order: PropTypes.object.isRequired
	}

	render() {
		const { order } = this.props

		const inputProps = { readOnly: true, disableUnderline: true }
		const inputLabelProps = { shrink: true }

		return (
			<Fragment>
				<Grid
					container
					spacing={2}
					justifyContent="space-between"
					alignItems="center"
				>
					<Grid item>
						<Box sx={sx.heading}>
							<Typography variant="h6">
								{t('app:Orders.Deliveries.heading')}
							</Typography>
						</Box>
					</Grid>
					<Grid item>
						<IconButton component={Link} to="/orders" size="large">
							<SvgIcon>
								<TimesFal />
							</SvgIcon>
						</IconButton>
					</Grid>
				</Grid>
				<Paper sx={sx.paper}>
					<Grid container spacing={2}>
						<Grid item md={4} sm={6} xs={12}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										value={order.orderNumber || ''}
										fullWidth
										label={t('app:Orders.Details.orderNumber')}
										InputProps={inputProps}
										InputLabelProps={inputLabelProps}
										variant="standard"
										multiline
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										value={order.project.name || ''}
										label={t('app:Orders.Details.project')}
										InputProps={inputProps}
										InputLabelProps={inputLabelProps}
										variant="standard"
										multiline
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item md={4} sm={6} xs={12}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										value={order.customer.customerNumber || ''}
										fullWidth
										label={t('app:Orders.Details.customerNr')}
										InputProps={inputProps}
										InputLabelProps={inputLabelProps}
										variant="standard"
										multiline
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										multiline
										fullWidth
										value={order.customer.name || ''}
										label={t('app:Orders.Details.customerName')}
										InputProps={inputProps}
										InputLabelProps={inputLabelProps}
										variant="standard"
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item md={4} sm={12}>
							<Grid container spacing={2}>
								<Grid item md={12} sm={6} xs={12}>
									<TextField
										multiline
										fullWidth
										value={order.project.projectNumber || ''}
										label={t('app:Orders.Details.projectNumber')}
										InputProps={inputProps}
										InputLabelProps={inputLabelProps}
										variant="standard"
									/>
								</Grid>
								<Grid item md={12} sm={6} xs={12}>
									<TextField
										multiline
										fullWidth
										value={order.project.city || ''}
										label={t('app:Orders.Details.projectCity')}
										InputProps={inputProps}
										InputLabelProps={inputLabelProps}
										variant="standard"
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Fragment>
		)
	}
}

export default HeaderInfo
