import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ContactPerson from './ContactPerson'
import newCallOffActionCreators from '../../../../../redux/newCallOff/actionCreators'
import newCallOffSelectors from '../../../../../redux/newCallOff/selectors'

class ContactPersonContainer extends PureComponent {
	static propTypes = {
		freightIndex: PropTypes.number.isRequired,
		freight: PropTypes.object.isRequired,
		user: PropTypes.object.isRequired,
		updateFreight: PropTypes.func.isRequired,
		showFillContactPersonButton: PropTypes.bool.isRequired,
		fillContactPersonOnAllCallOffs: PropTypes.func.isRequired
	}

	handleNameChange = (name) => {
		const { freightIndex, updateFreight } = this.props
		updateFreight(freightIndex, { name })
	}

	handlePhoneNumberChange = (phone) => {
		const { freightIndex, updateFreight } = this.props
		updateFreight(freightIndex, { phone })
	}

	handleFillUserInfoCheckboxChange = ({ fillUserInfo, name, phone }) => {
		const { freightIndex, updateFreight } = this.props
		const userAsContactPerson = fillUserInfo

		updateFreight(freightIndex, { name, phone, userAsContactPerson })
	}

	handleFillContactPersonEverywhere = () => {
		const { fillContactPersonOnAllCallOffs } = this.props
		fillContactPersonOnAllCallOffs()
	}

	render() {
		const { freight, showFillContactPersonButton, user } = this.props

		return (
			<ContactPerson
				freight={freight}
				nameChange={this.handleNameChange}
				phoneNumberChange={this.handlePhoneNumberChange}
				fillUserInfoCheckboxChange={this.handleFillUserInfoCheckboxChange}
				showFillContactPersonButton={showFillContactPersonButton}
				handleFillContactPersonEverywhere={
					this.handleFillContactPersonEverywhere
				}
				user={user}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		freightIndex: state.newCallOffState.freightIndex,
		freight: newCallOffSelectors.selectedFreightSelector(state),
		user: state.userState.user,
		showFillContactPersonButton:
			newCallOffSelectors.showFillContactPersonButtonSelector(state)
	}),
	{
		fillContactPersonOnAllCallOffs:
			newCallOffActionCreators.fillContactPersonOnAllCallOffs,
		updateFreight: newCallOffActionCreators.updateFreight
	}
)

export default connector(ContactPersonContainer)
