import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { t } from 'i18next'
import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
import {
	Button,
	Grid,
	Typography,
	TextField,
	FormGroup,
	FormControlLabel,
	Checkbox
} from '@mui/material'
import { styled } from '@mui/styles'

const mixinsGuttersStyle = ({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	[theme.breakpoints.up('sm')]: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	}
})

const StyledGrid = styled(Grid)(mixinsGuttersStyle)

const sx = {
	mainContent: {
		paddingBottom: (theme) => theme.spacing(2)
	},
	typography: {
		paddingTop: (theme) => theme.spacing(2),
		fontWeight: (theme) => theme.typography.fontWeightMedium
	}
}

class ContactPerson extends PureComponent {
	static propTypes = {
		freight: PropTypes.object.isRequired,
		nameChange: PropTypes.func.isRequired,
		phoneNumberChange: PropTypes.func.isRequired,
		fillUserInfoCheckboxChange: PropTypes.func.isRequired,
		showFillContactPersonButton: PropTypes.bool.isRequired,
		handleFillContactPersonEverywhere: PropTypes.func.isRequired,
		user: PropTypes.object.isRequired
	}

	handleNameChange = (event) => {
		const { nameChange } = this.props
		nameChange(event.target.value)
	}

	handlePhoneNumberChange = (event) => {
		const { freight, phoneNumberChange } = this.props
		let phone = event.target.value

		// libphonenumber-js does not support "00" while typing, replace "00" by "+"
		if (phone === '00') {
			phone = '+'
		}

		// Format phone number to international string if possible
		let phoneNumber = parsePhoneNumberFromString(
			phone,
			freight.country.toUpperCase()
		)
		phoneNumber = phoneNumber ? phoneNumber.formatInternational() : phone
		phoneNumberChange(phoneNumber)
	}

	handleFillUserInfoCheckboxChange = (event) => {
		const { fillUserInfoCheckboxChange, freight, user } = this.props
		const fillUserInfo = event.target.checked
		const name = fillUserInfo ? `${user.firstName} ${user.lastName}` : ''
		let phone = ''
		if (fillUserInfo && user.phoneNumber !== '') {
			const phoneNumber = parsePhoneNumberFromString(
				user.phoneNumber,
				freight.country.toUpperCase()
			)
			phone = phoneNumber.formatInternational()
		}
		fillUserInfoCheckboxChange({ fillUserInfo, name, phone })
	}

	render() {
		const {
			freight,
			showFillContactPersonButton,
			handleFillContactPersonEverywhere
		} = this.props
		const phoneNumber = parsePhoneNumberFromString(
			freight.phone,
			freight.country.toUpperCase()
		)
		const validPhoneNumber = phoneNumber ? phoneNumber.isValid() : false

		let fillContactPersonButton
		if (showFillContactPersonButton) {
			fillContactPersonButton = (
				<Grid item xs={12}>
					<Button
						variant="outlined"
						onClick={handleFillContactPersonEverywhere}
					>
						{t('app:Delivery.fillContactPersonOnAllCallOffs')}
					</Button>
				</Grid>
			)
		}

		return (
			<StyledGrid container spacing={2} alignItems="center" sx={sx.mainContent}>
				<Grid item xs={12}>
					<Typography variant="subtitle1" sx={sx.typography}>
						{t('app:Delivery.contactDetails')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						variant="outlined"
						value={freight.name}
						fullWidth
						label={t('app:Delivery.name')}
						inputProps={{ maxLength: 35 }}
						onChange={this.handleNameChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						type="tel"
						variant="outlined"
						helperText={
							!validPhoneNumber &&
							freight.phone !== '' &&
							t('app:Delivery.phoneHelperText')
						}
						error={!validPhoneNumber && freight.phone !== ''}
						required
						value={freight.phone}
						fullWidth
						label={t('app:Delivery.phone')}
						inputProps={{ maxLength: 16 }}
						onChange={this.handlePhoneNumberChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormGroup row>
						<FormControlLabel
							control={
								<Checkbox
									checked={Boolean(freight.userAsContactPerson)}
									onChange={this.handleFillUserInfoCheckboxChange}
								/>
							}
							label={t('app:Delivery.fillUserData')}
						/>
					</FormGroup>
				</Grid>
				{fillContactPersonButton}
			</StyledGrid>
		)
	}
}

export default ContactPerson
