import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
	AppBar,
	IconButton,
	Toolbar,
	Typography,
	Popover,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
	SvgIcon
} from '@mui/material'

import {
	BarsFal,
	UserCircleFas,
	SignOutAltFal
} from '@oliverit/react-fontawesome'
import { t } from 'i18next'
import { styled } from '@mui/styles'

const drawerWidth = 280

const sx = {
	flex: {
		flexGrow: 1
	},
	menuButton: {
		marginLeft: (theme) => theme.spacing(-1.5),
		marginRight: (theme) => theme.spacing(2.5)
	},
	appBar: {
		zIndex: (theme) => theme.zIndex.drawer + 1,
		transition: (theme) =>
			theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			})
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: (theme) =>
			theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			})
	},
	hide: {
		display: 'none'
	}
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
	[theme.breakpoints.down('sm')]: {
		height: theme.getToolbarMinHeight('xs')
	},
	[theme.breakpoints.between('sm', 'md')]: {
		height: theme.getToolbarMinHeight('sm')
	},
	[theme.breakpoints.up('md')]: {
		height: theme.getToolbarMinHeight()
	}
}))

class TopBar extends PureComponent {
	static propTypes = {
		fullName: PropTypes.string.isRequired,
		isSideMenuOpen: PropTypes.bool.isRequired,
		logout: PropTypes.func.isRequired,
		showSideMenu: PropTypes.func.isRequired,
		title: PropTypes.string
	}

	state = {
		isAccountInfoOpen: false,
		anchorEl: null
	}

	handleShowAccountInfo = (event) => {
		this.setState({
			isAccountInfoOpen: true,
			anchorEl: event.currentTarget
		})
	}

	handleHideAccountInfo = () => {
		this.setState({
			isAccountInfoOpen: false,
			anchorEl: null
		})
	}

	render() {
		const { logout, fullName, isSideMenuOpen, showSideMenu, title } = this.props
		const { anchorEl, isAccountInfoOpen } = this.state

		const appBarStyle = isSideMenuOpen
			? { ...sx.appBar, ...sx.appBarShift }
			: sx.appBar
		const iconButtonStyle = isSideMenuOpen
			? { ...sx.menuButton, ...sx.hide }
			: sx.menuButton

		return (
			<StyledAppBar position="absolute" sx={appBarStyle}>
				<Toolbar>
					<IconButton
						sx={iconButtonStyle}
						color="inherit"
						onClick={showSideMenu}
						size="large"
					>
						<SvgIcon>
							<BarsFal />
						</SvgIcon>
					</IconButton>
					<Typography variant="h6" color="inherit" sx={sx.flex}>
						{title}
					</Typography>
					<IconButton
						color="inherit"
						onClick={this.handleShowAccountInfo}
						size="large"
					>
						<SvgIcon>
							<UserCircleFas />
						</SvgIcon>
					</IconButton>
					<Popover
						open={isAccountInfoOpen}
						onClose={this.handleHideAccountInfo}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
					>
						<List dense>
							<ListItem>
								<ListItemText>
									<Typography variant="subtitle1">{fullName}</Typography>
								</ListItemText>
							</ListItem>
							<Divider />
							<Divider />
							<ListItem button onClick={logout}>
								<ListItemIcon>
									<SvgIcon>
										<SignOutAltFal />
									</SvgIcon>
								</ListItemIcon>
								<ListItemText primaryTypographyProps={{ variant: 'body2' }}>
									{t('app:Afroep.Main.logout')}
								</ListItemText>
							</ListItem>
						</List>
					</Popover>
				</Toolbar>
			</StyledAppBar>
		)
	}
}

export default TopBar
