import React, { PureComponent } from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { Grid, ListItem, Typography } from '@mui/material'

const sx = {
	container: {
		padding: (theme) => theme.spacing()
	}
}

class SummaryMissingMaterials extends PureComponent {
	static propTypes = {
		missingMaterials: PropTypes.string.isRequired
	}

	render() {
		const { missingMaterials } = this.props
		return (
			<ListItem divider>
				<Grid container spacing={2} sx={sx.container}>
					<Grid item xs={12}>
						<Typography variant="h5">
							{t('app:Materials.missingMaterialsTitle')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1">{missingMaterials}</Typography>
					</Grid>
				</Grid>
			</ListItem>
		)
	}
}

export default SummaryMissingMaterials
