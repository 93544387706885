import i18n from 'i18next'
import { nl, fr } from 'date-fns/locale'
import { nlNL, frFR } from '@mui/x-date-pickers'

const momentLocale = (userLanguage) => {
	switch (userLanguage) {
		case 'nl': {
			return 'nl'
		}
		case 'de': {
			return 'de'
		}
		case 'en': {
			return 'en'
		}
		case 'fr': {
			return 'fr'
		}
		default: {
			return 'nl'
		}
	}
}

const numeralLocale = (userLanguage) => {
	switch (userLanguage) {
		case 'nl': {
			return 'nl-nl'
		}
		case 'de': {
			return 'de'
		}
		case 'en': {
			return 'en'
		}
		case 'fr': {
			return 'fr'
		}
		default: {
			return 'nl-nl'
		}
	}
}

const getDateFnsLocale = () => {
	if (i18n.language === 'nl') {
		return nl
	}
	if (i18n.language === 'fr') {
		return fr
	}
	return nl // Use nl as fallback
}

const getLocaleTexts = () => {
	if (i18n.language === 'nl') {
		return nlNL.components.MuiLocalizationProvider.defaultProps.localeText
	}
	if (i18n.language === 'fr') {
		return frFR.components.MuiLocalizationProvider.defaultProps.localeText
	}
	return nlNL.components.MuiLocalizationProvider.defaultProps.localeText // Use nl as fallback
}

export { momentLocale, numeralLocale, getDateFnsLocale, getLocaleTexts }
