import { Snackbar, Alert, Button, AlertTitle } from '@mui/material'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

const VersionUpdateNotification = () => {
	const [initialVersion, setInitialVersion] = useState(null)
	const [hasNewVersion, setHasNewVersion] = useState(false)

	useEffect(() => {
		const checkHasNewVersion = async () => {
			try {
				// Fetch latest version from version.json in the public folder
				const response = await fetch('/version.json')
				const versionInformation = await response.json()
				const { version } = versionInformation
				if (!version) {
					// Version missing, unable to complete the version check
					return
				}
				if (initialVersion === null) {
					// First check is used to set the initial version
					setInitialVersion(version)
				} else {
					// Next checks are used to see if a new version was deployed
					setHasNewVersion(initialVersion !== version)
				}
			} catch (err) {
				// Could not fetch or parse version information, unable to complete the version check
			}
		}

		// Immediately and periodically check if there is a new version
		checkHasNewVersion()
		const checkHasNewVersionInterval = setInterval(checkHasNewVersion, 600000) // 10 minutes

		// Clear the interval on effect cleanup
		return () => clearInterval(checkHasNewVersionInterval)
	}, [initialVersion])

	const handleReloadApp = () => {
		window.location.reload()
	}

	return (
		<Snackbar
			open={hasNewVersion}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<Alert
				severity="warning"
				action={
					<Button
						color="inherit"
						size="small"
						variant="outlined"
						onClick={handleReloadApp}
					>
						{t('app:Generic.VersionCheck.update')}
					</Button>
				}
			>
				<AlertTitle>{t('app:Generic.VersionCheck.title')}</AlertTitle>
				{t('app:Generic.VersionCheck.description')}
			</Alert>
		</Snackbar>
	)
}

export default VersionUpdateNotification
