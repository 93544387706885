import i18next from 'i18next'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { Fragment, PureComponent } from 'react'
import { getAvailableDeliveryWindows } from '../../../../utils/newCallOff'
import SummaryItem from './SummaryItem'
import SummaryMissingMaterials from './SummaryMissingMaterials'

class Summary extends PureComponent {
	static propTypes = {
		availableCountries: PropTypes.array.isRequired,
		deliveryWindows: PropTypes.object.isRequired,
		deliveryType: PropTypes.string.isRequired,
		freights: PropTypes.array.isRequired,
		materials: PropTypes.array.isRequired,
		missingMaterials: PropTypes.string.isRequired,
		returnPalletsState: PropTypes.object.isRequired
	}

	matchDeliveryWindow = (freight) => {
		const { deliveryType, deliveryWindows } = this.props
		const { deliveryDate, deliveryWindow } = freight
		const availableDeliveryWindows = getAvailableDeliveryWindows({
			deliveryDate,
			deliveryWindows,
			deliveryType,
			freight
		})
		const matchedDeliveryWindow = availableDeliveryWindows.find(
			({ key }) => key === deliveryWindow
		)
		return `${moment(matchedDeliveryWindow.timeFrom, 'HH:mm:ss').format(
			'HH:mm'
		)} - ${moment(matchedDeliveryWindow.timeTill, 'HH:mm:ss').format('HH:mm')}`
	}

	matchCountryName = (countryCode) => {
		const { availableCountries } = this.props
		const language = i18next.language
		const matchedCountry = availableCountries.find(
			({ code }) => code === countryCode
		)
		return matchedCountry.descriptions[language]
	}

	render() {
		const {
			availableCountries,
			deliveryType,
			freights,
			materials,
			missingMaterials,
			returnPalletsState
		} = this.props

		let missingMaterialsSection
		if (missingMaterials) {
			missingMaterialsSection = (
				<SummaryMissingMaterials missingMaterials={missingMaterials} />
			)
		}

		return (
			<Fragment>
				{missingMaterialsSection}
				{freights.map((freight, index) => (
					<SummaryItem
						key={index}
						freight={freight}
						index={index}
						availableCountries={availableCountries}
						materials={materials}
						deliveryType={deliveryType}
						matchDeliveryWindow={this.matchDeliveryWindow}
						matchCountryName={this.matchCountryName}
						returnPalletsState={returnPalletsState}
					/>
				))}
			</Fragment>
		)
	}
}

export default Summary
