import { t } from 'i18next'
import {
	NetworkError,
	ServerError,
	UnauthenticatedError,
	TwoFactorTokenError,
	RefreshTokenError
} from '../utils/errors'
import oeServer from '../utils/oeServer'

const loginUrl = `${oeServer}/api/authenticate`
const refreshTokensUrl = `${oeServer}/api/refreshtokens/refresh`

const login = async (credentials, accessToken) => {
	let response
	try {
		const headers = {
			'Content-Type': 'application/json'
		}
		response = await fetch(loginUrl, {
			method: 'POST',
			headers,
			body: JSON.stringify({ ...credentials, accessToken }),
			timeout: 10000
		})
	} catch (error) {
		throw new NetworkError(t('app:Api.Generic.networkError'))
	}
	if (!response.ok) {
		if (response.status === 401) {
			throw new UnauthenticatedError(t('app:Api.Login.unauthorized'))
		}
		throw new ServerError(t('app:Api.Generic.serverError'))
	} else {
		const result = await response.json()
		return result
	}
}

const refreshTokens = async (refreshToken, twoFactorToken) => {
	let response
	try {
		const headers = {
			Authorization: `Bearer ${refreshToken}`,
			'Content-Type': 'application/json'
		}
		response = await fetch(refreshTokensUrl, {
			method: 'POST',
			headers,
			body: JSON.stringify({ twoFactorToken }),
			timeout: 10000
		})
	} catch (error) {
		throw new NetworkError(t('app:Api.Generic.networkError'))
	}

	let result
	try {
		result = await response.json()
	} catch {
		result = {}
	}

	if (!response.ok) {
		if (
			response.status === 401 &&
			result.message &&
			result.message === 'iam:twoFactor.verify.invalidToken'
		) {
			throw new TwoFactorTokenError(t('app:Api.Login.invalidTwoFactorToken'))
		}
		if (
			response.status === 401 ||
			response.status === 403 ||
			response.status === 404
		) {
			throw new RefreshTokenError(t('app:Api.Generic.invalidRefreshToken'))
		}
		throw new ServerError(t('app:Api.Generic.serverError'))
	} else {
		return result
	}
}

const apiAuth = {
	login,
	refreshTokens
}
export default apiAuth
