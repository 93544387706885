const actionTypes = {
	ANOTHER_USER: 'elements/auth/ANOTHER_USER',
	AUTOLOGIN: 'elements/auth/AUTOLOGIN',
	AUTOLOGIN_FAILURE: 'elements/auth/AUTOLOGIN_FAILURE',
	AUTOLOGIN_REQUEST: 'elements/auth/AUTOLOGIN_REQUEST',
	AUTOLOGIN_NOTOKEN: 'elements/auth/AUTOLOGIN_NOTOKEN',
	LOGIN: 'elements/auth/LOGIN',
	LOGIN_FAILURE: 'elements/auth/LOGIN_FAILURE',
	LOGIN_REQUEST: 'elements/auth/LOGIN_REQUEST',
	LOGIN_SUCCESS: 'elements/auth/LOGIN_SUCCESS',
	LOGIN_CANCELLED: 'elements/auth/LOGIN_CANCELLED',
	LOGOUT: 'elements/auth/LOGOUT',
	TWOFACTOR_FAILURE: 'elements/auth/TWOFACTOR_FAILURE',
	TWOFACTOR_NOTOKEN: 'elements/auth/TWOFACTOR_NOTOKEN',
	TWOFACTOR_REQUEST: 'elements/auth/TWOFACTOR_REQUEST',
	TWOFACTOR_REQUIRED: 'elements/auth/TWOFACTOR_REQUIRED',
	TWOFACTOR_SUBMIT: 'elements/auth/TWOFACTOR_SUBMIT'
}

export default actionTypes
