import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import notificationsActionCreators from '../../../redux/notifications/actionCreators'
import Notification from './Notification'

class NotificationContainer extends PureComponent {
	static propTypes = {
		notifications: PropTypes.array.isRequired,
		removeNotification: PropTypes.func.isRequired
	}

	handleDone = (id) => {
		const { removeNotification } = this.props
		removeNotification(id)
	}

	render() {
		// Only display one notification at a time (the oldest one)
		const { notifications } = this.props
		const [notification] = notifications

		if (!notification) {
			return null
		}

		return (
			<Notification
				done={this.handleDone}
				key={`notification-${notification.id}`}
				notification={notification}
			/>
		)
	}
}

const connector = connect(
	(state) => ({
		notifications: state.notificationsState.notifications
	}),
	{
		removeNotification: notificationsActionCreators.removeNotification
	}
)

export default connector(NotificationContainer)
