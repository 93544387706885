import actionTypes from './actionTypes'

const actionCreators = {
	fetchOrders: () => ({
		type: actionTypes.FETCH_ORDERS
	}),

	fetchOrdersRequest: () => ({
		type: actionTypes.FETCH_ORDERS_REQUEST
	}),

	fetchOrdersSuccess: (orders) => ({
		type: actionTypes.FETCH_ORDERS_SUCCESS,
		payload: {
			orders
		}
	}),

	fetchOrdersFailure: (errorMessages) => ({
		type: actionTypes.FETCH_ORDERS_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	filterOrders: ({ searchInput }) => ({
		type: actionTypes.FILTER_ORDERS,
		meta: {
			searchInput
		}
	}),

	refreshOrders: () => ({
		type: actionTypes.REFRESH_ORDERS
	}),

	refreshOrdersRequest: () => ({
		type: actionTypes.REFRESH_ORDERS_REQUEST
	}),

	addFavoriteOrder: (orderNumber) => ({
		type: actionTypes.ADD_FAVORITE_ORDER,
		meta: {
			orderNumber
		}
	}),

	addFavoriteOrderRequest: (orderNumber) => ({
		type: actionTypes.ADD_FAVORITE_ORDER_REQUEST,
		meta: {
			orderNumber
		}
	}),

	addFavoriteOrderSuccess: (user) => ({
		type: actionTypes.ADD_FAVORITE_ORDER_SUCCESS,
		payload: {
			user
		}
	}),

	addFavoriteOrderFailure: (errorMessages) => ({
		type: actionTypes.ADD_FAVORITE_ORDER_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	removeFavoriteOrder: (orderNumber) => ({
		type: actionTypes.REMOVE_FAVORITE_ORDER,
		meta: {
			orderNumber
		}
	}),

	removeFavoriteOrderRequest: (orderNumber) => ({
		type: actionTypes.REMOVE_FAVORITE_ORDER_REQUEST,
		meta: {
			orderNumber
		}
	}),

	removeFavoriteOrderSuccess: (user) => ({
		type: actionTypes.REMOVE_FAVORITE_ORDER_SUCCESS,
		payload: {
			user
		}
	}),

	removeFavoriteOrderFailure: (errorMessages) => ({
		type: actionTypes.REMOVE_FAVORITE_ORDER_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	lookupOrder: (orderNumber) => ({
		type: actionTypes.LOOKUP_ORDER,
		meta: {
			orderNumber
		}
	}),

	lookupOrderRequest: (orderNumber) => ({
		type: actionTypes.LOOKUP_ORDER_REQUEST,
		meta: {
			orderNumber
		}
	}),

	lookupOrderSuccess: () => ({
		type: actionTypes.LOOKUP_ORDER_SUCCESS
	}),

	lookupOrderFailure: (errorMessages) => ({
		type: actionTypes.LOOKUP_ORDER_FAILURE,
		payload: {
			errorMessages
		},
		error: true
	}),

	showLookupOrder: () => ({
		type: actionTypes.LOOKUP_ORDER_SHOW
	}),

	hideLookupOrder: () => ({
		type: actionTypes.LOOKUP_ORDER_HIDE
	})
}
export default actionCreators
